/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {NodeDelete} from './nodeDelete'
import {Link, useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
import {Loading} from '../../../../_metronic/layout/Loading'
import {NotFound} from '../../../../_metronic/layout/NotFound'
import * as actions from '../redux/organizationActions'
import {Tree, TreeNode} from 'react-organizational-chart'
import {
  dragAndDropNode,
  findAndBuildNode,
  findAndInsertNode,
  findAndRemoveNode,
  findEmptyNode,
  findNodeAndMakeIsEditable,
  removeValidationOnNodes,
  validateAllDragAndDropTargets,
  validateDragAndDrop,
} from '../helper/functions'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {Button} from 'react-bootstrap-v5'
import {useDrag, useDrop} from 'react-dnd'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
let selectedUserOfNode: any
const ChartTree: React.FC<any> = () => {
  const dispatch = useDispatch()
  const [rolesObject, setRolesObject] = useState<any>({})
  const [RolesArray, setRolesArray] = useState<any>([])
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const [deletedRolesArray, setDeletedRolesArray] = useState<any>([])
  const [showDialogBox, setShowDialogBox] = useState(false)
  const [idForDelete, setIdForDelete] = useState('')
  const [isEdit, setIsEdit] = useState<boolean>(false)
  // const [selectedUser, setSelectedUser] = useState("");
  var selectedCurrentUser: string = ''
  // const [selectedUser, setSelectedUser] = useState<any>([])
  let usersArray: any = []

  const {listLoading, chartDetail, savedRolesDetails, userRoles, allUsers}: any =
    useSelector<RootState>(
      (state) => ({
        listLoading: state.organization.listLoading,
        chartDetail: state.organization.chartDetail,
        savedRolesDetails: state.organization.savedRolesDetails,
        allUsers: state.organization.allUsers,
        userRoles: state.organization.allUserRoles,
      }),
      shallowEqual
    ) as any
  const {id}: {id: any} = useParams()
  useEffect(() => {
    dispatch(actions.fetchRoles())
    dispatch(actions.fetchUsers())
  }, [])
  useEffect(() => {
    if (!listLoading && Object?.keys(chartDetail?.chartJSON)?.length > 0) {
      setRolesObject(chartDetail?.chartJSON)
    }
  }, [chartDetail])
  useEffect(() => {
    dispatch(actions.fetchChartRoles({id}))
  }, [id])

  useEffect(() => {
    if (savedRolesDetails?.chartID === id) {
      if (RolesArray.length > 0) setRolesArray([])
      if (deletedRolesArray.length > 0) setDeletedRolesArray([])
      setRolesObject({})
      dispatch(actions.fetchChartRoles({id}))
    }
  }, [savedRolesDetails])
  const crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: `Groups`, url: `/groups`},
    {name: `${chartDetail?.data?.name}`, url: `/groups`},
    {name: `Hierarchy`, url: ``},
  ]
  const saveRolesToDB = async () => {
    if (RolesArray.length > 0) dispatch(actions.saveChartRoles({id}, {roles: RolesArray}))

    if (deletedRolesArray.length > 0)
      dispatch(actions.deleteChartRoles({id}, {roles: deletedRolesArray}))
  }
  const makeTempChild = async (nodeId: string) => {
    const tempRolesObject = JSON.parse(JSON.stringify(rolesObject))
    await findAndInsertNode(tempRolesObject, nodeId)
    setRolesObject(tempRolesObject)
  }
  const buildTreeChild = async (Node: any) => {
    const tempRolesObject = JSON.parse(JSON.stringify(rolesObject))
    await findAndBuildNode(tempRolesObject, Node)
    setRolesObject(tempRolesObject)
  }
  const removeNode = async (NodeId: any) => {
    const tempRolesObject = JSON.parse(JSON.stringify(rolesObject))
    const updatedJSON = await findAndRemoveNode(tempRolesObject, NodeId)
    setRolesObject(updatedJSON)
    const filterNodes = RolesArray.filter((role: any) => role.nodeId != NodeId)

    if (filterNodes.length < RolesArray.length) {
      // dispatch(actions.saveChartRoles({id}, {roles: filterNodes}))
      setRolesArray(filterNodes)
    } else {
      setDeletedRolesArray((v: any) => v.concat({nodeId: NodeId}))
    }
    if (filterNodes.length < RolesArray.length)
      dispatch(actions.saveChartRoles({id}, {roles: filterNodes}))

    // if (deletedRolesArray.length > 0)
    dispatch(actions.deleteChartRoles({id}, {roles: [{nodeId: NodeId}]}))
  }
  const isNodeEditable = async (NodeId: any, status: boolean) => {
    const tempRolesObject = JSON.parse(JSON.stringify(rolesObject))
    await findNodeAndMakeIsEditable(tempRolesObject, NodeId, status)
    setRolesObject(tempRolesObject)
  }
  const extractChildren = useCallback((node: any, result: any) => {
    if (!result.find((nod: any) => nod._id === node._id)) {
      result.push(node)
    }
    if (node.childrens && node.childrens.length > 0) {
      for (let child of node.childrens) {
        extractChildren(child, result)
      }
    }
  }, [])

  const StyledNode = ({children}: any) => {
    extractChildren(children, usersArray)
    const isValidTarget: any = useRef(null)
    const [{isDragging, didDrop, item}, drag] = useDrag(() => ({
      type: 'role',
      item: {
        nodeId: children?.nodeId,
        name: children?.name,
        type: children?.type,
        groupHeadName: children?.groupHeadName,
        _id: children?._id,
        role: children.role,
        user: children.user,
        parent: children?.parent,
        roleName: children.roleName,
      },
      collect: (monitor) => ({
        item: monitor.getItem(),
        isDragging: !!monitor.isDragging(),
        didDrop: monitor.didDrop(),
      }),
    }))

    const [{isOver, getItem}, drop] = useDrop(() => ({
      accept: 'role',
      drop: (item) => {
        setTimeout(() => {
          submitNodes(item)
        }, 100)
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        getItem: monitor.getItem(),
      }),
      hover: () => {
        itemRef.current = children
      },
    }))

    useEffect(() => {
      if (rolesObject) {
        const dragButtons = document.querySelectorAll('.dragging-button')
        dragButtons.forEach((btn) => {
          btn.addEventListener('dragend', function handleClick(event) {
            submitNodes(null)
          })
        })
      }
    }, [rolesObject])

    useEffect(() => {
      if (isOver) {
        validateTarget(getItem, itemRef.current)
      }
    }, [isOver])

    useEffect(() => {
      if (isDragging) {
        validateAllTarget(getItem)
      }
    }, [isDragging])

    const validateTarget = async (child: any, parent: any) => {
      let permission = {valid: true}
      await validateDragAndDrop(rolesObject, child, parent, permission)
      isValidTarget.current = permission.valid
    }

    const validateAllTarget = async (pickedNode: any) => {
      const tempRolesObject = JSON.parse(JSON.stringify(rolesObject))
      await validateAllDragAndDropTargets(tempRolesObject, tempRolesObject, pickedNode)
      setRolesObject(tempRolesObject)
    }

    const itemRef: any = useRef(null)
    const submitNodes = async (item: any) => {
      const tempRolesObject = JSON.parse(JSON.stringify(rolesObject))
      if (!isValidTarget.current) {
        await removeValidationOnNodes(tempRolesObject)
        setRolesObject(tempRolesObject)
      }
      if (isValidTarget.current && item) {
        await dragAndDropNode(tempRolesObject, item, itemRef.current)
        await removeValidationOnNodes(tempRolesObject)
        setRolesObject(tempRolesObject)

        const Node = {
          ...item,
          parent: itemRef.current?.nodeId,
        }
        const filterNodes = RolesArray.filter((role: any) => role.nodeId != item?.nodeId)
        filterNodes.push(Node)
        setRolesArray(filterNodes)
        if (filterNodes.length > 0) dispatch(actions.saveChartRoles({id}, {roles: filterNodes}))
      }
    }

    const deleteNode = () => {
      setIdForDelete(children?.nodeId)
      setShowDialogBox(true)
    }
    return (
      <>
        <NodeDelete
          show={showDialogBox}
          handleClose={() => {
            setShowDialogBox(false)
            removeNode(idForDelete)
          }}
          setShowDialogBox={setShowDialogBox}
        />
        <div
          ref={drop}
          className={`card shadow-sm p-1 rounded ${
            children?.validTarget === true
              ? 'tree-node-valid-target'
              : children?.validTarget === false
              ? 'tree-node-inValid-target'
              : ''
          }`}
          style={{
            width: '18rem',
            display: 'inline-block',
            border: isDragging ? '1px solid pink' : '0px',
          }}
        >
          <div className='card-body d-flex flex-center flex-column p-9 '>
            <div className='fw-bold text-gray-400 mb-3'>
              Type: {children.type === 'role' ? 'Resource' : 'Group'}
            </div>
            {children.type === 'group' && children?.groupHeadName && (
              <div className='fw-bold text-gray-400 mb-3'>
                Head: {children?.groupHeadName ?? ''}
              </div>
            )}
            {children?.role?.name ? (
              <div className='fw-bold text-gray-400 mb-3'>Role: {children?.role?.name}</div>
            ) : (
              <div className='fw-bold text-gray-400 mb-3'>Role: {children?.role}</div>
            )}
            {children?.user?.firstName ? (
              <div className='fs-4 text-gray-800 fw-bolder mb-3'>
                {children?.user.firstName + ' ' + children?.user.lastName}
              </div>
            ) : (
              <div className='fs-4 text-gray-800 fw-bolder mb-3'>{children?.name}</div>
            )}
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                title='Create Node'
                onClick={async () => {
                  setIsUpdate(false)
                  let permission = {valid: true}
                  await findEmptyNode(rolesObject, permission)
                  if (permission.valid) {
                    makeTempChild(children?.nodeId)
                  } else {
                    alert('Please Save or remove already editable node')
                  }
                }}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/general/add.svg' className='svg-icon-3' />
              </button>
              <button
                title='Edit Node'
                onClick={async () => {
                  setIsUpdate(true)
                  let permission = {valid: true}

                  selectedUserOfNode = children?.user?._id

                  await findEmptyNode(rolesObject, permission)
                  if (permission.valid) {
                    isNodeEditable(children?.nodeId, true)
                  } else {
                    alert('Please Save or remove already editable node')
                  }
                }}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </button>
              <button
                title='Delete Node'
                onClick={() => {
                  setIsUpdate(false)
                  deleteNode()
                }}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
              </button>
              <button
                ref={drag}
                title='Drag Node'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 dragging-button'
              >
                <KTSVG path='/media/icons/duotune/general/gen022.svg' className='svg-icon-3' />
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
  const EmptyStyledNode = ({children}: any) => {
    const [getUserRoles, setUserRoles] = useState<any>()
    const [getAllUsers, setAllUsers] = useState<any>()
    useEffect(() => {
      ReStructureUsersRoles()
    }, [userRoles])
    useEffect(() => {
      ReStructureAllUsers()
    }, [allUsers])
    const ReStructureUsersRoles = () => {
      const StructuredRoles: any = []
      if (userRoles.length > 0) {
        userRoles.map((role: any) => {
          StructuredRoles.push({
            name: role.name,
            value: role._id,
          })
        })
      }
      setUserRoles(StructuredRoles)
    }
    const ReStructureAllUsers = () => {
      const StructuredUsers: any = []
      if (allUsers.length > 0) {
        allUsers.map((user: any) => {
          StructuredUsers.push({
            name: user.firstName + ' ' + user.lastName,
            value: user._id,
          })
        })
      }
      setAllUsers(StructuredUsers)
    }
    const inits: any = {
      name: children?.name ? children?.name : '',
      type: children?.type ? children?.type : '',
      groupHeadName: children?.groupHeadName ? children?.groupHeadName : '',
      parent: children?.parent ? children?.parent : '',
      role: children?.role?._id ? children?.role?._id : children?.role ? children?.role : '',
      user: children?.user?._id ? children?.user?._id : children?.user ? children?.user : '',
    }
    const userValidationSchema = {
      name: Yup.string().required('Name is required').label('name'),
      // .min(2, 'Name must contain more than 1 digits'),
      type: Yup.string().required('Type is required').label('Type'),
      groupHeadName: Yup.string().when('type', {
        is: (ty: any) => ty == 'group',
        then: Yup.string().required('Group Name is required'),
      }),
      parent: Yup.string(),
      role: Yup.string()
        .when('type', {
          is: (ty: any) => ty == 'group',
          then: Yup.string().required('Group Head Role is required'),
        })
        .required('User Role is required')
        .label('User Role'),
      user: Yup.string()
        .when('type', {
          is: (ty: any) => ty == 'group',
          then: Yup.string().required('Group Head is required'),
        })
        .required('User is required')
        .label('User'),
    }
    const createAppSchema = Yup.object().shape(userValidationSchema)

    return (
      <div
        className='card shadow-sm p-1 bg-white rounded'
        style={{width: '18rem', display: 'inline-block'}}
      >
        <div className='card-body d-flex flex-center flex-column'>
          <Formik
            validationSchema={createAppSchema}
            initialValues={inits}
            enableReinitialize
            onSubmit={(values: any) => {
              selectedUserOfNode = ''
              const filterNodes = RolesArray.filter((role: any) => role.nodeId != children?.nodeId)
              usersArray = filterNodes
              const Node = {
                name: values.name,
                type: values.type,
                groupHeadName: values.groupHeadName,
                role: values.role,
                user: values.user,
                parent: children?.parent,
                nodeId: children?.nodeId,
                _id: children._id,
              }
              buildTreeChild(Node)
              filterNodes.push(Node)
              setRolesArray(filterNodes)

              if (filterNodes.length > 0)
                dispatch(actions.saveChartRoles({id}, {roles: filterNodes}))
            }}
          >
            {({setFieldValue, values, errors}) => (
              <Form className='form' noValidate id='kt_modal_create_app_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <h4 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-5 mb-1'>{`${
                      isUpdate ? 'Update' : 'Create'
                    } ${
                      values.type === 'role' ? 'Resource' : values.type === 'group' ? 'Group' : ''
                    }`}</span>
                  </h4>
                  <div className='mb-3'>
                    <label className='d-flex align-items-center fs-7 fw-bold mb-2'>
                      <span className='required'> Select Type</span>
                    </label>
                    <div>
                      <select
                        className='form-select form-select-solid'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        name='type'
                        onChange={(e) => setFieldValue('type', e.target.value)}
                        value={values.type}
                      >
                        <option value=''>Select</option>
                        <option value='group'>Group</option>
                        <option value='role'>Resource</option>
                      </select>
                      <div className='text-danger text-start'>
                        <ErrorMessage name='type' />
                      </div>
                    </div>
                  </div>
                  {values.type === 'group' && (
                    <div className='w-100'>
                      <div className='fv-row mb-5'>
                        <label className='d-flex align-items-center fs-7 fw-bold mb-2'>
                          <span className='required'>Group Name</span>
                        </label>
                        <Field
                          type='text'
                          name='groupHeadName'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Group Name'
                          style={{minHeight: '41px', maxHeight: '41px'}}
                        />
                        <div className='text-danger text-start'>
                          <ErrorMessage name='groupHeadName' />
                        </div>
                      </div>
                    </div>
                  )}
                  {values.type === 'group' ? (
                    <>
                      <div className='w-100'>
                        <div className='fv-row mb-5'>
                          <label className='d-flex align-items-center fs-7 fw-bold mb-2'>
                            <span className='required'>Group Head Role</span>
                          </label>
                          <Field
                            type='text'
                            name='role'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='Group Head Role'
                            style={{minHeight: '41px', maxHeight: '41px'}}
                          />
                          <div className='text-danger text-start'>
                            <ErrorMessage name='role' />
                          </div>
                        </div>
                      </div>
                      <div className='mb-3'>
                        <label className='d-flex align-items-center fs-7 fw-bold mb-2'>
                          <span className='required'>Select Group Head</span>
                        </label>
                        <div>
                          <select
                            className='form-select form-select-solid'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            name='users'
                            onChange={(e) => {
                              setFieldValue('user', e.target.value)
                              setFieldValue(
                                'name',
                                e.target[e.target.selectedIndex]
                                  .getAttribute('data-order')
                                  ?.toString()
                              )
                            }}
                            value={values.user}
                          >
                            <option value=''>Select User</option>
                            {getAllUsers
                              ?.filter(
                                (user: any) =>
                                  !usersArray.some(
                                    (selUser: any) =>
                                      selUser?.user?._id === user?.value &&
                                      selUser?.user?._id !== selectedUserOfNode
                                  )
                              )
                              ?.map((user: any, index: number) => (
                                <option
                                  key={index}
                                  value={`${user?.value}`}
                                  data-order={user?.name}
                                >
                                  {user?.name}
                                </option>
                              ))}
                          </select>
                          <div className='text-danger text-start'>
                            <ErrorMessage name='user' />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='w-100'>
                        <div className='fv-row mb-5'>
                          <label className='d-flex align-items-center fs-7 fw-bold mb-2'>
                            <span className='required'>User Role</span>
                          </label>
                          <Field
                            type='text'
                            name='role'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='User Role'
                            style={{minHeight: '41px', maxHeight: '41px'}}
                          />
                          <div className='text-danger text-start'>
                            <ErrorMessage name='role' />
                          </div>
                        </div>
                      </div>
                      <div className='mb-3'>
                        <label className='d-flex align-items-center fs-7 fw-bold mb-2'>
                          <span className='required'> Select User</span>
                        </label>
                        <div>
                          <select
                            className='form-select form-select-solid'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            name='users'
                            onChange={(e) => {
                              setFieldValue('user', e.target.value)
                              setFieldValue(
                                'name',
                                e.target[e.target.selectedIndex]
                                  .getAttribute('data-order')
                                  ?.toString()
                              )
                            }}
                            value={values.user}
                          >
                            <option value=''>Select User</option>

                            {getAllUsers
                              ?.filter(
                                (user: any) =>
                                  !usersArray.some(
                                    (selUser: any) =>
                                      selUser?.user?._id === user?.value &&
                                      selUser?.user?._id !== selectedUserOfNode
                                  )
                              )

                              ?.map((user: any, index: number) => (
                                <option
                                  key={index}
                                  value={`${user?.value}`}
                                  data-order={user?.name}
                                >
                                  {user?.name}
                                </option>
                              ))}
                          </select>
                          <div className='text-danger text-start'>
                            <ErrorMessage name='user' />
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className='d-flex justify-content-end'>
                    <button
                      className='btn btn-sm fw-bolder me-3 fs-7 cancel_button'
                      type='button'
                      title={children?.edit ? 'Cancel' : 'Remove'}
                      onClick={() => {
                        selectedUserOfNode = ''
                        children?.edit
                          ? isNodeEditable(children?.nodeId, false)
                          : removeNode(children?.nodeId)
                      }}
                    >
                      {children?.edit ? 'Cancel' : 'Remove'}
                    </button>
                    <button
                      className='btn btn-lg btn-primary btn-blue fs-7'
                      type='submit'
                      title='Done'
                    >
                      Done
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
  const DrawNode = (nodes: any[]) => {
    return nodes?.map((node: any, index: number) =>
      node?.childrens?.length > 0 ? (
        <TreeNode
          key={index}
          label={
            node?.edit === true || node?.name === '' ? (
              <EmptyStyledNode>
                {{
                  ...node,
                }}
              </EmptyStyledNode>
            ) : (
              <StyledNode>
                {{
                  ...node,
                }}
              </StyledNode>
            )
          }
        >
          {DrawNode(node?.childrens)}
        </TreeNode>
      ) : (
        <TreeNode
          key={index}
          label={
            node?.edit === true || node?.name === '' ? (
              <EmptyStyledNode>
                {{
                  ...node,
                }}
              </EmptyStyledNode>
            ) : (
              <StyledNode>
                {{
                  ...node,
                }}
              </StyledNode>
            )
          }
        />
      )
    )
  }
  const StyledTree = ({RolesObject}: {RolesObject: any}) => (
    <Tree
      lineWidth={'1px'}
      lineColor={'grey'}
      lineBorderRadius={'15px'}
      label={
        RolesObject?.edit === true || RolesObject?.name === '' ? (
          <EmptyStyledNode>
            {{
              ...RolesObject,
            }}
          </EmptyStyledNode>
        ) : (
          <StyledNode>
            {{
              ...RolesObject,
            }}
          </StyledNode>
        )
      }
    >
      {DrawNode(RolesObject?.childrens)}
    </Tree>
  )

  return (
    <>
      <div className={`card legend-card-main rounded-0`}>
        {!listLoading && (
          <div className='px-6 px-sm-12'>
            <div className='row'>
              <div className='col-sm-6'>
                <h2 className='fw-500 fs-2 m-0 py-5'>{`${chartDetail?.data?.name} Group`}</h2>
              </div>
            </div>
          </div>
        )}
        <div className='d-flex justify-content-end align-items-center bg-lighter flex-sm-nowrap flex-wrap'>
          {crumbs && <Breadcrumbs crumbs={crumbs} />}{' '}
          <Button
            className='bg-lighter fs-7 btn btn-default btn-sm p-0 me-5 me-sm-10'
            variant='default'
            size='sm'
            disabled={
              RolesArray?.length > 0 ||
              deletedRolesArray.length > 0 ||
              Object.keys(rolesObject).length === 0
                ? false
                : true
            }
            onClick={() => {
              Object.keys(rolesObject).length > 0 || deletedRolesArray.length > 0
                ? saveRolesToDB()
                : makeTempChild('root')
            }}
          >
            <span
              className='btn btn-sm bg-transparent text-blue pe-5 d-flex align-items-center'
              style={{whiteSpace: 'nowrap'}}
              title={
                Object.keys(rolesObject).length > 0 || deletedRolesArray.length > 0
                  ? 'Save Group'
                  : 'Create Hierarchy'
              }
            >
              <KTSVG path='' className='svg-icon-3' />
              <i className='fa fa-plus-circle link_button' aria-hidden='true'></i>
              <span className='link_button'>
                {Object.keys(rolesObject).length > 0 || deletedRolesArray.length > 0
                  ? 'Hierarchy Saved'
                  : 'Create Hierarchy'}
              </span>
            </span>
          </Button>
        </div>
        <div className=''>
          <div className='h-100 card-body d-flex flex-column py-3 px-10'>
            {!listLoading && Object?.keys(rolesObject)?.length > 0 ? (
              <div className='pb-20 pt-5' style={{overflow: 'hidden', overflowX: 'auto'}}>
                <StyledTree RolesObject={rolesObject} />
              </div>
            ) : !listLoading && Object?.keys(rolesObject)?.length <= 0 ? (
              <NotFound />
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export {ChartTree}
