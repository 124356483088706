import React, {useEffect, useState} from 'react'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import * as actions from '../redux/AppointmentsAction'
import DatePicker from 'react-datepicker'
import TimeSlots from './TimeSlots'
import {
  AppointmentBreaks,
  AppointmentTimeInterval,
  ICreateAccount,
  Props,
  Venue,
  editableWeekDays,
  getLocationData,
  inits,
  releaseNotesValidationSchema,
} from '../helpers'
import {
  getAppointmentsLocations,
  getDateRangeOfEvent,
  getDiffInDays,
  getEventAvailabilities,
  getSelectedDatesRange,
  getWeekDaysFromRes,
} from '../helpers/utils'
import moment from 'moment'
import '../helpers/styles/appointmentForm.css'
import {showUserNames, showUserTeamNames} from '../../workspace/WorkspaceUIHelper'
import {AssigneesList} from '../../../Common/assignee-teams/Assignee&TeamList'
import axios from 'axios'
import {LIST_TEAMS, LIST_USERS} from '../../avengersInitiatives/components/avengersInitiativesAdd'
import _ from 'lodash'
import './popover.css'

export const AppointmentsAdd: React.FC<Props> = ({id, onHide}) => {
  const dispatch = useDispatch()
  const [initValues, setInitValues] = useState<ICreateAccount>(inits)
  const [appointmentsLocations, setAppointmentsLocations] = useState<Venue[]>([])
  const [popupTitle, setPopupTitle] = useState('')
  const [assigneeAndTeams, setassigneeAndTeams]: any = useState()
  const [usersId, setUsersId] = useState([])
  const [teamsId, setTeamsId] = useState([])
  const [assigneesId, setAssigneesId] = useState([])
  const [assigneeTeamsId, setAssigneeTeamsId] = useState([])
  const [showAssigneesDialog, setShowAssigneesDialog] = useState(false)

  const getAssigneesData = async () => {
    const usersData: any = await axios.get(LIST_USERS)
    const teams = await axios.get(LIST_TEAMS)

    setassigneeAndTeams({
      assignee: usersData?.data,
      teams: teams?.data?.data,
    })
  }

  useEffect(() => {
    getAssigneesData()
  }, [])

  useEffect(() => {
    setAssigneesId(usersId)
    setAssigneeTeamsId(teamsId)
  }, [usersId, teamsId])

  const {appointmentDetail, listLoading, settings}: any = useSelector<RootState>(
    (state) => ({
      userInfo: state.auth.user,
      appointmentDetail: state.appointments.AppointmentsDetail,
      listLoading: state.appointments.listLoading,
      settings: state.settings.entities,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (settings) {
      setAppointmentsLocations(getAppointmentsLocations(settings))
    }
  }, [settings])

  useEffect(() => {
    if (appointmentDetail && id && !listLoading && appointmentsLocations?.length) {
      const {
        name,
        description,
        status,
        venue,
        venueData,
        duration,
        availability,
        dateRangeOption,
        dateRange,
        hasCustomInterval,
        beforeBreakTime,
        afterBreakTime,
        assignee,
      } = appointmentDetail

      setInitValues({
        ...initValues,
        name,
        description,
        status,
        venue,
        venueData,
        duration: hasCustomInterval ? 'custom' : '' + duration,
        customDuration: hasCustomInterval ? duration : 0,
        numOfDay: getDiffInDays(dateRangeOption, dateRange),
        dateRangeOption,
        selectedDates: getSelectedDatesRange(dateRangeOption, dateRange),
        daysOfWeek: {
          ...editableWeekDays,
          ...getWeekDaysFromRes(availability, initValues),
        },
        isBeforeBreakTimeEnabled: beforeBreakTime > 0,
        isAfterBreakTimeEnabled: afterBreakTime > 0,
        beforeBreakTime: '' + beforeBreakTime,
        afterBreakTime: '' + afterBreakTime,
      })
      setUsersId(assignee)
    }
    return () => {
      if (appointmentDetail && id) dispatch(actions.clearAppointmentsDetailData())
    }
  }, [appointmentDetail, appointmentsLocations, listLoading, id])

  useEffect(() => {
    if (id) {
      dispatch(actions.fetchAppointmentsById(id))
    }
  }, [])

  const crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Appointments', url: `/appointments`},
    {
      name: id ? 'Edit Appointment' : 'Create Appointment',
      url: `/appointments/add`,
    },
  ]

  const onSubmit = (values: ICreateAccount) => {
    const availability = getEventAvailabilities(values)
    const dateRange = getDateRangeOfEvent(values)
    const {
      name,
      description,
      status,
      venue,
      venueData,
      duration,
      dateRangeOption,
      customDuration,
      isBeforeBreakTimeEnabled,
      beforeBreakTime,
      isAfterBreakTimeEnabled,
      afterBreakTime,
    } = values
    const submitAbleValues = {
      name,
      description,
      status,
      venue,
      venueData,
      dateRange,
      availability,
      duration: duration === 'custom' ? customDuration : +duration,
      dateRangeOption,
      hasCustomInterval: duration === 'custom',
      beforeBreakTime: isBeforeBreakTimeEnabled ? +beforeBreakTime : 0,
      afterBreakTime: isAfterBreakTimeEnabled ? +afterBreakTime : 0,
      assignee: usersId,
    }

    if (id) {
      dispatch(actions.UpdateAppointments(submitAbleValues, id))
      onHide()
    } else {
      dispatch(actions.createAppointments(submitAbleValues))
      onHide()
    }
  }

  return (
    <>
      <AssigneesList
        show={showAssigneesDialog}
        handleClose={() => {
          setShowAssigneesDialog(false)
        }}
        assignee={assigneeAndTeams?.assignee}
        teams={assigneeAndTeams?.teams}
        calledBy={'appointments'}
        setAssigneesId={setUsersId}
        assigneesId={usersId}
        teamsId={teamsId}
        setTeamsId={setTeamsId}
        id={id}
        title={popupTitle}
      />
      <div className='page-content mh-80'>
        <div className='px-12'>
          <div className='row'>
            <div className='col-sm-6'>
              <h2 className='fw-500 fs-2 m-0 py-5'>
                {id ? 'Edit Appointment' : 'Create Appointment'}
              </h2>
            </div>
          </div>
        </div>
        <Breadcrumbs crumbs={crumbs} />
        <div className='page-body py-lg-10 px-lg-10 overflow_height_scroll'>
          <div
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_app_stepper'
          >
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <Formik
                enableReinitialize={true}
                validationSchema={releaseNotesValidationSchema}
                initialValues={initValues}
                onSubmit={onSubmit}
              >
                {({values, setFieldValue}) => (
                  <Form className='form' noValidate id='kt_modal_create_app_form'>
                    <div className='current' data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <div className='fv-row mb-5'>
                          <label className='fs-6 fw-bold form-label mb-2 required'>
                            <span>Name</span>
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-lg  form-control-solid'
                            name='name'
                            placeholder='Name'
                          />
                          <div className='text-danger'>
                            <ErrorMessage name='name' />
                          </div>
                        </div>
                        <div className='fv-row mb-5'>
                          <label className='fs-6 fw-bold form-label mb-2'>
                            <span>Description</span>
                          </label>
                          <Field
                            component='textarea'
                            className='form-control form-control-lg  form-control-solid'
                            name='description'
                            placeholder='Description'
                          />
                          <div className='text-danger'>
                            <ErrorMessage name='description' />
                          </div>
                        </div>

                        <div className='row fv-row'>
                          <div className='col-12 col-sm-6 py-2 py-sm-0'>
                            <label className='fs-6 fw-bold form-label mb-2 required'>
                              <span>Status</span>
                            </label>
                            <div className='row fv-row'>
                              <div className='col-2 col-sm-4 py-2 py-sm-0'>
                                <label>
                                  <Field
                                    type='radio'
                                    name='status'
                                    value='active'
                                    className=' form-check-input smaller-form-input me-2'
                                  />
                                  Active
                                </label>
                              </div>
                              <div className='col-2 col-sm-6 py-2 py-sm-0'>
                                <label>
                                  <Field
                                    type='radio'
                                    name='status'
                                    value='inactive'
                                    className='form-check-input smaller-form-input me-2'
                                  />
                                  Inactive
                                </label>
                              </div>
                            </div>
                            <div className='text-danger'>
                              <ErrorMessage name='status' />
                            </div>
                          </div>
                          <div className='col-12 col-sm-6 py-2 py-sm-0'>
                            <label className='fs-6 fw-bold form-label mb-2 required'>
                              <span>Venue</span>
                            </label>
                            <Field
                              as='select'
                              name='venue'
                              className='form-select form-select-lg form-select-solid'
                            >
                              {appointmentsLocations.map((location, index) => (
                                <option key={index} value={location?.value}>
                                  {location?.name}
                                </option>
                              ))}
                            </Field>
                            <div className='text-danger'>
                              <ErrorMessage name='venue' />
                            </div>
                          </div>
                        </div>

                        <div className='row fv-row pt-4'>
                          <div className='col-12 col-sm-6 py-2 py-sm-0'>
                            <label className='fs-6 fw-bold form-label mb-2 required'>
                              <span>{getLocationData[values?.venue] ?? 'Venue Related Data'}</span>
                            </label>
                            <Field
                              type='text'
                              className='form-control form-control-lg  form-control-solid'
                              name='venueData'
                              placeholder={getLocationData[values?.venue] ?? 'Venue Related Data'}
                            />
                            <div className='text-danger'>
                              <ErrorMessage name='venueData' />
                            </div>
                          </div>
                          <div className='col-12 col-sm-6 py-2 py-sm-0'>
                            <label className='fs-6 fw-bold form-label mb-2 required'>
                              <span>Time Interval</span>
                            </label>
                            <Field
                              as='select'
                              name='duration'
                              className='form-select form-select-lg form-select-solid'
                            >
                              {AppointmentTimeInterval?.map((location: Venue, index: number) => (
                                <option key={index} value={location?.value}>
                                  {location?.name}
                                </option>
                              ))}
                            </Field>
                            <div className='text-danger'>
                              <ErrorMessage name='duration' />
                            </div>
                          </div>
                        </div>

                        <div className='row fv-row pt-4'>
                          <div className='col-12 col-sm-6 py-2 py-sm-0 '>
                            <label className='fs-6 fw-bold form-label '>
                              <span className='required'>Valid Upto</span>
                              <div className='fs-7 text-light-grey-pagination'>
                                Set a range of dates when you can accept meetings.
                              </div>
                            </label>

                            <div className=''>
                              <div className='form-check'>
                                <input
                                  type='radio'
                                  name='radioBtn'
                                  id='option1'
                                  className='form-check-input smaller-form-input'
                                  value='option1'
                                  checked={values.dateRangeOption === 'option1'}
                                  onChange={(event) => {
                                    setFieldValue('dateRangeOption', event.target.value)
                                    setFieldValue('selectedDates', [null, null])
                                    setFieldValue(
                                      'isUserSelectedDateRange',
                                      !values?.isUserSelectedDateRange
                                    )
                                  }}
                                />
                                <label htmlFor='option1' className='form-check-label mb-2 mb-sm-0'>
                                  Days in future
                                </label>
                              </div>
                              <div className='mt-4'>
                                {values.dateRangeOption === 'option1' && (
                                  <div>
                                    <Field
                                      placeholder='Days'
                                      min='1'
                                      type='number'
                                      className='form-control form-control-lg  form-control-solid'
                                      name='numOfDay'
                                      onWheel={(e: any) => e.target.blur()}
                                    />
                                  </div>
                                )}
                                <div className='text-danger'>
                                  <ErrorMessage name='numOfDay' />
                                </div>
                              </div>
                              <div className='row fv-row mt-3'>
                                <div className='col-12 col-sm-6 py-2 py-sm-0'>
                                  <input
                                    type='radio'
                                    name='radioBtn'
                                    id='option2'
                                    className='form-check-input smaller-form-input'
                                    value='option2'
                                    checked={values.dateRangeOption === 'option2'}
                                    onChange={(event) => {
                                      setFieldValue('dateRangeOption', event.target.value)
                                      setFieldValue('numOfDay', 1)
                                    }}
                                  />
                                  <label
                                    htmlFor='option2'
                                    className='form-check-label ms-3 mb-sm-0'
                                  >
                                    Select on Calender
                                  </label>
                                  {values.dateRangeOption === 'option2' && (
                                    <div className='text-danger mb-2'>
                                      <ErrorMessage name='selectedDates[0]' />
                                    </div>
                                  )}
                                </div>
                                <div className='row' style={{position: 'relative'}}>
                                  {/* {values.dateRangeOption === 'option2' && (
                                    <>
                                      {values?.isUserSelectedDateRange && (
                                        <div
                                          onClick={() =>
                                            setFieldValue(
                                              'isUserSelectedDateRange',
                                              !values?.isUserSelectedDateRange
                                            )
                                          }
                                        >
                                          <Field
                                            placeholder='Date Range'
                                            type='text'
                                            className='form-control form-control-lg  form-control-solid'
                                            value={`Selected Range: ${moment(
                                              values.selectedDates[0]
                                            ).format('DD MMM YYYY')} - ${moment(
                                              values.selectedDates[1]
                                            ).format('DD MMM YYYY')}`}
                                            disabled
                                          />
                                        </div>
                                      )}
                                    </>
                                  )} */}
                                  {values.dateRangeOption === 'option2' && (
                                    // <div className='popover-container-date'>
                                    <div id='datepicker-portal' className='show col-12 ms-2'>
                                      <DatePicker
                                        portalId='datepicker-portal'
                                        // className='myDatePicker'
                                        className='form-control form-control-lg  form-control-solid mb-2'
                                        selected={values.selectedDates[0]}
                                        onChange={(dates: any) => {
                                          setFieldValue('selectedDates', dates)
                                          !!dates[1] &&
                                            setFieldValue(
                                              'isUserSelectedDateRange',
                                              !values?.isUserSelectedDateRange
                                            )
                                        }}
                                        placeholderText='Select date range'
                                        minDate={new Date()}
                                        startDate={values.selectedDates[0]}
                                        endDate={values.selectedDates[1]}
                                        selectsRange
                                        monthsShown={2}
                                        popperPlacement='bottom-start'
                                      />
                                    </div>
                                    // </div>
                                  )}
                                </div>
                              </div>

                              <div className='form-check mt-3'>
                                <input
                                  type='radio'
                                  name='radioBtn'
                                  id='option3'
                                  className='form-check-input smaller-form-input'
                                  value='option3'
                                  checked={values.dateRangeOption === 'option3'}
                                  onChange={(event) => {
                                    setFieldValue('dateRangeOption', event.target.value)
                                    setFieldValue('numOfDay', 0)
                                    setFieldValue('selectedDates', [null, null])
                                    setFieldValue(
                                      'isUserSelectedDateRange',
                                      !values?.isUserSelectedDateRange
                                    )
                                  }}
                                />
                                <label htmlFor='option3' className='form-check-label mb-2 mb-sm-0'>
                                  Indefinitely in future
                                </label>
                                {values.dateRangeOption === 'option3' && <div></div>}
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-sm-6 py-2 py-sm-0'>
                            {values?.duration === 'custom' && (
                              <>
                                <label className='fs-6 fw-bold form-label mb-2 required'>
                                  <span>Custom Time Interval (min)</span>
                                </label>
                                <Field
                                  type='number'
                                  className='form-control form-control-lg  form-control-solid'
                                  name='customDuration'
                                  min='15'
                                  placeholder='Custom Duration'
                                  onWheel={(e: any) => e.target.blur()}
                                />
                                <div className='text-danger'>
                                  <ErrorMessage name='customDuration' />
                                </div>
                              </>
                            )}
                            <div
                              className='col-12 fv-row'
                              style={{marginTop: values?.duration === 'custom' ? 4 : 0}}
                            >
                              <label className='fs-6 fw-bold mb-2 d-flex'>
                                <span>Assignee</span>{' '}
                                {assigneesId?.length + assigneeTeamsId?.length > 0 ? (
                                  <span className='text-style-count ms-2'>
                                    {assigneesId?.length + assigneeTeamsId?.length}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </label>
                              <div className='row fv-row'>
                                <div className='col-12 multisel'>
                                  <div className='d-flex justify-content-center align-items-center bg-lightest'>
                                    <div
                                      style={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: '1',
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {assigneesId?.length > 0 && (
                                        <span
                                          className='d-inline-flex align-items-center text-blue border border-light-blue border-runded-4 px-2 fs-8 m-2'
                                          style={{borderRadius: '4px'}}
                                          title={showUserNames(
                                            assigneesId,
                                            assigneeAndTeams?.assignee
                                          )}
                                        >
                                          Users
                                          <span className='badge ms-2 text-danger py-1 px-2 fs-9 m-1'>
                                            {assigneesId?.length}
                                          </span>
                                        </span>
                                      )}
                                      {assigneeTeamsId?.length > 0 && (
                                        <span
                                          className='d-inline-flex align-items-center text-blue border border-light-blue border-runded-4 px-2 fs-8 m-2'
                                          style={{borderRadius: '4px'}}
                                          title={showUserTeamNames(
                                            assigneeTeamsId,
                                            assigneeAndTeams?.teams
                                          )}
                                        >
                                          Teams
                                          <span className='badge ms-2 text-danger py-1 px-2 fs-9 m-1'>
                                            {assigneeTeamsId?.length}
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                    <a
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_create_app'
                                      id='kt_modal_assignees_list_app'
                                      onClick={() => {
                                        setUsersId(assigneesId)
                                        setTeamsId(assigneeTeamsId)
                                        setPopupTitle('Assignee')
                                        setShowAssigneesDialog(true)
                                      }}
                                    >
                                      <i
                                        className='fa fa-plus-circle text-blue'
                                        aria-hidden='true'
                                      ></i>
                                      <button
                                        title='Add'
                                        type='button'
                                        className='btn btn-sm  bg-transparent text-primary fw-500 p-2 '
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_create_app'
                                        id='kt_modal_assignees_list_app'
                                      >
                                        add
                                      </button>
                                    </a>
                                  </div>
                                  <div className='text-danger'>
                                    <ErrorMessage name='assignee' />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='pt-4 mt-4'>
                          <label className='fs-6 fw-bold form-label mb-2 required'>
                            <span>Set Availability</span>
                          </label>
                          <div className='text-danger'>
                            <ErrorMessage name='daysOfWeek' />
                          </div>
                          <div className='container'>
                            {Object.keys(values.daysOfWeek).map((key, index) => {
                              const day = values.daysOfWeek[key]

                              return (
                                <div className='row my-3 align-items-center' key={index}>
                                  <div className='col-sm-6 form-check'>
                                    <input
                                      className='form-check-input smaller-form-input'
                                      type='checkbox'
                                      id={day.value}
                                      checked={!!day.isSelected}
                                      onChange={() => {
                                        day.isSelected = !!!day.isSelected
                                        setFieldValue('daysOfWeek', {
                                          ...values.daysOfWeek,
                                          [key]: day,
                                        })
                                      }}
                                    />
                                    <label className='form-check-label' htmlFor={day.value}>
                                      {day.label}
                                    </label>
                                  </div>
                                  <div className='col-sm-6 my-5 my-sm-0'>
                                    {!!day.isSelected ? (
                                      <TimeSlots
                                        day={day.value}
                                        startTime={day.startTime}
                                        endTime={day.endTime}
                                        onTimeChange={(
                                          timeType: 'startTime' | 'endTime',
                                          value: Date | null
                                        ) => {
                                          day[timeType] = value
                                          setFieldValue('daysOfWeek', {
                                            ...values.daysOfWeek,
                                            [key]: day,
                                          })
                                        }}
                                        applyToAll={(
                                          startTime: Date | null,
                                          endTime: Date | null
                                        ) => {
                                          const updatedValues = _.cloneDeep(values.daysOfWeek)
                                          // Update the startTime and endTime values
                                          Object.keys(updatedValues).forEach((key) => {
                                            updatedValues[key].startTime = startTime
                                            updatedValues[key].endTime = endTime
                                          })
                                          // Assign the updated values
                                          setFieldValue('daysOfWeek', updatedValues)
                                        }}
                                      />
                                    ) : (
                                      <p className='pt-4'>Unavailable</p>
                                    )}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        <label className='fs-6 fw-bold form-label mt-6'>
                          <span>Want to add time before or after your events? </span>
                          <div className='fs-7 text-light-grey-pagination'>
                            Give yourself some buffer time to prepare for or wrap up from booked
                            Appointments events.
                          </div>
                        </label>
                        <div className='row fv-row'>
                          <div className='col-12 col-sm-6 py-2 py-sm-0'>
                            <div className='pb-2'>
                              <Field
                                className='form-check-input smaller-form-input me-2'
                                type='checkbox'
                                name='isBeforeBreakTimeEnabled'
                              />
                              <label htmlFor='isBeforeBreakTimeEnabled'>Before Appointment</label>
                            </div>
                            {values?.isBeforeBreakTimeEnabled && (
                              <>
                                <Field
                                  as='select'
                                  name='beforeBreakTime'
                                  className={`form-select form-select-lg form-select-solid`}
                                >
                                  <option value={'0'}>Select break time</option>
                                  {AppointmentBreaks?.map((location: Venue, index: number) => (
                                    <option key={index} value={location?.value}>
                                      {location?.name}
                                    </option>
                                  ))}
                                </Field>
                                <div className='text-danger'>
                                  <ErrorMessage name='beforeBreakTime' />
                                </div>
                              </>
                            )}
                          </div>
                          <div className='col-12 col-sm-6 py-2 py-sm-0'>
                            <div className='pb-2'>
                              <Field
                                className='form-check-input smaller-form-input me-2'
                                type='checkbox'
                                name='isAfterBreakTimeEnabled'
                              />
                              <label htmlFor='isAfterBreakTimeEnabled'>After Appointment</label>
                            </div>
                            {values?.isAfterBreakTimeEnabled && (
                              <>
                                <Field
                                  as='select'
                                  name='afterBreakTime'
                                  className='form-select form-select-lg form-select-solid'
                                >
                                  <option value={'0'}>Select break time</option>
                                  {AppointmentBreaks?.map((location: Venue, index: number) => (
                                    <option key={index} value={location?.value}>
                                      {location?.name}
                                    </option>
                                  ))}
                                </Field>
                                <div className='text-danger'>
                                  <ErrorMessage name='afterBreakTime' />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex flex-stack pt-10'>
                      <div> </div>
                      <div className='me-2'>
                        <button
                          type='button'
                          className='btn cancel_button fs-7 fw-bolder me-3'
                          onClick={() => onHide()}
                          title='Cancel'
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-sm btn-blue fs-7'
                          title={id ? 'Update' : 'Create'}
                        >
                          <span className='indicator-label'>{id ? 'Update' : 'Create'}</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
