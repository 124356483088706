import React, {useEffect, useState} from 'react'
import '../../../../_metronic/assets/sass/components/opportunity-watch-add.scss'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {Form, Formik} from 'formik'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import * as actions from '../redux/ProvidentFundAction'
import {useParams} from 'react-router-dom'
import {initsVal, providentFundSchema, PFResponseInterface} from '../Models/ProvidentFundModel'
import {ProvidentFundBasicForm} from './ProvidentFundBasicForm'
import {TemporaryLoanForm} from './TemporaryLoanForm'
import {PermanentLoanForm} from './PermanentLoanForm'
import {FullLoanSettlementForm} from './FullLoanSettlementForm'
import {PFOptOutForm} from './PFOptOutForm'
import {Can} from '../../../Common/RBAC/Can'
import Ability from '../../../Common/RBAC/Ability'
import {Loading} from '../../../../_metronic/layout/Loading'

interface Props {
  onHide: () => void
  id?: string | null
  workSpaceId?: string | null
}
const allowedStatus = ['Return', 'Draft']
export const ProvidentFundAdd: React.FC<Props> = ({onHide}) => {
  const dispatch = useDispatch()
  const {id}: {id: string} = useParams()
  const [initValues, setInitValues] = useState<Partial<PFResponseInterface>>(initsVal)
  const [currentValidationSchema, setCurrentValidationSchema]: any = useState(
    providentFundSchema?.temporaryLoan
  )
  const {loanType, pfDetails, attachments, errorMessage}: any = useSelector<RootState>(
    (state) => state.providentFund,
    shallowEqual
  ) as {loanType: string}

  useEffect(() => {
    if (loanType) setCurrentValidationSchema(providentFundSchema[loanType])
  }, [loanType])

  useEffect(() => {
    if (pfDetails) {
      setInitValues(pfDetails)
    }
    if (pfDetails?.loanType === 'temporaryLoan' || pfDetails?.loanType === 'permanentLoan') {
      dispatch(actions.loadAttachments(pfDetails?.['documentPaths']))
    }
  }, [pfDetails])

  useEffect(() => {
    if (id) {
      dispatch(actions.getProvidentFundById(id))
    }
    return () => {
      dispatch(actions.resetForm())
      dispatch(actions.loadAttachments([]))
    }
  }, [])

  let crumbs
  crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Provident Fund Request', url: `/provident-fund`},
    {
      name: id ? 'Edit Provident Fund Request' : 'Create Provident Fund Request',
      url: '/provident-fund/' + id ? `edit/${id}` : `/provident-fund/add`,
    },
  ]

  return (
    <div className='mh-80 page-content'>
      <div className='px-6 px-sm-12'>
        <div className='row'>
          <div className='col-sm-6'>
            <h2 className='fw-500 fs-2 m-0 py-5'>
              {id ? 'Edit' : 'Create'} Provident Fund Request
            </h2>
          </div>
        </div>
      </div>

      <Breadcrumbs crumbs={crumbs} containerClass='row mx-10' />
      <div className='overflow_height_scroll'>
        <div className='page-body py-lg-10 px-lg-10'>
          <Can I='update' this='provident-fund'>
            <fieldset disabled={id && !allowedStatus.includes(pfDetails?.status) ? true : false}>
              <div
                className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_modal_create_app_stepper'
              >
                <div className='flex-row-fluid py-lg-5 px-lg-15'>
                  {errorMessage && <div className='alert alert-danger'>{errorMessage}</div>}
                  <Formik
                    validationSchema={currentValidationSchema}
                    initialValues={initValues}
                    enableReinitialize={true}
                    onSubmit={async (val: any) => {
                      const values = JSON.parse(JSON.stringify(val))
                      if (id && !allowedStatus.includes(pfDetails?.status)) {
                        return
                      }

                      try {
                        if (
                          values.loanType === 'temporaryLoan' ||
                          values.loanType === 'permanentLoan'
                        ) {
                          values['documents'] = attachments?.length
                            ? attachments?.map(
                                (attachment: any) => attachment?.path + '.' + attachment.type
                              )
                            : []
                        }
                        if (id) {
                          await dispatch(
                            actions.updateProvidentFund(id, {
                              loanType,
                              documents: values['documents'],
                              [loanType]: values[loanType],
                            })
                          )
                        } else {
                          await dispatch(
                            actions.createProvidentFund({
                              loanType,
                              documents: values['documents'],
                              [loanType]: values[loanType],
                            })
                          )
                        }
                        onHide()
                      } catch (err) {
                        console.log('error', err)
                      }
                    }}
                  >
                    {({values, errors}: any) => (
                      <Form
                        className='form form_opportunity_form'
                        noValidate
                        id='kt_modal_create_app_form'
                      >
                        <div className='current' data-kt-stepper-element='content'>
                          <div className='w-100'>
                            <div className='row'>
                              <ProvidentFundBasicForm />

                              {values?.loanType === 'temporaryLoan' && <TemporaryLoanForm />}

                              {values?.loanType === 'permanentLoan' && <PermanentLoanForm />}
                              {values?.loanType === 'fullLoanSettlement' && (
                                <FullLoanSettlementForm />
                              )}
                              {values?.loanType === 'PFOptOutForm' && <PFOptOutForm />}
                            </div>
                          </div>
                        </div>

                        <div className='d-flex flex-stack pt-10'>
                          <div></div>

                          <Can I='create' this='provident-fund'>
                            <div className='me-2'>
                              <button
                                type='button'
                                className='btn btn-sm fw-bolder  me-3 fs-7 cancel_button'
                                onClick={() => onHide()}
                                title='Cancel'
                              >
                                Cancel
                              </button>
                              <button
                                type='submit'
                                className='btn btn-lg btn-primary btn-blue  fs-7'
                                title={id ? 'Update' : 'Create'}
                              >
                                <span className='indicator-label'>{id ? 'Update' : 'Create'}</span>
                              </button>
                            </div>
                          </Can>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </fieldset>
          </Can>
          {id && !Ability.can('update', 'provident-fund') && <Loading />}
        </div>
      </div>
    </div>
  )
}
