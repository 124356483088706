import {FC, useEffect, useState, useReducer} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import * as boardActions from '../redux/BoardActions'
import {BoardColumnList} from './column/BoardColumnList'
import {BoardHeader} from './BoardHeader'
import {AddNewColumn} from './column/AddNewColumn'
import {Loading} from '../../../../_metronic/layout/Loading'
import {Task} from './task/Task'
import {AddNewTask} from './task/AddNewTask'
import {SearchBox} from './headerPartially/SearchBox'
import {boardInitialState} from '../model/Board'

const boardReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'RESET_SEARCH_BOX':
      return {...state, resetSearch: action.payload}
    default:
      return state
  }
}

const Board: FC = () => {
  const {workspaceid, id}: {workspaceid: string; id: string} = useParams()
  const [dragType, setDragType] = useState('column')
  const [boardStates, boardDispatch] = useReducer(boardReducer, boardInitialState)

  const dispatch: any = useDispatch()
  const {currentSpace, currentProject, loginUser, loading}: any = useSelector<RootState>(
    (state) => ({
      currentSpace: state.board.currentSpace,
      currentProject: state.board.currentProject,
      loading: state.board.loading,
      loginUser: state.auth.user,
    }),
    shallowEqual
  )

  function moveCard(
    dragColumnIndex: number,
    dragIndex: number,
    hoverColumnIndex: number,
    hoverIndex: number
  ) {
    if (dragType === 'column') {
      return
    }
    let currentProjectObject = JSON.parse(JSON.stringify(currentProject))
    const columnList = JSON.parse(JSON.stringify(currentProjectObject?.board?.columns))
    if (dragColumnIndex === hoverColumnIndex) {
      columnList[dragColumnIndex].task.splice(
        hoverIndex,
        0,
        columnList[dragColumnIndex].task.splice(dragIndex, 1)[0]
      )
    } else {
      // move element to new place
      columnList[hoverColumnIndex].task.splice(
        hoverIndex,
        0,
        columnList[dragColumnIndex].task[dragIndex]
      )
      // delete element from old place
      columnList[dragColumnIndex].task.splice(dragIndex, 1)
    }
    currentProjectObject['board']['columns'] = columnList

    dispatch(boardActions.updateCurrentProject(currentProjectObject))
  }

  function sortColumns() {
    const ids = currentProject.board.columns.map((column: any) => column._id)
    dispatch(boardActions.sortColumns(ids))
  }

  function sortTasks(columnIndex: number, taskId: string) {
    const ids = currentProject.board.columns[columnIndex].task.map((task: any) => task._id)
    dispatch(
      boardActions.updateTask(
        {field: 'status', value: currentProject.board.columns[columnIndex]._id, _id: taskId},
        workspaceid
      )
    )
    dispatch(boardActions.sortTasksOrder(ids))
  }

  useEffect(() => {
    const data = {
      project: id,
    }
    if (workspaceid) {
      dispatch(boardActions.fetchWorkspace(workspaceid))
      dispatch(boardActions.getAllWorkspaceUsers(workspaceid, loginUser))
    }
    dispatch(boardActions.currentProject(id))
    dispatch(boardActions.getAllTasksByProjectId(data))
  }, [])

  let crumbs = workspaceid
    ? [
        {name: 'Spaces', url: '/dashboard'},
        {name: `${currentSpace?.name}`, url: `/${currentSpace?._id}/features`},
        {name: 'Project', url: `/${currentSpace?._id}/project`},
        {name: currentProject?.name},
      ]
    : [
        {name: 'Spaces', url: '/dashboard'},
        {name: 'Project', url: `/project`},
      ]

  function moveList(dragIndex: number, hoverIndex: number) {
    let currentProjectObject = JSON.parse(JSON.stringify(currentProject))
    const columnList = JSON.parse(JSON.stringify(currentProjectObject?.board?.columns))
    const column = columnList[dragIndex]
    columnList.splice(dragIndex, 1)
    columnList.splice(hoverIndex, 0, column)
    currentProjectObject['board']['columns'] = columnList
    dispatch(boardActions.updateCurrentProject(currentProjectObject))
  }

  const handleDragType = (type: string) => {
    setDragType(type)
  }

  if (loading) {
    return <Loading />
  }
  return (
    <>
      <section className=''>
        <div className=''>
          <div className='mh-80 card card-custom rounded-0'>
            <div className='card-body p-0'>
              <div className='tab-content'>
                <div className='tab-pane tab-pane2 pb-5 active rounded'>
                  <div className='px-6 px-sm-12'>
                    <div className='row'>
                      <div className='col-sm-6'>
                        <h2 className='fw-500 fs-2 m-0 py-5 heading_overflow_control'>
                          {currentProject?.name} Board
                        </h2>
                      </div>
                      <div className='col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center'>
                        <SearchBox
                          boardDispatch={boardDispatch}
                          resetSearch={boardStates.resetSearch}
                        />
                      </div>
                    </div>
                  </div>
                  <Breadcrumbs crumbs={crumbs} />
                  {!loading ? (
                    <>
                      <div className='overflow_height_scroll'>
                        <BoardHeader boardDispatch={boardDispatch} />
                        <div className='row drag-drop-card-main card-margins'>
                          <div className='mt-0'>
                            <div className='kanban-container' id='kanban-main'>
                              {currentProject.board?.columns?.map((column: any, index: any) => {
                                return (
                                  <BoardColumnList
                                    key={index}
                                    index={index}
                                    column={column}
                                    moveList={moveList}
                                    dragType={dragType}
                                    sortColumns={sortColumns}
                                    dragTaskType={dragType}
                                    handleDragType={handleDragType}
                                    moveCard={moveCard}
                                    cardIndex={column?.task?.length}
                                    sortTasks={sortTasks}
                                  >
                                    {column?.task?.map((task: any, i: any) => (
                                      <Task
                                        key={i}
                                        index={i}
                                        columnIndex={index}
                                        task={task}
                                        columnId={column?._id}
                                        moveCard={moveCard}
                                        dragType={dragType}
                                        handleDragType={handleDragType}
                                        sortTasks={sortTasks}
                                      />
                                    ))}
                                    <AddNewTask
                                      index={column?.task?.length}
                                      board={currentProject?.board?._id}
                                      columnId={column?._id}
                                    />
                                  </BoardColumnList>
                                )
                              })}

                              {(currentProject?.workspace?.maintainers?.includes(loginUser?._id) ||
                                currentProject?.workspace?.owner === loginUser?._id ||
                                currentProject?.createdBy?._id === loginUser?._id) && (
                                <AddNewColumn />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Loading />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export {Board}
