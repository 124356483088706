import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useLocation, useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import * as actions from '../redux/AppointmentsAction'
import {NotFound} from '../../../../_metronic/layout/NotFound'
import {Loading} from '../../../../_metronic/layout/Loading'
import '../../../../_metronic/assets/sass/components/cards.scss'
import '../../../../_metronic/assets/sass/components/release-notes.scss'
import {convertPascalCaseToSentence, iterateFullMonth} from '../helpers/utils'
import Calendar from 'react-calendar'
import {Value} from 'react-calendar/dist/cjs/shared/types'
import 'react-calendar/dist/Calendar.css'
import '../helpers/styles/calendar.css'
import TimeList from './TimeList'
import AppointmentModal from './AppointmentModal'
import {bookedSlotsObj, convertTo24HourFormat, getStructuredTimeSlots} from '../helpers'
import {Can} from '../../../Common/RBAC/Can'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
interface Props {
  userId?: string | null
  onHide: () => void
}
const AppointmentsDetails: React.FC<Props> = ({userId, onHide}) => {
  const {state}: {state: any} = useLocation()

  const dispatch = useDispatch()
  const [date, setDate] = useState<Date>()
  const [timeSlotsOfMonth, setTimeSlotsOfMonth] = useState<any>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [clickedSlot, setClickedSlot] = useState<string>()
  const [bookedSlots, setBookedSlots] = useState<bookedSlotsObj[]>([])
  const [appointmentEventValues, setAppointmentEventValues] = useState(null)
  const {id}: {id: string} = useParams()

  const {listDetail, listLoading, bookedTimeSlotData, bookedTimeSlots}: any =
    useSelector<RootState>(
      (state) => ({
        listDetail: state.appointments.AppointmentsDetail,
        listLoading: state.appointments.listLoading,
        bookedTimeSlotData: state.appointments.bookedTimeSlotData,
        bookedTimeSlots: state.appointments.bookedTimeSlots,
      }),
      shallowEqual
    )

  useEffect(() => {
    if (bookedTimeSlots) {
      const structuredTimeSlots = getStructuredTimeSlots(bookedTimeSlots)
      setBookedSlots(structuredTimeSlots)
    }
  }, [bookedTimeSlots])

  useEffect(() => {
    if (bookedTimeSlotData) {
      //Hiding the modal when api successfully return a response
      setShowModal(false)
      //refetching the booked time slots
      date && getUpdatedBookedTimeSlots(date)
    }
  }, [bookedTimeSlotData])

  useEffect(() => {
    if (id) {
      dispatch(actions.fetchAppointmentsById(id))
    }
  }, [id])

  useEffect(() => {
    if (listDetail) {
      const startDate = new Date(listDetail?.dateRange?.start)
      setDate(startDate)
      getUpdatedBookedTimeSlots(startDate)
      getTimeSlotsOfMonth(startDate)
    }
  }, [listDetail])

  const onChangeMonth = (activeStartDate: Date | null) => {
    if (activeStartDate) {
      setTimeSlotsOfMonth([])
      setDate(activeStartDate)
      getTimeSlotsOfMonth(activeStartDate)
      getUpdatedBookedTimeSlots(activeStartDate)
    }
  }
  const getUpdatedBookedTimeSlots = (targetDate: Date) => {
    dispatch(
      actions.getAppointmentTimeSlot(id, targetDate?.getMonth() + 1, targetDate?.getFullYear())
    )
  }

  const getTimeSlotsOfMonth = (currDate: Date) => {
    const timeSlotsOfThisMonth = iterateFullMonth(
      new Date(currDate),
      listDetail?.availability,
      listDetail?.duration,
      listDetail?.beforeBreakTime,
      listDetail?.afterBreakTime
    )
    setTimeSlotsOfMonth(timeSlotsOfThisMonth)
  }

  const handleConfirm = () => {
    let values: any
    if (appointmentEventValues) {
      values = JSON.parse(JSON.stringify(appointmentEventValues))
      values.startTime = new Date(date as Date)
      const startTime = convertTo24HourFormat(clickedSlot).split(':')
      values.startTime = new Date(date as Date)
      values.startTime.setHours(startTime[0], startTime[1], 0)
      values.endTime = new Date(date as Date)
      values.endTime.setHours(startTime[0], +startTime[1] + listDetail?.duration, 0)
      dispatch(actions.sendAppointmentEvent(values))
    }

    const submitAbleValues: any = {
      startTime: clickedSlot,
      duration: listDetail?.duration,
      eventDate: date,
    }

    if (values) submitAbleValues['eventBooked'] = values

    dispatch(actions.createAppointmentTimeSlot(submitAbleValues, id))
    setShowModal(false)
    setClickedSlot('')
    setAppointmentEventValues(null)
  }

  function closeModal(canSubmit: boolean, values = null) {
    if (canSubmit) {
      setShowModal(true)
      setAppointmentEventValues(values)
    } else {
      setShowModal(false)
      setClickedSlot('')
    }
  }

  const crumbs =
    state?.profileData?.from === 'scheduledEvents'
      ? [
          {name: 'Dashboard', url: '/dashboard'},
          {name: 'Appointments', url: `/appointments`},
          {
            name: 'Scheduled Appointments',
            url: `/appointments/scheduled`,
          },
          {name: `${listDetail?.name}`, url: ''},
        ]
      : [
          {name: 'Dashboard', url: '/dashboard'},
          {name: 'Appointments', url: `/appointments`},
          {name: `${listDetail?.name}`, url: ''},
        ]

  return (
    <>
      <div className='mh-80 bg-white'>
        <div className='px-6 px-sm-12 '>
          <div className='row'>
            <div className='col-sm-6'>
              <h2 className='fw-500 fs-2 m-0 py-5 heading_overflow_control'>{listDetail?.name}</h2>
            </div>
          </div>
        </div>
        {crumbs?.length > 0 && <Breadcrumbs crumbs={crumbs} />}
        <div className='overflow_height_scroll'>
          <div className='card-body px-0 mt-5 mx-2 mx-sm-10'>
            {!listLoading && listDetail ? (
              <div className='row mx-auto drag-drop-card-main g-4 pb-0'>
                <div className='col-lg-12 mt-0'>
                  <div className='drag-drop-card card mb-5 border-card-grey justify-content-evenly'>
                    <div className='d-flex justify-content-between align-items-center'>
                      {listDetail.name && (
                        <div
                          className='d-flex align-items-center mb-2 text-gray-800  fs-5 fw-500 me-1 w-75'
                          title={listDetail.name}
                        >
                          {listDetail.name}
                        </div>
                      )}
                      <Can I='update' this='event-scheduling'>
                        <Link
                          to={`/appointments/${id}/edit`}
                          title='Edit'
                          className='btn btn-sm btn-light-blue  text-blue ms-3'
                        >
                          <img
                            src={toAbsoluteUrl('/media/icons/projects/svgexport-10.svg')}
                            className='me-2 w-25'
                          ></img>
                          Edit
                        </Link>
                      </Can>
                    </div>
                    <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 align-items-center  me-5 '>
                      {listDetail?.venue && (
                        <span
                          className='d-flex align-items-center me-5 mt-sm-0 mt-4'
                          title={convertPascalCaseToSentence(listDetail?.venue)}
                        >
                          <span className='fw-bolder fs-7 text-muted'>Venue</span>
                          <span className='ms-2'>
                            {convertPascalCaseToSentence(listDetail?.venue)}
                          </span>
                        </span>
                      )}
                      {listDetail?.duration && (
                        <span
                          className='d-flex align-items-center me-5 mt-sm-0 mt-4'
                          title={listDetail?.duration}
                        >
                          <span className='fw-bolder fs-7 text-muted'>Time Interval</span>
                          <span className='ms-2'>
                            {listDetail?.duration ? listDetail?.duration + ' min' : ''}
                          </span>
                        </span>
                      )}
                      {listDetail?.dateRange?.start && (
                        <span
                          className='d-flex align-items-center me-5 mt-sm-0 mt-4'
                          title={
                            listDetail?.dateRange?.start
                              ? moment(listDetail?.dateRange?.start).format('DD/MM/YYYY')
                              : ''
                          }
                        >
                          <span className='fw-bolder fs-7 text-muted'>Start Date </span>
                          <span className='ms-2'>
                            {listDetail?.dateRange?.start
                              ? moment(listDetail?.dateRange?.start).format('DD/MM/YYYY')
                              : ''}
                          </span>
                        </span>
                      )}
                      <span
                        className='d-flex align-items-center me-5 mt-sm-0 mt-4'
                        title={
                          listDetail?.dateRange?.end
                            ? moment(listDetail?.dateRange?.end).format('DD/MM/YYYY')
                            : 'Indefinitely in future'
                        }
                      >
                        <span className='fw-bolder fs-7 text-muted'>End Date</span>
                        <span className='ms-2'>
                          {listDetail?.dateRange?.end
                            ? moment(listDetail?.dateRange?.end).format('DD/MM/YYYY')
                            : 'Indefinitely in future'}
                        </span>
                      </span>
                    </div>
                    {listDetail?.description && (
                      <span className='d-flex flex-row'>
                        <span className='fw-bolder subheading me-5'>Description</span>
                        <div
                          title={'Appointment Descriptions'}
                          className='d-flex flex-column description '
                          dangerouslySetInnerHTML={{
                            __html: listDetail?.description,
                          }}
                        />
                      </span>
                    )}
                    <hr className='hr' />
                    {date && (
                      <>
                        <span className='d-flex align-items-center my-4' title={'Select Time Slot'}>
                          <span className='fw-500 fs-2'>Select Time Slot</span>
                          <span className='fw-bolder fs-5 text-muted ms-2'>
                            {'(' + moment(date.toDateString()).format('DD MMM YYYY') + ')'}
                          </span>
                        </span>
                        <div className='row fv-row'>
                          <div className='col-12 col-sm-6 py-2 py-sm-0 mt-1'>
                            <Calendar
                              onChange={(value: Value) => {
                                value && setDate(new Date(value.toString()))
                              }}
                              value={date}
                              minDate={new Date(listDetail?.dateRange?.start)}
                              maxDate={
                                listDetail?.dateRange?.end
                                  ? new Date(listDetail?.dateRange?.end)
                                  : undefined
                              }
                              onActiveStartDateChange={({activeStartDate, view}) =>
                                view === 'month' && onChangeMonth(activeStartDate)
                              }
                            />
                          </div>
                          <div className='col-12 col-sm-6 py-2 py-sm-0'>
                            <TimeList
                              timeSlotsOfMonth={timeSlotsOfMonth[date.getDate() - 1]}
                              onBookingAppointment={(time: string | undefined) => {
                                setClickedSlot(time)
                              }}
                              selectedDate={date}
                              selectedTime={clickedSlot || ''}
                              timeDuration={listDetail?.duration}
                              bookedSlots={bookedSlots}
                              showModal={showModal}
                              closeModal={closeModal}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : !listLoading && !listDetail ? (
              <NotFound />
            ) : (
              <Loading />
            )}
          </div>
          <AppointmentModal
            showModal={showModal}
            handleCloseModal={() => setShowModal(false)}
            handleConfirm={handleConfirm}
            handleCancel={() => {
              setShowModal(false)
              setClickedSlot('')
            }}
            clickedTime={clickedSlot}
          />
        </div>
      </div>
    </>
  )
}

export {AppointmentsDetails}
