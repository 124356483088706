import React, {FC} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import {InputField} from './fields/InputField'
import {countries} from '../modules/clients/core/ClientUIHelper'
import {ReactSelect} from './Select'
import {timezones} from '../modules/clients/core/ClientTimezone'
interface Props {
  values: any
  arrayHelpers: any
  field: string
  setFieldValue: (field: string, value: string) => void
  mandatory: boolean
}
export const OtherContacts: FC<Props> = ({
  values,
  arrayHelpers,
  field,
  setFieldValue,
  mandatory,
}) => {
  return (
    <>
      {values?.length > 0 &&
        values?.map((value: any, index: any) => (
          <table className='table table-responsive ' key={index}>
            <tbody>
              <tr>
                <td data-label='Name'>
                  <div className=' w-100'>
                    <div className='row'>
                      <div className='col-md-10'>
                        <div className='row mb-5'>
                          <div className='col-md-3'>
                            <InputField
                              label='First name'
                              fieldTye='text'
                              fieldName={`${field}[${index}]firstName`}
                              fieldPlaceholder='First name'
                              mandatory={mandatory}
                            />
                          </div>
                          <div className='col-md-3'>
                            <InputField
                              label='Last name'
                              fieldTye='text'
                              fieldName={`${field}[${index}]lastName`}
                              fieldPlaceholder='Last name'
                              mandatory={mandatory}
                            />
                          </div>
                          <div className='col-md-3'>
                            <InputField
                              label='Email'
                              fieldTye='text'
                              fieldName={`${field}[${index}]email`}
                              fieldPlaceholder='Email address'
                              mandatory={mandatory}
                            />
                          </div>
                          <div className='col-md-3'>
                            <InputField
                              label='Phone no. / Mobile no.'
                              fieldTye='text'
                              fieldName={`${field}[${index}]mobile`}
                              fieldPlaceholder='Phone no. / Mobile no.'
                              mandatory={mandatory}
                            />
                          </div>
                        </div>
                        <div className='row'>
                          {/* <div className='col-md-6'>
                            <InputField
                              label='Other contact'
                              fieldTye='text'
                              fieldName={`${field}[${index}]otherContact`}
                              fieldPlaceholder='Other contact'
                              mandatory={mandatory}
                            />
                          </div> */}
                          <div className='fv-row'>
                            <div className='row'>
                              <div className='col-md-3'>
                                <InputField
                                  label='City'
                                  fieldTye='text'
                                  fieldName={`${field}[${index}]city`}
                                  fieldPlaceholder='City'
                                  mandatory={mandatory}
                                />
                              </div>
                              <div className='col-md-3'>
                                <InputField
                                  label='State'
                                  fieldTye='text'
                                  fieldName={`${field}[${index}]state`}
                                  fieldPlaceholder='State'
                                  mandatory={mandatory}
                                />
                              </div>
                              <div className='col-md-3'>
                                <ReactSelect
                                  label='Country'
                                  fieldName={`${field}[${index}]country`}
                                  fieldValue={value?.country}
                                  fieldPlaceholder='Select country'
                                  options={countries}
                                  setFieldValue={setFieldValue}
                                  mandatory={mandatory}
                                />
                              </div>
                              <div className='col-md-3'>
                                <ReactSelect
                                  label='Timezone'
                                  fieldName={`${field}[${index}]timezone`}
                                  fieldValue={value?.timezone}
                                  fieldPlaceholder='Select timezone'
                                  options={timezones}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-2 mt-10'>
                        <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                          onClick={() => arrayHelpers.remove(index)}
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          title='Remove Item'
                          disabled={values?.length > 1 ? false : true}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon svg-icon-3'
                          />
                        </button>
                        {values?.length === index + 1 && (
                          <button
                            type='button'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Add New member'
                            onClick={() => {
                              arrayHelpers.push({
                                firstName: '',
                                lastName: '',
                                mobile: '',
                                email: '',
                                otherContact: '',
                                city: '',
                                state: '',
                                country: '',
                                timezone: '',
                              })
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr013.svg'
                              className='svg-icon  svg-icon-3'
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        ))}
    </>
  )
}
