import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {Search} from '../../../../_metronic/layout/search/Search'
import '../../../../_metronic/assets/sass/components/todo-table-row.scss'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import * as actions from '../redux/OpportunityWatchAction'
import {DeleteOpportunityWatch} from './OpportunityWatchDelete'
import * as workspaceActions from '../../workspace/redux/WorkspaceActions'
import {Pagination} from '../../../pagination/pagination'
import {NotFound} from '../../../../_metronic/layout/NotFound'
import {Loading} from '../../../../_metronic/layout/Loading'
import {Can} from '../../../Common/RBAC/Can'

type Props = {
  className: string
}

type OpportunityWatch = [
  {
    _id: string
    technology?: string
    plan?: string
    assignees?: string[]
    teams?: string[]
    activeDate?: Date | String | null
    createdBy?: string[]
  }
]

interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  page: number
}

export const OpportunityWatchTable: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()

  const {workspaceid}: {workspaceid: string} = useParams()
  const [opportunityWatchList, setOpportunityWatchList] = useState([{}])
  const [sortValues, setSortValues] = useState({
    orderby: 'desc',
    sortby: '',
    status: '',
    createdAt: '',
    activeDate: '',
    assignee: '',
    teams: '',
  })
  const [toggleView, setToggleView] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [itemToDelete, setItemToDelete] = useState('')

  const opportunityWatchData: OpportunityWatch = useSelector<RootState>(
    (state) => state.opportunityWatch.entities,
    shallowEqual
  ) as OpportunityWatch

  const isLoading: OpportunityWatch = useSelector<RootState>(
    (state) => state.opportunityWatch.listLoading,
    shallowEqual
  ) as OpportunityWatch

  const paginationData: PaginationModel = useSelector<RootState>(
    (state) => state.opportunityWatch,
    shallowEqual
  ) as PaginationModel

  const {spaceDetail}: any = useSelector<RootState>(
    (state) => ({
      spaceDetail: state.workspace.workspaceDetail,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (opportunityWatchData.length > 0) {
      setOpportunityWatchList(opportunityWatchData)
      // fetchAssigneesData(opportunityWatchData)
    } else {
      setOpportunityWatchList([])
    }
  }, [opportunityWatchData])

  useEffect(() => {
    if (opportunityWatchData.length > 0) {
      setOpportunityWatchList(opportunityWatchData)
      // fetchAssigneesData(opportunityWatchData)
    } else {
      setOpportunityWatchList([])
    }
  }, [opportunityWatchData])

  const removeSortingClass = () => {
    const cells = document.querySelectorAll('th')
    cells.forEach(function (cell) {
      cell.classList.remove('table-sort-desc')
      cell.classList.remove('table-sort-asc')
    })
  }

  useEffect(() => {
    let tableHead: any
    tableHead = document.getElementById(`todo-${sortValues.sortby}-head`)

    if (sortValues.orderby === 'desc' && tableHead && opportunityWatchList?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-asc')
      tableHead.classList.add('table-sort-desc')
    } else if (tableHead && opportunityWatchList?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-desc')
      tableHead.classList.add('table-sort-asc')
    }
    dispatch(
      actions.fetchOpportunityWatch({
        search: '',
        page: '',
        sortby: sortValues.sortby,
        orderby: sortValues.orderby,
        status: sortValues.status,
        createdAt: sortValues.createdAt,
        activeDate: sortValues.activeDate,
        // assignee: sortValues.assignee,
        // teams: sortValues.teams,
        // workSpace: workspaceid ? workspaceid : null,
      })
    )
  }, [sortValues])

  useEffect(() => {
    if (workspaceid) {
      dispatch(workspaceActions.fetchWorkspace(workspaceid))
    }
  }, [dispatch])

  const deleteOppotunityWatch = (_id: string) => async (event: any) => {
    setItemToDelete(_id)
    setShowDeleteDialog(true)
  }

  const handleSearch = (search: string) => {
    dispatch(
      actions.fetchOpportunityWatch({
        search,
        page: paginationData?.page,
        sortby: '',
        orderby: '',
        status: '',
        createdAt: '',
        activeDate: '',
        // assignee: [],
        // teams: [],
        // workSpace: workspaceid ? workspaceid : null,
      })
    )
  }

  const handlePagination = (event: any) => {
    dispatch(
      actions.fetchOpportunityWatch({
        search: '',
        page: event.selected + 1,
        sortby: '',
        orderby: '',
        status: '',
        createdAt: '',
        activeDate: '',
        // assignee: [],
        // teams: [],
        // workSpace: workspaceid ? workspaceid : null,
      })
    )
  }

  let crumbs
  // if (spaceDetail) {
  crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    // {name: 'Spaces', url: '/spaces'},
    // {name: `${spaceDetail?.name}`, url: `/${spaceDetail?._id}/features`},
    {name: 'Opportunity Watch', url: `/opportunity-watch`},
  ]
  // }

  const sortColumn = (event: any) => {
    if (event.target.classList.contains('table-sort-desc')) {
      removeSortingClass()
      setSortValues({
        orderby: 'asc',
        sortby: event.target.abbr,
        status: sortValues.status,
        createdAt: sortValues.createdAt,
        activeDate: sortValues.activeDate,
        assignee: sortValues.assignee,
        teams: sortValues.teams,
      })
      event.target.classList.remove('table-sort-desc')
      event.target.classList.add('table-sort-asc')
    } else {
      removeSortingClass()
      setSortValues({
        orderby: 'desc',
        sortby: event.target.abbr,
        status: sortValues.status,
        createdAt: sortValues.createdAt,
        activeDate: sortValues.activeDate,
        assignee: sortValues.assignee,
        teams: sortValues.teams,
      })
      event.target.classList.remove('table-sort-asc')
      event.target.classList.add('table-sort-desc')
    }
  }

  return (
    <>
      <DeleteOpportunityWatch
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
        _id={itemToDelete}
        workspaceid={workspaceid ? workspaceid : null}
      />
      <>
        <div className={`mh-80 tabs-main card legend-card-main rounded-0`}>
          {!isLoading && (
            <div className='px-6 px-sm-12'>
              <div className="row">
                <div className="col-sm-6">
                  <h2 className='fw-500 fs-2 m-0 py-5'>
                    Opportunity Watch
                  </h2>
                </div>
                <div className="col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center">
                  <Search handleSearch={handleSearch}/>
                </div>
              </div>
            </div>
          )}
          <div className='d-flex justify-content-end align-items-center bg-lighter flex-sm-nowrap flex-wrap'>
            {crumbs && <Breadcrumbs crumbs={crumbs} />}
            <Link className='bg-lighter me-5 me-sm-10' to={`/opportunity-watch/add`}>
              <span
                className='btn btn-sm  bg-transparent text-blue pe-5 d-flex align-items-center'
                style={{whiteSpace: 'nowrap'}}
              >
                <KTSVG path='' className='svg-icon-3' />
                <i className='fa fa-plus-circle link_button' aria-hidden='true'></i>
                <span className='link_button'> Create Opportunity</span>
              </span>
            </Link>
          </div>
          {/* end::Header */}
          {/* begin::Body */}

          <>
          <div className="overflow_height_scroll">
            <div className='card-body d-flex flex-column py-3 px-10'>
              {/* begin::Table container */}

              {!isLoading && opportunityWatchList?.length > 0 ? (
                <div className='table-responsive'>
                  {/* begin::Table */}

                  <table className='todo-table table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th
                          className='min-w-120px'
                          style={{cursor: 'pointer', minWidth: '130px'}}
                          onClick={sortColumn}
                          abbr='lead'
                          id='todo-lead-head'
                        >
                          Leads
                        </th>
                        <th
                          className=''
                          style={{cursor: 'pointer', minWidth: '130px'}}
                          onClick={sortColumn}
                          abbr='technology'
                          id='todo-technology-head'
                        >
                          Technology
                        </th>
                        {/* <th className='min-w-120px'>Assignees</th> */}
                        <th
                          className='min-w-120px'
                          style={{cursor: 'pointer', minWidth: '95px'}}
                          onClick={sortColumn}
                          abbr='createdBy'
                          id='todo-createdBy-head'
                        >
                          Created By
                        </th>

                        <th
                          className='min-w-120px table-sort-desc'
                          style={{cursor: 'pointer', minWidth: '125px'}}
                          onClick={sortColumn}
                          abbr='createdAt'
                          id='todo-createdAt-head'
                        >
                          Created Date
                        </th>
                        <th
                          className='min-w-120px'
                          style={{cursor: 'pointer'}}
                          onClick={sortColumn}
                          abbr='activeDate'
                          id='todo-activeDate-head'
                        >
                          Active Date
                        </th>

                        <th
                          className='min-w-120px text-center'
                          style={{cursor: 'pointer'}}
                          onClick={sortColumn}
                          abbr='status'
                          id='todo-status-head'
                        >
                          Status
                        </th>
                        <th className='min-w-100px '>Actions</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {opportunityWatchList?.map((opportunityWatch: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td
                            className='w-25'
                              data-label='Lead'
                              title={opportunityWatch?.lead ? opportunityWatch.lead : '-'}
                            >
                              {opportunityWatch?.lead ? (
                                <>
                                  <Can I='read' this='opportunity-watch'>
                                    <Link to={`/opportunity-watch/${opportunityWatch?._id}`} className="w-100" >
                                        <div className='d-flex align-items-center justify-content-start flex-column line-clamp'>
                                          {opportunityWatch?.lead}
                                        </div>
                                    </Link>
                                  </Can>
                                  <Can not I='read' this='opportunity-watch'>
                                      <div className='d-flex align-items-center justify-content-start flex-column'>
                                        {opportunityWatch?.lead}
                                      </div>
                                  </Can>
                                </>
                              ) : (
                                  <div className='d-flex align-items-center justify-content-start flex-column'>
                                    {'-'}
                                  </div>
                              )}
                            </td>
                            <td data-label='Technology' className='w-25'>
                                  <div className='d-flex justify-content-start flex-column line-clamp'>
                                    <span
                                      title={
                                        opportunityWatch?.technology
                                          ? opportunityWatch?.technology
                                          : '-'
                                      }
                                      // style={{
                                      //   display: 'block',
                                      //   width: '230px',
                                      //   minWidth: '230px',
                                      //   overflow: 'hidden',
                                      //   whiteSpace: 'nowrap',
                                      //   textOverflow: 'ellipsis',
                                      // }}
                                    >
                                      {opportunityWatch?.technology
                                        ? opportunityWatch?.technology
                                        : '-'}
                                    </span>
                                  </div>
                            </td>
                            <td
                              data-label='Created By'
                              className='text-end'
                              title={opportunityWatch?.createdBy?.firstName}
                            >
                              <div className='d-flex flex-column me-2'>
                                <div className='d-flex flex-stack'>
                                  {opportunityWatch?.createdBy
                                    ? opportunityWatch?.createdBy?.firstName
                                    : '-'}
                                </div>
                              </div>
                            </td>
                            <td
                              data-label='Created Date'
                              className='text-end'
                              title={moment(opportunityWatch?.createdAt).format('MM/DD/YYYY')}
                            >
                              <div className='d-flex flex-column me-2'>
                                <div className='d-flex flex-stack'>
                                  {moment(opportunityWatch?.createdAt).format('MM/DD/YYYY')}
                                </div>
                              </div>
                            </td>

                            <td
                              data-label='Active Date'
                              title={
                                opportunityWatch?.activeDate
                                  ? moment(opportunityWatch?.activeDate).format('MM/DD/YYYY')
                                  : '-'
                              }
                            >
                              {opportunityWatch?.activeDate ? (
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {opportunityWatch?.activeDate
                                      ? moment(opportunityWatch?.activeDate).format('MM/DD/YYYY')
                                      : '-'}
                                  </div>
                                </div>
                              ) : (
                                <div className='d-flex align-items-center '>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {'-'}
                                  </div>
                                </div>
                              )}
                            </td>

                            <td
                              data-label='Priority/Status'
                              title={
                                opportunityWatch?.status === '0'
                                  ? '-'
                                  : opportunityWatch?.status === '1'
                                  ? 'Declined'
                                  : opportunityWatch?.status === '2'
                                  ? 'Active'
                                  : opportunityWatch?.status === '3'
                                  ? 'Fulfilled'
                                  : 'Fell Off'
                              }
                            >
                              <div className='d-flex align-items-center justify-content-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {opportunityWatch?.status === '0' ? (
                                    '-'
                                  ) : opportunityWatch?.status === '1' ? (
                                    <span className='badge badge-light-info fs-7 '>Declined</span>
                                  ) : opportunityWatch?.status === '2' ? (
                                    <span className='badge badge-light-success fs-7 fw-bold'>
                                      Active
                                    </span>
                                  ) : opportunityWatch?.status === '3' ? (
                                    <span className='badge badge-light-primary fs-7 fw-bold text-shade-blue'>
                                      Fulfilled
                                    </span>
                                  ) : (
                                    <span className='badge badge-light-danger fs-7 fw-bold'>
                                      Fell Off
                                    </span>
                                  )}
                                </div>
                              </div>
                            </td>

                            <td data-label='Actions' className='action_btns'>
                              <div className=' flex-shrink-0'>
                                <Can I='read' this='opportunity-watch'>
                                  <Link
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
                                    title='Activity'
                                    to={`/opportunity-watch/${opportunityWatch?._id}`}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen032.svg'
                                      className='svg-icon-3'
                                    />
                                  </Link>
                                </Can>
                                {/* {todo?.createdBy?._id === userInfo?._id &&  */}
                                <Can I='update' this='opportunity-watch'>
                                  <Link
                                    // to={`/${workspaceid}/opportunity-watch/${opportunityWatch?._id}/edit`}
                                    to={`/opportunity-watch/${opportunityWatch?._id}/edit`}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
                                    // onClick={fetchTodoTask(todo._id)}
                                    title='Edit'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='svg-icon-3'
                                    />
                                  </Link>
                                </Can>

                                <Can I='delete' this='opportunity-watch'>
                                  <button
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm bg-light-blue-actions'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_delete_app'
                                    id='kt_toolbar_primary_button'
                                    onClick={deleteOppotunityWatch(opportunityWatch?._id)}
                                    title='Delete'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen027.svg'
                                      className='svg-icon-3'
                                    />
                                  </button>
                                </Can>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  {/* end::Table */}
                </div>
              ) : !isLoading && opportunityWatchList?.length <= 0 ? (
                <NotFound />
              ) : (
                <Loading />
              )}

              {/* end::Table container */}
            </div>
            </div>

            <Pagination paginationData={paginationData} fetchData={handlePagination} />
          </>

          {/* begin::Body */}
        </div>
      </>
    </>
  )
}
