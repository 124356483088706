import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {IGLightbox} from '../IGLightbox/IGLightbox'
import {RootState} from '../../../setup'
import axios from 'axios'
interface Props {
  attachments: any
  attachmentType: string
  loadAttachments: any
  id: string | null | undefined
  itemType: string
}
const filesExtensions = ['csv', 'pdf', 'doc', 'docx', 'csv', 'xls', 'xlsx', 'txt', 'ppt', 'pptx']

const AttachmentListing: React.FC<Props> = ({
  attachments,
  attachmentType,
  loadAttachments,
  id,
  itemType,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  // const [imagesUrl, setImagesUrl]: any = useState([])

  const accessToken: any = useSelector<RootState>(
    (state) => state.auth.accessToken,
    shallowEqual
  ) as any

  const dispatch = useDispatch()
  let images = attachments?.map((attachment: any) => {
    if (attachment?.type === 'jpg' || attachment?.type === 'jpeg' || attachment?.type === 'png')
      return `${attachment.url}?token=${accessToken}&itemType=${itemType}&itemId=${id}`
  })
  images = images.filter((url: string) => url)
  useEffect(() => {}, [images, photoIndex, isOpen])
  const handleOpen = (index: number) => {
    setPhotoIndex(index)
    setIsOpen((isOpen) => !isOpen)
  }

  // const getImage = async (thumbnailUrl: string) => {
  //   const x: any = await axios.get(thumbnailUrl, {
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   })
  //   return x.config.url
  // }
  // const getImages = async (attachments: any[]) => {
  //   const images: string[] = await Promise.all(
  //     attachments.map(async (attachment) => {
  //       const image = await getImage(attachment.thumbnailUrl)
  //       return image
  //     })
  //   )
  //   setImagesUrl(images)
  // }

  // useEffect(() => {
  //   if (attachmentType !== 'attachmentUpload') {
  //     getImages(attachments)
  //   }
  // }, [attachments])

  return (
    <>
      {id && (
        <>
          {isOpen && (
            <IGLightbox
              images={images}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              photoIndex={photoIndex}
              setPhotoIndex={setPhotoIndex}
            />
          )}
          {attachmentType === 'attachmentUpload' ? (
            <div className='activity-icons flex-wrap me-sm-5'>
              {attachments.map((attach: any, index: number) =>
                filesExtensions.includes(attach?.type) && attach?.uploaded !== false ? (
                  <div className='activity-inner my-1' key={index}>
                    <a
                      href={`${attach?.url}?token=${accessToken}&itemType=${itemType}&itemId=${id}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={toAbsoluteUrl(`/media/icons/files/${attach?.type}.png`)}
                        title={attach?.url.split('/').pop()}
                      />
                    </a>
                    <button
                      type='button'
                      className='btn btn-icon btn-active-color-primary btn-sm mt-2'
                      onClick={() => {
                        const filteredArray = attachments.filter((a: any) => {
                          return a.url !== attach.url
                        })

                        dispatch(loadAttachments(filteredArray))
                      }}
                      title='Delete'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </button>
                  </div>
                ) : (
                  <React.Fragment key={index}>
                    {attach?.uploaded !== false && (
                      <div className='activity-inner' key={index}>
                        <span
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={(e) => handleOpen(index)}
                          title={attach?.thumbnailUrl.split('/').pop()}
                        >
                          <img
                            src={`${attach?.thumbnailUrl}?token=${accessToken}&itemType=${itemType}&itemId=${id}`}
                          />
                        </span>
                        <button
                          type='button'
                          className='btn btn-icon btn-active-color-primary btn-sm mt-2'
                          onClick={() => {
                            const filteredArray = attachments?.filter((a: any) => {
                              return a.url !== attach?.url
                            })

                            dispatch(loadAttachments(filteredArray))
                          }}
                          title='Delete'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </button>
                      </div>
                    )}
                  </React.Fragment>
                )
              )}
            </div>
          ) : (
            <div className='activity-icons flex-wrap'>
              {attachments?.length > 0 &&
                attachments?.map((attach: any, index: number) =>
                  filesExtensions.includes(attach?.type) && attach?.uploaded !== false ? (
                    <React.Fragment key={index}>
                      {attach?.url && (
                        <div className='activity-inner my-1' key={index}>
                          <a
                            href={`${attach?.url}?token=${accessToken}&itemType=${itemType}&itemId=${id}`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <img
                              src={toAbsoluteUrl(`/media/icons/files/${attach.type}.png`)}
                              title={attach.url.split('/').pop()}
                            />
                          </a>
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={index}>
                      {attach?.thumbnailUrl && (
                        <div className='activity-inner my-1' key={index}>
                          <span
                            style={{
                              cursor: 'pointer',
                            }}
                            title={attach.thumbnailUrl.split('/').pop()}
                            onClick={(e) => handleOpen(index)}
                          >
                            <img
                              src={`${attach?.thumbnailUrl}?token=${accessToken}&itemType=${itemType}&itemId=${id}`}
                            />
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  )
                )}
            </div>
          )}
        </>
      )}
    </>
  )
}

export {AttachmentListing}
