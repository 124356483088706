import {ErrorMessage} from 'formik'
import React, {useMemo, useState} from 'react'
import Select from 'react-select'

interface ISelectProps<T> {
  label: string
  fieldName: string
  fieldValue: string
  fieldPlaceholder: string
  options: T[]
  mandatory?: boolean
  setFieldValue: (field: string, value: string) => void
}

const ReactSelect = <T,>({
  label,
  fieldName,
  fieldValue,
  fieldPlaceholder,
  options,
  mandatory,
  setFieldValue,
  ...rest
}: ISelectProps<T>) => {
  const [disabled] = useState(window.location.pathname.includes('view'))
  const optionsArr = useMemo(() => {
    if (options?.length > 0) {
      return typeof options[0] === 'string'
        ? options.map((option) => ({value: option, label: option}))
        : options
    }
    return []
  }, [options])

  const selectedOption = useMemo(() => {
    return fieldValue && typeof fieldValue === 'string'
      ? {value: fieldValue, label: fieldValue}
      : fieldValue
  }, [fieldValue])

  return (
    <>
      <label className='fs-6 fw-bold form-label mb-2'>
        <span className={`${mandatory ? 'required' : ''}`}>{label}</span>
      </label>
      <Select
        className='multi-select-container'
        name={fieldName}
        value={selectedOption}
        onChange={(e: any) => {
          setFieldValue(fieldName, e?.value || null)
        }}
        placeholder={fieldPlaceholder}
        options={optionsArr as any}
        isClearable={true}
        {...rest}
        isDisabled={disabled}
      />
      <div className='text-danger'>
        <ErrorMessage name={fieldName} />
      </div>
    </>
  )
}

export {ReactSelect}
