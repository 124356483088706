import * as actions from '../redux/ClientsAction'
import React, {useEffect, useState} from 'react'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {FieldArray, Form, Formik} from 'formik'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {Address} from '../../../Common/Address'
import {OtherContacts} from '../../../Common/OtherContacts'
import {
  clientInitialValues,
  clientSchema,
  communicationMediums,
  companySize,
  currencies,
  getBreadCrumbs,
  industries,
} from '../core/ClientUIHelper'
import {ReactSelect} from '../../../Common/Select'
import {InputField} from '../../../Common/fields/InputField'
import {timezones} from '../core/ClientTimezone'

interface IClientProps {
  id?: string | null
  onHide: () => void
}

export const ClientsAdd: React.FC<IClientProps> = ({id, onHide}) => {
  const dispatch = useDispatch()
  const [disabled] = useState(window.location.pathname.includes('view'))

  const {clientDetail, listLoading, errorMessage}: any = useSelector<RootState>(
    (state) => ({
      userInfo: state.auth.user,
      clientDetail: state.clients.clientDetail,
      listLoading: state.clients.listLoading,
      errorMessage: state.clients.errorMessage,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (id) {
      dispatch(actions.fetchClient(id))
    }
    return () => {
      dispatch(actions.resetClient())
    }
  }, [])

  const onSubmit = async (values: any) => {
    try {
      if (id) {
        await dispatch(actions.updateClients(values))
      } else {
        await dispatch(actions.createClients(values))
      }
      onHide()
    } catch (err) {
      console.log('error', err)
    }
  }

  return (
    <>
      <div className='page-content mh-80'>
        <div className='px-12'>
          <div className='row'>
            <div className='col-sm-6'>
              <h2 className='fw-500 fs-2 m-0 py-5'>
                {id && !disabled ? 'Edit Client' : id && disabled ? 'View Client' : 'Create Client'}
              </h2>
            </div>
          </div>
        </div>
        <Breadcrumbs crumbs={getBreadCrumbs(id, disabled)} />
        <div className='page-body py-lg-10 px-lg-10 overflow_height_scroll'>
          <div
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_app_stepper'
          >
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              {errorMessage && <div className='alert alert-danger'>{errorMessage}</div>}

              <Formik
                enableReinitialize={true}
                validationSchema={clientSchema}
                initialValues={{...clientInitialValues, ...clientDetail}}
                onSubmit={onSubmit}
              >
                {({values, setFieldValue}) => (
                  <Form className='form' noValidate id='kt_modal_create_app_form'>
                    <fieldset disabled={disabled}>
                      <div className='current' data-kt-s tepper-element='content'>
                        <div className='w-100'>
                          <div className='row'>
                            <div className='col-4 mb-5'>
                              <InputField
                                label='First name'
                                fieldTye='text'
                                fieldName='firstName'
                                fieldPlaceholder='First name'
                                mandatory={true}
                              />
                            </div>
                            <div className='col-4 mb-5'>
                              <InputField
                                label='Last name'
                                fieldTye='text'
                                fieldName='lastName'
                                fieldPlaceholder='Last name'
                                mandatory={true}
                              />
                            </div>
                            <div className='col-4 mb-5'>
                              <InputField
                                label='Nick name'
                                fieldTye='text'
                                fieldName='nickName'
                                fieldPlaceholder='Nick name'
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6 mb-5'>
                              <InputField
                                label='Email'
                                fieldTye='text'
                                fieldName='email'
                                fieldPlaceholder='Email address'
                                mandatory={true}
                              />
                            </div>
                            <div className='col-6 mb-5'>
                              <InputField
                                label='Phone no. / Mobile no.'
                                fieldTye='text'
                                fieldName='mobile'
                                fieldPlaceholder='Phone no. / Mobile no.'
                              />
                            </div>
                          </div>
                          <Address
                            values={values?.address}
                            setFieldValue={setFieldValue}
                            field={'address'}
                            mandatory={false}
                          />
                          <div className='row'>
                            <div className='col-6 mb-5'>
                              <InputField
                                label='Company name'
                                fieldTye='text'
                                fieldName='companyName'
                                fieldPlaceholder='Company name'
                              />
                            </div>
                            <div className='col-6 mb-5'>
                              <ReactSelect
                                label='Company size'
                                fieldName='companySize'
                                fieldValue={values?.companySize}
                                fieldPlaceholder='Select size of company'
                                options={companySize}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6 mb-5'>
                              <InputField
                                label='Business email'
                                fieldTye='text'
                                fieldName='businessEmail'
                                fieldPlaceholder='Business email'
                              />
                            </div>
                            <div className='col-6 mb-5'>
                              <InputField
                                label='Business contact'
                                fieldTye='text'
                                fieldName='businessContact'
                                fieldPlaceholder='Business contact'
                              />
                            </div>
                          </div>
                          <div className='row'>
                            {/* <div className='col-6 mb-5 d-none'>
                              <ReactSelect
                                label='Currency'
                                fieldName='currency'
                                fieldValue={values?.currency}
                                fieldPlaceholder='Select currency'
                                options={currencies}
                                setFieldValue={setFieldValue}
                              />
                            </div> */}
                            <div className='col-6 mb-5'>
                              <ReactSelect
                                label='Industry'
                                fieldName='industry'
                                fieldValue={values?.industry}
                                fieldPlaceholder='Select an industry'
                                options={industries}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                            <div className='col-6 mb-5'>
                              <ReactSelect
                                label='Timezone'
                                fieldName='timezone'
                                fieldValue={values?.timezone}
                                fieldPlaceholder='Select timezone'
                                options={timezones}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6 mb-5'>
                              <ReactSelect
                                label='Communication medium'
                                fieldName='communicationMedium'
                                fieldValue={values?.communicationMedium}
                                fieldPlaceholder='Select communication medium'
                                options={communicationMediums}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                            <div className='col-6 mb-5'>
                              <InputField
                                label='Communication medium contact'
                                fieldTye='text'
                                fieldName='communicationMediumContact'
                                fieldPlaceholder='Communication medium contact'
                              />
                            </div>
                            <h4>Additional contacts</h4>
                            <FieldArray
                              name='otherContacts'
                              render={(arrayHelpers) => (
                                <OtherContacts
                                  values={values?.otherContacts}
                                  arrayHelpers={arrayHelpers}
                                  field='otherContacts'
                                  setFieldValue={setFieldValue}
                                  mandatory={false}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    <div className='d-flex flex-stack pt-10'>
                      <div> </div>
                      {!disabled && (
                        <div className='me-2'>
                          <button
                            type='button'
                            className='btn cancel_button fs-7 fw-bolder me-3'
                            onClick={() => onHide()}
                            title='Cancel'
                          >
                            Cancel
                          </button>
                          <button
                            type='submit'
                            className='btn btn-sm btn-blue fs-7'
                            title={id ? 'Update' : 'Create'}
                          >
                            <span className='indicator-label'>{id ? 'Update' : 'Create'}</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
