import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const APPOINTMENTS_URL = `${API_URL}/event-scheduling`
export function getAppointmentsList({
  search,
  page,
  orderby,
  sortby,
  createdAt,
}: {
  search: string
  page: number | string
  orderby: string
  sortby: string
  createdAt: string
}) {
  return axios.get(APPOINTMENTS_URL, {
    params: {
      search,
      page,
      orderby,
      sortby,
      createdAt,
    },
  })
}
export function getScheduledAppointmentsList({
  search,
  page,
  orderby,
  sortby,
  createdAt,
}: {
  search: string
  page: number | string
  orderby: string
  sortby: string
  createdAt: string
}) {
  return axios.get(`${APPOINTMENTS_URL}/scheduled-events`, {
    params: {
      search,
      page,
      orderby,
      sortby,
      createdAt,
    },
  })
}
export function addAppointmentsList(AppointmentsToCreate: any) {
  return axios.post(APPOINTMENTS_URL, AppointmentsToCreate)
}
export function updateAppointmentsList(_id: string, AppointmentsToCreate: any) {
  return axios.patch(`${APPOINTMENTS_URL}/${_id}`, AppointmentsToCreate)
}

export function deleteAppointmentsList(_id: string) {
  return axios.delete(`${APPOINTMENTS_URL}/${_id}`)
}
export function deleteScheduledAppointmentsList(_id: string) {
  return axios.delete(`${APPOINTMENTS_URL}/scheduled-events/${_id}`)
}

export function fetchAppointmentsById(_id: string) {
  return axios.get(`${APPOINTMENTS_URL}/${_id}`)
}

export function addAppointmentTimeSlot(AppointmentTimeSlotToCreate: any, AppointmentId: string) {
  return axios.post(`${APPOINTMENTS_URL}/time-slot/${AppointmentId}`, AppointmentTimeSlotToCreate)
}

export function sendAppointmentEvent(appointEventValues: any) {
  return axios.post(`${APPOINTMENTS_URL}/sendEvent`, appointEventValues)
}

export function getAppointmentTimeSlot(AppointmentId: string, month: number, year: number) {
  return axios.get(`${APPOINTMENTS_URL}/time-slot/${AppointmentId}/${month}/${year}`)
}
