import {ErrorMessage, Field, Form, Formik} from 'formik'
import React, {FC, useEffect, useRef, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import * as boardActions from '../../redux/BoardActions'
import {Link, useParams} from 'react-router-dom'
import {ColumnActions} from './ColumnActions'
import {DeleteColumnPopup} from './DeleteColumnPopup'
import {DragSourceMonitor, useDrag, useDrop} from 'react-dnd'
import scroll from '../scrolling'
import {TaskChildren} from './TaskChildren'

type Props = {
  column: any
  index: any
  moveList: any
  dragType: string
  sortColumns: any
  children: any
  dragTaskType: any
  handleDragType: any
  moveCard: any
  cardIndex: any
  // columnIndex: any
  sortTasks: any
}

const BoardColumnList: FC<Props> = ({
  dragTaskType,
  handleDragType,
  column,
  index,
  moveList,
  dragType,
  sortColumns,
  children,
  moveCard,
  cardIndex,
  sortTasks,
  // columnIndex,
}) => {
  const {currentProject, loginUser}: any = useSelector<RootState>(
    (state) => ({
      currentProject: state.board.currentProject,
      loginUser: state.auth.user,
    }),
    shallowEqual
  )

  const {workspaceid, id}: {workspaceid: string; id: string} = useParams()
  const [changeName, setChangeName] = useState<boolean>(false)
  const [showActions, setShowActions] = useState<boolean>(false)
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [columnId, setColumnId] = useState<string>('')
  const [columnAction, setColumnAction] = useState<any>('')
  const wrapperRef: any = useRef(null)
  const [canDrag, setCanDrag] = useState(false)

  const dispatch: any = useDispatch()

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef?.current && !wrapperRef?.current?.contains(event?.target)) {
        setChangeName(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const editColumn = (columnId: any) => {
    setChangeName(true)
    setColumnId(columnId)
  }
  const updateColumn = (values: any, board: any) => {
    const updateColumn: {
      _id: string
      name: string
      workSpace: string
      board: string
      project: string
      order: string
    } = {
      _id: columnId,
      name: values.name,
      workSpace: workspaceid ? workspaceid : '',
      board: board,
      project: currentProject._id,
      order: values.order,
    }
    dispatch(boardActions.updateColumn(updateColumn)).then((res: any) => {
      if (res.status === 200) {
        dispatch(boardActions.currentProject(id))
        // setChangeName(false)
      }
    })
  }

  const columnActions = (columnData: any) => {
    setShowActions(true)
    setColumnAction(columnData)
  }

  const [{isDragging}, dragRef] = useDrag(
    () => ({
      type: 'item',

      canDrag: (monitor: DragSourceMonitor) => {
        return true
      },
      item: {...column, index},
      collect: (monitor: DragSourceMonitor) => ({
        // dropTask(monitor),

        isDragging: monitor.isDragging(),
      }),
    }),
    [canDrag]
  )

  const [, dropRef] = useDrop({
    accept: 'item',
    canDrop: (monitor: any) => {
      return true
    },
    hover(column, monitor: any) {
      scroll?.addEventListenerForSidebar()
      if (dragType !== 'column') {
        return
      }

      const dragIndex = column?.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref?.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect?.bottom - hoverBoundingRect?.top) / 2

      // Get horizontal middle
      const hoverMiddleX = (hoverBoundingRect?.right - hoverBoundingRect?.left) / 2

      // Determine mouse position
      const clientOffset = monitor?.getClientOffset()

      // Get pixels to the top
      const hoverClientY = clientOffset?.y - hoverBoundingRect?.top

      // Get pixels to the left
      const hoverClientX = clientOffset?.x - hoverBoundingRect?.left

      const upwards = dragIndex > hoverIndex && hoverClientY > hoverMiddleY
      const downwards = dragIndex < hoverIndex && hoverClientY < hoverMiddleY

      const leftwards = dragIndex > hoverIndex && hoverClientX > hoverMiddleX
      const rightwards = dragIndex < hoverIndex && hoverClientX < hoverMiddleX

      if (upwards && (leftwards || rightwards)) {
        return
      }
      if (downwards && (leftwards || rightwards)) {
        return
      }
      moveList(dragIndex, hoverIndex)
      column.index = index
    },
    drop(column: any, monitor: any) {
      if (dragType === 'column') {
        scroll.removeEventListenerForSidebar()
        sortColumns()
      }
      setCanDrag(false)
    },
  })

  const ref: any = useRef(null)
  const dragDropRef: any = dragRef(dropRef(ref))
  const opacity = isDragging ? 0.5 : 1

  useEffect(() => {
    if (dragDropRef && dragDropRef?.current) {
      dragDropRef.current.addEventListener(
        'click',
        (e: any) => {
          if (
            e.target.parentNode.className === 'kanban-title-board' ||
            e.target.className === 'kanban-title-board'
          ) {
            setCanDrag(true)
          }
        },
        false
      )
    }
  }, [dragDropRef])

  return (
    <>
      <DeleteColumnPopup
        showPopup={showPopup}
        handleClose={() => {
          setShowPopup(false)
        }}
        column={columnAction}
      />
      <div className='kanban-board'>
        <main className='kanban-drag'>
          <header className='kanban-board-header primary mb-6' ref={dragDropRef} style={{opacity}}>
            <div className='kanban-title-board'>
              <div className='d-flex justify-content-between align-items-center margin-10'>
                <div className='d-flex'>
                  {!changeName && (
                    // <Link to={'#'}>
                    <div>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        height='15px'
                        width='15px'
                        style={{marginRight: '-10px'}}
                        viewBox='0 0 128 512'
                      >
                        <path d='M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z' />
                      </svg>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        height='15px'
                        width='15px'
                        viewBox='0 0 128 512'
                      >
                        <path d='M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z' />
                      </svg>
                    </div>
                  )}
                  {changeName && columnId === column?._id ? (
                    <Formik
                      initialValues={{name: column.name, order: column.order}}
                      onSubmit={(values, {resetForm}) => {
                        updateColumn(values, currentProject?.board?._id)
                      }}
                    >
                      {({isSubmitting}) => (
                        <Form
                          ref={wrapperRef}
                          className=' todo-bar-search-from position-relative px-0 boder-1 rounded'
                        >
                          <Field
                            type='text'
                            name='name'
                            className='kanban-title-input form-control-flush py-2'
                            placeholder='Column Name...'
                          />
                          <ErrorMessage name='name' component='div' />
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <h2
                      className='px-2'
                      onClick={() =>
                        (column?.workSpace?.maintainers?.includes(loginUser?._id) ||
                          column?.workSpace?.owner === loginUser?._id ||
                          column?.project?.createdBy === loginUser?._id) &&
                        editColumn(column._id)
                      }
                    >
                      {column?.name}
                      {/* {column?.task?.length > 0 && (
                        <span className='fs-12px px-1'>{column?.task?.length}</span>
                      )} */}
                    </h2>
                  )}
                </div>

                {(column?.workSpace?.maintainers?.includes(loginUser?._id) ||
                  column?.workSpace?.owner === loginUser?._id ||
                  column?.project?.createdBy === loginUser?._id) &&
                  !changeName && (
                    <Link to={'#'}>
                      <div className='position-relative'>
                        <svg
                          onClick={() => columnActions(column)}
                          xmlns='http://www.w3.org/2000/svg'
                          height='15px'
                          width='15px'
                          viewBox='0 0 512 512'
                        >
                          <path d='M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z' />
                        </svg>
                        {showActions && columnAction?._id === column?._id && (
                          <ColumnActions
                            columnId={columnId}
                            setShowActions={setShowActions}
                            setShowPopup={setShowPopup}
                            name={column.name}
                            setChangeName={setChangeName}
                          />
                        )}
                      </div>
                    </Link>
                  )}
                {}
              </div>
            </div>
            {/* <div className='scroll-class'>{children}</div> */}
            <TaskChildren
              children={children}
              dragType={dragType}
              handleDragType={handleDragType}
              index={cardIndex}
              columnIndex={index}
              board={currentProject?.board?._id}
              moveCard={moveCard}
              task={null}
              sortTasks={sortTasks}
            />
          </header>
        </main>
      </div>
    </>
  )
}

export {BoardColumnList}
