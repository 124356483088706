import React, {useEffect} from 'react'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import {useDispatch} from 'react-redux'
import * as actions from '../redux/ProvidentFundAction'
import {NumericFormat} from 'react-number-format'

export const ProvidentFundBasicForm: React.FC = () => {
  const dispatch = useDispatch()
  const {values, setFieldValue, setTouched}: any = useFormikContext()
  useEffect(() => {
    dispatch(actions.setLoanType(values.loanType))
    setTouched({}, false)
  }, [values?.loanType])

  return (
    <>
      <div className='row fv-row mb-5'>
        <label className='fs-6 fw-bold mb-2 required'>
          <span>Select Request Type</span>
        </label>
        <div className='col-2 col-sm-3 py-2 py-sm-0'>
          <label>
            <Field
              type='radio'
              name='loanType'
              value='temporaryLoan'
              className=' form-check-input smaller-form-input me-2'
            />
            Temporary Withdrawal
          </label>
        </div>
        <div className='col-2 col-sm-3 py-2 py-sm-0'>
          <label>
            <Field
              type='radio'
              name='loanType'
              value='permanentLoan'
              className=' form-check-input smaller-form-input me-2'
            />
            Permanent Withdrawal
          </label>
        </div>
        <div className='col-2 col-sm-3 py-2 py-sm-0'>
          <label>
            <Field
              type='radio'
              name='loanType'
              value='fullLoanSettlement'
              className=' form-check-input smaller-form-input me-2'
            />
            Loan Settlement
          </label>
        </div>
        <div className='col-2 col-sm-3 py-2 py-sm-0'>
          <label>
            <Field
              type='radio'
              name='loanType'
              value='PFOptOutForm'
              className=' form-check-input smaller-form-input me-2'
            />
            Opt Out
          </label>
        </div>
      </div>

      {values?.loanType !== 'PFOptOutForm' && (
        <>
          <div className='col-md-6 fv-row mb-5'>
            <label className='fs-6 fw-bold mb-2 required'>
              <span>Member Name</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg  form-control-solid'
              name={`${values.loanType}.memberName`}
              placeholder='Member Name'
            />
            <div className='text-danger'>
              <ErrorMessage name={`${values.loanType}.memberName`} />
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-6 fw-bold required mb-2'>
              Employee ID
            </label>
            <NumericFormat
              placeholder='Employee ID'
              className='form-control form-control-lg form-control-solid'
              name={`${values.loanType}.employeeId`}
              value={values?.[values?.loanType]?.['employeeId']}
              onChange={(e: any) => {
                setFieldValue(`${values.loanType}.employeeId`, +e.target.value)
              }}
            />

            <div className='text-danger'>
              <ErrorMessage name={`${values.loanType}.employeeId`} />
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='fs-6 fw-bold fw-bold form-label mb-2 required'>Designation</label>
            <div className='row fv-row'>
              <div className='col-12'>
                <Field
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name={`${values.loanType}.designation`}
                  placeholder='Designation'
                />
                <div className='text-danger'>
                  <ErrorMessage name={`${values.loanType}.designation`} />
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-6 fw-bold mb-2 required'>
              Department
            </label>

            <div className='col-12'>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name={`${values.loanType}.department`}
                placeholder='Department'
              />
              <div className='text-danger'>
                <ErrorMessage name={`${values.loanType}.department`} />
              </div>
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-6 fw-bold  mb-2 required'>
              <span className=''>Date of Joining (PF)</span>
            </label>

            <Field
              className='form-control form-control-lg form-control-solid'
              name={`${values.loanType}.dateOfPFJoining`}
              placeholder='Date of Joining (PF)'
              type='date'
            />

            <div className='text-danger'>
              <ErrorMessage name={`${values.loanType}.dateOfPFJoining`} />
            </div>
          </div>
        </>
      )}
    </>
  )
}
