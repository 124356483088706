import moment from 'moment'
import React, {useEffect, useRef, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {Search} from '../../../../_metronic/layout/search/Search'
import '../../../../_metronic/assets/sass/components/todo-table-row.scss'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import * as actions from '../redux/ProvidentFundAction'
import {Pagination} from '../../../pagination/pagination'
import {NotFound} from '../../../../_metronic/layout/NotFound'
import {Loading} from '../../../../_metronic/layout/Loading'
import {PFModel, ProvidentFundCommon, PFResponseInterface} from '../Models/ProvidentFundModel'
import {Can} from '../../../Common/RBAC/Can'
import {ActionModal} from '../../../Common/modals/ActionModal'
import PrintTable from './PrintTable'
import {useReactToPrint} from 'react-to-print'
import Ability from '../../../Common/RBAC/Ability'

type Props = {
  className: string
}

const allowedStatuses = ['Draft', 'Return']

type ProvidentFund = [
  {
    memberName: string
    employeeId: number
    designation: string
    department: string
    appointmentDate: Date
    dateOfPFJoining: Date
  }
]

interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  page: number
}

type LoanType = {
  temporaryLoan: string
  permanentLoan: string
  fullLoanSettlement: string
  PFOptOutForm: string
}

const loanType: any = {
  temporaryLoan: 'Temporary Withdrawal',
  permanentLoan: 'Permanent withdrawal',
  fullLoanSettlement: 'Loan Settlement',
  PFOptOutForm: 'Opt Out',
}

export const getValue = (item: any, loanType: string, property: string): string | undefined => {
  return item?.[loanType]?.[property] || ''
}

export const ProvidentFundTable: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()
  let componentRef: any = React.createRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const [providentFundList, setProvidentFundList] = useState<PFResponseInterface[]>([])
  const [PFData, setPFData] = useState<any>()
  const [sortValues, setSortValues] = useState({
    orderby: 'desc',
    sortby: '',
    status: '',
    createdAt: '',
    activeDate: '',
    assignee: '',
    teams: '',
  })
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [itemToDelete, setItemToDelete] = useState('')

  const providentFundData: PFResponseInterface[] = useSelector<RootState>(
    (state) => state.providentFund.entities,
    shallowEqual
  ) as PFResponseInterface[]

  const isLoading: ProvidentFund = useSelector<RootState>(
    (state) => state.providentFund.listLoading,
    shallowEqual
  ) as ProvidentFund
  const treasurers: ProvidentFund = useSelector<RootState>(
    (state) => state.providentFund.treasurers,
    shallowEqual
  ) as ProvidentFund

  const paginationData: PaginationModel = useSelector<RootState>(
    (state) => state.providentFund,
    shallowEqual
  ) as PaginationModel

  useEffect(() => {
    if (PFData) {
      handlePrint()
    }
  }, [PFData])

  useEffect(() => {
    if (providentFundData.length > 0) {
      setProvidentFundList(providentFundData)
    } else {
      setProvidentFundList([])
    }
  }, [providentFundData])

  useEffect(() => {
    if (providentFundData.length > 0) {
      setProvidentFundList(providentFundData)
    } else {
      setProvidentFundList([])
    }
  }, [providentFundData])

  const removeSortingClass = () => {
    const cells = document.querySelectorAll('th')
    cells.forEach(function (cell) {
      cell.classList.remove('table-sort-desc')
      cell.classList.remove('table-sort-asc')
    })
  }

  useEffect(() => {
    let tableHead: any
    tableHead = document.getElementById(`todo-${sortValues.sortby}-head`)

    if (sortValues.orderby === 'desc' && tableHead && providentFundList?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-asc')
      tableHead.classList.add('table-sort-desc')
    } else if (tableHead && providentFundList?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-desc')
      tableHead.classList.add('table-sort-asc')
    }
    dispatch(
      actions.fetchProvidentFund({
        search: '',
        page: '',
        sortby: sortValues.sortby,
        orderby: sortValues.orderby,
        status: sortValues.status,
        createdAt: sortValues.createdAt,
        activeDate: sortValues.activeDate,
      })
    )
  }, [sortValues])

  const deleteProvidentFund = (_id: string) => async (event: any) => {
    setItemToDelete(_id)
    setShowDeleteDialog(true)
  }

  const handleSearch = (search: string) => {
    dispatch(
      actions.fetchProvidentFund({
        search,
        page: paginationData?.page,
        sortby: '',
        orderby: '',
        status: '',
        createdAt: '',
        activeDate: '',
      })
    )
  }

  const handlePagination = (event: any) => {
    dispatch(
      actions.fetchProvidentFund({
        search: '',
        page: event.selected + 1,
        sortby: '',
        orderby: '',
        status: '',
        createdAt: '',
        activeDate: '',
      })
    )
  }

  let crumbs

  crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Provident Fund Requests', url: `/provident-fund`},
  ]

  const sortColumn = (event: any) => {
    if (event.target.classList.contains('table-sort-desc')) {
      removeSortingClass()
      setSortValues({
        orderby: 'asc',
        sortby: event.target.abbr,
        status: sortValues.status,
        createdAt: sortValues.createdAt,
        activeDate: sortValues.activeDate,
        assignee: sortValues.assignee,
        teams: sortValues.teams,
      })
      event.target.classList.remove('table-sort-desc')
      event.target.classList.add('table-sort-asc')
    } else {
      removeSortingClass()
      setSortValues({
        orderby: 'desc',
        sortby: event.target.abbr,
        status: sortValues.status,
        createdAt: sortValues.createdAt,
        activeDate: sortValues.activeDate,
        assignee: sortValues.assignee,
        teams: sortValues.teams,
      })
      event.target.classList.remove('table-sort-asc')
      event.target.classList.add('table-sort-desc')
    }
  }

  return (
    <>
      <div style={{display: 'none'}}>
        <PrintTable ref={componentRef} PFData={PFData} treasurers={treasurers || []} />
      </div>
      <ActionModal
        show={showDeleteDialog}
        label='Delete Provident fund request'
        description='Are you sure you want to delete this request?'
        handleClose={({canDelete, id}: {canDelete: boolean; id: string}) => {
          if (canDelete) {
            dispatch(actions.deleteProvidentFund(id))
          }
          setShowDeleteDialog(false)
        }}
        id={itemToDelete}
        buttonLabel='Delete'
      />
      <div className={`mh-80 tabs-main card legend-card-main rounded-0`}>
        {!isLoading && (
          <div className='px-6 px-sm-12'>
            <div className='row'>
              <div className='col-sm-6'>
                <h2 className='fw-500 fs-2 m-0 py-5'>Provident Fund Requests</h2>
              </div>
              <div className='col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center'>
                <Search handleSearch={handleSearch} />
              </div>
            </div>
          </div>
        )}
        <div className='d-flex justify-content-end align-items-center bg-lighter flex-sm-nowrap flex-wrap'>
          {crumbs && <Breadcrumbs crumbs={crumbs} />}
          <Can I='create' this='provident-fund'>
            <Link className='bg-lighter me-5 me-sm-10' to={`/provident-fund/add`}>
              <span
                className='btn btn-sm  bg-transparent text-blue pe-5 d-flex align-items-center'
                style={{whiteSpace: 'nowrap'}}
              >
                <KTSVG path='' className='svg-icon-3' />
                <i className='fa fa-plus-circle link_button' aria-hidden='true'></i>
                <span className='link_button'> Create Provident Fund Request</span>
              </span>
            </Link>
          </Can>
        </div>

        <>
          <div className='overflow_height_scroll'>
            <div className='card-body d-flex flex-column py-3 px-10'>
              {!isLoading && providentFundList?.length > 0 ? (
                <div className='table-responsive'>
                  <table className='todo-table table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th
                          className=''
                          style={{cursor: 'pointer', minWidth: '95px'}}
                          onClick={sortColumn}
                          abbr='memberName'
                          id='member-name'
                        >
                          Member Name
                        </th>

                        <th
                          className='min-w-100px'
                          style={{cursor: 'pointer'}}
                          onClick={sortColumn}
                          abbr='employeeId'
                          id='todo-createdBy-head'
                        >
                          Employee ID
                        </th>

                        <th
                          className='min-w-100px'
                          style={{cursor: 'pointer'}}
                          onClick={sortColumn}
                          abbr='designation'
                          id='todo-activeDate-head'
                        >
                          Designation
                        </th>

                        <th
                          className='min-w-80px'
                          style={{cursor: 'pointer'}}
                          onClick={sortColumn}
                          abbr='loanType'
                          id='request-type'
                        >
                          Request Type
                        </th>

                        <th
                          className='min-w-100px table-sort-desc'
                          style={{cursor: 'pointer', minWidth: '125px'}}
                          onClick={sortColumn}
                          abbr='department'
                          id='todo-createdAt-head'
                        >
                          Department
                        </th>

                        <th
                          className='min-w-100px'
                          style={{cursor: 'pointer'}}
                          onClick={sortColumn}
                          abbr='status'
                          id='todo-status-head'
                        >
                          Status
                        </th>
                        <th className='min-w-100px '>Actions</th>
                      </tr>
                    </thead>
                    <Can I='list' this='provident-fund'>
                      <tbody>
                        {providentFundList?.map(
                          (providentFund: PFResponseInterface, index: number) => {
                            return (
                              <tr key={index}>
                                <td data-label='Technology' className='w-20'>
                                  {Ability.can('read', 'provident-fund') ? (
                                    <Link
                                      to={`/provident-fund/${providentFund?._id}`}
                                      className='w-100'
                                    >
                                      <div className='d-flex justify-content-start flex-column line-clamp'>
                                        <span
                                          title={getValue(
                                            providentFund,
                                            providentFund.loanType,
                                            'memberName'
                                          )}
                                        >
                                          {getValue(
                                            providentFund,
                                            providentFund.loanType,
                                            'memberName'
                                          )}
                                        </span>
                                      </div>
                                    </Link>
                                  ) : (
                                    <div className='d-flex justify-content-start flex-column line-clamp'>
                                      <span
                                        title={getValue(
                                          providentFund,
                                          providentFund.loanType,
                                          'memberName'
                                        )}
                                      >
                                        {getValue(
                                          providentFund,
                                          providentFund.loanType,
                                          'memberName'
                                        )}
                                      </span>
                                    </div>
                                  )}
                                </td>
                                <td
                                  data-label='Created By'
                                  className='text-end'
                                  title={getValue(
                                    providentFund,
                                    providentFund.loanType,
                                    'employeeId'
                                  )}
                                >
                                  <div className='d-flex flex-column me-2'>
                                    <div className='d-flex flex-stack'>
                                      {getValue(
                                        providentFund,
                                        providentFund.loanType,
                                        'employeeId'
                                      )}
                                    </div>
                                  </div>
                                </td>

                                <td
                                  data-label='Created By'
                                  className='text-end'
                                  title={getValue(
                                    providentFund,
                                    providentFund.loanType,
                                    'designation'
                                  )}
                                >
                                  <div className='d-flex flex-column me-2'>
                                    <div className='d-flex flex-stack'>
                                      {getValue(
                                        providentFund,
                                        providentFund.loanType,
                                        'designation'
                                      )}
                                    </div>
                                  </div>
                                </td>

                                <td
                                  className='w-20'
                                  data-label='Lead'
                                  title={loanType[providentFund?.loanType]}
                                >
                                  <div className='d-flex align-items-center justify-content-start flex-column line-clamp'>
                                    {loanType[providentFund?.loanType]}
                                  </div>
                                </td>
                                <td
                                  data-label='Created Date'
                                  className='text-end'
                                  title={getValue(
                                    providentFund,
                                    providentFund.loanType,
                                    'department'
                                  )}
                                >
                                  <div className='d-flex flex-column me-2'>
                                    <div className='d-flex flex-stack'>
                                      {getValue(
                                        providentFund,
                                        providentFund.loanType,
                                        'department'
                                      )}
                                    </div>
                                  </div>
                                </td>

                                <td data-label='Active Date' title={providentFund?.status || '-'}>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start flex-column'>
                                      {providentFund?.status || '-'}
                                    </div>
                                  </div>
                                </td>

                                <td data-label='Actions' className='action_btns'>
                                  <div className=' flex-shrink-0'>
                                    <Can I='read' this='provident-fund'>
                                      <Link
                                        to={`/provident-fund/${providentFund?._id}`}
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
                                        title='View'
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          xmlnsXlink='http://www.w3.org/1999/xlink'
                                          width='28px'
                                          height='28px'
                                          viewBox='0 0 30 30'
                                          version='1.1'
                                          className='kt-svg-icon'
                                        >
                                          <g
                                            stroke='none'
                                            stroke-width='1'
                                            fill='none'
                                            fill-rule='evenodd'
                                          >
                                            <rect x='0' y='0' width='24' height='24' />
                                            <path
                                              d='M3,12 C3,12 5.45454545,6 12,6 C16.9090909,6 21,12 21,12 C21,12 16.9090909,18 12,18 C5.45454545,18 3,12 3,12 Z'
                                              fill='#000'
                                              fill-rule='nonzero'
                                              opacity='0.3'
                                            />
                                            <path
                                              d='M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z'
                                              fill='#000000'
                                              opacity='0.3'
                                            />
                                          </g>
                                        </svg>
                                      </Link>
                                    </Can>
                                    <Can I='update' this='provident-fund'>
                                      {allowedStatuses.includes(providentFund?.status) && (
                                        <Link
                                          to={`/provident-fund/${providentFund?._id}/edit`}
                                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
                                          title='Edit'
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/art/art005.svg'
                                            className='svg-icon-3'
                                          />
                                        </Link>
                                      )}
                                    </Can>
                                    <Can I='delete' this='provident-fund'>
                                      {allowedStatuses.includes(providentFund?.status) && (
                                        <button
                                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm bg-light-blue-actions'
                                          data-bs-toggle='modal'
                                          data-bs-target='#kt_modal_delete_app'
                                          id='kt_toolbar_primary_button'
                                          onClick={deleteProvidentFund(providentFund?._id || '')}
                                          title='Delete'
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen027.svg'
                                            className='svg-icon-3'
                                          />
                                        </button>
                                      )}
                                    </Can>
                                    <Can I='read' this='provident-fund'>
                                      <button
                                        className='ms-1 btn btn-icon btn-bg-light btn-active-color-primary btn-sm bg-light-blue-actions'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_delete_app'
                                        id='kt_toolbar_primary_button'
                                        onClick={() => {
                                          setPFData(providentFund)
                                        }}
                                        title='Print'
                                      >
                                        <span className='svg-icon svg-icon-2x'>
                                          {' '}
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            xmlnsXlink='http://www.w3.org/1999/xlink'
                                            width='28px'
                                            height='28px'
                                            viewBox='0 0 30 30'
                                            version='1.1'
                                          >
                                            <g
                                              stroke='none'
                                              stroke-width='1'
                                              fill='none'
                                              fill-rule='evenodd'
                                            >
                                              <rect x='0' y='0' width='28' height='28' />
                                              <path
                                                d='M16,17 L16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 L8,17 L5,17 C3.8954305,17 3,16.1045695 3,15 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,15 C21,16.1045695 20.1045695,17 19,17 L16,17 Z M17.5,11 C18.3284271,11 19,10.3284271 19,9.5 C19,8.67157288 18.3284271,8 17.5,8 C16.6715729,8 16,8.67157288 16,9.5 C16,10.3284271 16.6715729,11 17.5,11 Z M10,14 L10,20 L14,20 L14,14 L10,14 Z'
                                                fill='#000000'
                                              />
                                              <rect
                                                fill='#000000'
                                                opacity='0.3'
                                                x='8'
                                                y='2'
                                                width='8'
                                                height='2'
                                                rx='1'
                                              />
                                            </g>
                                          </svg>
                                        </span>
                                      </button>
                                    </Can>
                                  </div>
                                </td>
                              </tr>
                            )
                          }
                        )}
                      </tbody>
                    </Can>
                  </table>
                </div>
              ) : !isLoading && providentFundList?.length <= 0 ? (
                <NotFound />
              ) : (
                <Loading />
              )}
            </div>
          </div>

          <Pagination paginationData={paginationData} fetchData={handlePagination} />
        </>
      </div>
    </>
  )
}
