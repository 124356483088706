/* eslint-disable jsx-a11y/anchor-is-valid */
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FC, useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import * as boardActions from '../../redux/BoardActions'
type Props = {
  index: any
  columnId: any
  board: string
}
const AddNewTask: FC<Props> = ({columnId, board, index}) => {
  const {workspaceid, id}: {workspaceid: string; id: string} = useParams()
  const [addForm, setAddForm] = useState<boolean>(false)
  const wrapperRef: any = useRef(null)
  const dispatch: any = useDispatch()

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        let title = wrapperRef.current.elements['title'].value
        if (title) {
          createTask({title})
        }
        setAddForm(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const createTask = ({title}: {title: string}) => {
    const createTask: {
      title: string
      status: string
      board: string
      project: string
      order: number
    } = {
      title: title,
      status: columnId,
      board: board,
      project: id,
      order: index,
    }
    dispatch(boardActions.createTask(createTask)).then((res: any) => {
      if (res.status === 201) {
        setAddForm(false)
        dispatch(boardActions.currentProject(id))
        const data = {
          project: id,
        }
        dispatch(boardActions.getAllTasksByProjectId(data))
      }
    })
  }

  return (
    <>
      {!addForm ? (
        <div
          className='kanban-title-board kanban-add-card mb-6'
          onClick={() => {
            setAddForm(true)
          }}
        >
          <div className='pb-10'>
            <a className='kanban-board-add-card-hover'>
              <span>
                <i className='fa fa-plus' aria-hidden='true'>
                  <span className='add-card'>Add a task</span>
                </i>
              </span>
            </a>
          </div>
        </div>
      ) : (
        <Formik
          initialValues={{title: ''}}
          validate={(values) => {}}
          onSubmit={(values: any) => {
            createTask(values)
          }}
        >
          {() => (
            <Form
              ref={wrapperRef}
              className=' todo-bar-search-from position-relative boder-1 rounded mt-2 form-search-dimention'
            >
              <Field
                type='text'
                name='title'
                className='kanban-title-input form-control-flush py-2 w-100'
                placeholder='Enter a title...'
              />
              <ErrorMessage name='title' component='div' />
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

export {AddNewTask}
