import * as serverRequest from './ClientsCRUD'
import {clientsSlice, callTypes} from './ClientsSlice'

const {actions} = clientsSlice

export const fetchClients =
  ({
    search,
    page,
    sortby,
    orderby,
    createdAt,
  }: {
    search: string
    page: string
    sortby: string
    orderby: string
    createdAt: string
  }) =>
  (dispatch: any) => {
    if (sortby === '' && page === '') {
      dispatch(actions.startCall({callType: callTypes.list}))
    }
    return serverRequest
      .getClientsList({
        search,
        page,
        sortby,
        orderby,
        createdAt,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data

        dispatch(
          actions.clientsFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
          })
        )
      })
  }
export const createClients =
  (clientData: any) =>
  (dispatch: any): Promise<any> => {
    return serverRequest
      .addClientsList(clientData)
      .then((response) => {})
      .catch((error) => {
        console.log(error?.message)
        dispatch(actions?.setClientErrorMessage({message: error?.response?.data?.message}))
        throw new Error()
      })
  }
export const updateClients = (clientData: any) => (dispatch: any) => {
  return serverRequest
    .updateClientsList(clientData)
    .then((response) => {})
    .catch((error) => {
      console.log(error?.message)
      dispatch(actions?.setClientErrorMessage({message: error?.response?.data?.message}))
      throw new Error()
    })
}

export const deleteClient = (id: string) => (dispatch: any) => {
  return serverRequest.deleteClient(id).then((response) => {
    serverRequest
      .getClientsList({
        search: '',
        page: '',
        sortby: '',
        orderby: '',
        createdAt: '',
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data
        dispatch(
          actions.clientsFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
          })
        )
      })
  })
}

export const fetchClient = (_id: string) => (dispatch: any) => {
  return serverRequest.fetchClient(_id).then((response) => {
    dispatch(actions.clientFetched(response?.data))
  })
}

export const resetClient = () => (dispatch: any) => {
  dispatch(actions.setClientErrorMessage({message: ''}))
  dispatch(
    actions.clientFetched({
      clientDetail: {},
    })
  )
}
