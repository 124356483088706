import {getWorkingHours} from './utils'
import * as Yup from 'yup'

export interface ICreateAccount {
  name: string
  description: string
  status: 'active' | 'inactive'
  venue: string
  venueData: string
  duration: string
  customDuration: number
  dateRangeOption: 'option1' | 'option2' | 'option3'
  selectedDates: null[] | Date[]
  daysOfWeek: any
  numOfDay: number
  beforeBreakTime: string
  afterBreakTime: string
  isBeforeBreakTimeEnabled: boolean
  isAfterBreakTimeEnabled: boolean
  isUserSelectedDateRange: boolean
}

export interface Day {
  label: string
  value: string
  startTime: Date | null
  endTime: Date | null
  isSelected?: true
}

const workingHours = getWorkingHours()

export const weekDays = {
  mon: {
    label: 'Monday',
    value: 'monday',
    startTime: workingHours?.startTime,
    endTime: workingHours?.endTime,
    isSelected: true,
  },
  tue: {
    label: 'Tuesday',
    value: 'tuesday',
    startTime: workingHours?.startTime,
    endTime: workingHours?.endTime,
    isSelected: true,
  },
  wed: {
    label: 'Wednesday',
    value: 'wednesday',
    startTime: workingHours?.startTime,
    endTime: workingHours?.endTime,
    isSelected: true,
  },
  thu: {
    label: 'Thursday',
    value: 'thursday',
    startTime: workingHours?.startTime,
    endTime: workingHours?.endTime,
    isSelected: true,
  },
  fri: {
    label: 'Friday',
    value: 'friday',
    startTime: workingHours?.startTime,
    endTime: workingHours?.endTime,
    isSelected: true,
  },
  sat: {
    label: 'Saturday',
    value: 'saturday',
    startTime: workingHours?.startTime,
    endTime: workingHours?.endTime,
  },
  sun: {
    label: 'Sunday',
    value: 'sunday',
    startTime: workingHours?.startTime,
    endTime: workingHours?.endTime,
  },
}

export const editableWeekDays = {
  mon: {
    label: 'Monday',
    value: 'monday',
    startTime: workingHours?.startTime,
    endTime: workingHours?.endTime,
  },
  tue: {
    label: 'Tuesday',
    value: 'tuesday',
    startTime: workingHours?.startTime,
    endTime: workingHours?.endTime,
  },
  wed: {
    label: 'Wednesday',
    value: 'wednesday',
    startTime: workingHours?.startTime,
    endTime: workingHours?.endTime,
  },
  thu: {
    label: 'Thursday',
    value: 'thursday',
    startTime: workingHours?.startTime,
    endTime: workingHours?.endTime,
  },
  fri: {
    label: 'Friday',
    value: 'friday',
    startTime: workingHours?.startTime,
    endTime: workingHours?.endTime,
  },
  sat: {
    label: 'Saturday',
    value: 'saturday',
    startTime: workingHours?.startTime,
    endTime: workingHours?.endTime,
  },
  sun: {
    label: 'Sunday',
    value: 'sunday',
    startTime: workingHours?.startTime,
    endTime: workingHours?.endTime,
  },
}

export const inits: ICreateAccount = {
  name: '',
  description: '',
  status: 'active',
  venue: 'inPersonMeeting',
  venueData: '',
  duration: '15',
  customDuration: 0,
  dateRangeOption: 'option1',
  selectedDates: [null, null],
  daysOfWeek: weekDays,
  numOfDay: 1,
  beforeBreakTime: '0',
  afterBreakTime: '0',
  isBeforeBreakTimeEnabled: false,
  isAfterBreakTimeEnabled: false,
  isUserSelectedDateRange: false,
}

export interface Props {
  onHide: () => void
  id?: string | null
}

export const weekDaysValidationSchema = Yup.object()
  .shape({
    mon: Yup.object().shape({
      isSelected: Yup.boolean(),
    }),
    tue: Yup.object().shape({
      isSelected: Yup.boolean(),
    }),
    wed: Yup.object().shape({
      isSelected: Yup.boolean(),
    }),
    thu: Yup.object().shape({
      isSelected: Yup.boolean(),
    }),
    fri: Yup.object().shape({
      isSelected: Yup.boolean(),
    }),
    sat: Yup.object().shape({
      isSelected: Yup.boolean(),
    }),
    sun: Yup.object().shape({
      isSelected: Yup.boolean(),
    }),
  })
  .test('at-least-one-selected', 'At least one day must be selected.', (values) => {
    return Object.values(values).some((day) => day.isSelected)
  })

export const releaseNotesValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .label('name')
    .min(1, 'Name must contain more than 3 characters'),
  description: Yup.string().nullable(true),
  venue: Yup.string()
    .required('Venue is required')
    .label('venue')
    .min(1, 'Venue must contain 1 character'),
  venueData: Yup.string()
    .when('venue', (venue, schema) => {
      if (venue === 'inPersonMeeting') {
        return schema.required('Meetup location is required')
      } else if (venue === 'phoneCall') {
        return schema
          .required('Phone number is required')
          .matches(/^\d{11}$/, 'Phone number must be 11 digits')
      } else if (
        venue === 'googleMeet' ||
        venue === 'zoom' ||
        venue === 'microsoftTeams' ||
        venue === 'goToMeeting'
      ) {
        return schema
          .required('Meeting Link is required')
          .matches(/^(https?:\/\/).*/, 'URL must start with "https://" or "http://"')
      }
      return schema.required('Venue data is required')
    })
    .label('venueData')
    .min(1, 'Venue data must contain 1 character'),
  dateRangeOption: Yup.string().optional(),
  duration: Yup.mixed()
    .oneOf(['15', '30', '45', '60', 'custom'], 'Invalid duration value')
    .required('Duration is required'),
  customDuration: Yup.number().when(['duration'], {
    is: (value: any) => value && value.length > 0 && value == 'custom' && true,
    then: () =>
      Yup.number()
        .test(
          'is-multiple-of-15',
          'Custom time interval must be a multiple of 15',
          (value: any) => value % 15 === 0 && value != 0
        )
        .required('Custom Duration is required'),
    otherwise: () => Yup.string().optional(),
  }),
  numOfDay: Yup.number().when(['dateRangeOption'], {
    is: (value: any) => value && value.length > 0 && value == 'option1' && true,
    then: () => Yup.number().min(1, 'Days must contain 1 value'),
    otherwise: () => Yup.string().optional(),
  }),
  selectedDates: Yup.array().when(['dateRangeOption'], {
    is: (value: any) => value && value.length > 0 && value == 'option2' && true,
    then: () =>
      Yup.array()
        .of(Yup.date().required('Date is required'))
        .min(2, 'Two dates are required')
        .max(2, 'Only two dates are allowed'),
    otherwise: () => Yup.array().optional(),
  }),
  daysOfWeek: weekDaysValidationSchema,
  isBeforeBreakTimeEnabled: Yup.boolean().optional(),
  isAfterBreakTimeEnabled: Yup.boolean().optional(),
  beforeBreakTime: Yup.number().when(['isBeforeBreakTimeEnabled'], {
    is: (value: any) => value && value == true && true,
    then: () =>
      Yup.mixed()
        .oneOf(
          ['5', '10', '15', '30', '45', '60', '90', '120', '150', '180'],
          'Invalid before break time'
        )
        .required('Invalid before break is required'),
    otherwise: () => Yup.number().optional(),
  }),
  afterBreakTime: Yup.number().when(['isAfterBreakTimeEnabled'], {
    is: (value: any) => value && value === true && true,
    then: () =>
      Yup.mixed()
        .oneOf(
          ['5', '10', '15', '30', '45', '60', '90', '120', '150', '180'],
          'Invalid before break time'
        )
        .required('Invalid before break is required'),
    otherwise: () => Yup.number().optional(),
  }),
})

export const appointmentEventValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .label('name')
    .min(1, 'Name must contain more than 3 characters'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
})

export interface DateRange {
  start: Date | undefined
  end: Date | undefined | typeof Infinity
}

export const mapEventStatus = {
  active: 'Active',
  inactive: 'In Active',
}

export interface Venue {
  name: string
  value: string
}

export const AppointmentBreaks: Venue[] = [
  {name: '5 min', value: '5'},
  {name: '10 min', value: '10'},
  {name: '15 min', value: '15'},
  {name: '30 min', value: '30'},
  {name: '45 min', value: '45'},
  {name: '1 hr', value: '60'},
  {name: '1 hr 30 min', value: '90'},
  {name: '2 hrs', value: '120'},
  {name: '2 hrs 30 min', value: '150'},
  {name: '3 hrs', value: '180'},
]

export const AppointmentTimeInterval: Venue[] = [
  {name: '15 min', value: '15'},
  {name: '30 min', value: '30'},
  {name: '45 min', value: '45'},
  {name: '60 min', value: '60'},
  {name: 'Custom', value: 'custom'},
]

export const getLocationData: any = {
  inPersonMeeting: 'Location',
  phoneCall: 'Phone Number',
  googleMeet: 'Meeting Link',
  zoom: 'Meeting Link',
  microsoftTeams: 'Meeting Link',
  goToMeeting: 'Meeting Link',
}

export const getStructuredTimeSlots = (bookedTimeSlots: any[]) => {
  const structuredTimeSlots = bookedTimeSlots.map((slot) => ({
    time: slot?.startTime,
    date: slot?.eventDate,
  }))
  return structuredTimeSlots
}

export function convertTo24HourFormat(timeString: any) {
  let timeComponents = timeString.toString().split(':')
  let hours = parseInt(timeComponents[0])
  let minutes = parseInt(timeComponents[1].split(' ')[0])
  let isAM = timeComponents[1].toLowerCase().includes('am')

  if (!isAM && hours !== 12) {
    // For PM times (except 12 PM), add 12 to the hours
    hours += 12
  } else if (isAM && hours === 12) {
    // For 12 AM, set hours to 0
    hours = 0
  }

  // Format hours and minutes in 24-hour format
  let formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
  return formattedTime
}
export interface bookedSlotsObj {
  time: string
  date: string
}
