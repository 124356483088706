/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {TemplateContent} from './filesDownload/TemplateContent'

interface Props {
  show: boolean
  handleClose: () => void
  talentId: string
}

const FileDownload: FC<Props> = ({show, handleClose, talentId}) => {
  return (
    <Modal
      id='kt_modal_delete_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-700px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Download Resume</h2>
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
            <div className='flex-row-fluid px-lg-15'>
              <TemplateContent talendId={talentId} handleClose={handleClose} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {FileDownload}
