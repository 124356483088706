import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {AppointmentsTable} from './components/AppointmentsTable'
import {AppointmentsAdd} from './components/AppointmentsAdd'
import {AppointmentsDetails} from './components/AppointmentsDetails'
import {ScheduledAppointmentsTable} from './components/ScheduledAppointmentsTable'

const Appointments: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path='/appointments/scheduled'>
          <ScheduledAppointmentsTable />
        </Route>
        <Route exact path='/appointments/add'>
          {({match, history}) => (
            <AppointmentsAdd
              onHide={() => {
                history.push('/appointments')
              }}
            />
          )}
        </Route>
        <Route exact path='/appointments/:id/edit'>
          {({match, history}) => (
            <AppointmentsAdd
              id={match && match.params.id}
              onHide={() => {
                history.push(`/appointments`)
              }}
            />
          )}
        </Route>
        <Route exact path='/appointments/:id'>
          {({match, history}) => (
            <AppointmentsDetails
              userId={match && match.params.id}
              onHide={() => {
                history.push(`/appointments`)
              }}
            />
          )}
        </Route>

        <Route exact path='/appointments'>
          <AppointmentsTable />
        </Route>
      </Switch>
    </>
  )
}

export {Appointments}
