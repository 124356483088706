import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const PROVIDENT_FUND_URL = `${API_URL}/provident-fund`
export function getProvidentFund({
  search,
  page,
  orderby,
  sortby,
  status,
  createdAt,
  activeDate,
}: {
  search: string
  page: number | string
  orderby: string
  sortby: string
  status: string
  createdAt: string
  activeDate: string
}) {
  return axios.get(PROVIDENT_FUND_URL, {
    params: {
      search,
      page,
      orderby,
      sortby,
      status,
      createdAt,
      activeDate,
    },
  })
}

export function getProvidentFundById(id: string) {
  return axios.get(`${PROVIDENT_FUND_URL}/${id}`)
}

export function createProvidentFund(body: any) {
  return axios.post(PROVIDENT_FUND_URL, body)
}

export function updateProvidentFund(id: string, body: any) {
  return axios.patch(`${PROVIDENT_FUND_URL}/${id}`, {_id: id, ...body})
}

export function updateProvidentFundStatus(
  id: string,
  status: string,
  signature: string,
  bankDocumentPaths = []
) {
  const accountantStatusObject: any = {
    'send-for-approval': 'send-for-approval',
    'bank-transfer-request': 'bank-transfer-request',
    Completed: 'Completed',
    // Add more status values as needed
  }
  if (accountantStatusObject[status]) {
    return axios.patch(`${PROVIDENT_FUND_URL}/${id}/update-status/${status}`, {
      bankDocumentPaths,
    })
  } else {
    const obj: {signature?: string} = {}
    if (status === 'Approved') obj['signature'] = signature
    return axios.patch(`${PROVIDENT_FUND_URL}/${id}/${status}`, obj)
  }
}
export function deleteProvidentFund(id: string) {
  return axios.delete(`${PROVIDENT_FUND_URL}/${id}`)
}
