import moment from 'moment'
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Can} from '../../../Common/RBAC/Can'
import '../../../../_metronic/assets/sass/components/todo-table-row.scss'
import {convertPascalCaseToSentence} from '../helpers/utils'
import {Link} from 'react-router-dom'
type Props = {
  index: number
  appointment: {
    _id: string
    eventId: {
      _id: string
      name: string
      description: string
      status: 'active' | 'inactive'
      venue: string
      venueData: string
    }
    startTime: string
    duration: string
    eventDate: Date
    bookedBy: string
    createdAt: Date
    updatedAt: Date
  }
  deleteAppointments: Function
}

export const ScheduledAppointmentsRow: React.FC<Props> = ({
  index,
  appointment,
  deleteAppointments,
}) => {
  const isLink = /^(http|https):\/\/[^ "]+$/.test(appointment?.eventId?.venueData)

  const copyText = async () => {
    try {
      await navigator.clipboard.writeText(appointment?.eventId?.venueData)
    } catch (error) {
      console.log('copyText ~ error:', error)
    }
  }
  const profileData = {
    from: 'scheduledEvents',
  }

  return (
    <tr key={index} className='border-bottom releaseNote'>
      <td data-label='Name' title={appointment?.eventId?.name ? appointment?.eventId?.name : '-'}>
        {appointment?.eventId?.name && (
          <Link to={{pathname: `/appointments/${appointment?.eventId?._id}`, state: {profileData}}}>
            <div
              title={appointment?.eventId?.name}
              style={{
                display: 'block',
                width: '280px',
                minWidth: '280px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {appointment?.eventId?.name}
            </div>
          </Link>
        )}
      </td>
      <td data-label='Duration'>
        <div className='d-flex align-items-center text-center'>
          <div className='d-flex justify-content-start flex-column'>
            <div title={appointment?.duration ?? '-'}>{appointment?.duration ?? '-'}</div>
          </div>
        </div>
      </td>
      <td data-label='Venue'>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <div title={convertPascalCaseToSentence(appointment?.eventId?.venue)}>
              {convertPascalCaseToSentence(appointment?.eventId?.venue)}
            </div>
            <span id='textContainer' className='fw-bolder fs-8 text-muted'>
              {appointment?.eventId?.venueData}
            </span>
          </div>
        </div>
      </td>
      <td
        data-label='event Date'
        className='text-end'
        title={moment(appointment?.eventDate).format('DD/MM/YYYY')}
      >
        <div className='d-flex flex-column'>
          <div className='d-flex flex-stack'>
            {moment(appointment?.eventDate).format('DD/MM/YYYY')}
          </div>
        </div>
      </td>
      <td data-label='start time'>
        <div className='d-flex align-items-center text-center'>
          <div className='d-flex justify-content-start flex-column'>
            <div title={appointment?.startTime ?? '-'}>{appointment?.startTime ?? '-'}</div>
          </div>
        </div>
      </td>
      <td data-label='Actions' className='action_btns'>
        <div className=' flex-shrink-0'>
          {isLink ? (
            <div
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
              title='Join appointment'
              onClick={() => window.open(appointment?.eventId?.venueData, '_blank')}
            >
              <i className='fas fa-external-link-alt' aria-hidden='true' />
            </div>
          ) : (
            <div
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
              title='Copy Venue Date'
              onClick={copyText}
            >
              <i className='fa fa-link' aria-hidden='true' />
            </div>
          )}
          <Can I='delete' this='event-scheduling'>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm bg-light-blue-actions'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_delete_app'
              id='kt_toolbar_primary_button'
              onClick={deleteAppointments(appointment?._id)}
              title='Cancel'
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button>
          </Can>
        </div>
      </td>
    </tr>
  )
}
