import {ErrorMessage, Field, FieldProps} from 'formik'
import React from 'react'

interface IFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  fieldTye: string
  fieldName: string
  fieldPlaceholder: string
  mandatory?: boolean
}

const InputField = ({
  label,
  fieldTye,
  fieldName,
  fieldPlaceholder,
  mandatory,
  ...rest
}: IFieldProps) => {
  const fieldTypeProps = fieldTye === 'textarea' ? {component: fieldTye} : {type: fieldTye}
  return (
    <>
      <label className='fs-6 fw-bold form-label mb-2'>
        <span className={`${mandatory ? 'required' : ''}`}>{label}</span>
      </label>
      <Field
        {...fieldTypeProps}
        className='form-control form-control-lg  form-control-solid'
        name={fieldName}
        placeholder={fieldPlaceholder}
        {...rest}
      />
      <div className='text-danger'>
        <ErrorMessage name={fieldName} />
      </div>
    </>
  )
}

export {InputField}
