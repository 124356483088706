/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { ListDeleteDialog } from './ListDeleteDialog'
import * as actions from '../redux/listActions'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'
import moment from 'moment'
import { Pagination } from '../../../pagination/pagination'
import { Search } from '../../../../_metronic/layout/search/Search'
import { Link, useParams } from 'react-router-dom'
import * as workspaceActions from '../../workspace/redux/WorkspaceActions'
import { Breadcrumbs } from '../../../pages/Breadcrumbs'
import { NotFound } from '../../../../_metronic/layout/NotFound'
import { Loading } from '../../../../_metronic/layout/Loading'
import { Can } from '../../../Common/RBAC/Can'
import '../../../../_metronic/assets/sass/components/todo-table-row.scss'

type Props = {
  className: string
}
interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  page: number
}

const ListTable: React.FC<Props> = ({ className }) => {
  const { workspaceid }: { workspaceid: string } = useParams()

  const dispatch = useDispatch()
  const [showlistsDialog, setShowlistsDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [list, setList] = useState([{}])
  const [spaceDetail, setSpaceDetail] = useState()
  const [fetchRecords, setFetchRecords] = useState(false)
  const [delList, setDeleteList] = useState('')
  const [sortValues, setSortValues] = useState({
    orderby: 'desc',
    sortby: 'createdAt',
    NotFound: '',
  })

  const [isFiltered, setIsFiltered] = useState(false)

  const { listData, listDetail, error, isLoading }: any = useSelector<RootState>(
    (state) => ({
      listData: state.list.entities,
      listDetail: state.list.listDetail,
      error: state.list.error,
      isLoading: state.list.listLoading,
    }),
    shallowEqual
  )

  const paginationData: PaginationModel = useSelector<RootState>(
    (state) => state.list,
    shallowEqual
  ) as PaginationModel

  useEffect(() => {
    if (workspaceid) {
      dispatch(workspaceActions.fetchWorkspace(workspaceid))
    }
  }, [dispatch])

  const { spaceData }: any = useSelector<RootState>(
    (state) => ({
      spaceData: state.workspace.workspaceDetail,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (Array.isArray(listData)) {
      setList(listData)
    }
  }, [listData])

  useEffect(() => {
    if (listDetail) {
      setSpaceDetail(listDetail)
    }
  }, [listDetail])
  const fetchList = (_id: string, workspaceid: string) => async (event: any) => {
    dispatch(actions.fetchList(_id, workspaceid))

    setShowlistsDialog(true)
  }
  const deleteList = (_id: string) => async (event: any) => {
    setDeleteList(_id)
    setShowDeleteDialog(true)
  }

  const removeSortingClass = () => {
    const cells = document.querySelectorAll('th')
    cells.forEach(function (cell) {
      cell.classList.remove('table-sort-desc')
      cell.classList.remove('table-sort-asc')
    })
  }

  useEffect(() => {
    let tableHead: any
    tableHead = document.getElementById(`list-${sortValues.sortby}-head`)

    if (sortValues.orderby === 'desc' && tableHead && list?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-asc')
      tableHead.classList.add('table-sort-desc')
    } else if (tableHead && list?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-desc')
      tableHead.classList.add('table-sort-asc')
    }
    dispatch(
      actions.fetchLists({
        search: '',
        page: '',
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        workspace: workspaceid,
        NotFound: sortValues.NotFound,
      })
    )
  }, [sortValues, fetchRecords])

  const sortColumn = (event: any) => {
    if (event.target.classList.contains('table-sort-desc')) {
      removeSortingClass()
      setSortValues({
        orderby: 'asc',
        sortby: event.target.abbr,
        NotFound: event.target.abbr,
      })
      event.target.classList.remove('table-sort-desc')
      event.target.classList.add('table-sort-asc')
    } else {
      removeSortingClass()
      setSortValues({
        orderby: 'desc',
        sortby: event.target.abbr,
        NotFound: event.target.abbr,
      })
      event.target.classList.remove('table-sort-asc')
      event.target.classList.add('table-sort-desc')
    }
  }

  const handlePagination = (event: any) => {
    dispatch(
      actions.fetchLists({
        search: '',
        page: event.selected + 1,
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        NotFound: sortValues.NotFound,
        workspace: workspaceid,
      })
    )
  }

  const handleSearch = (search: string) => {
    dispatch(
      actions.fetchLists({
        search: search,
        page: paginationData?.page,
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        NotFound: sortValues.NotFound,
        workspace: workspaceid,
      })
    )
  }

  let crumbs
  if (spaceData) {
    crumbs = [
      { name: 'Spaces', url: '/dashboard' },
      // {name: 'Spaces', url: '/spaces'},
      { name: `${spaceData?.name}`, url: `/${spaceData?._id}/features` },
      { name: 'List', url: `/${spaceData?._id}/list` },
    ]
  }

  return (
    <>
      <ListDeleteDialog
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
        _id={delList}
        setFetchRecords={setFetchRecords}
        fetchReocords={fetchRecords}
      />

      <div className={`mh-80 tabs-main card legend-card-main rounded-0`}>
       
      <div className='px-6 px-sm-12'>
            <div className="row">
              <div className="col-sm-6">
                <h2 className='fw-500 fs-2 m-0 py-5 heading_overflow_control'>
                  List
                </h2>
              </div>
              <div className="col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center">
                <Search handleSearch={handleSearch}/>
              </div>
            </div>
          </div>
        {/*<div className='card-header border-0 px-12'>
          /~ begin::Header ~/
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-500 fs-2 mb-1'>List</span>
          </h3>
          <Search handleSearch={handleSearch} />

          /~ <div
            className='card-toolbar mt-0 pt-2'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Changes Notes Listing View'
          >
            <div className='d-flex align-items-center justify-content-end spaces_header'>
              <div className='input-group col-sm-2 p-1 me-1'>
                <input
                  type='text'
                  className='form-control form_control_space  bd-right-none py-1'
                  placeholder='Search'
                  aria-label="Recipient's username"
                  aria-describedby='basic-addon2'
                  onChange={(e: any) => {
                    handleSearch(e.target.value)
                  }}
                />
                <span
                  className='input-group-text bd-left-none bg-white py-1 px-3 z-indx-0'
                  id='basic-addon2'
                >
                  {' '}
                  <button className='btn bg-white btn-focus-white p-0 m-0'>
                    {' '}
                    <a>
                      {' '}
                      <KTSVG path='/media/icons/projects/svgexport-7.svg' />
                    </a>
                  </button>
                </span>{' '}
              </div>
            </div>
          </div> ~/
        </div>*/}

        <div className='d-flex justify-content-end align-items-center bg-lighter flex-column flex-sm-row'>
          {crumbs && <Breadcrumbs crumbs={crumbs} containerClass='row mx-10' />}{' '}
          <Can I='create' this='spaces'>
            <Link
              to={`/${workspaceid}/list/add`}
              className='bg-lighter me-5 me-sm-10 ms-auto'
              onClick={() => {
                setSpaceDetail(undefined)
                setShowlistsDialog(true)
              }}
            >
              <span
                className='btn btn-sm  bg-transparent text-blue pe-5 d-flex align-items-center'
                style={{ whiteSpace: 'nowrap' }}
              >
                <KTSVG path='' className='svg-icon-3' />
                <i className='fa fa-plus-circle link_button' aria-hidden='true'></i>
                <span className='link_button'> Create List</span>
              </span>
            </Link>
          </Can>
        </div>

        {/* end::Header */}
        {/* begin::Body */}

        <div className="overflow_height_scroll">
          <div className='card-body d-flex flex-column py-3'>
            {/* begin::Table container */}
            {list?.length > 0 && !isLoading && (
              <div className='table-responsive'>
                {/* begin::Table */}

                <table className='todo-table table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th
                        className=''
                        style={{ cursor: 'pointer', minWidth: '100px' }}
                        onClick={sortColumn}
                        abbr='title'
                        id='list-title-head'
                      >
                        Title
                      </th>

                      <th
                        className='min-w-120px'
                        // style={{cursor: 'pointer'}}
                        // onClick={sortColumn}
                        abbr='assignee'
                        id='list-assignee-head'
                      >
                        Assignees
                      </th>
                      <th
                        className='min-w-120px'
                        style={{ cursor: 'pointer' }}
                        onClick={sortColumn}
                        abbr='createdBy'
                        id='list-createdBy-head'
                      >
                        Created By
                      </th>

                      <th
                        className='min-w-120px table-sort-desc'
                        style={{ cursor: 'pointer' }}
                        onClick={sortColumn}
                        abbr='createdAt'
                        id='list-createdAt-head'
                      >
                        Created On
                      </th>

                      <th
                        className='min-w-120px'
                        style={{ cursor: 'pointer' }}
                        onClick={sortColumn}
                        abbr='updatedAt'
                        id='list-updatedAt-head'
                      >
                        Modified On
                      </th>

                      <th className='min-w-100px text-start'>Actions</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {list?.length > 0 &&
                      list?.map((list: any, index: number) => {
                        const assigneeLength = list?.assignee?.length - 1
                        return (
                          <tr key={index}>
                            <td data-label='Title'>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column titleName'>
                                  <Link to={`/${workspaceid}/list/${list?._id}`}>
                                    <span
                                      title={list?.title}
                                      style={{
                                        display: 'block',
                                        width: '200px',
                                        minWidth: '100px',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      {list?.title}
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              {/* </Link> */}
                            </td>

                            <td
                              data-label='Assignees'
                              title={list?.assignee?.map((assignee: any, index: number) => {
                                if (assigneeLength === index) {
                                  return assignee?.firstName
                                }
                                return assignee?.firstName?.concat(',')
                              })}
                            >
                              <div className='d-flex align-items-center'>
                                <div className='d-block justify-content-start flex-column textEllipsis'>
                                  {list._id
                                    ? list?.assignee?.map((assignee: any, index: number) => {
                                      if (assigneeLength === index) {
                                        return assignee?.firstName
                                      }
                                      return assignee?.firstName?.concat(',')
                                    })
                                    : '-'}
                                </div>
                              </div>
                            </td>
                            {list.createdBy ? (
                              <td data-label='Created By' title={list?.createdBy?.firstName}>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {list?.createdBy?.firstName}
                                  </div>
                                </div>
                              </td>
                            ) : (
                              <td data-label='Created By'>
                                <div className='d-flex align-items-center '>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {'-'}
                                  </div>
                                </div>
                              </td>
                            )}

                            <td
                              data-label='Created On'
                              title={
                                list.createdAt ? moment(list.createdAt).format('MM/DD/YYYY') : '-'
                              }
                            >
                              {list?.createdAt && (
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {moment(list.createdAt).format('MM/DD/YYYY')}
                                  </div>
                                </div>
                              )}
                            </td>

                            <td
                              data-label='Modified On'
                              title={
                                list?.updatedAt ? moment(list.updatedAt).format('MM/DD/YYYY') : '-'
                              }
                            >
                              {list?.updatedAt ? (
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {moment(list.updatedAt).format('MM/DD/YYYY')}
                                  </div>
                                </div>
                              ) : (
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {'-'}
                                  </div>
                                </div>
                              )}
                            </td>

                            <td data-label='Actions'>
                              <div className='d-flex justify-content-start flex-shrink-0'>
                                <Can I='read' this='spaces'>
                                  <Link
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
                                    title='View'
                                    to={`/${workspaceid}/list/${list?._id}`}
                                  >
                                    <KTSVG path='/media/icons/view.svg' className='svg-icon-4' />
                                  </Link>
                                </Can>
                                <Can I='update' this='spaces'>
                                  <Link
                                    to={`/${workspaceid}/list/${list?._id}/edit`}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
                                    title='Edit'
                                    onClick={fetchList(list?._id, workspaceid)}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='svg-icon-3'
                                    />
                                  </Link>
                                </Can>
                                <Can I='delete' this='spaces'>
                                  <button
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm  bg-light-blue-actions'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_delete_app'
                                    id='kt_toolbar_primary_button'
                                    onClick={deleteList(list?._id)}
                                    title='Delete'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen027.svg'
                                      className='svg-icon-3'
                                    />
                                  </button>
                                </Can>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                  {/* end::Table body */}
                </table>

                {/* end::Table */}
              </div>
            )}
            {list?.length <= 0 && !isLoading && <NotFound />}
            {isLoading && <Loading />}
            {/* end::Table container */}
          </div>
        </div>

        <Pagination paginationData={paginationData} fetchData={handlePagination} />

        {/* begin::Body */}
      </div>
    </>
  )
}

export { ListTable }
