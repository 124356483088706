import {FC, useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {getImageURL, KTSVG} from '../../../../_metronic/helpers'
import * as actions from '../redux/BoardActions'
import {AssigneesList} from '../../../Common/assignee-teams/Assignee&TeamList'
interface Props {
  boardDispatch: any
}
const BoardHeader: FC<Props> = ({boardDispatch}) => {
  const [showMembersDialog, setShowMembersDialog] = useState(false)
  const [assigneesId, setAssigneesId] = useState([])
  const {workspaceid, id}: {workspaceid: string; id: string; filter: any} = useParams()
  const {currentSpaceUsers, accessToken, filter}: any = useSelector<RootState>(
    (state) => ({
      currentSpaceUsers: state.board.currentSpaceUsers,
      accessToken: state.auth.accessToken,
      filter: state.board.filter,
    }),
    shallowEqual
  )

  const dispatch = useDispatch()

  const filterUser = (userId: string[]) => {
    if (!filter?.users?.includes(userId)) {
      dispatch(actions.filterProject({...filter, users: [...filter.users, userId]}))
    } else {
      dispatch(
        actions.filterProject({
          ...filter,
          users: filter.users.filter((user: any) => user !== userId),
        })
      )
    }
  }
  const resetFilters = () => {
    dispatch(
      actions.filterProject({
        ...{...filter, search: ''},
        users: [],
      })
    )
    boardDispatch({type: 'RESET_SEARCH_BOX', payload: true})
  }

  useEffect(() => {
    if (filter.users.length > 0) {
      setAssigneesId(filter.users.map((assignee: any) => assignee._id))
    }
  }, [filter.users])

  useEffect(() => {}, [assigneesId])

  return (
    <>
      {/* <AssigneesList
        show={showMembersDialog}
        handleClose={() => {
          setShowMembersDialog(false)
        }}
        calledBy={'task'}
        setAssigneesId={setAssigneesId}
        assigneesId={assigneesId}
        id={id}
        assignee={currentSpaceUsers}
        teams={[]}
        title={'Members'}
      /> */}
      <div className='row mx-10 mt-5'>
        <div className='kanban-board-header-main px-0'>
          <div className='left d-flex align-items-center w-100'>
            <div className='board-views-btn d-none'></div>
            <div className='ms-0 side-margins-responsive'>
              <Link
                className='btn btn-blue position-relative  fw-bolder fs-7 d-flex align-items-center ml-2'
                to={`/${workspaceid}/project/${id}/board/summary`}
                title={'Summary'}
                style={{height: '31px', minWidth: '72px'}}
              >
                Summary
              </Link>
            </div>
            <div className='side-margins-responsive'>
              <Link
                to={`/${workspaceid}/project/${id}/task`}
                className='btn btn-sm btn-light-primary text-blue ms-2 fw-bolder fs-7 d-flex align-items-center'
                title={'Tasks'}
                style={{height: '31px', minWidth: '72px'}}
              >
                {/* <KTSVG path='' className='svg-icon svg-icon-3' /> */}
                Tasks
              </Link>
            </div>

            <div className='side-margins-responsive d-none'>
              <Link
                to={'#'}
                className='btn btn-blue position-relative  fw-bolder px-5 fs-7 d-flex align-items-center ml-2'
                title={'Add Member'}
                style={{height: '31px', minWidth: '72px'}}
                onClick={() => {
                  // setShowMembersDialog(true)
                }}
              >
                {/* <KTSVG path='' className='svg-icon svg-icon-3' /> */}
                Add Members
              </Link>
            </div>

            <div className='side-margins-responsive'>
              <Link
                to={'#'}
                className='btn btn-blue position-relative  fw-bolder px-5 fs-7 d-flex align-items-center ml-2'
                title={'Reset'}
                style={{height: '31px', minWidth: '72px'}}
                onClick={resetFilters}
              >
                {/* <KTSVG path='' className='svg-icon svg-icon-3' /> */}
                Reset Filters
              </Link>
            </div>

            <div className='kanban-header-facepile header_kanban_ui mx-4'>
              <ul>
                {currentSpaceUsers.map((user: any, i: any) => {
                  return (
                    <li title={user?.fullName} key={i}>
                      <Link to='#'>
                        <img
                          className={`member-avatar ${
                            filter.users.includes(user._id) ? 'border border-primary' : ''
                          }`}
                          src={getImageURL(user?.image?.[0]?.thumbnailUrl, accessToken)}
                          alt={user?.fullName}
                          onClick={() => {
                            filterUser(user._id)
                          }}
                        />
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {BoardHeader}
