import React from 'react'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import {NumericFormat} from 'react-number-format'
import {Attachment} from '../../../Common/attachments/Attachment'
import * as actions from '../redux/ProvidentFundAction'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

export const PermanentLoanForm: React.FC = () => {
  const {values, setFieldValue}: any = useFormikContext()
  const {attachments}: any = useSelector<RootState>(
    (state) => state.providentFund,
    shallowEqual
  ) as {loanType: string}
  return (
    <>
      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2 required'>
          <span className=''>Appointment Date (Nextbridge Pvt Ltd) </span>
        </label>

        <Field
          className='form-control form-control-lg form-control-solid'
          name='permanentLoan.appointmentDate'
          placeholder='Appointment Date'
          type='date'
        />

        <div className='text-danger'>
          <ErrorMessage name='permanentLoan.appointmentDate' />
        </div>
      </div>
      <div className='col-md-6 fv-row mb-5'>
        <label className='fs-6 fw-bold mb-2 required'>
          <span>Current Gross Salary</span>
        </label>

        <NumericFormat
          placeholder='Current Gross Salary'
          className='form-control form-control-lg form-control-solid'
          name='permanentLoan.currentGrossSalary'
          value={values?.[values?.loanType]?.['currentGrossSalary']}
          onChange={(e: any) => {
            setFieldValue('permanentLoan.currentGrossSalary', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='permanentLoan.currentGrossSalary' />
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold required mb-2'>
          Current Basic Salary
        </label>

        <NumericFormat
          placeholder='Current Basic Salary'
          className='form-control form-control-lg form-control-solid'
          name='permanentLoan.currentBasicSalary'
          value={values?.[values?.loanType]?.['currentBasicSalary']}
          onChange={(e: any) => {
            setFieldValue('permanentLoan.currentBasicSalary', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='permanentLoan.currentBasicSalary' />
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='fs-6 fw-bold fw-bold form-label mb-2 required'>Amount Applied</label>
        <div className='row fv-row'>
          <div className='col-12'>
            <NumericFormat
              placeholder='Amount Applied'
              className='form-control form-control-lg form-control-solid'
              name='permanentLoan.amountApplied'
              value={values?.[values?.loanType]?.['amountApplied']}
              onChange={(e: any) => {
                setFieldValue('permanentLoan.amountApplied', +e.target.value)
              }}
            />

            <div className='text-danger'>
              <ErrorMessage name='permanentLoan.amountApplied' />
            </div>
          </div>
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='fs-6 fw-bold mb-2 required'>
          <span>Date of Application</span>
        </label>

        <Field
          type='date'
          className='form-control form-control-lg  form-control-solid'
          name='permanentLoan.dateOfApplication'
          placeholder='Date of Application'
        />
        <div className='text-danger'>
          <ErrorMessage name='permanentLoan.dateOfApplication' />
        </div>
      </div>

      <div className='row fv-row mb-5'>
        <label className='fs-6 fw-bold mb-2 required'>
          <span>
            Have you ever availed the withdrawal facility from Nextbridge Pvt Ltd. Employees
            Provident Fund?
          </span>
        </label>
        <div className='col-2 col-sm-3 py-2 py-sm-0'>
          <label>
            <Field
              type='radio'
              name='permanentLoan.didAvailPFFacility'
              value='yes'
              className=' form-check-input smaller-form-input me-2'
            />
            Yes
          </label>
        </div>
        <div className='col-2 col-sm-3 py-2 py-sm-0'>
          <label>
            <Field
              type='radio'
              name='permanentLoan.didAvailPFFacility'
              value='no'
              className=' form-check-input smaller-form-input me-2'
            />
            No
          </label>
        </div>
        <div className='text-danger'>
          <ErrorMessage name='permanentLoan.didAvailPFFacility' />
        </div>
      </div>

      <div className='col-md-12 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2 required'>
          Reason For Withdrawal
        </label>

        <div className='col-12'>
          <Field
            className='form-control form-control-lg form-control-solid'
            name='permanentLoan.reasonForWithdrawal'
            placeholder='Reason For Withdrawal'
            as='textarea'
            rows='5'
          />

          <div className='text-danger'>
            <ErrorMessage name='permanentLoan.reasonForWithdrawal' />
          </div>
        </div>
      </div>
      <div className='col-md-12 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2'>Supporting Documents</label>

        <div className=' d-flex justify-content-center align-items-center'>
          <Attachment
            attachments={attachments}
            loadAttachments={actions.loadAttachments}
            attachmentType='attachmentUpload'
            id={values._id}
            itemType='todo'
          />
        </div>
        <div className='text-danger'>
          <ErrorMessage name='documents' />
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold required mb-2'>
          Current Provident Balance
        </label>

        <NumericFormat
          placeholder='Current Provident Balance'
          className='form-control form-control-lg form-control-solid'
          name='permanentLoan.currentProvidentBalance'
          value={values?.[values?.loanType]?.['currentProvidentBalance']}
          onChange={(e: any) => {
            setFieldValue('permanentLoan.currentProvidentBalance', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='permanentLoan.currentProvidentBalance' />
        </div>
      </div>
      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold  mb-2 required'>
          <span className=''>Amount Approved</span>
        </label>

        <NumericFormat
          placeholder='Approved Amount'
          className='form-control form-control-lg form-control-solid'
          name='permanentLoan.approvedAmount'
          value={values?.[values?.loanType]?.['approvedAmount']}
          onChange={(e: any) => {
            setFieldValue('permanentLoan.approvedAmount', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='permanentLoan.approvedAmount' />
        </div>
      </div>

      <div className='col-md-12 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2'>Notes</label>

        <div className='col-12'>
          <Field
            className='form-control form-control-lg form-control-solid'
            name='permanentLoan.notes'
            placeholder='Notes'
            as='textarea'
            rows='5'
          />

          <div className='text-danger'>
            <ErrorMessage name='permanentLoan.notes' />
          </div>
        </div>
      </div>
    </>
  )
}
