import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const CLIENT_URL = `${API_URL}/clients`
const CLIENT_ATTACHMENT_DATA = `${API_URL}/attachments`

export function getClientsList({
  search,
  page,
  sortby,
  orderby,
  createdAt,
}: {
  search: string
  page: string
  sortby: string
  orderby: string
  createdAt: string
}) {
  // assignee = JSON.stringify(assignee)

  return axios.get(CLIENT_URL, {
    params: {
      search,
      page,
      sortby,
      orderby,
      createdAt,
    },
  })
}
export function addClientsList(clientData: any) {
  return axios.post(CLIENT_URL, clientData)
}
export function updateClientsList(clientData: any) {
  return axios.patch(`${CLIENT_URL}/${clientData._id}`, clientData)
}

export function deleteClient(_id: string) {
  return axios.delete(`${CLIENT_URL}/${_id}`)
}

export function markClientsAsDone(_id: string) {
  return axios.patch(`${CLIENT_URL}/status/done/${_id}`)
}
export function markClientsAsPinned(_id: string, pinned: boolean) {
  return axios.patch(`${CLIENT_URL}/pin/${_id}`, {pinned})
}

export function fetchClient(_id: string) {
  return axios.get(`${CLIENT_URL}/${_id}`)
}

export function addClientsAttachmentData({_id, attachmentPaths}: {_id: any; attachmentPaths: any}) {
  return axios.post(CLIENT_ATTACHMENT_DATA, {
    item: _id,
    attachmentPaths,
    itemType: 'Clients',
    type: 'Clients',
  })
}
export function updateClientsAttachmentData({
  _id,
  attachmentPaths,
}: {
  _id: string
  attachmentPaths: any
}) {
  return axios.patch(`${CLIENT_ATTACHMENT_DATA}/${_id}`, {
    item: _id,
    attachmentPaths,
    itemType: 'Clients',
    type: 'Clients',
  })
}
export function deleteAttachment(_id: string) {
  return axios.delete(`${CLIENT_ATTACHMENT_DATA}/${_id}`)
}
