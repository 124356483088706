/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useReducer} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {TalentDeleteDialog} from './TalentDeleteDialog'
import * as actions from '../redux/talentActions'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import moment from 'moment'
import {Pagination} from '../../../pagination/pagination'
import {Search} from '../../../../_metronic/layout/search/Search'
import {Link, useParams, useLocation, useHistory} from 'react-router-dom'
import * as workspaceActions from '../../workspace/redux/WorkspaceActions'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {NotFound} from '../../../../_metronic/layout/NotFound'
import {Loading} from '../../../../_metronic/layout/Loading'
import {Can} from '../../../Common/RBAC/Can'
import {FileDownload} from './FileDownload'
import {degrees, expYears, universities} from '../talentUIHelper'
import _ from 'lodash'
import '../../../../_metronic/assets/sass/components/todo-table-row.scss'
import {CreateTalentModel} from './CreateTalentModel'

type Props = {
  className: string
}
interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  page: number
}

const TalentTable: React.FC<Props> = ({className}) => {
  const {workspaceid}: {workspaceid: string} = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const [showtalentsDialog, setShowtalentsDialog] = useState(false)
  const [showDownloadDialog, setShowDownloadDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [talent, setList] = useState([{}])
  const [spaceDetail, setSpaceDetail] = useState()
  const [fetchRecords, setFetchRecords] = useState(false)
  const [delList, setDeleteList] = useState('')
  const [showCreateTalentModel, setShowCreateTalentModel] = useState<boolean>(false)
  // const [isCopyOnClipBoard, setIsCopyOnClicpBoard] = useState<boolean>(false)

  const [sortValues, setSortValues] = useState({
    orderby: 'desc',
    sortby: 'createdAt',
    NotFound: '',
  })
  const initialOperationsVal: any = {
    isCopyOnClipBoard: false,
    clickedTalentId: '',
  }

  const talentReducerFunction = (state: any, action: any) => {
    switch (action.type) {
      case 'COPY':
        return {isCopyOnClipBoard: true, clickedTalentId: action.payload}

      case 'UNDO_ACTION':
        return {isCopyOnClipBoard: false, clickedTalentId: ''}
      default:
        return state
    }
  }
  const [talentOperations, dispatchTalentOperations] = useReducer(
    talentReducerFunction,
    initialOperationsVal
  )

  const {talentData, talentDetail, error, isLoading, userInfo}: any = useSelector<RootState>(
    (state) => ({
      talentData: state.talent.entities,
      talentDetail: state.talent.talentDetail,
      error: state.talent.error,
      isLoading: state.talent.talentLoading,
      userInfo: state.auth.user,
    }),
    shallowEqual
  )

  const paginationData: PaginationModel = useSelector<RootState>(
    (state) => state.talent,
    shallowEqual
  ) as PaginationModel

  useEffect(() => {
    if (workspaceid) {
      dispatch(workspaceActions.fetchWorkspace(workspaceid))
    }
  }, [dispatch])

  const {spaceData}: any = useSelector<RootState>(
    (state) => ({
      spaceData: state.workspace.workspaceDetail,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (Array.isArray(talentData)) {
      setList(talentData)
    }
  }, [talentData])

  useEffect(() => {
    if (talentDetail) {
      setSpaceDetail(talentDetail)
    }
  }, [talentDetail])
  const fetchList = (_id: string, workspaceid: string) => async (event: any) => {
    dispatch(actions.fetchList(_id, workspaceid, '', userInfo))

    setShowtalentsDialog(true)
  }
  const deleteList = (_id: string) => async (event: any) => {
    setDeleteList(_id)
    setShowDeleteDialog(true)
  }

  const removeSortingClass = () => {
    const cells = document.querySelectorAll('th')
    cells.forEach(function (cell) {
      cell.classList.remove('table-sort-desc')
      cell.classList.remove('table-sort-asc')
    })
  }

  useEffect(() => {
    let tableHead: any
    tableHead = document.getElementById(`talent-${sortValues.sortby}-head`)

    if (sortValues.orderby === 'desc' && tableHead && talent?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-asc')
      tableHead.classList.add('table-sort-desc')
    } else if (tableHead && talent?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-desc')
      tableHead.classList.add('table-sort-asc')
    }
    dispatch(
      actions.fetchLists({
        search: '',
        page: '',
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        workspace: workspaceid,
        NotFound: sortValues.NotFound,
      })
    )
  }, [sortValues, fetchRecords])

  const sortColumn = (event: any) => {
    if (event.target.classList.contains('table-sort-desc')) {
      removeSortingClass()
      setSortValues({
        orderby: 'asc',
        sortby: event.target.abbr,
        NotFound: event.target.abbr,
      })
      event.target.classList.remove('table-sort-desc')
      event.target.classList.add('table-sort-asc')
    } else {
      removeSortingClass()
      setSortValues({
        orderby: 'desc',
        sortby: event.target.abbr,
        NotFound: event.target.abbr,
      })
      event.target.classList.remove('table-sort-asc')
      event.target.classList.add('table-sort-desc')
    }
  }

  const handlePagination = (event: any) => {
    dispatch(
      actions.fetchLists({
        search: '',
        page: event.selected + 1,
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        NotFound: sortValues.NotFound,
        workspace: workspaceid,
      })
    )
  }

  const handleSearch = (search: string) => {
    dispatch(
      actions.fetchLists({
        search: search,
        page: paginationData?.page,
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        NotFound: sortValues.NotFound,
        workspace: workspaceid,
      })
    )
  }

  let crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Talent', url: `/talent`},
  ]

  const downloadHandler = (id: string) => {
    setShowDownloadDialog(true)
    setDeleteList(id)
  }

  const copyIframe = (talentId: string, widgetDomain: string) => {
    navigator.clipboard.writeText(`${widgetDomain}/talent/?id=${talentId}`)
    dispatchTalentOperations({type: 'COPY', payload: talentId})
    setTimeout(() => {
      dispatchTalentOperations({type: 'UNDO_ACTION', payload: ''})
    }, 2000)
  }

  return (
    <>
      <TalentDeleteDialog
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
        _id={delList}
        setFetchRecords={setFetchRecords}
        fetchRecords={fetchRecords}
      />

      <CreateTalentModel
        show={showCreateTalentModel}
        handleClose={() => {
          setShowCreateTalentModel(false)
        }}
        setFetchRecords={setFetchRecords}
      />

      <FileDownload
        show={showDownloadDialog}
        handleClose={() => {
          setShowDownloadDialog(false)
        }}
        talentId={delList}
      />

      <div className={`mh-80 card legend-card-main rounded-0`}>
        <div className='px-6 px-sm-12'>
          <div className='row'>
            <div className='col-sm-6' title='Talent'>
              <h2 className='fw-500 fs-2 m-0 py-5'>Talent</h2>
            </div>
            <div className='col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center'>
              <Search handleSearch={handleSearch} />
            </div>
          </div>
        </div>
        {/*<div className='card-header border-0'>
          /~ begin::Header ~/
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Talent</span>
          </h3>
          <Search handleSearch={handleSearch} />
          /~ <Can I='create' this='spaces'>
            <div
              className='card-toolbar mt-0 pt-2'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Create Talent'
            >
              <Link
                to={`/talent/add`}
                className='btn btn-sm btn-light-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                onClick={() => {
                  setSpaceDetail(undefined)
                  setShowtalentsDialog(true)
                }}
              >
                <KTSVG path='' className='svg-icon-3' />
                Create Talent
              </Link>
            </div>
          </Can> ~/
        </div>*/}
        <div className='d-flex justify-content-between align-items-center bg-lighter flex-column flex-sm-row'>
          {crumbs && <Breadcrumbs crumbs={crumbs} containerClass='row mx-10' />}{' '}
          <Link
            onClick={() => setShowCreateTalentModel(true)}
            className='bg-lighter me-5 me-sm-10 ms-auto'
            to={'#'}
          >
            <span
              className='btn btn-sm  bg-transparent text-blue pe-5 d-flex align-items-center'
              style={{whiteSpace: 'nowrap'}}
            >
              <KTSVG path='' className='svg-icon-3' />
              <i className='fa fa-plus-circle link_button' aria-hidden='true'></i>
              <span className='link_button'> Create Talent</span>
            </span>
          </Link>
        </div>
        {/* end::Header */}
        {/* begin::Body */}

        <div className='overflow_height_scroll'>
          <div className='card-body d-flex flex-column py-3'>
            {/* begin::Table container */}
            {talent?.length > 0 && !isLoading && (
              <div className='table-responsive'>
                {/* begin::Table */}

                <table className='avengersInitiatives-table table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th
                        className=''
                        style={{cursor: 'pointer', minWidth: '100px'}}
                        onClick={sortColumn}
                        abbr='name'
                        id='talent-name-head'
                      >
                        Name
                      </th>
                      <th
                        className=''
                        style={{cursor: 'pointer', minWidth: '100px'}}
                        onClick={sortColumn}
                        abbr='jobTitle'
                        id='talent-job-title-head'
                      >
                        Job Title
                      </th>

                      {/* <th
                        className='min-w-120px'
                        style={{cursor: 'pointer'}}
                        onClick={sortColumn}
                        abbr='education'
                        id='talent-education-head'
                      >
                        Education
                      </th> */}

                      <th
                        className='min-w-120px'
                        style={{cursor: 'pointer'}}
                        onClick={sortColumn}
                        abbr='tenure'
                        id='talent-tenure-head'
                      >
                        Working Tenure
                      </th>

                      {/* <th
                        className='min-w-120px'
                        style={{cursor: 'pointer'}}
                        onClick={sortColumn}
                        abbr='widget'
                        id='talent-widget-head'
                      >
                        Widget
                      </th> */}

                      <th
                        className='min-w-120px'
                        style={{cursor: 'pointer'}}
                        onClick={sortColumn}
                        abbr='status'
                        id='talent-status-head'
                      >
                        Resume Status
                      </th>

                      <th
                        className='min-w-120px table-sort-desc'
                        style={{cursor: 'pointer'}}
                        onClick={sortColumn}
                        abbr='createdAt'
                        id='talent-createdAt-head'
                      >
                        Created Date
                      </th>

                      <th
                        className='min-w-120px'
                        style={{cursor: 'pointer'}}
                        onClick={sortColumn}
                        abbr='updatedAt'
                        id='talent-updatedAt-head'
                      >
                        Modified Date
                      </th>

                      <th className='min-w-100px text-start'>Actions</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {talent?.length > 0 &&
                      talent?.map((talent: any, index: number) => {
                        const skillsLength = talent?.skills?.length - 1
                        return (
                          <tr key={index}>
                            <td data-label='Name'>
                              <div className='ms-5 ms-sm-0 d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <Link to={`/talent/${talent?._id}`}>
                                    <span
                                      title={talent?.name}
                                      style={{
                                        display: 'block',
                                        width: '250px',
                                        minWidth: '100px',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      {talent?.name ? talent?.name : '-'}
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td data-label='Job Title'>
                              <div className='ms-5 ms-sm-0 d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {/* <Link to={`/talent/${talent?._id}`}> */}
                                  <span
                                    title={talent?.jobTitle}
                                    style={{
                                      display: 'block',
                                      width: '150px',
                                      minWidth: '100px',
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {talent?.jobTitle ? talent?.jobTitle : '-'}
                                  </span>
                                  {/* </Link> */}
                                </div>
                              </div>
                            </td>

                            {/* <td data-label='Education'>
                              <div className='ms-5 ms-sm-0 d-flex align-items-center'>
                                <div className='internalInitiativesTitle text-truncate'>
                                  {talent?.degree && talent?.university
                                    ? (talent?.degree !== 'other'
                                        ? degrees.find((d: any) => d.value === talent?.degree)
                                            ?.label
                                        : talent?.otherDegree) +
                                      ' - ' +
                                      (talent?.university !== 'other'
                                        ? universities.find(
                                            (u: any) => u.value === talent?.university
                                          )?.label
                                        : talent?.otherUniversity)
                                    : '-'}
                                </div>
                              </div>
                            </td> */}

                            <td data-label='Working Tenure'>
                              <div className='ms-5 ms-sm-0 d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {talent?.year
                                    ? expYears.find(
                                        (y: any) =>
                                          y?.value?.toString() === talent?.year?.toString()
                                      )?.label +
                                      ' Years ' +
                                      (talent?.experienceInMonth
                                        ? `${talent?.experienceInMonth} month`
                                        : '')
                                    : '-'}
                                </div>
                              </div>
                            </td>

                            {/* <td data-label='Widget'>
                              <div className='ms-5 ms-sm-0 d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {talent?.widget?.name ? talent?.widget?.name : '-'}
                                </div>
                              </div>
                            </td> */}
                            <td data-label='Resume Status'>
                              <div className='ms-5 ms-sm-0 d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {talent?.resumeStatus ? _.startCase(talent?.resumeStatus) : '-'}
                                </div>
                              </div>
                            </td>

                            <td
                              data-label='Created Date'
                              title={
                                talent.createdAt
                                  ? moment(talent.createdAt).format('MM/DD/YYYY')
                                  : '-'
                              }
                            >
                              {talent?.createdAt ? (
                                <div className='ms-5 ms-sm-0 d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {moment(talent.createdAt).format('MM/DD/YYYY')}
                                  </div>
                                </div>
                              ) : (
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {'-'}
                                  </div>
                                </div>
                              )}
                            </td>

                            <td
                              data-label='Modified Date'
                              title={
                                talent?.updatedAt
                                  ? moment(talent.updatedAt).format('MM/DD/YYYY')
                                  : '-'
                              }
                            >
                              {talent?.updatedAt ? (
                                <div className='ms-5 ms-sm-0 d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {moment(talent.updatedAt).format('MM/DD/YYYY')}
                                  </div>
                                </div>
                              ) : (
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {'-'}
                                  </div>
                                </div>
                              )}
                            </td>

                            <td data-label='Actions'>
                              <div className='ms-5 ms-sm-0 d-flex justify-content-start flex-shrink-0 align-items-center'>
                                {talent.widget && (
                                  <>
                                    <Can I='read' this='spaces'>
                                      <button
                                        className='btn btn-icon bg-light-blue-actions btn-active-color-primary btn-sm me-1'
                                        title='Copy URL'
                                        onClick={() => copyIframe(talent._id, talent.widget.domain)}
                                        style={{border: '2px '}}
                                      >
                                        <KTSVG
                                          path={`/media/icons/duotune/arrows/${
                                            talent._id !== talentOperations.clickedTalentId
                                              ? 'copy-outline.svg'
                                              : 'tick.svg'
                                          }`}
                                          className='svg-icon-2'
                                        />
                                      </button>
                                    </Can>
                                  </>
                                )}

                                <Can I='read' this='spaces'>
                                  <button
                                    className='btn btn-icon bg-light-blue-actions btn-active-color-primary btn-sm me-1'
                                    title='Download'
                                    onClick={() => downloadHandler(talent?._id)}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr044.svg'
                                      className='svg-icon-2'
                                    />
                                  </button>
                                </Can>

                                <Can I='update' this='spaces'>
                                  <Link
                                    to={`/talent/${talent?._id}/edit`}
                                    className='btn btn-icon bg-light-blue-actions btn-active-color-primary btn-sm me-1'
                                    title='Edit'
                                    onClick={fetchList(talent?._id, workspaceid)}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='svg-icon-3'
                                    />
                                  </Link>
                                </Can>
                                <Can I='delete' this='spaces'>
                                  <button
                                    className='btn btn-icon bg-light-blue-actions btn-active-color-primary btn-sm '
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_delete_app'
                                    id='kt_toolbar_primary_button'
                                    onClick={deleteList(talent?._id)}
                                    title='Delete'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen027.svg'
                                      className='svg-icon-3'
                                    />
                                  </button>
                                </Can>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                  {/* end::Table body */}
                </table>

                {/* end::Table */}
              </div>
            )}
            {talent?.length <= 0 && !isLoading && <NotFound />}
            {isLoading && <Loading />}
            {/* end::Table container */}
          </div>
        </div>

        <Pagination paginationData={paginationData} fetchData={handlePagination} />

        {/* begin::Body */}
      </div>
    </>
  )
}

export {TalentTable}
