import React from 'react'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import {NumericFormat} from 'react-number-format'
import axios from 'axios'
import {Attachment} from '../../../Common/attachments/Attachment'
import * as actions from '../redux/ProvidentFundAction'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

export const TemporaryLoanForm: React.FC = () => {
  const {values, setFieldValue}: any = useFormikContext()
  const {attachments}: any = useSelector<RootState>(
    (state) => state.providentFund,
    shallowEqual
  ) as {loanType: string}

  return (
    <>
      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2 required'>
          <span className=''>Appointment Date (Nextbridge Pvt Ltd) </span>
        </label>

        <Field
          className='form-control form-control-lg form-control-solid'
          name='temporaryLoan.appointmentDate'
          placeholder='Appointment Date'
          type='date'
        />

        <div className='text-danger'>
          <ErrorMessage name='temporaryLoan.appointmentDate' />
        </div>
      </div>
      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold  mb-2 required'>
          <span className=''>Current Gross Salary</span>
        </label>

        <NumericFormat
          placeholder='Current Gross Salary'
          className='form-control form-control-lg form-control-solid'
          name='temporaryLoan.currentGrossSalary'
          value={values?.[values?.loanType]?.['currentGrossSalary']}
          onChange={(e: any) => {
            setFieldValue('temporaryLoan.currentGrossSalary', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='temporaryLoan.currentGrossSalary' />
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2 required'>
          <span className=''>Current Basic Salary</span>
        </label>

        <NumericFormat
          placeholder='Current Basic Salary'
          className='form-control form-control-lg form-control-solid'
          name='temporaryLoan.currentBasicSalary'
          value={values?.[values?.loanType]?.['currentBasicSalary']}
          onChange={(e: any) => {
            setFieldValue('temporaryLoan.currentBasicSalary', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='temporaryLoan.currentBasicSalary' />
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold  mb-2 required'>
          <span className=''>Amount Applied</span>
        </label>

        <NumericFormat
          placeholder='Amount Applied'
          className='form-control form-control-lg form-control-solid'
          name='temporaryLoan.amountApplied'
          value={values?.[values?.loanType]?.['amountApplied']}
          onChange={(e: any) => {
            setFieldValue('temporaryLoan.amountApplied', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='temporaryLoan.amountApplied' />
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='fs-6 fw-bold mb-2 required'>
          <span>Date of Application</span>
        </label>

        <Field
          type='date'
          className='form-control form-control-lg  form-control-solid'
          name='temporaryLoan.dateOfApplication'
          placeholder='Date of Application'
        />
        <div className='text-danger'>
          <ErrorMessage name='temporaryLoan.dateOfApplication' />
        </div>
      </div>

      <div className='col-md-12 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2 required'>
          <span className=''>Reason For Withdrawal</span>
        </label>

        <Field
          className='form-control form-control-lg form-control-solid'
          name='temporaryLoan.reasonForWithdrawal'
          placeholder='Reason For Withdrawal'
          as='textarea'
          rows='5'
        />

        <div className='text-danger'>
          <ErrorMessage name='temporaryLoan.reasonForWithdrawal' />
        </div>
      </div>

      <h4 className='mb-6 mt-6'>Suggested Repayment Schedule</h4>
      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold required mb-2'>
          Monthly Installment
        </label>

        <NumericFormat
          placeholder='Monthly Installment'
          className='form-control form-control-lg form-control-solid'
          name='temporaryLoan.monthlyInstallmentSuggestion'
          value={values?.[values?.loanType]?.['monthlyInstallmentSuggestion']}
          onChange={(e: any) => {
            setFieldValue('temporaryLoan.monthlyInstallmentSuggestion', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='temporaryLoan.monthlyInstallmentSuggestion' />
        </div>
      </div>
      <div className='col-md-6 fv-row mb-5'>
        <label className='fs-6 fw-bold fw-bold form-label mb-2 required'>No. of Installments</label>
        <div className='row fv-row'>
          <div className='col-12'>
            <NumericFormat
              placeholder='No. of Installments'
              className='form-control form-control-lg form-control-solid'
              name='temporaryLoan.noOfInstallmentsSuggestion'
              value={values?.[values?.loanType]?.['noOfInstallmentsSuggestion']}
              onChange={(e: any) => {
                setFieldValue('temporaryLoan.noOfInstallmentsSuggestion', +e.target.value)
              }}
            />

            <div className='text-danger'>
              <ErrorMessage name='temporaryLoan.noOfInstallmentsSuggestion' />
            </div>
          </div>
        </div>
      </div>

      <div className='row fv-row mb-5'>
        <label className='fs-6 fw-bold mb-2 required'>
          <span>
            Have you ever availed the withdrawal facility from Nextbridge Pvt Ltd. Employees
            Provident Fund?
          </span>
        </label>
        <div className='col-2 col-sm-3 py-2 py-sm-0'>
          <label>
            <Field
              type='radio'
              name='temporaryLoan.didAvailPFFacility'
              value='yes'
              className=' form-check-input smaller-form-input me-2'
            />
            Yes
          </label>
        </div>
        <div className='col-2 col-sm-3 py-2 py-sm-0'>
          <label>
            <Field
              type='radio'
              name='temporaryLoan.didAvailPFFacility'
              value='no'
              className=' form-check-input smaller-form-input me-2'
            />
            No
          </label>
        </div>
        <div className='text-danger'>
          <ErrorMessage name='temporaryLoan.didAvailPFFacility' />
        </div>
      </div>

      <div className='col-md-12 fv-row'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2'>Supporting Documents</label>
        {/* <div className=' d-flex justify-content-center bg-lightest w-100 py-3 m-0  align-items-center'> */}
        <div className=' d-flex justify-content-center align-items-center'>
          <Attachment
            attachments={attachments}
            loadAttachments={actions.loadAttachments}
            attachmentType='attachmentUpload'
            id={values?._id}
            itemType='todo'
          />
        </div>
      </div>
      <h3 className='mb-6 mt-6'>Finance Verification</h3>
      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold required mb-2'>
          Current Provident Balance
        </label>

        <NumericFormat
          placeholder='Current Provident Balance'
          className='form-control form-control-lg form-control-solid'
          name='temporaryLoan.currentProvidentBalance'
          value={values?.[values?.loanType]?.['currentProvidentBalance']}
          onChange={(e: any) => {
            setFieldValue('temporaryLoan.currentProvidentBalance', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='temporaryLoan.currentProvidentBalance' />
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold  mb-2 required'>
          <span className=''>Amount Approved</span>
        </label>

        <NumericFormat
          placeholder='Approved Amount'
          className='form-control form-control-lg form-control-solid'
          name='temporaryLoan.approvedAmount'
          value={values?.[values?.loanType]?.['approvedAmount']}
          onChange={(e: any) => {
            setFieldValue('temporaryLoan.approvedAmount', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='temporaryLoan.approvedAmount' />
        </div>
      </div>

      <h4 className='mb-6 mt-6'>Repayment Schedule</h4>

      <div className='col-md-4 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold required mb-2'>
          Monthly Installment
        </label>

        <NumericFormat
          placeholder='Monthly Installment'
          className='form-control form-control-lg form-control-solid'
          name='temporaryLoan.approvedMonthlyInstallment'
          value={values?.[values?.loanType]?.['approvedMonthlyInstallment']}
          onChange={(e: any) => {
            setFieldValue('temporaryLoan.approvedMonthlyInstallment', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='temporaryLoan.approvedMonthlyInstallment' />
        </div>
      </div>

      <div className='col-md-4 fv-row mb-5'>
        <label className='fs-6 fw-bold fw-bold form-label mb-2 required'>No. of Installments</label>
        <div className='row fv-row'>
          <div className='col-12'>
            <NumericFormat
              placeholder='No. of Installments'
              className='form-control form-control-lg form-control-solid'
              name='temporaryLoan.approvedNoOfInstallments'
              value={values?.[values?.loanType]?.['approvedNoOfInstallments']}
              onChange={(e: any) => {
                setFieldValue('temporaryLoan.approvedNoOfInstallments', +e.target.value)
              }}
            />

            <div className='text-danger'>
              <ErrorMessage name='temporaryLoan.approvedNoOfInstallments' />
            </div>
          </div>
        </div>
      </div>

      <div className='col-md-4 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2 required'>
          Starting Date
        </label>

        <div className='col-12'>
          <Field
            type='date'
            className='form-control form-control-lg form-control-solid'
            name='temporaryLoan.startingDate'
            placeholder='Starting Date'
          />
          <div className='text-danger'>
            <ErrorMessage name='temporaryLoan.startingDate' />
          </div>
        </div>
      </div>
    </>
  )
}
