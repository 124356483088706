import React from 'react'
import {AttachmentUpload} from './AttachmentUpload'
import {AttachmentListing} from '../attachments/AttachmentListing'
import '../../../_metronic/assets/sass/components/comments.scss'

interface Props {
  loadAttachments: any
  attachments: any
  attachmentType: string
  id: string | null | undefined
  itemType: string
}
const Attachment: React.FC<Props> = ({
  loadAttachments,
  attachments,
  attachmentType,
  id,
  itemType,
}) => {
  return (
    <div className='d-flex flex-column w-100'>
      {attachmentType === 'attachmentUpload' && (
        <AttachmentUpload
          attachments={attachments}
          loadAttachments={loadAttachments}
          itemType={itemType}
        />
      )}

      <div className='mt-5'>
        {attachments?.length > 0 && (
          <AttachmentListing
            attachments={attachments}
            loadAttachments={loadAttachments}
            attachmentType={attachmentType}
            id={id}
            itemType={itemType}
          />
        )}
      </div>
    </div>
  )
}

export {Attachment}
