import {FC, useEffect, useMemo, useState} from 'react'

import '../../../../_metronic/assets/sass/components/summary.scss'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {getImageURL} from '../../../../_metronic/helpers'
import * as boardActions from '../redux/BoardActions'
import {ProjectKeys} from './SummaryPartials/ProjectKeys'
import {RadialBar} from './SummaryPartials/RadialBar'
import {CountStatus} from './SummaryPartials/CountStatus'
import {SummaryActivity} from './SummaryPartials/SummaryActivity'
import * as ProjectActions from '../../project/redux/ProjectActions'

const Summary: FC = () => {
  const {workspaceid, id}: {workspaceid: string; id: string} = useParams()

  const dispatch: any = useDispatch()

  const {currentSpace, currentProject, accessToken, loading, allProjects, loginUser}: any =
    useSelector<RootState>(
      (state) => ({
        currentSpace: state.board.currentSpace,
        currentProject: state.board.currentProject,
        accessToken: state.auth.accessToken,
        loading: state.board.loading,
        allProjects: state.project.allProjects,
        loginUser: state.auth.user,
      }),
      shallowEqual
    )

  useEffect(() => {
    if (workspaceid) {
      dispatch(ProjectActions.getAllProjects('', '', '', '', workspaceid, ''))
      dispatch(boardActions.fetchWorkspace(workspaceid))
      dispatch(boardActions.getAllWorkspaceUsers(workspaceid, loginUser))
    }
    dispatch(boardActions.currentProject(id))
    dispatch(boardActions.boardSummaryCount(id))
  }, [])

  useEffect(() => {
    if (id) dispatch(boardActions.boardSummaryCount(id))
  }, [id])

  let crumbs = workspaceid
    ? [
        {name: 'Spaces', url: '/dashboard'},
        {name: `${currentSpace?.name}`, url: `/${currentSpace?._id}/features`},
        {name: 'Project', url: `/${currentSpace?._id}/project`},
        {
          name: currentProject?.name,
          url: `/${currentSpace?._id}/project/${currentProject?._id}/board`,
        },
        {name: 'Summary'},
      ]
    : [
        {name: 'Spaces', url: '/dashboard'},
        {name: 'Project', url: `/project`},
      ]

  const getCurrentTime = () => {
    var today = new Date()
    var curHr = today.getHours()

    if (curHr < 12) {
      return 'Good morning'
    } else if (curHr < 18) {
      return 'Good afternoon'
    } else {
      return 'Good evening'
    }
  }

  return (
    <>
      <section className='mh-80'>
          <div className='card card-custom rounded-0'>
            <div className='card-body p-0'>
              <div className='tab-content'>
                <div className='tab-pane tab-pane2 active rounded'>
                  <div className='px-6 px-sm-12'>
                    <div className="row">
                      <div className="col-sm-6">
                        <h2 className='fw-500 fs-2 m-0 py-5 heading_overflow_control'>
                          {currentProject?.name} Summary
                        </h2>
                      </div>
                    </div>
                  </div>
                  {!loading && <Breadcrumbs crumbs={crumbs} />}

                  <div className="overflow_height_scroll_summary">
                  <div className='summaryPage py-15 px-7'>
                    <div className='row'>
                      <div className='col-lg-9'>
                        <div className='row'>
                          <div className='col-lg-5 col-md-5 col-sm-12'>
                            {/* crad one */}
                            <div className='card'>
                              <div className='card-body p-0'>
                                <div className=' bg-light-grey p-4'>
                                  <h5 className='py-3'>
                                    {getCurrentTime()}, {loginUser?.fullName}
                                  </h5>

                                  <div className='pb-15'>
                                    <p>
                                      Here's where you'll view a summary of GC's status, priorities,
                                      workload, and more.
                                    </p>
                                  </div>
                                </div>

                                <img
                                  className='rounded-circle img-width'
                                  src={getImageURL(
                                    currentProject?.createdBy?.image?.[0]?.thumbnailUrl
                                      ? currentProject?.createdBy?.image?.[0]?.thumbnailUrl
                                      : null,
                                    accessToken
                                  )}
                                  alt={currentProject?.createdBy?.fullName}
                                ></img>

                                <div className='px-5 pb-5'>
                                  <div className='d-flex justify-content-between'>
                                    <div className='d-flex flex-column'>
                                      <h6>Owner</h6>
                                      <p className='color-light-grey'>
                                        {currentProject?.createdBy?.fullName
                                          ? currentProject?.createdBy?.fullName
                                          : null}
                                      </p>
                                    </div>
                                    <div className='d-flex flex-column'>
                                      <h6>Project Key </h6>
                                      {allProjects.length < 2 ? (
                                        <p className='color-light-grey'>{currentProject?.name}</p>
                                      ) : (
                                        <div>
                                          <p
                                            className='color-light-grey'
                                            data-kt-menu-trigger='click'
                                            data-kt-menu-placement='bottom-end'
                                            data-kt-menu-flip='top-end'
                                          >
                                            {currentProject?.name}
                                            <i
                                              className='fa fa-angle-down icon-style'
                                              aria-hidden='true'
                                            ></i>
                                          </p>
                                          <ProjectKeys />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <h6 className='card-title mt-3'>Related Projects</h6>
                                  <p className='card-text'>
                                    Use projects to manage all your work in one place and stay
                                    aligned with stakeholders.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='col-lg-7  col-md-7 col-sm-12 margins-res-media'>
                            <div className='card card-Overveiw'>
                              <div className='card-body p-0'>
                                <div className='p-4'>
                                  <h5 className='py-3'>Overveiw</h5>
                                  <p>
                                    View the progress of your project based on the status of each
                                    item. For more details, go to the{' '}
                                    <Link
                                      to={`/${workspaceid}/project/${id}/board`}
                                      className='link-color text-blue'
                                    >
                                      board view
                                    </Link>
                                    .
                                  </p>
                                </div>
                                <div className='radial_bar_ui'>
                                  <RadialBar />
                                </div>
                              </div>
                            </div>
                          </div>
                          <CountStatus />
                        </div>
                      </div>
                      <SummaryActivity />
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
    </>
  )
}

export {Summary}
