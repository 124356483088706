import {Link, useParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import '../../../../_metronic/assets/sass/components/release-notes.scss'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {Search} from '../../../../_metronic/layout/search/Search'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import * as actions from '../redux/CredentialsAction'
import {Pagination} from '../../../pagination/pagination'
import {ClientsRow} from './CredentialsRow'
import {NotFound} from '../../../../_metronic/layout/NotFound'
import {Loading} from '../../../../_metronic/layout/Loading'
import {Can} from '../../../Common/RBAC/Can'
import {ActionModal} from '../../../Common/modals/ActionModal'
import {CreateCredentialsModel} from './CreateCredentialsModel'

export const ClientsTable: React.FC = () => {
  const dispatch = useDispatch()
  const [showCreateCredentialsModel, setShowCreateCredentialsModel] = useState<boolean>(false)
  const [fetchRecords, setFetchRecords] = useState(false)
  // const {id}: {id: string} = useParams()
  const [id, setId] = useState<string>('')
  const [credentialsObj, setCredentialsObj] = useState<any>('')

  const [sortValues, setSortValues] = useState({
    orderby: 'asc',
    sortby: '',
    createdAt: '',
  })

  useEffect(() => {
    console.log('clients table', id)
    // if(id){
    //   setCredentialsObj
    // }
  }, [id])
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [itemToDelete, setItemToDelete] = useState('')

  const {credentialsData, isLoading, paginationData}: any = useSelector<RootState>(
    (state) => ({
      credentialsData: state.credentials.entities,
      isLoading: state.credentials.listLoading,
      paginationData: state.credentials,
    }),
    shallowEqual
  )

  const removeSortingClass = () => {
    const cells = document.querySelectorAll('th')
    cells.forEach(function (cell) {
      cell.classList.remove('table-sort-desc')
      cell.classList.remove('table-sort-asc')
    })
  }

  useEffect(() => {
    let tableHead: any
    tableHead = document.getElementById(`todo-${sortValues.sortby}-head`)

    if (sortValues.orderby === 'desc' && tableHead && credentialsData?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-asc')
      tableHead.classList.add('table-sort-desc')
    } else if (tableHead && credentialsData?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-desc')
      tableHead.classList.add('table-sort-asc')
    }

    dispatch(
      actions.fetchClients({
        search: '',
        page: '',
        sortby: sortValues.sortby,
        orderby: sortValues.orderby,
        createdAt: sortValues.createdAt,
      })
    )
  }, [sortValues, fetchRecords])

  const deleteClient = (id: string) => async (event: any) => {
    setItemToDelete(id)
    setShowDeleteDialog(true)
  }

  const handleSearch = (search: string) => {
    dispatch(
      actions.fetchClients({
        search,
        page: paginationData?.page,
        sortby: '',
        orderby: '',
        createdAt: '',
      })
    )
  }

  const handlePagination = (event: any) => {
    dispatch(
      actions.fetchClients({
        search: '',
        page: event.selected + 1,
        sortby: '',
        orderby: '',
        createdAt: '',
      })
    )
  }

  let crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Credentials', url: ``},
  ]

  const sortColumn = (event: any) => {
    if (event.target.classList.contains('table-sort-desc')) {
      removeSortingClass()
      setSortValues({
        orderby: 'asc',
        sortby: event.target.abbr,
        createdAt: sortValues.createdAt,
      })
      event.target.classList.remove('table-sort-desc')
      event.target.classList.add('table-sort-asc')
    } else {
      removeSortingClass()
      setSortValues({
        orderby: 'desc',
        sortby: event.target.abbr,
        createdAt: sortValues.createdAt,
      })
      event.target.classList.remove('table-sort-asc')
      event.target.classList.add('table-sort-desc')
    }
  }

  return (
    <>
      <CreateCredentialsModel
        show={showCreateCredentialsModel}
        handleClose={() => {
          setShowCreateCredentialsModel(false)
          setCredentialsObj(null)
          setId('')
        }}
        credentials={credentialsObj}
        setFetchRecords={setFetchRecords}
      />
      <ActionModal
        show={showDeleteDialog}
        label='Delete Credential'
        description='Are you sure you want to delete this credential ?'
        handleClose={({canDelete, id}: {canDelete: boolean; id: string}) => {
          if (canDelete) {
            dispatch(actions.deleteClient(id))
          }
          setShowDeleteDialog(false)
        }}
        id={itemToDelete}
        buttonLabel='Delete'
      />
      <>
        <div className={`mh-80 tabs-main card legend-card-main rounded-0`}>
          {!isLoading && (
            <div className='px-6 px-sm-12'>
              <div className='row'>
                <div className='col-sm-6'>
                  <h2 className='fw-500 fs-2 m-0 py-5'>Credentials</h2>
                </div>
                <div className='col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center'>
                  <Search handleSearch={handleSearch} />
                </div>
              </div>
            </div>
          )}

          <div className='d-flex justify-content-between align-items-center bg-lighter'>
            {crumbs && <Breadcrumbs crumbs={crumbs} />}

            <Can I='create' this='event-scheduling'>
              <Link
                className='bg-lighter pe-10'
                to='#'
                onClick={() => setShowCreateCredentialsModel(true)}
              >
                <span
                  className='btn btn-sm bg-transparent text-blue pe-5 d-flex align-items-center'
                  style={{whiteSpace: 'nowrap'}}
                >
                  <i className='fa fa-plus-circle text-blue' aria-hidden='true'></i>
                  Create Credential
                </span>
              </Link>
            </Can>
          </div>

          {!isLoading && credentialsData?.length > 0 ? (
            <>
              <div className='overflow_height_scroll'>
                <div className='card-body d-flex flex-column py-3 px-10'>
                  <div className='table-responsive'>
                    <table className='todo-table table table-row-dashed align-middle gs-0 gy-4'>
                      <thead className='tableHeading'>
                        <tr className='fw-bolder text-muted border-bottom'>
                          <th
                            className='min-w-120px'
                            style={{cursor: 'pointer'}}
                            onClick={sortColumn}
                            abbr='firstName'
                            id='note-title-head'
                          >
                            Title
                          </th>
                          <th
                            className='min-w-150px'
                            style={{cursor: 'pointer'}}
                            onClick={sortColumn}
                            abbr='email'
                            id='note-duration-head'
                          >
                            URL
                          </th>
                          <th
                            className='min-w-150px'
                            style={{cursor: 'pointer'}}
                            onClick={sortColumn}
                            abbr='mobile'
                            id='note-venue-head'
                          >
                            Username / Email
                          </th>
                          <th
                            className='min-w-120px'
                            style={{cursor: 'pointer'}}
                            onClick={sortColumn}
                            abbr='status'
                            id='note-status-head'
                          >
                            Created Date
                          </th>
                          <th
                            className='min-w-120px'
                            style={{cursor: 'pointer'}}
                            // onClick={sortColumn}
                            abbr='actions'
                            id='note-actions-head'
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className='tableRow'>
                        {credentialsData?.map((credential: any, index: number) => {
                          return (
                            <ClientsRow
                              key={index}
                              index={index}
                              setData={setCredentialsObj}
                              credential={credential}
                              setShow={setShowCreateCredentialsModel}
                              deleteClient={deleteClient}
                            />
                          )
                        })}
                      </tbody>
                    </table>
                    {/* end::Table */}
                  </div>
                </div>
              </div>
              <Pagination paginationData={paginationData} fetchData={handlePagination} />
            </>
          ) : !isLoading && credentialsData?.length <= 0 ? (
            <NotFound />
          ) : (
            <Loading />
          )}
        </div>
      </>
    </>
  )
}
