import * as serverRequest from './AppointmentsCRUD'
import {appointmentsSlice} from './AppointmentsSlice'
import {AppDispatch} from '../../../../setup/redux/Store'

const {actions} = appointmentsSlice

export const fetchAppointments =
  ({
    search,
    page,
    sortby,
    orderby,
    createdAt,
  }: {
    search: string
    page: number | string
    sortby: string
    orderby: string
    createdAt: string
  }) =>
  (dispatch: any) => {
    if (sortby === '' && page === '') {
      dispatch(actions.startCall())
    }
    return serverRequest
      .getAppointmentsList({
        search,
        page,
        sortby,
        orderby,
        createdAt,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data

        dispatch(
          actions.AppointmentsFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
          })
        )
      })
  }
export const fetchScheduledAppointments =
  ({
    search,
    page,
    sortby,
    orderby,
    createdAt,
  }: {
    search: string
    page: number | string
    sortby: string
    orderby: string
    createdAt: string
  }) =>
  (dispatch: any) => {
    if (sortby === '' && page === '') {
      dispatch(actions.startCall())
    }
    return serverRequest
      .getScheduledAppointmentsList({
        search,
        page,
        sortby,
        orderby,
        createdAt,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data

        dispatch(
          actions.AppointmentsFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
          })
        )
      })
  }
export const createAppointments = (AppointmentsToCreate: any) => (dispatch: any) => {
  return serverRequest.addAppointmentsList(AppointmentsToCreate).then((response) => {
    const {data, totalCount}: {data: any; totalCount: any} = response.data
    dispatch(actions.AppointmentsCreated({totalCount, entities: data}))
    serverRequest
      .getAppointmentsList({
        search: '',
        page: '',
        orderby: '',
        sortby: '',
        createdAt: '',
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data
        dispatch(
          actions.AppointmentsFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
          })
        )
      })
  })
}
export const UpdateAppointments = (AppointmentsToCreate: any, id: any) => (dispatch: any) => {
  return serverRequest.updateAppointmentsList(id, AppointmentsToCreate).then((response) => {
    serverRequest
      .getAppointmentsList({
        search: '',
        page: '',
        orderby: '',
        sortby: '',
        createdAt: '',
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data
        dispatch(
          actions.AppointmentsFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
          })
        )
      })
  })
}

export const deleteAppointments = (_id: string) => (dispatch: any) => {
  return serverRequest.deleteAppointmentsList(_id).then((response) => {
    serverRequest
      .getAppointmentsList({
        search: '',
        page: '',
        orderby: '',
        sortby: '',
        createdAt: '',
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data
        dispatch(
          actions.AppointmentsFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
          })
        )
      })
  })
}

export const deleteScheduledAppointments = (_id: string) => (dispatch: any) => {
  return serverRequest.deleteScheduledAppointmentsList(_id).then((response) => {
    serverRequest
      .getScheduledAppointmentsList({
        search: '',
        page: '',
        orderby: '',
        sortby: '',
        createdAt: '',
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data
        dispatch(
          actions.AppointmentsFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
          })
        )
      })
  })
}

export const fetchAppointmentsById = (_id: string) => (dispatch: AppDispatch) => {
  dispatch(actions.startCall())
  return serverRequest
    .fetchAppointmentsById(_id)
    .then((response) => {
      const {data} = response
      dispatch(actions.AppointmentsFetchedById({data, listLoading: false}))
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch appointment"
      const errorMessage = error?.response?.data?.message
      dispatch(actions.catchError(errorMessage))
      throw new Error(errorMessage)
    })
}

export const clearAppointmentsDetailData = () => (dispatch: AppDispatch) => {
  dispatch(actions.clearAppointmentsDetailData())
}

export const createAppointmentTimeSlot =
  (AppointmentTimeSlotToCreate: any, AppointmentId: string) => (dispatch: any) => {
    return serverRequest
      .addAppointmentTimeSlot(AppointmentTimeSlotToCreate, AppointmentId)
      .then((response) => {
        dispatch(actions.AppointmentsTimeSlotCreated(response?.data))
      })
      .catch((error) => {
        error.clientMessage = "Can't create appointment time slot"
        const errorMessage = error?.response?.data?.message
        dispatch(actions.catchError(errorMessage))
        throw new Error(errorMessage)
      })
  }

export const getAppointmentTimeSlot =
  (AppointmentId: string, month: number, year: number) => (dispatch: any) => {
    return serverRequest
      .getAppointmentTimeSlot(AppointmentId, month, year)
      .then((response) => {
        dispatch(actions.AppointmentsBookedTimeSlots(response?.data))
      })
      .catch((error) => {
        error.clientMessage = "Can't get appointment time slots"
        const errorMessage = error?.response?.data?.message
        dispatch(actions.catchError(errorMessage))
        throw new Error(errorMessage)
      })
  }

export const sendAppointmentEvent = (values: any) => (dispatch: any) => {
  return serverRequest.sendAppointmentEvent(values).catch((error) => {
    error.clientMessage = "Can't send email notification"
    const errorMessage = error?.response?.data?.message
    dispatch(actions.catchError(errorMessage))
    throw new Error(errorMessage)
  })
}
