import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {areDatesEqual} from '../helpers/utils'

interface TimeSlotsProps {
  day: string
  startTime: Date | null
  endTime: Date | null
  onTimeChange: (timeType: 'startTime' | 'endTime', value: Date | null) => void
  applyToAll: (startTime: Date | null, endTime: Date | null) => void
}

const TimeSlots: React.FC<TimeSlotsProps> = ({
  day,
  startTime,
  endTime,
  onTimeChange,
  applyToAll,
}) => {
  const handleTimeChange = (timeType: 'startTime' | 'endTime', value: Date | null) => {
    if (timeType === 'endTime' && value && startTime && value < startTime) {
      alert(
        'Invalid time range: The end time must be greater than the start time. Please adjust the selected times to ensure the end time is later than the start time.'
      )
      return
    } else if (timeType === 'startTime' && value && endTime && value > endTime) {
      alert(
        'Invalid time range: The start time must be less than the end time. Please adjust the selected times to ensure the end time is later than the start time.'
      )
      return
    } else if (timeType === 'startTime' && value && endTime && areDatesEqual(value, endTime)) {
      alert(
        "Invalid time range: The start time can't be equal to the end time. Please adjust the selected times to ensure the end time is later than the start time."
      )
      return
    } else if (timeType === 'endTime' && value && startTime && areDatesEqual(value, startTime)) {
      alert(
        "Invalid time range: The end time can't be equal to the start time. Please adjust the selected times to ensure the end time is later than the start time."
      )
      return
    }

    onTimeChange(timeType, value)
  }

  return (
    <div className='row'>
      <div className='col-sm-4'>
        <label className='my-2 my-sm-0' htmlFor={`${day}-start`}>
          Start Time
        </label>
        <DatePicker
          selected={startTime ? startTime : new Date()}
          onChange={(time) => handleTimeChange('startTime', time)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption='Time'
          dateFormat='h:mm aa'
          timeFormat='h:mm aa'
          className='time-picker-input form-control'
          placeholderText='Select Time'
        />
      </div>
      <div className='col-sm-4'>
        <label className='my-2 my-sm-0' htmlFor={`${day}-end`}>
          End Time
        </label>
        <DatePicker
          selected={endTime ? endTime : new Date()}
          onChange={(time) => handleTimeChange('endTime', time)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption='Time'
          dateFormat='h:mm aa'
          timeFormat='h:mm aa'
          className='time-picker-input form-control'
          placeholderText='Select Time'
        />
      </div>
      <div
        className='col-sm-3'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          paddingTop: '20px',
        }}
      >
        <label
          style={{
            color: '#0F68CD',
            cursor: 'pointer',
          }}
          onClick={() => applyToAll(startTime, endTime)}
        >
          Apply to all
        </label>
      </div>
    </div>
  )
}

export default TimeSlots
