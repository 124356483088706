import {FC, useRef} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import * as actions from '../../redux/BoardActions'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../../setup'

interface Props {
  resetSearch: boolean
  boardDispatch: any
}

const SearchBox: FC<Props> = ({resetSearch, boardDispatch}) => {
  const dispatch = useDispatch()
  const searchRef: any = useRef(null)
  const {filter}: any = useSelector<RootState>(
    (state) => ({
      filter: state.board.filter,
    }),
    shallowEqual
  )

  const search = (e: any) => {
    dispatch(actions.filterProject({...filter, search: e.target.value}))
  }

  if (resetSearch) {
    searchRef.current.value = ''
    boardDispatch({type: 'RESET_SEARCH_BOX', payload: false})
  }

  return (
    <>
      <div className={`input-group w-100 w-sm-50 me-2`}>
        <input
          type='text'
          ref={searchRef}
          className='form-control form_control_space  bd-right-none py-1'
          placeholder='Search'
          aria-label="Recipient's username"
          aria-describedby='basic-addon2'
          title='Search'
          onChange={search}
        />
        <span
          className='input-group-text bd-left-none bg-white py-1 px-3 z-indx-0'
          id='basic-addon2'
        >
          <span className='p-0 m-0'>
            <span>
              <KTSVG path='/media/icons/projects/svgexport-7.svg' />
            </span>
          </span>
        </span>
      </div>
    </>
  )
}

export {SearchBox}
