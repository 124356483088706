import {lazy, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {Approvals} from '../modules/approvals/ApprovalsPage'
import {List} from '../modules/list/listPage'
import {OpportunityWatch} from '../modules/opportunityWatch/OpportunityWatchPage'
import {ReleaseNotes} from '../modules/releaseNotes/ReleaseNotesPage'
import {Workspace} from '../modules/workspace/WorkspacePage'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {DashboardWrapper10} from '../pages/dashboard/DashboardWrapper10'
import {DashboardWrapper2} from '../pages/dashboard/DashboardWrapper2'
import {DashboardDemo} from '../pages/dashboard/DashboardDemo'

// import {DashboardDemo} from '../pages/dashboard/DashboardDemo'
import {DashboardWrapper3} from '../pages/dashboard/DashboardWrapper3'
import {DashboardWrapper4} from '../pages/dashboard/DashboardWrapper4'
import {DashboardWrapper5} from '../pages/dashboard/DashboardWrapper5'
import {DashboardWrapper6} from '../pages/dashboard/DashboardWrapper6'
import {DashboardWrapper7} from '../pages/dashboard/DashboardWrapper7'
import {DashboardWrapper8} from '../pages/dashboard/DashboardWrapper8'
import {DashboardWrapper9} from '../pages/dashboard/DashboardWrapper9'
import {DashboardWrapperFeatures} from '../pages/dashboard/DashboardWrapperFeatures'
import {DashboardWrapperSpace} from '../pages/dashboard/DashboardWrapperSpace'
import {KanbanBoard} from '../pages/dashboard/KanbanBoard'
import {AvengersInitiatives} from '../modules/avengersInitiatives/AvengersInitiativesPage'
import {WorkspaceMembers} from '../modules/workspaceMembers/WorkspaceMembersPage'
import {Notification} from '../modules/notifications/NotificationPage'
import {Document} from '../modules/documents/documentsPage'
import {ProjectPage} from '../modules/project/ProjectPage'
import {BoardPage} from '../modules/board/BoardPage'

import {NotificationSettings} from '../modules/workspace/components/notifications/NotificationSettings'
import AppChatPage from '../modules/appChat/AppChatPage'
import Task from '../modules/Task/TaskPage'
import {Talent} from '../modules/talent/talentPage'
import {NewSpaces} from '../pages/dashboard/NewSpaces'
import {SpaceDetail} from '../pages/dashboard/SpaceDetail'
import {Organization} from '../modules/organizations/organizationPage'
import {HeaderBreadcrumbsTest} from '../pages/dashboard/HeaderBreadcrumbsTest'
import {HeaderBreadcrumbsTest2} from '../pages/dashboard/HeaderBreadcrumbsTest2'
import {Appointments} from '../modules/appointments/AppointmentsPage'
import ClientPage from '../modules/clients/ClientsPage'
import {ProvidentFundPage} from '../modules/providentFund/ProvidentFundPage'
import CredentialsPage from '../modules/credentials/CredentialsPage'
// import {Todo} from '../modules/Todo/TodoPage'
const Todos = lazy(() => import('../modules/Todo/TodoPage'))
// const ChatPage = lazy(() => import('../modules/chat/ChatPage'))
const ChatPage = lazy(() => import('../modules/workspace/components/chat/ChatPage'))
const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

export function PrivateRoutes() {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard2' component={DashboardWrapper} />
        {/* <Route path='/:workspaceid/features' component={DashboardWrapper2} /> */}
        <Route path='/:workspaceid/features' component={SpaceDetail} />

        <Route path='/dashboard3' component={DashboardWrapper3} />
        <Route path='/dashboard4' component={DashboardWrapper4} />
        <Route path='/dashboard5' component={DashboardWrapper5} />
        <Route path='/dashboard6' component={DashboardWrapper6} />
        <Route path='/dashboard7' component={DashboardWrapper7} />
        <Route path='/dashboard8' component={DashboardWrapper8} />
        <Route path='/dashboard9' component={DashboardWrapper9} />
        <Route path='/dashboard10' component={DashboardWrapper10} />
        <Route path='/kanbanboard' component={KanbanBoard} />
        <Route path={['/dashboard', '/spaces']} component={NewSpaces} />
        {/* <Route path='/myspaces' component={DashboardWrapperSpace} /> */}
        {/* <Route path='/myspaces' component={NewSpaces} /> */}
        {/* <Route path={'/dashboard'} component={DashboardWrapperFeatures} /> */}
        {/* <Route path={'/new-dashboard'} component={NewSpaces} /> */}
        <Route path={'/test-header'} component={HeaderBreadcrumbsTest} />
        <Route path={'/test-header2'} component={HeaderBreadcrumbsTest2} />

        {/* <Route path={'/dashboard'} component={DashboardWrapperFeatures} /> */}
        <Route path='/:workspaceid/todo' component={Todos} />
        <Route path='/clients' component={ClientPage} />
        <Route path='/credentials' component={CredentialsPage} />
        <Route path={['/project/:id/task', '/:workspaceid/project/:id/task']} component={Task} />
        <Route path='/:workspaceid/discussion' component={ChatPage} />
        <Route path='/discussion' component={AppChatPage} />
        <Route path='/space' component={Workspace} />
        <Route path='/:workspaceid/approvals' component={Approvals} />
        {/* <Route path='/:workspaceid/opportunity-watch' component={OpportunityWatch} /> */}
        <Route path='/internal-initiatives' component={AvengersInitiatives} />

        <Route path={['/organization', '/groups']} component={Organization} />
        <Route path='/:workspaceid/members' component={WorkspaceMembers} />
        <Route path='/:workspaceid/notifications' component={NotificationSettings} />

        {/* <Route path='/:workspaceid/opportunity-watch' component={OpportunityWatch} /> */}
        <Route
          path={['/:workspaceid/opportunity-watch', '/opportunity-watch']}
          component={OpportunityWatch}
        />
        <Route path={'/provident-fund'} component={ProvidentFundPage} />
        <Route path={['/documents', '/:workspaceid/documents']} component={Document} />
        <Route
          path={['/project/:id/board', '/:workspaceid/project/:id/board']}
          component={BoardPage}
        />
        <Route path={['/project', '/:workspaceid/project']} component={ProjectPage} />
        <Route path='/release-notes' component={ReleaseNotes} />
        <Route path='/appointments' component={Appointments} />

        {/* <Route path='/:workspaceid/avengers/initiatives' component={AvengersInitiatives} /> */}
        <Route path='/:workspaceid/list' component={List} />
        {/* <Route path='/talent' component={Talent} /> */}
        {!window.location.search.includes('widget') && <Route path='/talent' component={Talent} />}
        <Route path='/pages/profile' component={ProfilePage} />
        <Route path='/account' component={AccountPage} />
        <Redirect from='/' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
