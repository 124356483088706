/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Modal} from 'react-bootstrap-v5'

interface Props {
  show: boolean
  label: string
  description: string
  handleClose: ({canDelete, id}: {canDelete: boolean; id: string}) => void
  id: string
  buttonLabel: string
  children?: React.ReactNode
}

const ActionModal: React.FC<Props> = ({
  show,
  label,
  handleClose,
  description,
  id,
  buttonLabel,
  children,
}) => {
  const deleteItemHandler = (canDelete: boolean) => {
    handleClose({canDelete, id})
  }

  return (
    <Modal
      id='kt_modal_delete_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={show}
      onHide={() => handleClose({canDelete: false, id})}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>{label}</h2>
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
            <div className='flex-row-fluid px-lg-15'>
              <div className='current' data-kt-stepper-element='content'>
                <div className='w-100'>
                  <div className='fv-row mb-10'>
                    <label className='align-items-center fs-4'>{description}</label>
                  </div>
                </div>
              </div>
              {children}
              <div className='d-flex flex-stack justify-content-center pt-'>
                <div className='me-2'>
                  <button
                    title='Cancel'
                    type='button'
                    className='btn btn-sm cancel_button me-3'
                    onClick={() => {
                      deleteItemHandler(false)
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    title='Delete'
                    type='submit'
                    className='btn btn-sm btn-blue me-3'
                    onClick={() => {
                      deleteItemHandler(true)
                    }}
                  >
                    <span className='indicator-label'>{buttonLabel}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {ActionModal}
