import React, {useState, useEffect, useContext} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {AvengersInitiativesDelete} from './avengersInitiativesDelete'
import * as actions from '../redux/avengersInitiativesActions'
import * as workspaceActions from '../../workspace/redux/WorkspaceActions'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import moment from 'moment'
import {updateFrequency, currentStatus, activity} from '../AvengersInitiativesUIHelper'
import {Pagination} from '../../../pagination/pagination'
import {Link, useParams} from 'react-router-dom'
import {TodoListSorting} from '../../../../_metronic/layout/sort/Sort'
import {Search} from '../../../../_metronic/layout/search/Search'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {NotFound} from '../../../../_metronic/layout/NotFound'
import {Loading} from '../../../../_metronic/layout/Loading'
import {Can} from '../../../Common/RBAC/Can'
import '../../../../_metronic/assets/sass/components/todo-table-row.scss'

type Props = {
  className: string
}
type AvengersInitiativesModel = [
  {
    _id: string
    title: string
    description: string
  }
]
interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  page: number
}

const AvengersInitiativesTable: React.FC<Props> = ({className}) => {
  const {workspaceid}: {workspaceid: any} = useParams()
  const dispatch = useDispatch()

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [avengersInitiativessList, setAvengersInitiativessList] = useState([{}])
  const [itemToDelete, setItemToDelete] = useState('')
  const [toggleView, setToggleView] = useState(false)
  const [isFiltered, setIsFiltered] = useState(false)
  const [sortValues, setSortValues] = useState({
    orderby: 'desc',
    sortby: '',
    currentStatus: 0,
    updateFrequency: 0,
    activity: 0,
    title: '',
  })

  const avengersInitiativesData: AvengersInitiativesModel = useSelector<RootState>(
    (state) => state.avengersInitiatives.entities,
    shallowEqual
  ) as AvengersInitiativesModel
  const listLoading: AvengersInitiativesModel = useSelector<RootState>(
    (state) => state.avengersInitiatives.listLoading,
    shallowEqual
  ) as AvengersInitiativesModel

  const paginationData: PaginationModel = useSelector<RootState>(
    (state) => state.avengersInitiatives,
    shallowEqual
  ) as PaginationModel

  useEffect(() => {
    if (workspaceid) {
      dispatch(workspaceActions.fetchWorkspace(workspaceid))
    }
  }, [dispatch])

  const {spaceDetail, userData}: any = useSelector<RootState>(
    (state) => ({
      spaceDetail: state.workspace.workspaceDetail,
      userData: state.auth.user,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (avengersInitiativesData?.length > 0) {
      setAvengersInitiativessList(avengersInitiativesData)
      // fetchAssigneesData(avengersInitiativesData)
    } else {
      setAvengersInitiativessList([])
    }
  }, [avengersInitiativesData])

  const deleteAvengersInitiativesTask = (_id: string) => async (event: any) => {
    setItemToDelete(_id)
    setShowDeleteDialog(true)
  }

  const removeSortingClass = () => {
    const cells = document.querySelectorAll('th')
    cells.forEach(function (cell) {
      cell.classList.remove('table-sort-desc')
      cell.classList.remove('table-sort-asc')
    })
  }

  useEffect(() => {
    let tableHead: any
    tableHead = document.getElementById(`avengersInitiatives-${sortValues.sortby}-head`)

    if (sortValues.orderby === 'desc' && tableHead && avengersInitiativessList?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-asc')
      tableHead.classList.add('table-sort-desc')
    } else if (tableHead && avengersInitiativessList?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-desc')
      tableHead.classList.add('table-sort-asc')
    }
    dispatch(
      actions.fetchAvengersInitiatives({
        search: '',
        page: '',
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        currentStatus: sortValues.currentStatus,
        updateFrequency: sortValues.updateFrequency,
        activity: sortValues.activity,

        workspace: workspaceid,
      })
    )
  }, [sortValues])

  const getUsersAndWhoWillUpdate = (whoWillUpdate: [], whoWillUpdateTeam: []) => {
    const whoWillUpdateAll =
      whoWillUpdate || whoWillUpdateTeam
        ? whoWillUpdate?.concat(whoWillUpdateTeam)
        : whoWillUpdate
        ? whoWillUpdate
        : whoWillUpdateTeam
    const whoWillUpdateLength = whoWillUpdateAll?.length - 1
    let whoWillUpdateData = []
    if (whoWillUpdateAll?.length > 0) {
      whoWillUpdateData = whoWillUpdateAll.map((user: any, index: number) => {
        if (whoWillUpdateLength === index) {
          return user?.firstName ? user?.firstName : user?.name
        } else return user?.firstName ? user?.firstName?.concat(',') : user?.name?.concat(',')
      })
      return whoWillUpdateData
    }
  }
  const getUsersAndWhoWillUpdateForTitle = (whoWillUpdate: [], whoWillUpdateTeam: []) => {
    const whoWillUpdateAll =
      whoWillUpdate || whoWillUpdateTeam
        ? whoWillUpdate?.concat(whoWillUpdateTeam)
        : whoWillUpdate
        ? whoWillUpdate
        : whoWillUpdateTeam
    const whoWillUpdateLength = whoWillUpdateAll?.length - 1
    let whoWillUpdateData = []
    if (whoWillUpdateAll?.length > 0) {
      whoWillUpdateData = whoWillUpdateAll.map((user: any, index: number) => {
        return user?.firstName ? user?.firstName : user?.name
      })
      return whoWillUpdateData
    }
  }

  const sortColumn = (event: any) => {
    if (event.target.classList.contains('table-sort-desc')) {
      removeSortingClass()
      setSortValues({
        orderby: 'asc',
        sortby: event.target.abbr,
        currentStatus: sortValues.currentStatus,
        updateFrequency: sortValues.updateFrequency,
        activity: sortValues.activity,
        title: sortValues.title,
      })
      event.target.classList.remove('table-sort-desc')
      event.target.classList.add('table-sort-asc')
    } else {
      removeSortingClass()
      setSortValues({
        orderby: 'desc',
        sortby: event.target.abbr,
        currentStatus: sortValues.currentStatus,
        updateFrequency: sortValues.updateFrequency,
        activity: sortValues.activity,
        title: sortValues.title,
      })
      event.target.classList.remove('table-sort-asc')
      event.target.classList.add('table-sort-desc')
    }
  }

  const handlePagination = (event: any) => {
    dispatch(
      actions.fetchAvengersInitiatives({
        search: '',
        page: event.selected + 1,
        orderby: '',
        sortby: '',
        currentStatus: 0,
        updateFrequency: 0,
        activity: 0,
        workspace: workspaceid,
      })
    )
  }

  const allowToEditInitiative = (
    whoWillUpdateTeams: [],
    whoWillUpdateMembers: [],
    assignee: [],
    userId: string
  ) => {
    let authorized = false
    if (whoWillUpdateTeams?.length > 0) {
      whoWillUpdateTeams.map((team: any) => {
        team?.members?.map((member: any) => {
          if (member === userId) {
            authorized = true
          }
        })
      })
    } else if (whoWillUpdateMembers?.length > 0) {
      whoWillUpdateMembers.map((member: any) => {
        if (member._id === userId) {
          authorized = true
        }
      })
    } else if (assignee?.length > 0) {
      assignee.map((member: any) => {
        if (member._id === userId) {
          authorized = true
        }
      })
    }
    return authorized
  }

  const handleSearch = (search: string) => {
    dispatch(
      actions.fetchAvengersInitiatives({
        search,
        page: paginationData?.page,
        orderby: '',
        sortby: '',
        currentStatus: 0,
        updateFrequency: 0,
        activity: 0,
        workspace: workspaceid,
      })
    )
  }

  let crumbs
  if (avengersInitiativesData) {
    crumbs = [
      {name: 'Dashboard', url: '/dashboard'},
      {name: 'Internal Initiatives', url: `/internal-initiatives`},
    ]
  }
  let dataWhoWillUpdate: any = []
  let dataWhoWillUpdateTitle: any = []

  let assigneesAndTeams: any = []
  let assigneesAndTeamsTitle: any = []
  return (
    <>
      <AvengersInitiativesDelete
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
        _id={itemToDelete}
      />
      <div className={`mh-80 tabs-main card legend-card-main rounded-0`}>
        {!listLoading && (
          <div className='px-6 px-sm-12'>
            <div className='row'>
              <div className='col-sm-6'>
                <h2 className='fw-500 fs-2 m-0 py-5'>Internal Initiative</h2>
              </div>
              <div className='col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center'>
                <Search handleSearch={handleSearch} />
              </div>
            </div>
          </div>
        )}
        <div className='d-flex justify-content-end align-items-center bg-lighter flex-sm-nowrap flex-wrap'>
          {crumbs && <Breadcrumbs crumbs={crumbs} />}{' '}
          <div className='d-flex w-100 w-sm-auto flex-wrap flex-sm-nowrap'>
            <div className='d-flex align-items-center justify-content-end fv-row ms-7 ms-sm-0'>
              <div className='d-flex align-items-center'>
                <div className='low-label-ui color-purple'>
                  <span className='low_label'></span>
                </div>
                <span className='me-2'>Weekly</span>
              </div>
              <div className='d-flex align-items-center'>
                <div className='low-label-ui color-powder-blue'>
                  <span className='low_label'></span>
                </div>
                <span className='me-2'>Monthly</span>
              </div>
              <div className='high d-flex align-items-center'>
                <div className='low-label-ui color-orange-red'>
                  <span className='low_label'></span>
                </div>
                <span className=''>Quaterly</span>
              </div>
            </div>

            <Link className='bg-lighter me-5 me-sm-10 ms-auto' to={`/internal-initiatives/add`}>
              <span
                className='btn btn-sm bg-transparent  text-blue d-flex align-items-center'
                style={{whiteSpace: 'nowrap'}}
              >
                <i className='fa fa-plus-circle text-blue' aria-hidden='true'></i>
                <span className='link_button'> Create Initiative</span>
              </span>
            </Link>
          </div>
        </div>

        {toggleView && (
          <TodoListSorting
            sortValues={sortValues}
            setSortValues={setSortValues}
            setIsFiltered={setIsFiltered}
          />
        )}
        {/* end::Header */}
        {/* begin::Body */}

        <>
          <div className='overflow_height_scroll'>
            <div className='card-body d-flex flex-column py-3 px-10'>
              {/* begin::Table container */}

              {!listLoading && avengersInitiativessList?.length > 0 ? (
                <div className='table-responsive'>
                  {/* begin::Table */}

                  <table className='avengersInitiatives-table table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th
                          className=''
                          style={{cursor: 'pointer', minWidth: '150px'}}
                          onClick={sortColumn}
                          abbr='title'
                          id='avengersInitiatives-title-head'
                        >
                          Title
                        </th>
                        <th
                          className='min-w-120px'
                          // style={{cursor: 'pointer', minWidth: '95px'}}
                          // onClick={sortColumn}
                          abbr='createdBy'
                          id='avengersInitiatives-createdBy-head'
                        >
                          Initiated By
                        </th>
                        <th className='' style={{minWidth: '130px'}}>
                          Assignees
                        </th>
                        <th className='' style={{minWidth: '130px'}}>
                          Who Will Update
                        </th>
                        <th
                          className='min-w-120px'
                          style={{cursor: 'pointer'}}
                          onClick={sortColumn}
                          abbr='currentStatus'
                          id='avengersInitiatives-currentStatus-head'
                        >
                          Update/Status
                        </th>
                        {/* <th
                          className='min-w-120px'
                          style={{cursor: 'pointer'}}
                          onClick={sortColumn}
                          abbr='updateFrequency'
                          id='avengersInitiatives-updateFrequency-head'
                        >
                          Update Frequency
                        </th> */}

                        <th
                          className='min-w-120px'
                          style={{cursor: 'pointer', minWidth: '95px', textAlign: 'center'}}
                          onClick={sortColumn}
                          abbr='activity'
                          id='avengersInitiatives-activity-head'
                        >
                          Activity
                        </th>
                        <th
                          className='min-w-120px table-sort-desc'
                          style={{cursor: 'pointer', minWidth: '125px'}}
                          onClick={sortColumn}
                          abbr='createdAt'
                          id='avengersInitiatives-createdAt-head'
                        >
                          Created Date
                        </th>

                        <th className='min-w-100px'>Actions</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {avengersInitiativessList.map((avengersInitiatives: any, index: number) => {
                        const assigneeLength = avengersInitiatives?.assignee?.length - 1
                        const whoWillUpdateLength = avengersInitiatives?.whoWillUpdate?.length - 1
                        dataWhoWillUpdate = getUsersAndWhoWillUpdate(
                          avengersInitiatives?.whoWillUpdate,
                          avengersInitiatives?.whoWillUpdateTeam
                        )
                        dataWhoWillUpdateTitle = getUsersAndWhoWillUpdateForTitle(
                          avengersInitiatives?.whoWillUpdate,
                          avengersInitiatives?.whoWillUpdateTeam
                        )
                        assigneesAndTeams = getUsersAndWhoWillUpdate(
                          avengersInitiatives?.assignee,
                          avengersInitiatives?.teams
                        )
                        assigneesAndTeamsTitle = getUsersAndWhoWillUpdateForTitle(
                          avengersInitiatives?.assignee,
                          avengersInitiatives?.teams
                        )
                        return (
                          <tr key={index}>
                            <td data-label='Title'>
                              <div className='d-flex align-items-center'>
                                {/* <Link
                                  className='d-flex'
                                  to={`/${workspaceid}/avengersInitiatives/${avengersInitiatives._id}`}
                                > */}
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span
                                      className='internalInitiativesTitle'
                                      title={avengersInitiatives.title}
                                      style={{
                                        display: 'block',
                                        width: '150px',
                                        minWidth: '150px',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      {avengersInitiatives.title}
                                    </span>
                                  </div>
                                </div>
                                {/* </Link> */}
                              </div>
                            </td>
                            <td
                              data-label='Initiated By'
                              title={
                                avengersInitiatives.createdBy?.firstName +
                                ' ' +
                                avengersInitiatives.createdBy?.lastName
                              }
                            >
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {avengersInitiatives.createdBy
                                    ? avengersInitiatives.createdBy?.firstName +
                                      ' ' +
                                      avengersInitiatives.createdBy?.lastName
                                    : '-'}
                                </div>
                              </div>
                            </td>
                            <td
                              data-label='Assignees'
                              title={
                                avengersInitiatives.assignee?.length > 0 ||
                                avengersInitiatives.teams?.length > 0
                                  ? assigneesAndTeamsTitle?.map((user: any) => {
                                      return user
                                    })
                                  : '-'
                              }
                            >
                              <div className='align-items-center'>
                                {avengersInitiatives.assignee?.length > 0 ||
                                avengersInitiatives.teams?.length > 0
                                  ? assigneesAndTeams?.map((user: any) => {
                                      return user
                                    })
                                  : '-'}
                              </div>
                            </td>
                            <td data-label='Who Will Update'>
                              <div
                                className='align-items-center'
                                title={
                                  avengersInitiatives.whoWillUpdate?.length > 0 ||
                                  avengersInitiatives.whoWillUpdateTeam?.length > 0
                                    ? dataWhoWillUpdateTitle?.map((user: any) => {
                                        return user
                                      })
                                    : '-'
                                }
                              >
                                {avengersInitiatives.whoWillUpdate?.length > 0 ||
                                avengersInitiatives.whoWillUpdateTeam?.length > 0
                                  ? dataWhoWillUpdate?.map((user: any) => {
                                      return user
                                    })
                                  : '-'}
                              </div>
                            </td>

                            <td
                              data-label='Update/Status'
                              title={currentStatus[avengersInitiatives.currentStatus - 1]?.name}
                            >
                              <div className='d-flex align-items-center justify-content-center'>
                                {/* for running  */}
                                {/*<div className='d-flex justify-content-start align-items-center bg-light-blue'>
                                  {updateFrequency.map((frequency: any) => {
                                    if (frequency.value === avengersInitiatives.updateFrequency) {
                                      if (frequency.name === 'Weekly') {
                                        return (
                                          <div className='low-label-ui color-purple'>
                                            {/~ <span className='low_label'>Weekly</span> ~/}
                                          </div>
                                        )
                                      }
                                      if (frequency.name === 'Monthly') {
                                        return (
                                          <div className='d-flex align-items-center'>
                                            <div className='low-label-ui color-powder-blue'>
                                              {/~ <span className='low_label'>Montly</span> ~/}
                                            </div>
                                          </div>
                                        )
                                      }
                                      if (frequency.name === 'Quaterly') {
                                        return (
                                          <div className='d-flex align-items-center'>
                                            <div className='low-label-ui color-orange-red'>
                                              {/~ <span className='low_label'>Quaterly</span> ~/}
                                            </div>
                                          </div>
                                        )
                                      }
                                    }
                                  })}
                                  {currentStatus.map((status: any) => {
                                    if (status.value === avengersInitiatives.currentStatus)
                                      return <div className='d-flex   py-1 fs-8 text-light-blue'>{status.name}</div>
                                  })}
                                </div>*/}
                                {/* for running  */}

                                {/* for non running  */}
                                {/*<div className='d-flex justify-content-start align-items-center bg-light-yellow'>
                                  {updateFrequency.map((frequency: any) => {
                                    if (frequency.value === avengersInitiatives.updateFrequency) {
                                      if (frequency.name === 'Weekly') {
                                        return (
                                          <div className='low-label-ui color-purple'>
                                            {/~ <span className='low_label'>Weekly</span> ~/}
                                          </div>
                                        )
                                      }
                                      if (frequency.name === 'Monthly') {
                                        return (
                                          <div className='d-flex align-items-center'>
                                            <div className='low-label-ui color-powder-blue'>
                                              {/~ <span className='low_label'>Montly</span> ~/}
                                            </div>
                                          </div>
                                        )
                                      }
                                      if (frequency.name === 'Quaterly') {
                                        return (
                                          <div className='d-flex align-items-center'>
                                            <div className='low-label-ui color-orange-red'>
                                              {/~ <span className='low_label'>Quaterly</span> ~/}
                                            </div>
                                          </div>
                                        )
                                      }
                                    }
                                  })}
                                  {currentStatus.map((status: any) => {
                                    if (status.value === avengersInitiatives.currentStatus)
                                      return <div className='d-flex  py-1 fs-8 text-light-yellow'>{status.name}</div>
                                  })}
                                </div>*/}
                                {/* for non running  */}

                                {/* for concluded  */}
                                {/*<div className='d-flex justify-content-start align-items-center bg-light-green'>
                                  {updateFrequency.map((frequency: any) => {
                                    if (frequency.value === avengersInitiatives.updateFrequency) {
                                      if (frequency.name === 'Weekly') {
                                        return (
                                          <div className='low-label-ui color-purple'>
                                            {/~ <span className='low_label'>Weekly</span> ~/}
                                          </div>
                                        )
                                      }
                                      if (frequency.name === 'Monthly') {
                                        return (
                                          <div className='d-flex align-items-center'>
                                            <div className='low-label-ui color-powder-blue'>
                                              {/~ <span className='low_label'>Montly</span> ~/}
                                            </div>
                                          </div>
                                        )
                                      }
                                      if (frequency.name === 'Quaterly') {
                                        return (
                                          <div className='d-flex align-items-center'>
                                            <div className='low-label-ui color-orange-red'>
                                              {/~ <span className='low_label'>Quaterly</span> ~/}
                                            </div>
                                          </div>
                                        )
                                      }
                                    }
                                  })}
                                  {currentStatus.map((status: any) => {
                                    if (status.value === avengersInitiatives.currentStatus)
                                      return <div className='d-flex  py-1 fs-8 text-light-green'>{status.name}</div>
                                  })}
                                </div>*/}
                                {/* for concluded  */}

                                {/* Refactor code */}
                                <div
                                  className={
                                    'd-flex justify-content-start align-items-center ' +
                                    (avengersInitiatives.currentStatus == 1
                                      ? 'bg-light-blue'
                                      : avengersInitiatives.currentStatus == 2
                                      ? 'bg-light-yellow'
                                      : avengersInitiatives.currentStatus == 3
                                      ? 'bg-light-green'
                                      : '')
                                  }
                                >
                                  {updateFrequency.map((frequency: any, i: number) => {
                                    if (frequency.value === avengersInitiatives.updateFrequency) {
                                      return (
                                        <div className='d-flex align-items-center' key={i}>
                                          <div
                                            className={
                                              'low-label-ui ' +
                                              (frequency.name === 'Weekly'
                                                ? 'color-purple'
                                                : frequency.name === 'Monthly'
                                                ? 'color-powder-blue'
                                                : frequency.name === 'Quaterly'
                                                ? 'color-orange-red'
                                                : '')
                                            }
                                          ></div>
                                        </div>
                                      )
                                    }
                                  })}
                                  {currentStatus.map((status: any, i: number) => {
                                    if (status.value === avengersInitiatives.currentStatus)
                                      return (
                                        <div
                                          key={i}
                                          className={
                                            'd-flex py-1 fs-8 ' +
                                            (status.value == 1
                                              ? 'text-light-blue'
                                              : status.value == 2
                                              ? 'text-light-yellow'
                                              : status.value == 3
                                              ? 'text-light-green'
                                              : '')
                                          }
                                        >
                                          {status.name}
                                        </div>
                                      )
                                  })}
                                </div>

                                {/* bg-light-blue */}
                                {/* text-light-blue */}

                                {/* bg-light-yellow */}
                                {/* text-light-yellow */}

                                {/* bg-light-green */}
                                {/* text-light-green */}

                                {/* color-purple */}
                                {/* color-powder-blue */}
                                {/* color-orange-red */}
                              </div>
                            </td>
                            {/* <td
                              data-label='updateFrequency'
                              title={updateFrequency[avengersInitiatives.updateFrequency - 1]?.name}
                            >
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {updateFrequency.map((frequency: any) => {
                                    if (frequency.value === avengersInitiatives.updateFrequency)
                                      return frequency.name
                                  })}
                                </div>
                              </div>
                            </td> */}
                            <td
                              data-label='activity'
                              title={activity[avengersInitiatives.activity - 1]?.name}
                            >
                              {avengersInitiatives.activity ? (
                                <div className='d-flex align-items-center justify-content-center'>
                                  <div className='d-flex justify-content-start flex-column badge badge-light'>
                                    {activity.map((activity: any, index: number) => {
                                      if (activity.value === avengersInitiatives.activity)
                                        return activity.name
                                    })}
                                  </div>
                                </div>
                              ) : (
                                <span title='-'>-</span>
                              )}
                            </td>
                            <td
                              data-label='Created Date'
                              title={
                                avengersInitiatives.createdAt
                                  ? moment(avengersInitiatives.createdAt).format('MM/DD/YYYY')
                                  : '-'
                              }
                            >
                              {avengersInitiatives.createdAt ? (
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {moment(avengersInitiatives.createdAt).format('MM/DD/YYYY')}
                                  </div>
                                </div>
                              ) : (
                                <div className='d-flex align-items-center '>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {'-'}
                                  </div>
                                </div>
                              )}
                            </td>

                            <td data-label='Actions' className='action_btns'>
                              <div className='flex-shrink-0'>
                                {/* <Link
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  title='Activity'
                                  to={`/${workspaceid}/avengers/initiatives/${avengersInitiatives._id}`}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen032.svg'
                                    className='svg-icon-3'
                                  />
                                </Link> */}

                                {(avengersInitiatives?.createdBy?._id === userData?._id ||
                                  allowToEditInitiative(
                                    avengersInitiatives?.whoWillUpdateTeam,
                                    avengersInitiatives?.whoWillUpdate,
                                    avengersInitiatives?.assignee,
                                    userData?._id
                                  )) && (
                                  <Can I='update' this='internal-initiative'>
                                    <Link
                                      className='btn btn-icon btn-bg-light btn-active-color-primary bg-light-blue-actions btn-sm me-1'
                                      to={`/internal-initiatives/${avengersInitiatives._id}/edit`}
                                      title='Edit Initiative'
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/art/art005.svg'
                                        className='svg-icon-3'
                                      />
                                    </Link>
                                  </Can>
                                )}
                                <Can I='delete' this='internal-initiative'>
                                  {avengersInitiatives?.createdBy?._id === userData?._id && (
                                    <button
                                      className='btn btn-icon btn-bg-light btn-active-color-primary bg-light-blue-actions btn-sm '
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_delete_app'
                                      onClick={deleteAvengersInitiativesTask(
                                        avengersInitiatives._id
                                      )}
                                      title='Delete Initiative'
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen027.svg'
                                        className='svg-icon-3'
                                      />
                                    </button>
                                  )}
                                </Can>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              ) : !listLoading && avengersInitiativessList?.length <= 0 ? (
                <NotFound />
              ) : (
                <Loading />
              )}

              {/* end::Table container */}
            </div>
          </div>

          <Pagination paginationData={paginationData} fetchData={handlePagination} />
        </>
      </div>
    </>
  )
}

export {AvengersInitiativesTable}
