import React, {RefObject, useEffect, useRef, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import {KTSVG, getImageURL, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {RootState} from '../../../../setup'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import * as actions from '../redux/ProvidentFundAction'
import {Attachment} from '../../../Common/attachments/Attachment'
import {Loading} from '../../../../_metronic/layout/Loading'
import {Can} from '../../../Common/RBAC/Can'
import '../../../../_metronic/assets/sass/components/opportunity-watch-add.scss'
import {Activity} from '../../generalActivityLogs/components/Activity'
import {getValue} from './ProvidentFundTable'
import {getFormattedPrice} from './PrintTable'
import {ActionModal} from '../../../Common/modals/ActionModal'
import {BankTransferModal} from './BankTransferModel'
import SignatureCanvas from 'react-signature-canvas'

const loanType: any = {
  temporaryLoan: 'Temporary Withdrawal',
  permanentLoan: 'Permanent withdrawal',
  fullLoanSettlement: 'Loan Settlement',
  PFOptOutForm: 'Opt Out',
}

function isApprovalAllow(pfDetails: any, user: any): boolean {
  return pfDetails?.status === 'Bank Transfer Created'
    ? !pfDetails.bankTransferApprovedBy.includes(user._id)
    : !pfDetails.approvedBy.includes(user._id) && !pfDetails.rejectedBy.includes(user._id)
}

function getStatus(pfDetails: any) {
  switch (pfDetails?.status) {
    case 'Pending Approval':
      return (
        <span title='Request Status'>
          <span className='badge badge-light-warning me-auto'>
            <span className='ms-2 fs-7 fw-bolder'>{pfDetails.status}</span>{' '}
            <span className='ms-2 small fw-bolder'>
              {pfDetails?.approvedBy?.length > 0
                ? ` ( Approved by ${pfDetails?.approvedBy?.length} person )`
                : ''}
            </span>
          </span>
        </span>
      )
    case 'Approved':
      return (
        <span title='Request Status'>
          <span className='badge badge-light-success me-auto'>
            <span className='ms-2 fs-7 fw-bolder'>{pfDetails.status}</span>
          </span>
        </span>
      )
    case 'Rejected':
      return (
        <span title='Request Status'>
          <span className='badge badge-light-danger me-auto'>
            <span className='ms-2 small fw-bolder'>{pfDetails.status}</span>{' '}
            {/* <span className='ms-2 small fw-bolder'>
              {pfDetails?.rejectedBy?.length < 2
                ? ` ( Rejected by ${pfDetails?.rejectedBy?.length} person )`
                : ''}{' '}
            </span> */}
          </span>
        </span>
      )
    case 'Bank Transfer Created':
      return (
        <span title='Request Status'>
          <span className='badge badge-light-info  me-auto'>
            <span className='ms-2 fs-7 fw-bolder'>{pfDetails.status}</span>{' '}
            <span className='ms-2 small fw-bolder'>
              {pfDetails?.bankTransferApprovedBy?.length < 2
                ? ` ( Approved by ${pfDetails?.bankTransferApprovedBy?.length} person )`
                : ''}{' '}
            </span>
          </span>
        </span>
      )
    case 'Bank Transfer Approved':
      return (
        <span title='Request Status'>
          <span className='badge badge-light-success  me-auto'>
            <span className='ms-2 fs-7 fw-bolder'>{pfDetails.status}</span>{' '}
          </span>
        </span>
      )

    case 'Completed':
      return (
        <span title='Request Status'>
          <span className='badge badge-light-success me-auto'>
            <span className='ms-2 fs-7 fw-bolder'>{pfDetails.status}</span>
          </span>
        </span>
      )

    case 'Returned':
      return (
        <span title='Request Status'>
          <span className='badge badge-light-info  me-auto'>
            <span className='ms-2 fs-7 fw-bolder'>{pfDetails.status}</span>
          </span>
        </span>
      )

    default:
      return (
        <span title='Request Status'>
          <span className='badge badge-light-info text-grey me-auto'>
            <span className='ms-2 fs-7 fw-bolder'>{pfDetails.status}</span>
          </span>
        </span>
      )
  }
}

const allowedStatuses: string[] = ['Draft', 'Pending Approval', 'Returned']

const ProvidentFundDetail: React.FC = () => {
  const dispatch = useDispatch()
  const signature: RefObject<SignatureCanvas> = useRef(null)
  const {id}: {id: string} = useParams()
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showActivity, setShowActivity] = useState(true)
  const [refreshActivity, setRefreshActivity] = useState(false)
  const [showBankTransferDialogue, setShowBankTransferDialogue] = useState(false)
  const [status, setStatus] = useState('')
  const [signatureData, setSignatureData] = useState('')

  const disableCanvas = () => {
    const signatureCanvas = signature?.current?.getCanvas()

    // Disable pointer events on the canvas
    if (signatureCanvas) {
      signatureCanvas.style.pointerEvents = 'none'
    }
  }
  const {pfDetails, user, listLoading, attachments, accessToken}: any = useSelector<RootState>(
    (state) => ({
      pfDetails: state.providentFund.pfDetails,
      attachments: state.providentFund.attachments,
      listLoading: state.providentFund.listLoading,
      user: state.auth.user,
      accessToken: state.auth.accessToken,
    }),
    shallowEqual
  ) as any

  useEffect(() => {
    if (pfDetails?.treasurers?.length > 0 && signature) {
      const pfSignature =
        pfDetails?.treasurers?.find((treasurer: any) => treasurer?.user === user?._id)?.signature ||
        ''

      if (pfSignature) {
        signature?.current?.fromDataURL(pfSignature)
        setSignatureData(pfSignature)
      }
    }
  }, [pfDetails, signature?.current])

  useEffect(() => {
    if (id) {
      dispatch(actions.getProvidentFundById(id))
    }

    return () => {
      dispatch(actions.resetForm())
    }
  }, [])

  useEffect(() => {
    if (id && refreshActivity) {
      dispatch(actions.getProvidentFundById(id))
      setRefreshActivity(false)
    }

    if (showBankTransferDialogue || showDeleteDialog) {
      setShowActivity(false)
    } else {
      setShowActivity(true)
    }
  }, [refreshActivity, showBankTransferDialogue, showDeleteDialog])

  let crumbs
  if (pfDetails) {
    crumbs = [
      {name: 'Dashboard', url: '/dashboard'},
      {name: `Provident Fund Request`, url: `/provident-fund`},
      {
        name: `${getValue(pfDetails, pfDetails.loanType, 'memberName')}`,
        url: `/provident-fund/${id}`,
      },
    ]
  }

  return (
    <>
      <ActionModal
        show={showDeleteDialog}
        label={`${
          status.toLowerCase().includes('return')
            ? 'Return'
            : status.toLowerCase() === 'send-for-approval'
            ? 'Submit'
            : status.toLowerCase().includes('reject')
            ? 'Reject'
            : 'Approve'
        } Provident fund request`}
        description={`Are you sure you want to ${
          status.toLowerCase() === 'send-for-approval'
            ? 'submit'
            : status.toLowerCase() === 'bank-transfer-approved'
            ? 'approve'
            : status.toLowerCase() === 'return-bank-transfer-request'
            ? 'return'
            : status.toLowerCase() === 'bank-transfer-request'
            ? 'make bank transfer'
            : status.toLowerCase().includes('reject')
            ? 'reject'
            : status.toLowerCase().includes('return')
            ? 'return'
            : status.toLowerCase()
        }
        
        ${status.toLowerCase() === 'bank-transfer-request' ? 'request' : 'this request'} ${
          status.toLowerCase() === 'send-for-approval' ? 'for approval' : ''
        }?`}
        handleClose={async ({canDelete, id}: {canDelete: boolean; id: string}) => {
          if (status === 'Approved' && !signatureData && canDelete) {
            return
          }
          if (canDelete) {
            await dispatch(
              actions.updateProvidentFundStatus(
                id,
                status === 'complete' ? 'Completed' : status,
                signatureData
              )
            )
            setRefreshActivity(true)
          }
          setShowDeleteDialog(false)
          setStatus('')
        }}
        id={id}
        buttonLabel='Yes'
      >
        {status === 'Approved' && (
          <div className='clearfix'>
            <SignatureCanvas
              ref={signature}
              canvasProps={{
                width: 330,
                height: 150,
                className: 'sigCanvas border border-3 border-secondary rounded',
              }}
              onEnd={() => {
                setSignatureData(signature?.current?.toDataURL() as any)
              }}
            />
            <button
              type='button'
              style={{
                padding: '4px 10px',
                fontSize: '.75rem',
              }}
              onClick={() => {
                signature?.current?.clear()
                setSignatureData('')
                // setFieldValue(fieldName, null)
              }}
              className='btn btn-sm btn-light-danger d-block float-end'
            >
              Clear
            </button>

            {!signatureData && <span className='text-danger'>Signature is required</span>}
          </div>
        )}
      </ActionModal>

      <BankTransferModal
        show={showBankTransferDialogue}
        attachments={attachments}
        label='Bank Transfer request'
        handleClose={async ({canSubmit, id}: {canSubmit: boolean; id: string}) => {
          if (canSubmit) {
            const PFData = JSON.parse(JSON.stringify(pfDetails))
            PFData['bankDocumentPaths'] = attachments.map(
              (attachment: {path: string}) => attachment.path
            )
            PFData['status'] = 'Bank Transfer Created'
            await dispatch(
              actions.updateProvidentFundStatus(id, status, PFData['bankDocumentPaths'])
            )
            // await dispatch(actions.updateProvidentFund(id, PFData))
            setRefreshActivity(true)
          }
          setShowBankTransferDialogue(false)
          setStatus('')
          dispatch(actions.loadAttachments([]))
        }}
        id={id}
        buttonLabel='Submit'
        actions={actions}
      />
      <div className='mh-80 bg-white'>
        <div className='px-6 px-sm-12'>
          <div className='row'>
            <div className='col-sm-6'>
              <h2 className='fw-500 fs-2 m-0 py-5'>Provident Fund Request</h2>
            </div>
          </div>
        </div>
        {!listLoading && crumbs && <Breadcrumbs crumbs={crumbs} containerClass='mx-10' />}
        {listLoading && <Loading />}
        {!listLoading && (
          <>
            <div className='overflow_height_scroll'>
              <div className='card-body pt-15 pb-2 px-0 mx-2 mx-sm-10'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                  <div className='flex-grow-1 border_grey_info'>
                    <div className='d-flex flex-wrap mb-2'>
                      {pfDetails && (
                        <div className='d-flex flex-column w-100'>
                          <div className='d-flex justify-content-between'>
                            <div className='d-flex flex-column'>
                              <div className='d-flex align-items-center mb-2'>
                                <span
                                  className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                                  title='Member Name'
                                >
                                  {getValue(pfDetails, pfDetails?.loanType, 'memberName')}
                                </span>
                                <span title='Employee ID'>
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen026.svg'
                                    className='svg-icon-1 svg-icon-primary'
                                  />
                                  <span className='fs-5'>
                                    {' '}
                                    {getValue(pfDetails, pfDetails?.loanType, 'employeeId')}
                                  </span>
                                </span>
                              </div>
                            </div>

                            <div className='d-flex'>
                              {pfDetails?.status === 'Draft' || pfDetails?.status === 'Returned' ? (
                                <>
                                  <Can I='update' this='provident-fund'>
                                    <button
                                      title='Send for Approval'
                                      className='btn btn-sm btn-primary ms-3'
                                      onClick={() => {
                                        setShowDeleteDialog(true)
                                        setStatus('send-for-approval')
                                      }}
                                    >
                                      Send for Approval
                                    </button>
                                  </Can>
                                  <Can I='update' this='provident-fund'>
                                    <Link to={`/provident-fund/${id}/edit`}>
                                      <button
                                        title='Edit'
                                        className='btn btn-sm btn-light-blue text-blue ms-3'
                                      >
                                        <img
                                          src={toAbsoluteUrl(
                                            '/media/icons/projects/svgexport-10.svg'
                                          )}
                                          className='me-2 w-25'
                                        />
                                        Edit
                                      </button>
                                    </Link>
                                  </Can>
                                </>
                              ) : (
                                <>
                                  {allowedStatuses.includes(pfDetails?.status) &&
                                    isApprovalAllow(pfDetails, user) && (
                                      <Can I='approve/reject' this='provident-fund'>
                                        <button
                                          title='Approve'
                                          className='btn btn-sm btn-primary ms-3'
                                          onClick={() => {
                                            setShowDeleteDialog(true)
                                            setStatus('Approved')
                                          }}
                                        >
                                          Approve
                                        </button>
                                      </Can>
                                    )}
                                  {allowedStatuses.includes(pfDetails?.status) &&
                                    isApprovalAllow(pfDetails, user) && (
                                      <Can I='approve/reject' this='provident-fund'>
                                        <button
                                          title='Reject'
                                          className='btn btn-sm btn-danger ms-3'
                                          onClick={() => {
                                            setShowDeleteDialog(true)
                                            setStatus('Rejected')
                                          }}
                                        >
                                          Reject
                                        </button>
                                      </Can>
                                    )}
                                  {allowedStatuses.includes(pfDetails?.status) &&
                                    isApprovalAllow(pfDetails, user) && (
                                      <Can I='approve/reject' this='provident-fund'>
                                        <button
                                          title='Return'
                                          className='btn btn-sm btn-secondary ms-3'
                                          onClick={() => {
                                            setShowDeleteDialog(true)
                                            setStatus('Returned')
                                          }}
                                        >
                                          Return
                                        </button>
                                      </Can>
                                    )}
                                </>
                              )}

                              {pfDetails?.status === 'Bank Transfer Approved' && (
                                <>
                                  <Can I='update' this='provident-fund'>
                                    <button
                                      title='Complete Request'
                                      className='btn btn-sm btn-primary ms-3'
                                      onClick={() => {
                                        setShowDeleteDialog(true)
                                        setStatus('complete')
                                      }}
                                    >
                                      Complete Request
                                    </button>
                                  </Can>
                                </>
                              )}

                              {pfDetails?.status === 'Approved' && (
                                <>
                                  <Can I='update' this='provident-fund'>
                                    <button
                                      title='Bank Transfer Request'
                                      className='btn btn-sm btn-primary ms-3'
                                      onClick={() => {
                                        setShowBankTransferDialogue(true)
                                        setStatus('bank-transfer-request')
                                      }}
                                    >
                                      Bank Transfer Request
                                    </button>
                                  </Can>
                                </>
                              )}

                              {pfDetails?.status === 'Bank Transfer Created' &&
                                isApprovalAllow(pfDetails, user) && (
                                  <>
                                    <Can I='approve/reject' this='provident-fund'>
                                      <button
                                        title='Approve Bank Transfer Request'
                                        className='btn btn-sm btn-primary ms-3'
                                        onClick={() => {
                                          setShowDeleteDialog(true)
                                          setStatus('bank-transfer-approved')
                                        }}
                                      >
                                        Approve Bank Transfer Request
                                      </button>
                                    </Can>
                                  </>
                                )}

                              {pfDetails?.status === 'Bank Transfer Created' &&
                                isApprovalAllow(pfDetails, user) && (
                                  <>
                                    <Can I='approve/reject' this='provident-fund'>
                                      <button
                                        title='Return Bank Transfer Request'
                                        className='btn btn-sm btn-danger ms-3'
                                        onClick={() => {
                                          setShowDeleteDialog(true)
                                          setStatus('return-bank-transfer-request')
                                        }}
                                      >
                                        Return Bank Transfer Request
                                      </button>
                                    </Can>
                                  </>
                                )}
                            </div>
                          </div>
                          <div className='d-flex align-items-center mb-1'>
                            <span
                              className='text-gray-800 text-hover-primary fs-4 fw-bold me-3'
                              title='Request Type'
                            >
                              {loanType[pfDetails?.loanType] + ' Request'}
                            </span>
                            <span className='fs-6'>{getStatus(pfDetails)}</span>
                          </div>

                          <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                            {getValue(pfDetails, pfDetails?.loanType, 'designation') && (
                              <span
                                title='Designation'
                                className='d-flex align-items-center text-gray-400 text-hover-primary me-2 mb-2'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/communication/com006.svg'
                                  className='svg-icon-4 me-1'
                                />

                                <span className='d-flex align-items-center me-2 mb-2 mb-sm-0'>
                                  <span className='ms-2 fs-7 fw-bolder'>
                                    {getValue(pfDetails, pfDetails?.loanType, 'designation')}
                                  </span>
                                </span>
                              </span>
                            )}
                            <span
                              className='d-flex align-items-center text-gray-400 text-hover-primary me-4 mb-2'
                              title='Department'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen018.svg'
                                className='svg-icon-4 me-1'
                              />
                              {getValue(pfDetails, pfDetails?.loanType, 'department')}
                            </span>
                            <span
                              className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                              title='Requested Date'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen014.svg'
                                className='svg-icon-4 me-1'
                              />
                              {pfDetails.createdAt
                                ? moment(pfDetails.createdAt).format('DD-MM-YYYY')
                                : ''}
                            </span>
                          </div>

                          {pfDetails?.loanType && (
                            <div className='row g-6 g-xl-9'>
                              {pfDetails?.loanType !== 'PFOptOutForm' && (
                                <div className='col-md-6'>
                                  <div className='card border_grey_info p-3  h-100'>
                                    <div className='card-header border-0 pt-2 ps-2'>
                                      <div className='card-title m-0'>
                                        <span className='d-flex text-primary align-items-center mb-2'>
                                          <KTSVG
                                            path='/media/icons/duotune/communication/com012.svg'
                                            className='svg-icon-4 me-1'
                                          />{' '}
                                          Basic Information
                                        </span>
                                      </div>
                                    </div>
                                    <div className='card-body p-2'>
                                      <div className='d-flex flex-wrap mb-5'>
                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                          <div className='fw-semibold text-gray-500'> Name</div>
                                          <div className='fs-6 text-gray-800 fw-bold'>
                                            {getValue(pfDetails, pfDetails?.loanType, 'memberName')}
                                          </div>
                                        </div>
                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                          <div className='fw-semibold text-gray-500'>
                                            {' '}
                                            Appointment Date (Nextbridge Pvt Ltd)
                                          </div>
                                          <div className='fs-6 text-gray-800 fw-bold'>
                                            {moment(
                                              getValue(
                                                pfDetails,
                                                pfDetails?.loanType,
                                                'appointmentDate'
                                              )
                                            ).format('DD-MM-YYYY')}
                                          </div>
                                        </div>
                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                          <div className='fw-semibold text-gray-500'>
                                            Date of Joining (PF)
                                          </div>
                                          <div className='fs-6 text-gray-800 fw-bold'>
                                            {moment(
                                              getValue(
                                                pfDetails,
                                                pfDetails?.loanType,
                                                'dateOfPFJoining'
                                              )
                                            ).format('DD-MM-YYYY')}
                                          </div>
                                        </div>

                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'currentGrossSalary'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              {' '}
                                              Current Gross Salary
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'currentGrossSalary'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'currentBasicSalary'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              Current Basic Salary
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'currentBasicSalary'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'principalAmountOfLoan'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              Principal Amount Of Loan
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'principalAmountOfLoan'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'totalNumberOfInstallments'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              Total No. of Installments
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'totalNumberOfInstallments'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(pfDetails, pfDetails?.loanType, 'amountPaid') && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              Amount Paid
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'amountPaid'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'installmentsPaid'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              Installments Paid
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'installmentsPaid'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'amountApplied'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              {' '}
                                              Amount Applied
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'amountApplied'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'monthlyInstallmentSuggestion'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 me-2 px-4 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              Monthly Installment
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'monthlyInstallmentSuggestion'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'noOfInstallmentsSuggestion'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              {' '}
                                              No. of Installments
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getValue(
                                                pfDetails,
                                                pfDetails?.loanType,
                                                'noOfInstallmentsSuggestion'
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'dateOfApplication'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              Date of Application
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {moment(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'dateOfApplication'
                                                )
                                              ).format('DD-MM-YYYY')}
                                            </div>
                                          </div>
                                        )}

                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'didAvailPFFacility'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              {' '}
                                              Have you ever availed the withdrawal facility
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getValue(
                                                pfDetails,
                                                pfDetails?.loanType,
                                                'didAvailPFFacility'
                                              )?.toUpperCase()}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {pfDetails?.loanType !== 'PFOptOutForm' &&
                                pfDetails?.loanType !== 'fullLoanSettlement' && (
                                  <div className='col-md-6  d-none'>
                                    <div className='card border_grey_info p-3  h-100'>
                                      <div className='card-header border-0 pt-2 ps-2'>
                                        <div className='card-title m-0'>
                                          <span className='d-flex text-primary align-items-center mb-2'>
                                            <KTSVG
                                              path='/media/icons/duotune/communication/com012.svg'
                                              className='svg-icon-4 me-1'
                                            />{' '}
                                            Suggested Repayment Schedule
                                          </span>
                                        </div>
                                      </div>
                                      <div className='card-body p-2'>
                                        <div className='d-flex flex-wrap mb-5'>
                                          {getValue(
                                            pfDetails,
                                            pfDetails?.loanType,
                                            'amountApplied'
                                          ) && (
                                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                              <div className='fw-semibold text-gray-500'>
                                                {' '}
                                                Amount Applied
                                              </div>
                                              <div className='fs-6 text-gray-800 fw-bold'>
                                                {getFormattedPrice(
                                                  getValue(
                                                    pfDetails,
                                                    pfDetails?.loanType,
                                                    'amountApplied'
                                                  ) as any
                                                )}
                                              </div>
                                            </div>
                                          )}
                                          {getValue(
                                            pfDetails,
                                            pfDetails?.loanType,
                                            'monthlyInstallmentSuggestion'
                                          ) && (
                                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 me-2 px-4 mb-3'>
                                              <div className='fw-semibold text-gray-500'>
                                                Monthly Installment
                                              </div>
                                              <div className='fs-6 text-gray-800 fw-bold'>
                                                {getFormattedPrice(
                                                  getValue(
                                                    pfDetails,
                                                    pfDetails?.loanType,
                                                    'monthlyInstallmentSuggestion'
                                                  ) as any
                                                )}
                                              </div>
                                            </div>
                                          )}
                                          {getValue(
                                            pfDetails,
                                            pfDetails?.loanType,
                                            'noOfInstallmentsSuggestion'
                                          ) && (
                                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                              <div className='fw-semibold text-gray-500'>
                                                {' '}
                                                No. of Installments
                                              </div>
                                              <div className='fs-6 text-gray-800 fw-bold'>
                                                {getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'noOfInstallmentsSuggestion'
                                                )}
                                              </div>
                                            </div>
                                          )}
                                          {getValue(
                                            pfDetails,
                                            pfDetails?.loanType,
                                            'dateOfApplication'
                                          ) && (
                                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                              <div className='fw-semibold text-gray-500'>
                                                Date of Application
                                              </div>
                                              <div className='fs-6 text-gray-800 fw-bold'>
                                                {moment(
                                                  getValue(
                                                    pfDetails,
                                                    pfDetails?.loanType,
                                                    'dateOfApplication'
                                                  )
                                                ).format('DD-MM-YYYY')}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {pfDetails?.loanType === 'fullLoanSettlement' && (
                                <div className='col-md-6'>
                                  <div className='card border_grey_info p-3  h-100'>
                                    <div className='card-header border-0 pt-2 ps-2'>
                                      <div className='card-title m-0'>
                                        <span className='d-flex text-primary align-items-center mb-2'>
                                          <KTSVG
                                            path='/media/icons/duotune/communication/com012.svg'
                                            className='svg-icon-4 me-1'
                                          />{' '}
                                          Current Payment /Loan Settlement Status
                                        </span>
                                      </div>
                                    </div>
                                    <div className='card-body p-2'>
                                      <div className='d-flex flex-wrap mb-5'>
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'monthlyInstallment'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              {' '}
                                              Monthly Installment
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'monthlyInstallment'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'remainingInstallments'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 me-2 px-4 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              Monthly Installment Left
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'remainingInstallments'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(pfDetails, pfDetails?.loanType, 'amountLeft') && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              {' '}
                                              Amount Left
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'amountLeft'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'willMakePaymentVia'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              Will Make Payment Via
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getValue(
                                                pfDetails,
                                                pfDetails?.loanType,
                                                'willMakePaymentVia'
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {pfDetails?.loanType === 'PFOptOutForm' && (
                                <div className='col-md-6 '>
                                  <div className=' card border_grey_info p-3  h-100'>
                                    <div className='card-header border-0 pt-2 ps-2'>
                                      <div className='card-title m-0'>
                                        <span className='d-flex text-primary align-items-center mb-2'>
                                          <KTSVG
                                            path='/media/icons/duotune/communication/com012.svg'
                                            className='svg-icon-4 me-1'
                                          />{' '}
                                          Basic Information
                                        </span>
                                      </div>
                                    </div>
                                    <div className='card-body p-2'>
                                      <div className='d-flex flex-wrap mb-5'>
                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                          <div className='fw-semibold text-gray-500'>Name</div>
                                          <div className='fs-6 text-gray-800 fw-bold'>
                                            {getValue(pfDetails, pfDetails?.loanType, 'memberName')}
                                          </div>
                                        </div>

                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                          <div className='fw-semibold text-gray-500'>
                                            Father Name
                                          </div>
                                          <div className='fs-6 text-gray-800 fw-bold'>
                                            {getValue(pfDetails, pfDetails?.loanType, 'fatherName')}
                                          </div>
                                        </div>
                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                          <div className='fw-semibold text-gray-500'>
                                            {' '}
                                            Date of birth
                                          </div>
                                          <div className='fs-6 text-gray-800 fw-bold'>
                                            {moment(
                                              getValue(pfDetails, pfDetails?.loanType, 'dob')
                                            ).format('DD-MM-YYYY')}
                                          </div>
                                        </div>
                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                          <div className='fw-semibold text-gray-500'> CNIC</div>
                                          <div className='fs-6 text-gray-800 fw-bold'>
                                            {getValue(pfDetails, pfDetails?.loanType, 'CNIC')}
                                          </div>
                                        </div>

                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                          <div className='fw-semibold text-gray-500'>
                                            {' '}
                                            Address (As per your CNIC)
                                          </div>
                                          <div className='fs-6 text-gray-800 fw-bold'>
                                            {getValue(pfDetails, pfDetails?.loanType, 'address')}
                                          </div>
                                        </div>
                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                          <div className='fw-semibold text-gray-500'>
                                            PF Activation Month
                                          </div>
                                          <div className='fs-6 text-gray-800 fw-bold'>
                                            {moment(
                                              getValue(
                                                pfDetails,
                                                pfDetails?.loanType,
                                                'PFActivationMonth'
                                              )
                                            ).format('MMM YYYY')}
                                          </div>
                                        </div>
                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                          <div className='fw-semibold text-gray-500'>
                                            PF Discontinued Month
                                          </div>
                                          <div className='fs-6 text-gray-800 fw-bold'>
                                            {moment(
                                              getValue(
                                                pfDetails,
                                                pfDetails?.loanType,
                                                'PFDiscontinuedMonth'
                                              )
                                            ).format('MMM YYYY')}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {pfDetails?.loanType !== 'PFOptOutForm' && (
                                <div className='col-md-6'>
                                  <div className='card border_grey_info p-3  h-100'>
                                    <div className='card-header border-0 pt-2 ps-2'>
                                      <div className='card-title m-0'>
                                        <span className='d-flex text-primary align-items-center mb-2'>
                                          <KTSVG
                                            path='/media/icons/duotune/communication/com012.svg'
                                            className='svg-icon-4 me-1'
                                          />{' '}
                                          Finance Verification
                                        </span>
                                      </div>
                                    </div>
                                    <div className='card-body p-2'>
                                      <div className='d-flex flex-wrap mb-5'>
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'currentProvidentBalance'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              {' '}
                                              Current Provident Balance
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'currentProvidentBalance'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'approvedAmount'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              Amount Approved
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'approvedAmount'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'monthlyInstallmentOffice'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              {' '}
                                              Monthly Installment
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'monthlyInstallmentOffice'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'remainingInstallmentsOffice'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 me-2 px-4 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              Monthly Installment Left
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'remainingInstallmentsOffice'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'amountLeftOffice'
                                        ) && (
                                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                            <div className='fw-semibold text-gray-500'>
                                              {' '}
                                              Amount Left
                                            </div>
                                            <div className='fs-6 text-gray-800 fw-bold'>
                                              {getFormattedPrice(
                                                getValue(
                                                  pfDetails,
                                                  pfDetails?.loanType,
                                                  'amountLeftOffice'
                                                ) as any
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      {pfDetails?.loanType === 'temporaryLoan' && (
                                        <>
                                          <div className='card-header border-0 pt-2 ps-2'>
                                            <div className='card-title m-0'>
                                              <span className='d-flex text-primary align-items-center mb-2'>
                                                <KTSVG
                                                  path='/media/icons/duotune/communication/com012.svg'
                                                  className='svg-icon-4 me-1'
                                                />{' '}
                                                Repayment Schedule
                                              </span>
                                            </div>
                                          </div>
                                          <div className='card-body p-2'>
                                            <div className='d-flex flex-wrap mb-5'>
                                              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                                <div className='fw-semibold text-gray-500'>
                                                  {' '}
                                                  Monthly Installment
                                                </div>
                                                <div className='fs-6 text-gray-800 fw-bold'>
                                                  {getFormattedPrice(
                                                    getValue(
                                                      pfDetails,
                                                      pfDetails?.loanType,
                                                      'approvedMonthlyInstallment'
                                                    ) as any
                                                  )}
                                                </div>
                                              </div>
                                              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 me-2 px-4 mb-3'>
                                                <div className='fw-semibold text-gray-500'>
                                                  No. of Installments
                                                </div>
                                                <div className='fs-6 text-gray-800 fw-bold'>
                                                  {getValue(
                                                    pfDetails,
                                                    pfDetails?.loanType,
                                                    'approvedNoOfInstallments'
                                                  )}
                                                </div>
                                              </div>
                                              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3'>
                                                <div className='fw-semibold text-gray-500'>
                                                  Starting Date
                                                </div>
                                                <div className='fs-6 text-gray-800 fw-bold'>
                                                  {moment(
                                                    getValue(
                                                      pfDetails,
                                                      pfDetails?.loanType,
                                                      'startingDate'
                                                    )
                                                  ).format('DD-MM-YYYY')}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {getValue(pfDetails, pfDetails?.loanType, 'reasonForWithdrawal') && (
                                <div className='col-md-6'>
                                  <div className='card border_grey_info p-3  h-100'>
                                    <div className='card-header border-0 pt-2 ps-2'>
                                      <div className='card-title m-0'>
                                        <span className='d-flex text-primary align-items-center mb-2'>
                                          <KTSVG
                                            path='/media/icons/duotune/communication/com012.svg'
                                            className='svg-icon-4 me-1'
                                          />{' '}
                                          Reason for Withdrawal
                                        </span>
                                      </div>
                                    </div>
                                    <div className='card-body p-2'>
                                      <div className='d-flex flex-wrap mb-5'>
                                        {getValue(
                                          pfDetails,
                                          pfDetails?.loanType,
                                          'reasonForWithdrawal'
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {getValue(pfDetails, pfDetails?.loanType, 'notes') && (
                                <div className='col-md-6 '>
                                  <div className='card border_grey_info p-3  h-100'>
                                    <div className='card-header border-0 pt-2 ps-2'>
                                      <div className='card-title m-0'>
                                        <span className='d-flex text-primary align-items-center mb-2'>
                                          <KTSVG
                                            path='/media/icons/duotune/communication/com012.svg'
                                            className='svg-icon-4 me-1'
                                          />{' '}
                                          Notes
                                        </span>
                                      </div>
                                    </div>
                                    <div className='card-body p-2'>
                                      <div className='d-flex flex-wrap mb-5'>
                                        {getValue(pfDetails, pfDetails?.loanType, 'notes')}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div
                                className={`col-md-6 ${
                                  pfDetails?.documents?.length === 0 &&
                                  pfDetails?.bankDocumentPaths?.length === 0
                                    ? 'd-none'
                                    : ''
                                }`}
                              >
                                <div className='card border_grey_info p-3  h-100'>
                                  {pfDetails?.documentPaths?.length > 0 && (
                                    <>
                                      <div className='card-header border-0 pt-2 ps-2'>
                                        <div className='card-title m-0'>
                                          <span className='d-flex text-primary align-items-center mb-2'>
                                            <KTSVG
                                              path='/media/icons/duotune/communication/com012.svg'
                                              className='svg-icon-4 me-1'
                                            />{' '}
                                            Supporting Documents
                                          </span>
                                        </div>
                                      </div>
                                      <div className='card-body p-2'>
                                        <div className='d-flex flex-wrap mb-5'>
                                          {pfDetails?.documentPaths?.map(
                                            (document: any, index: number) => (
                                              <a
                                                href={`${document?.url}?token=${accessToken}&itemType=profile&itemId=${id}`}
                                                target='_blank'
                                                rel='noreferrer'
                                                key={index}
                                              >
                                                {['png', 'jpg', 'jpeg'].includes(
                                                  document?.type.toLowerCase()
                                                ) ? (
                                                  <img
                                                    src={document?.url}
                                                    title={document?.path}
                                                    style={{height: '30px', width: '30px'}}
                                                    className='me-2'
                                                  />
                                                ) : (
                                                  <>
                                                    {' '}
                                                    <img
                                                      src={`/media/icons/files/${document?.type}.png`}
                                                      title={document?.path}
                                                      style={{height: '30px', width: '30px'}}
                                                      className='me-2'
                                                    />
                                                  </>
                                                )}
                                              </a>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {pfDetails?.bankDocuments?.length > 0 && (
                                    <>
                                      <div className='card-header border-0 pt-2 ps-2'>
                                        <div className='card-title m-0'>
                                          <span className='d-flex text-primary align-items-center mb-2'>
                                            <KTSVG
                                              path='/media/icons/duotune/communication/com012.svg'
                                              className='svg-icon-4 me-1'
                                            />{' '}
                                            Bank Documents
                                          </span>
                                        </div>
                                      </div>
                                      <div className='card-body p-2'>
                                        <div className='d-flex flex-wrap mb-5'>
                                          {pfDetails?.bankDocuments?.map(
                                            (bankDocument: any, index: number) => (
                                              <a
                                                href={`${bankDocument?.url}?token=${accessToken}&itemType=profile&itemId=${id}`}
                                                target='_blank'
                                                rel='noreferrer'
                                                key={index}
                                              >
                                                {['png', 'jpg', 'jpeg'].includes(
                                                  bankDocument?.type.toLowerCase()
                                                ) ? (
                                                  <img
                                                    src={bankDocument?.url}
                                                    title={bankDocument?.path}
                                                    style={{height: '30px', width: '30px'}}
                                                    className='me-2'
                                                  />
                                                ) : (
                                                  <>
                                                    {' '}
                                                    <img
                                                      src={`/media/icons/files/${bankDocument?.type}.png`}
                                                      title={bankDocument?.path}
                                                      style={{height: '30px', width: '30px'}}
                                                      className='me-2'
                                                    />
                                                  </>
                                                )}
                                              </a>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {/* {pfDetails?.bankDocuments?.length > 0 && <h5>Bank Documents</h5>} */}
                  </div>
                </div>
              </div>

              {showActivity && <Activity itemType={'ProvidentFund'} activityItemId={id} />}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export {ProvidentFundDetail}
