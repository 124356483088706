import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {ProvidentFundTable} from './components/ProvidentFundTable'
import {ProvidentFundDetail} from './components/ProvidentFundDetail'
import {ProvidentFundAdd} from './components/ProvidentFundAdd'
const ProvidentFundPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/provident-fund'}>
          <ProvidentFundTable className='mb-5 mb-xl-8' />
        </Route>
        <Route path={['/provident-fund/add', '/provident-fund/:id/edit']}>
          {({history}) => (
            <ProvidentFundAdd
              onHide={() => {
                history.push('/provident-fund')
              }}
            />
          )}
        </Route>
        <Route path='/provident-fund/:id'>
          <ProvidentFundDetail />
        </Route>
      </Switch>
    </>
  )
}

export {ProvidentFundPage}
