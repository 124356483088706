import {ListGroup} from 'react-bootstrap-v5'
import '../helpers/styles/timeSlots.css'
import {bookedSlotsObj} from '../helpers'
import {useEffect, useState} from 'react'
import {AppointmentEvent} from './AppointmentEvent'

const TimeList = ({
  timeSlotsOfMonth,
  onBookingAppointment,
  selectedDate,
  bookedSlots,
  timeDuration,
  selectedTime,
  showModal,
  closeModal,
}: {
  timeSlotsOfMonth: string[]
  onBookingAppointment: (time: string) => void
  selectedDate: Date
  bookedSlots: bookedSlotsObj[]
  timeDuration: string
  selectedTime: string
  showModal: boolean
  closeModal(value: boolean): void
}) => {
  const [todaysTimeSlots, setTodaysTimeSlots] = useState(timeSlotsOfMonth)
  useEffect(() => {
    const todayBookedSlots = bookedSlots.filter((slot) => slot.date === selectedDate.toISOString())
    if (todayBookedSlots) {
      const bookedTimes = todayBookedSlots.map((slot) => slot.time)
      const filteredTimeSlots = timeSlotsOfMonth.filter((slot) => !bookedTimes.includes(slot))
      setTodaysTimeSlots(filteredTimeSlots)
    }
  }, [selectedDate, bookedSlots])
  return (
    <>
      {selectedTime && <AppointmentEvent closeModal={closeModal} />}
      {!!!selectedTime && (
        <div>
          {todaysTimeSlots?.length ? (
            <ListGroup>
              {todaysTimeSlots.map((time, index) => (
                <ListGroup.Item
                  key={index}
                  className='d-flex justify-content-between align-items-center timeSlotItem'
                  onClick={() => onBookingAppointment(time)}
                >
                  <span className='heading fs-5'>{time}</span>
                  <button title='Book Now' type='button' className='btn btn-sm btn-blue me-3'>
                    <span className='indicator-label'>Book Now</span>
                  </button>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <div className='slot-container'>
              <div className='slot-centered-text'>Time slots are not available</div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default TimeList
