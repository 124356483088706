import * as Yup from 'yup'
export type ProvidentFundCommon = {
  memberName: string
  employeeId: string
  designation: string
  department: string
  dateOfPFJoining: string
}

export interface TemporaryLoan extends ProvidentFundCommon {
  appointmentDate: string
  dateOfPFJoining: string
  dateOfApplication: string
  startingDate: string
  currentGrossSalary: number | string
  currentBasicSalary: number | string
  amountApplied: number | string
  reasonForWithdrawal: string
  approvedAmount: string
  didAvailPFFacility: string
  approvedMonthlyInstallment: number | string
  monthlyInstallmentSuggestion: number | string
  noOfInstallmentsSuggestion: number | string
  approvedAmountApplied: number | string
  approvedNoOfInstallments: number | string
  currentProvidentBalance: number | string
  documents: string[]
  documentPaths?: string[]
}

export interface PermanentLoan extends ProvidentFundCommon {
  appointmentDate: string
  dateOfPFJoining: string
  dateOfApplication: string
  currentGrossSalary: number | string
  currentBasicSalary: number | string
  amountApplied: number | string
  reasonForWithdrawal: string
  approvedAmount: string
  currentProvidentBalance: string
  notes: string
  didAvailPFFacility: string
}

export interface FullLoanSettlement extends ProvidentFundCommon {
  principalAmountOfLoan: number | string
  totalNumberOfInstallments: number | string
  amountPaid: number | string
  installmentsPaid: number | string
  remainingInstallments: number | string
  amountLeft: number | string
  willMakePaymentVia: string
  monthlyInstallment: number | string
  appointmentDate: string
  dateOfPFJoining: string
}

export interface PFOptOutForm {
  memberName: string
  employeeId: string
  designation: string
  department: string
  dob: string
  CNIC: string
  fatherName: string
  address: string
  PFActivationMonth: string
  PFDiscontinuedMonth: string
}

export type TemporaryLoanPF = TemporaryLoan | ProvidentFundCommon

export type PermanentLoanPF = PermanentLoan | ProvidentFundCommon

export type FullLoanSettlementLoan = FullLoanSettlement | ProvidentFundCommon

export type PFModel = TemporaryLoan | PermanentLoan | FullLoanSettlement | ProvidentFundCommon

export interface PFResponseInterface {
  _id?: string
  loanType: string
  status: string
  temporaryLoan?: TemporaryLoan
  permanentLoan?: PermanentLoan
  fullLoanSettlement?: FullLoanSettlement
  PFOptOutForm?: PFOptOutForm
  createdAt?: string
  updatedAt?: string
}

export const initsVal: Partial<PFResponseInterface> = {
  loanType: 'temporaryLoan',
  temporaryLoan: {
    memberName: '',
    employeeId: '',
    designation: '',
    department: '',
    didAvailPFFacility: '',
    appointmentDate: '',
    dateOfPFJoining: '',
    dateOfApplication: '',
    startingDate: '',
    currentGrossSalary: '',
    currentBasicSalary: '',
    amountApplied: '',
    currentProvidentBalance: '',
    reasonForWithdrawal: '',
    approvedAmount: '',
    approvedMonthlyInstallment: '',
    monthlyInstallmentSuggestion: '',
    noOfInstallmentsSuggestion: '',
    approvedAmountApplied: '',
    approvedNoOfInstallments: '',
    documents: [],
    documentPaths: [],
  },
  permanentLoan: {
    memberName: '',
    employeeId: '',
    designation: '',
    didAvailPFFacility: '',
    department: '',
    appointmentDate: '',
    dateOfPFJoining: '',
    dateOfApplication: '',
    currentGrossSalary: '',
    currentBasicSalary: '',
    amountApplied: '',
    reasonForWithdrawal: '',
    approvedAmount: '',
    currentProvidentBalance: '',
    notes: '',
  },
  fullLoanSettlement: {
    memberName: '',
    employeeId: '',
    designation: '',
    department: '',
    principalAmountOfLoan: '',
    totalNumberOfInstallments: '',
    amountPaid: '',
    installmentsPaid: '',
    remainingInstallments: '',
    amountLeft: '',
    willMakePaymentVia: '',
    monthlyInstallment: '',
    appointmentDate: '',
    dateOfPFJoining: '',
  },
  PFOptOutForm: {
    memberName: '',
    employeeId: '',
    designation: '',
    department: '',
    dob: '',
    CNIC: '',
    fatherName: '',
    address: '',
    PFActivationMonth: '',
    PFDiscontinuedMonth: '',
  },
}

export const validateBasicFields = {
  memberName: Yup.string().required('Member Name is required'),
  employeeId: Yup.string().required('Employee Id is required'),
  designation: Yup.string().required('Designation is required'),
  department: Yup.string().required('Department is required'),
  dateOfPFJoining: Yup.string().required('Date of PF Joining is required'),
}

export const temporaryLoanValidationSchema = {
  appointmentDate: Yup.string().required('Appointment Date is required'),
  dateOfApplication: Yup.string().required('Date of Application is required'),
  startingDate: Yup.string().required('Starting Date is required'),
  currentGrossSalary: Yup.number().required('Current Gross Salary is required'),
  currentProvidentBalance: Yup.number().required('Current Provident Balance is required'),
  currentBasicSalary: Yup.number().required('Current Basic Salary is required'),
  didAvailPFFacility: Yup.string().required('Please select this field'),
  amountApplied: Yup.number().required('Amount Applied is required'),
  reasonForWithdrawal: Yup.string().required('Reason for Withdrawal is required'),
  approvedAmount: Yup.string().required('Approved Amount is required'),
  documents: Yup.array().of(Yup.string()),
  approvedMonthlyInstallment: Yup.number().required('Monthly Installment is required'),
  // approvedAmountApplied: Yup.number().required('Amount applied is required'),
  monthlyInstallmentSuggestion: Yup.number().required('Monthly Installment is required'),
  noOfInstallmentsSuggestion: Yup.number()
    .max(36, 'Value must not be greater than 36')
    .required('Number of Installments is required'),
  approvedNoOfInstallments: Yup.number()
    .max(36, 'Value must not be greater than 36')
    .required('Number of Installments is required'),
}

export const permanentLoanValidationSchema = {
  appointmentDate: Yup.string().required('Appointment Date is required'),
  dateOfPFJoining: Yup.string().required('Date of PF Joining is required'),
  dateOfApplication: Yup.string().required('Date of Application is required'),
  didAvailPFFacility: Yup.string().required('Please select this field'),
  currentGrossSalary: Yup.number().required('Current Gross Salary is required'),
  currentBasicSalary: Yup.number().required('Current Basic Salary is required'),
  amountApplied: Yup.number().required('Applied Amount  is required'),
  reasonForWithdrawal: Yup.string().required('Reason For Withdrawal is required'),
  currentProvidentBalance: Yup.string().required('Current Provident Balance is required'),
  approvedAmount: Yup.string().required('Approved Amount is required'),
}

export const fullLoanSettlementFields = {
  principalAmountOfLoan: Yup.number().required('Principal Amount of Loan is required'),
  totalNumberOfInstallments: Yup.number().required('Total Number of Installments is required'),
  amountPaid: Yup.number().required('Paid Amount is required'),
  installmentsPaid: Yup.number().required('Paid Installments is required'),
  remainingInstallments: Yup.number().required('Remaining Installments is required'),
  amountLeft: Yup.number().required('Amount Left is required'),
  willMakePaymentVia: Yup.string().required('Will Make Payment Via is required'),
  monthlyInstallment: Yup.number().required('Monthly Installment is required'),
  appointmentDate: Yup.string().required('Appointment Date is required'),
  dateOfPFJoining: Yup.string().required('Date of PF Joining is required'),
}

export const PFOptOutFormFields = {
  memberName: Yup.string().required('Name is required'),
  employeeId: Yup.string().required('Employee Id is required'),
  designation: Yup.string().required('Designation is required'),
  department: Yup.string().required('Department is required'),
  dob: Yup.string().required('Date of Birth is required'),
  CNIC: Yup.string().required('CNIC is required'),
  fatherName: Yup.string().required('Father Name is required'),
  address: Yup.string().required('Address is required'),
  PFActivationMonth: Yup.string().required('PF Activation Month is required'),
  PFDiscontinuedMonth: Yup.string().required('PF Discontinued Month is required'),
}
export const providentFundSchema: any = {
  temporaryLoan: Yup.object().shape({
    loanType: Yup.string().required('Request Type is required'),
    temporaryLoan: Yup.object().when('loanType', {
      is: (value: string) => value === 'temporaryLoan',
      then: Yup.object().shape({
        ...validateBasicFields,
        ...temporaryLoanValidationSchema,
      }),
    }),
  }),

  permanentLoan: Yup.object().shape({
    loanType: Yup.string().required('Request Type is required'),
    permanentLoan: Yup.object().when('loanType', {
      is: 'permanentLoan',
      then: Yup.object().shape({
        ...validateBasicFields,
        ...permanentLoanValidationSchema,
      }),
    }),
  }),

  fullLoanSettlement: Yup.object().shape({
    loanType: Yup.string().required('Request Type is required'),
    fullLoanSettlement: Yup.object().when('loanType', {
      is: 'fullLoanSettlement',
      then: Yup.object().shape({
        ...validateBasicFields,
        ...fullLoanSettlementFields,
      }),
    }),
  }),

  PFOptOutForm: Yup.object().shape({
    loanType: Yup.string().required('Request Type is required'),
    PFOptOutForm: Yup.object().when('loanType', {
      is: 'PFOptOutForm',
      then: Yup.object().shape({
        ...PFOptOutFormFields,
      }),
    }),
  }),
}
