import {FC} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import { RootState } from '../../../../setup'
import {Search} from '../../../../_metronic/layout/search/Search'
import { Breadcrumbs } from '../../../pages/Breadcrumbs'
import * as projectActions from '../redux/ProjectActions'

type Props = {}

const ProjectHeader: FC<Props> = () => {
  const {workspaceid}: {workspaceid: string} = useParams()
  const dispatch: any = useDispatch()
  const {currentSpace}: any = useSelector<RootState>(
    (state) => ({
      state,
      currentSpace: state.project.currentSpace,
    }),
    shallowEqual
  )
  const handleSearch = (search: string) => {
    let page = ''
    let sortby = ''
    let orderby = ''
    let workSpace = workspaceid ? workspaceid : ''
    let createdAt = ''
    dispatch(projectActions.getAllProjects(page, sortby, orderby, search, workSpace, createdAt))
  }

  let crumbs = workspaceid
    ? [
        {name: 'Spaces', url: '/dashboard'},
        {name: `${currentSpace?.name}`, url: `/${currentSpace?._id}/features`},
        {name: 'Project', url: `/${currentSpace?._id}`},
      ]
    : [
        {name: 'Spaces', url: '/dashboard'},
        {name: 'Project', url: `/project`},
      ]

  return (
    <>
    <div className='px-6 px-sm-12'>
      <div className="row">
        <div className="col-sm-6">
          <h2 className='fw-500 fs-2 m-0 py-5'>
          Projects
          </h2>
        </div>
        <div className="col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center">
          <Search handleSearch={handleSearch}/>
        </div>
      </div>
    </div>
      {/* <div className='card-header border-0 px-12'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-500 fs-2 mb-1'>Projects </span>
        </h3>
        <Search handleSearch={handleSearch} />
      </div> */}

      <div className='d-flex justify-content-end align-items-center bg-lighter flex-wrap flex-sm-nowrap'>
        {crumbs && <Breadcrumbs crumbs={crumbs} />}{' '}
          <Link className='bg-lighter link_button me-5 me-sm-10' to={workspaceid ? `/${workspaceid}/project/add` : `/project/add`}>
            <span
              className='btn btn-sm bg-transparent  text-blue pe-5 d-flex align-items-center'
              style={{whiteSpace: 'nowrap'}}
            >
              <i className='fa fa-plus-circle  link_button' aria-hidden='true'></i>
             <span className='link_button'> Create Project</span>
            </span>
          </Link>
      </div>
    </>
  )
}

export {ProjectHeader}
