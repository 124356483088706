import * as serverRequest from './talentCrud'
import {talentSlice, callTypes} from './talentSlice'
import {AppDispatch} from '../../../../setup/redux/Store'
const {actions} = talentSlice

export const fetchLists =
  ({
    search,
    page,
    sortby,
    orderby,
    workspace,
    NotFound,
  }: {
    search: string
    page: number | string
    sortby: string
    orderby: string
    workspace: string
    NotFound: string
  }) =>
  (dispatch: AppDispatch) => {
    if (NotFound === '') {
      dispatch(actions.startCall({callType: callTypes.talent}))
    }
    return serverRequest.getLists({search, page, sortby, orderby, workspace}).then((response) => {
      const {data, page, totalCount, start, end, limitPerPage} = response?.data
      dispatch(
        actions.talentsFetched({
          page,
          totalCount,
          start,
          end,
          limitPerPage,
          entities: data,
          talentLoading: false,
          actionsLoading: false,
        })
      )
    })
  }
export const createList =
  (talentToCreate: any, widget_id = '') =>
  (dispatch: AppDispatch) => {
    return serverRequest
      .createList(talentToCreate, widget_id)
      .then((response) => {
        return response
      })
      .catch((error) => {
        error.clientMessage = "Can't create talents"
        const errorMessage = error?.response?.data?.message
        dispatch(actions.catchError(errorMessage))
        throw error
      })
  }
export const updateList =
  (talentToUpdate: any, widget_id = '') =>
  (dispatch: AppDispatch) => {
    return serverRequest
      .updateList(talentToUpdate, widget_id)

      .then((response) => {
        const {data} = response
        dispatch(actions.talentUpdated({data}))
      })
      .catch((error) => {
        error.clientMessage = "Can't update talents"
        const errorMessage = error?.response?.data?.message
        dispatch(actions.catchError(errorMessage))
        throw error
      })
  }

export const deleteList = (_id: string, workspaceid: string) => (dispatch: AppDispatch) => {
  return serverRequest.deleteList(_id, workspaceid).then((response) => {
    serverRequest
      .getLists({search: '', page: 0, sortby: '', orderby: '', workspace: workspaceid})
      .then((response) => {
        const {data, page, totalCount, start, end, limitPerPage} = response?.data?.data

        dispatch(
          actions.talentFetched({page, totalCount, start, end, limitPerPage, entities: data})
        )
      })
  })
}

export const fetchList =
  (_id: string, widget_id = '', uniqueId = '', user: any) =>
  (dispatch: AppDispatch) => {
    dispatch(actions.singleTalent(true))
    return serverRequest
      .fetchList(_id, widget_id, uniqueId)
      .then((response) => {
        const {data} = response.data

        dispatch(actions.singleTalent(false))
        dispatch(actions.talentFetched({data, userTalent: user?._id === data?.createdBy?._id}))
        return response.data
      })
      .catch((error) => {
        dispatch(actions.singleTalent(false))
        error.clientMessage = "Can't fetch talent"
        const errorMessage = error?.response?.data?.message
        localStorage.removeItem('talentId')
        dispatch(actions.catchError(errorMessage))
        throw error
      })
  }

export const fetchUserTalent = () => (dispatch: AppDispatch) => {
  return serverRequest
    .fetchUserTalent()
    .then((response) => {
      const {data} = response.data
      dispatch(actions.talentFetched({data, userTalent: !!data}))
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch talent"
      const errorMessage = error?.response?.data?.message
      localStorage.removeItem('talentId')
      dispatch(actions.catchError(errorMessage))
    })
}

export const makeListEmpty = (data: any) => (dispatch: AppDispatch) => {
  dispatch(actions.talentFetched({data}))
}

export const removeErrorMessage = (error: any) => (dispatch: AppDispatch) => {
  dispatch(actions.catchError(error))
}

export const findOne = (id: any) => (dispatch: AppDispatch) => {
  return serverRequest
    .findOne(id)
    .then((response) => {
      const {data} = response
      return data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const fetchWidget = (_id: any) => (dispatch: AppDispatch) => {
  return serverRequest
    .fetchWidget(_id)
    .then((response) => {
      const {data} = response
      dispatch(actions.widgetFetched(data.data))
      return data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getAllWidgets = () => (dispatch: AppDispatch) => {
  return serverRequest.getAllWidgets()
}

export const createTalentByName = (name: {name: string}) => () => {
  return serverRequest
    .createTalentByName(name)
    .then((res: any) => {
      const {data} = res
      return data
    })
    .catch((error) => {
      throw error
    })
}
