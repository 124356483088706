import React, {useState} from 'react'
import {Field, Form, Formik} from 'formik'
import {useDispatch} from 'react-redux'
import * as actions from '../redux/AppointmentsAction'
import {appointmentEventValidationSchema} from '../helpers'

import {InputField} from '../../../Common/fields/InputField'
// import TagInput from '../../../Common/fields/TagInput'
// import TagTextarea from '../../../Common/fields/TagTextarea'
import TagsInput from 'react-tagsinput'

import 'react-tagsinput/react-tagsinput.css'
import './appointmentEvent.css'

interface IAppointmentEventProps {
  closeModal(value: boolean, values: any): void
}

export const AppointmentEvent: React.FC<IAppointmentEventProps> = ({closeModal}) => {
  const [initValues, setInitValues] = useState<any>({
    guestEmails: [],
    name: '',
    email: '',
    requestType: 'REQUEST',
  })

  const onSubmit = (values: any) => {
    closeModal(true, values)
  }

  function renderTag(props: any) {
    let {tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other} = props
    return (
      <span key={key} className='badge bg-primary m-1'>
        {getTagDisplayValue(tag)}
        {!disabled && (
          <a className={classNameRemove} onClick={(e) => onRemove(key)}>
            <i className='close-icon ms-1'>&times;</i>
          </a>
        )}
      </span>
    )
  }

  return (
    <>
      <div className='card border border-2'>
        <div className='card-body'>
          <Formik
            enableReinitialize={true}
            validationSchema={appointmentEventValidationSchema}
            initialValues={initValues}
            onSubmit={onSubmit}
          >
            {({values, setFieldValue}) => (
              <Form className='form' noValidate id='kt_modal_create_app_form'>
                <div className='row'>
                  <div className='col-12 mb-2'>
                    <InputField
                      label='Name'
                      fieldTye='text'
                      fieldName='name'
                      fieldPlaceholder='Name'
                      mandatory={true}
                    />
                  </div>
                  <div className='col-12 mb-2'>
                    <InputField
                      label='Email'
                      fieldTye='text'
                      fieldName='email'
                      fieldPlaceholder='Email'
                      mandatory={true}
                    />
                  </div>

                  <div className='col-12 mb-2'>
                    <label className='fs-6 fw-bold form-label mb-2'>
                      <span>Guest email(s)</span>
                    </label>
                    <Field name='guestEmails'>
                      {({}) => (
                        <TagsInput
                          value={values.guestEmails}
                          validationRegex={
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                          }
                          inputProps={{placeholder: 'Guest email'}}
                          className='form-control form-control-lg  form-control-solid'
                          onlyUnique={true}
                          maxTags={10}
                          renderTag={renderTag}
                          onChange={(updatedTags) => setFieldValue('guestEmails', updatedTags)}
                        />
                      )}
                    </Field>
                  </div>
                  <div className='col-12 mb-2'>
                    <InputField
                      label='Please share anything that will help prepare for our meeting.'
                      fieldTye='textarea'
                      fieldName='comment'
                      fieldPlaceholder=''
                    />
                  </div>
                </div>
                <div className='d-flex flex-stack pt-10'>
                  <div> </div>
                  <div className='me-2'>
                    <button
                      type='button'
                      className='btn cancel_button fs-7 fw-bolder me-3'
                      onClick={() => closeModal(false, null)}
                      title='Cancel'
                    >
                      Cancel
                    </button>
                    <button type='submit' className='btn btn-sm btn-blue fs-7' title='Create'>
                      <span className='indicator-label'>Create</span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
