import {FC} from 'react'
import {useDrop} from 'react-dnd'

type Props = {
  children: any
  index: any
  moveCard: any
  columnIndex: number
  board: string
  task: null
  dragType: string
  handleDragType: any
  sortTasks: any
}
export const TaskChildren: FC<Props> = ({
  columnIndex,
  board,
  index,
  moveCard,
  task,
  dragType,
  handleDragType,
  children,
  sortTasks,
}) => {
  const [, drop] = useDrop({
    accept: 'item',

    hover(draggedTask: any, monitor: any) {
      if (dragType === 'column') {
        return
      }
      if (draggedTask?.task) {
        handleDragType('column')
        return
      }

      const dragIndex = draggedTask?.index
      const hoverIndex = index

      const dragColumnIndex = draggedTask?.columnIndex
      const hoverColumnIndex = columnIndex

      // Don't replace items with themselves
      if (dragColumnIndex === hoverColumnIndex && dragIndex === hoverIndex) {
        return
      }

      if (dragColumnIndex === hoverColumnIndex && !task) {
        return
      }

      moveCard(dragColumnIndex, dragIndex, hoverColumnIndex, hoverIndex)
      draggedTask.index = index
      draggedTask.columnIndex = columnIndex
    },
    canDrop(item, monitor) {
      return dragType === 'card'
    },
    drop(task: any, monitor: any) {
      handleDragType('column')
      if (dragType !== 'column') {
        sortTasks(columnIndex, task._id)
      }
    },
  })

  return (
    <div ref={drop} className='scroll-class'>
      {children}
    </div>
  )
}
