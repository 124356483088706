import React, {useEffect, useState} from 'react'
import '../../../../_metronic/assets/sass/components/release-notes.scss'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import * as actions from '../redux/AppointmentsAction'
import {Pagination} from '../../../pagination/pagination'
import {NotFound} from '../../../../_metronic/layout/NotFound'
import {Loading} from '../../../../_metronic/layout/Loading'
import {ScheduledAppointmentsRow} from './ScheduledAppointmentsRow'
import {ScheduledAppointmentsDelete} from './ScheduledAppointmentsDelete'

export const ScheduledAppointmentsTable: React.FC = () => {
  const dispatch = useDispatch()

  const [releaseNoteList, setReleaseNoteList] = useState([{}])
  const [sortValues, setSortValues] = useState({
    orderby: 'asc',
    sortby: '',
    createdAt: '',
  })
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [itemToDelete, setItemToDelete] = useState('')

  const {releaseNoteData, isLoading, paginationData}: any = useSelector<RootState>(
    (state) => ({
      releaseNoteData: state.appointments.entities,
      isLoading: state.appointments.listLoading,
      paginationData: state.appointments,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (releaseNoteData.length > 0) {
      setReleaseNoteList(releaseNoteData)
    } else {
      setReleaseNoteList([])
    }
  }, [releaseNoteData])

  const removeSortingClass = () => {
    const cells = document.querySelectorAll('th')
    cells.forEach(function (cell) {
      cell.classList.remove('table-sort-desc')
      cell.classList.remove('table-sort-asc')
    })
  }

  useEffect(() => {
    let tableHead: any
    tableHead = document.getElementById(`todo-${sortValues.sortby}-head`)

    if (sortValues.orderby === 'desc' && tableHead && releaseNoteList?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-asc')
      tableHead.classList.add('table-sort-desc')
    } else if (tableHead && releaseNoteList?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-desc')
      tableHead.classList.add('table-sort-asc')
    }

    dispatch(
      actions.fetchScheduledAppointments({
        search: '',
        page: '',
        sortby: sortValues.sortby,
        orderby: sortValues.orderby,
        createdAt: sortValues.createdAt,
      })
    )
  }, [sortValues])

  const deleteAppointments = (_id: string) => async (event: any) => {
    setItemToDelete(_id)
    setShowDeleteDialog(true)
  }

  const handleSearch = (search: string) => {
    dispatch(
      actions.fetchScheduledAppointments({
        search,
        page: paginationData?.page,
        sortby: '',
        orderby: '',
        createdAt: '',
      })
    )
  }

  const handlePagination = (event: any) => {
    dispatch(
      actions.fetchScheduledAppointments({
        search: '',
        page: event.selected + 1,
        sortby: '',
        orderby: '',
        createdAt: '',
      })
    )
  }

  let crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Appointments', url: `/appointments`},
    {name: 'Scheduled Appointments', url: ``},
  ]

  const sortColumn = (event: any) => {
    if (event.target.classList.contains('table-sort-desc')) {
      removeSortingClass()
      setSortValues({
        orderby: 'asc',
        sortby: event.target.abbr,
        createdAt: sortValues.createdAt,
      })
      event.target.classList.remove('table-sort-desc')
      event.target.classList.add('table-sort-asc')
    } else {
      removeSortingClass()
      setSortValues({
        orderby: 'desc',
        sortby: event.target.abbr,
        createdAt: sortValues.createdAt,
      })
      event.target.classList.remove('table-sort-asc')
      event.target.classList.add('table-sort-desc')
    }
  }

  return (
    <>
      <ScheduledAppointmentsDelete
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
        _id={itemToDelete}
      />
      <>
        <div className={`mh-80 tabs-main card legend-card-main rounded-0`}>
          {!isLoading && (
            <div className='px-6 px-sm-12'>
              <div className='row'>
                <div className='col-sm-6'>
                  <h2 className='fw-500 fs-2 m-0 py-5'>Scheduled Appointments</h2>
                </div>
                {/* <div className='col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center'>
                  <Search handleSearch={handleSearch} />
                </div> */}
              </div>
            </div>
          )}

          <div className='d-flex justify-content-between align-items-center bg-lighter'>
            {crumbs && <Breadcrumbs crumbs={crumbs} />}{' '}
          </div>

          {!isLoading && releaseNoteList?.length > 0 ? (
            <>
              <div className='overflow_height_scroll'>
                <div className='card-body d-flex flex-column py-3 px-10'>
                  <div className='table-responsive'>
                    <table className='todo-table table table-row-dashed align-middle gs-0 gy-4'>
                      <thead className='tableHeading'>
                        <tr className='fw-bolder text-muted border-bottom'>
                          <th
                            className='min-w-120px'
                            style={{cursor: 'pointer'}}
                            onClick={sortColumn}
                            abbr='name'
                            id='note-title-head'
                          >
                            Name
                          </th>
                          <th
                            className='min-w-120px'
                            style={{cursor: 'pointer'}}
                            onClick={sortColumn}
                            abbr='duration'
                            id='note-duration-head'
                          >
                            Duration (min)
                          </th>
                          <th
                            className='min-w-120px'
                            style={{cursor: 'pointer'}}
                            onClick={sortColumn}
                            abbr='venue'
                            id='note-venue-head'
                          >
                            Venue
                          </th>
                          <th
                            className='min-w-120px'
                            style={{cursor: 'pointer'}}
                            onClick={sortColumn}
                            abbr='eventDate'
                            id='note-eventDate-head'
                          >
                            Event Date
                          </th>
                          <th
                            className='min-w-120px'
                            style={{cursor: 'pointer'}}
                            onClick={sortColumn}
                            abbr='startTime'
                            id='note-startTime-head'
                          >
                            Event Time
                          </th>
                          <th
                            className='min-w-120px'
                            style={{cursor: 'pointer'}}
                            // onClick={sortColumn}
                            abbr='actions'
                            id='note-actions-head'
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className='tableRow'>
                        {releaseNoteList?.map((appointment: any, index: number) => {
                          return (
                            <ScheduledAppointmentsRow
                              key={index}
                              index={index}
                              appointment={appointment}
                              deleteAppointments={deleteAppointments}
                            />
                          )
                        })}
                      </tbody>
                    </table>
                    {/* end::Table */}
                  </div>
                </div>
              </div>
              <Pagination paginationData={paginationData} fetchData={handlePagination} />
            </>
          ) : !isLoading && releaseNoteList?.length <= 0 ? (
            <NotFound />
          ) : (
            <Loading />
          )}
        </div>
      </>
    </>
  )
}
