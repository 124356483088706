import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface entities {
  _id?: string
  title: string
  description: string
}

// Define a type for the slice state
interface credentialsState {
  listLoading: boolean
  actionsLoading: boolean
  totalCount?: number
  entities: entities[]
  start: number
  end: number
  errorMessage?: string
  limitPerPage: number
  clientDetail?: any
}

// Define the initial state using that type
const initialState: credentialsState = {
  listLoading: true,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  start: 1,
  end: 10,
  limitPerPage: 10,
  clientDetail: {},
  errorMessage: '',
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const credentialsSlice = createSlice({
  name: 'credentials',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    credentialsFetched: (state, action: PayloadAction<credentialsState>) => {
      const {totalCount, entities, start, end} = action.payload
      state.listLoading = false
      state.entities = entities
      state.totalCount = totalCount
      state.start = start
      state.end = end
    },
    clientFetched: (state, action) => {
      state.clientDetail = action.payload
    },
    credentialsCreated: (state, action) => {
      // state.entities.splice(0, 0, action.payload.entities)
      state.actionsLoading = false
      state.entities.push(action.payload.entities)
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    credentialsUpdated: (state, action) => {
      state.actionsLoading = false
      const {entities} = action.payload
      if (state && state.entities.length > 0) {
        state.entities.map((entity: entities) => {
          if (entity._id === entities._id) {
            return state.entities.push(entities)
          }
          return state.entities.push(entity)
        })
      }
    },
    setClientErrorMessage: (state, action) => {
      // state.entities.splice(0, 0, action.payload.entities)
      state.errorMessage = action.payload.message
    },

    activityLogsCreated: (state, action) => {
      //   state.entities.splice(0, 0, action.payload.entities)
      state.entities.push(action.payload.entities)
    },
    activityLogsFetched: (state, action: PayloadAction<credentialsState>) => {
      const {entities, totalCount, start, end, limitPerPage} = action.payload
      state.listLoading = false
      if (entities && Array.isArray(entities)) {
        state.entities = entities
        state.totalCount = totalCount
        state.start = start
        state.end = end
      }
    },
  },
})
