import * as serverRequest from './ProvidentFundCRUD'
import {providentFundSlice, callTypes} from './ProvidentFundSlice'
import {AppDispatch} from '../../../../setup/redux/Store'
import {AxiosResponse} from 'axios'

const {actions} = providentFundSlice

export const fetchProvidentFund =
  ({
    search,
    page,
    sortby,
    orderby,
    status,
    createdAt,
    activeDate,
  }: {
    search: string
    page: number | string
    sortby: string
    orderby: string
    status: string
    createdAt: string
    activeDate: string
  }) =>
  (dispatch: any) => {
    if (sortby === '' && page === '') {
      dispatch(actions.startCall({callType: callTypes.list}))
    }
    return serverRequest
      .getProvidentFund({
        search,
        page,
        sortby,
        orderby,
        status,
        createdAt,
        activeDate,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage, treasurers} = response.data

        dispatch(
          actions.providentFundFetched({
            totalCount,
            start,
            end,
            treasurers,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
          })
        )
      })
  }

export const getProvidentFundById = (id: string) => (dispatch: any) => {
  return serverRequest.getProvidentFundById(id).then((response) => {
    dispatch(actions.pfDetails(response.data))
  })
}

export const createProvidentFund =
  (body: any) =>
  (dispatch: any): Promise<any> => {
    return serverRequest
      .createProvidentFund(body)
      .then((response) => {})
      .catch((error) => {
        console.log(error?.message)
        dispatch(actions?.setErrorMessage({message: error?.response?.data?.message}))
        throw new Error()
      })
  }

export const updateProvidentFund =
  (id: string, body: any) =>
  (dispatch: any): Promise<any> => {
    return serverRequest
      .updateProvidentFund(id, body)
      .then((response) => {})
      .catch((error) => {
        console.log(error?.message)
        dispatch(actions?.setErrorMessage({message: error?.response?.data?.message}))
        throw new Error()
      })
  }

export const updateProvidentFundStatus =
  (id: string, status: string, signature: string, bankDocumentPaths = []) =>
  (dispatch: any): Promise<any> => {
    return serverRequest
      .updateProvidentFundStatus(id, status, signature, bankDocumentPaths)
      .then((response) => {})
      .catch((error) => {
        console.log(error?.message)
        dispatch(actions?.setErrorMessage({message: error?.response?.data?.message}))
        throw new Error()
      })
  }

export const setLoanType = (loanType: string) => (dispatch: any) => {
  dispatch(actions.setLoanType(loanType))
}

export const resetForm = () => (dispatch: any) => {
  dispatch(actions.setErrorMessage({message: ''}))
  dispatch(actions.pfDetails(undefined))
}

export const loadAttachments = (data: any) => (dispatch: any) => {
  dispatch(actions.loadAttachments(data))
}

export const deleteProvidentFund = (id: string) => (dispatch: any) => {
  return serverRequest.deleteProvidentFund(id).then((response) => {
    return serverRequest
      .getProvidentFund({
        search: '',
        page: '',
        sortby: '',
        orderby: '',
        status: '',
        createdAt: '',
        activeDate: '',
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data

        dispatch(
          actions.providentFundFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
          })
        )
      })
  })
}
