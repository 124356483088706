/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {Attachment} from '../../../Common/attachments/Attachment'

interface Props {
  show: boolean
  label: string
  handleClose: ({canSubmit, id}: {canSubmit: boolean; id: string}) => void
  id: string
  buttonLabel: string
  actions: any
  attachments: any[]
}

const BankTransferModal: React.FC<Props> = ({
  show,
  label,
  handleClose,
  id,
  buttonLabel,
  actions,
  attachments,
}) => {
  const [error, setError] = useState('')
  const submitItemHandler = (canSubmit: boolean) => {
    handleClose({canSubmit, id})
  }

  useEffect(() => {
    if (attachments.length > 0) {
      setError('')
    }
  }, [attachments])

  return (
    <Modal
      id='kt_modal_delete_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={show}
      onHide={() => handleClose({canSubmit: false, id})}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>{label}</h2>{' '}
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
            <div className='flex-row-fluid px-lg-15'>
              <div className='col-md-12 fv-row mb-5'>
                <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                  Bank Transfer Documents
                </label>

                <div className=' d-flex justify-content-center align-items-center'>
                  <Attachment
                    attachments={''}
                    loadAttachments={actions.loadAttachments}
                    attachmentType='attachmentUpload'
                    id={''}
                    itemType='todo'
                  />
                </div>
                <div className='text-danger'>{error}</div>
              </div>
              <div className='d-flex flex-stack justify-content-center pt-'>
                <div className='me-2'>
                  <button
                    title='Cancel'
                    type='button'
                    className='btn btn-sm cancel_button me-3'
                    onClick={() => {
                      submitItemHandler(false)
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    title='Delete'
                    type='submit'
                    className='btn btn-sm btn-blue me-3'
                    onClick={() => {
                      if (attachments.length > 0) {
                        submitItemHandler(true)
                      } else {
                        setError('Please upload attachment/s')
                      }
                    }}
                  >
                    <span className='indicator-label'>{buttonLabel}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {BankTransferModal}
