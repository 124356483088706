import React, {lazy, Suspense} from 'react'
// import {TodoTable} from './components/TodoTable'
import {Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../../_metronic/partials'
import {ClientsTable} from './components/ClientsTable'
import {ClientsAdd} from './components/ClientsAdd'

const ClientPage: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/clients'>
        <Suspense fallback={<FallbackView />}>
          <ClientsTable />
        </Suspense>
      </Route>
      <Route exact path='/clients/add'>
        {({history, match}) => {
          return (
            <ClientsAdd
              onHide={() => {
                history.push(`/clients`)
              }}
            />
          )
        }}
      </Route>
      <Route exact path={['/clients/:id/edit', '/clients/:id/view']}>
        {({history, match}) => (
          <ClientsAdd
            id={match && match.params.id}
            onHide={() => {
              history.push(`/clients`)
            }}
          />
        )}
      </Route>
      {/* <Route exact path='/clients/:id'>
        <TodoDataHeader />
      </Route> */}
    </Switch>
  )
}

export default ClientPage
