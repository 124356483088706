import React from 'react'
import {ErrorMessage, Field} from 'formik'
import {countries} from '../modules/clients/core/ClientUIHelper'
import {ReactSelect} from './Select'
import {InputField} from './fields/InputField'

interface IAddressProps {
  values: any
  setFieldValue: (field: string, val: string) => void
  field: string
  title?: string
  mandatory?: boolean
}

export const Address = ({
  values,
  setFieldValue,
  field,
  title = '',
  mandatory = true,
}: IAddressProps) => {
  return (
    <>
      <div className='fv-row'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className={`${mandatory ? 'required' : ''}`}>{title ? title : 'Address line'}</span>
        </label>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid mb-5'
          name={`${field}.streetAddress1`}
          placeholder='Address line 1'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`${field}.streetAddress1`} />
        </div>
      </div>
      <div className='fv-row'>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid  mb-5'
          name={`${field}.streetAddress2`}
          placeholder='Address line 2'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`${field}.streetAddress2`} />
        </div>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <InputField
              label='City'
              fieldTye='text'
              fieldName={`${field}.city`}
              fieldPlaceholder='City'
              mandatory={mandatory}
            />
          </div>
          <div className='col-md-6 fv-row mb-5'>
            <InputField
              label='Zip code'
              fieldTye='text'
              fieldName={`${field}.postalCode`}
              fieldPlaceholder='Zip code'
              mandatory={mandatory}
            />
          </div>
        </div>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <InputField
              label='State'
              fieldTye='text'
              fieldName={`${field}.state`}
              fieldPlaceholder='State'
              mandatory={mandatory}
            />
          </div>
          <div className='col-md-6 fv-row mb-5'>
            <ReactSelect
              label='Country'
              fieldName={`${field}.country`}
              fieldValue={values?.country}
              fieldPlaceholder='Select country'
              options={countries}
              setFieldValue={setFieldValue}
              mandatory={mandatory}
            />
          </div>
        </div>
      </div>
    </>
  )
}
