import React, {forwardRef, useEffect} from 'react'
import './tableStyles.css'
import moment from 'moment'
interface PrintTableProps {
  PFData: any
  treasurers: any[]
}
const pfArr = ['permanentLoan', 'temporaryLoan']

function isValidDate(date: any): boolean {
  if (typeof date !== 'string') {
    return false
  }

  const parsedDate = new Date(date)
  return !isNaN(parsedDate.getTime()) && parsedDate.toISOString().startsWith(date)
}

export function getFormattedPrice(price: number): any {
  return price?.toString().length > 2 ? `PKR   ${price?.toLocaleString()}` : price?.toLocaleString()
}
const PFFieldsLabel: any = {
  fullLoanSettlementStatus: `<h5>Current Payment /Loan Settlement Status</h5>`,
  temporaryLoanHeading: 'NXB-HR-T-12-v1.1-Private-PF Temporary Withdrawal Loan Form',
  PFOptOutFormHeading: 'NXB-HR-T-11-v1.2-Private-PF Opt Out Form',
  permanentLoanHeading: 'NXB-HR-T-10-v1.1-Private - PF Permanent Withdrawal Form',
  fullLoanSettlementHeading: 'NXB-HR-T-04-v1.1-Private - PF Full Loan Settlement Form',
  PFOptOutForm: `<h5>Disclaimer:</h5>
  <span>I have read/had read and understood the Nextbridge Ltd. – Employees Provident Fund Rules and I hereby agree on the 
  following rules:</span>
  <ol>
  <li>If I want to rejoin the Nextbridge Ltd. – Employees Provident Fund, I need to wait one (1) full year after availing of the 
  Opt-Out option.</li>
  <li>I am fully aware that if I rejoin, I will rejoin as a new member and all the terms and conditions of a new member will 
  apply to me (Reference of Provident Fund Policy).</li></ol>
  
  <div class='row p-1'><div class='col-sm-6'><span> _______________________________</span></div><div class='col-sm-6 text-end'><span> _____________________</span>  </div></div>
  <div class='row p-1 mt-1'><div class='col-sm-6'>Member’s Signature (as per CNIC)</div><div class='col-sm-6 text-end'><span class="pe-6" style='margin-right: 35px!important;'">Date</span></div></div>`,
  approvedByText: `<td
  class='custom-cell'
  colspan='4'
>
Approved by: Treasurer, Nextbridge Employees Provident Fund
</td>`,
  approvedByHeading: `
<th class='custom-cell text-center fw-bolder' colspan='1'>Name</th>
<th class='text-center fw-bolder' colspan='2'>Signature</th>
<th class='text-center fw-bolder'>Date</th>

`,
  approvedByParticipants: [
    `<td class='custom-cell text-center'  colspan='1'>Syed Saadat Ali</td>
<td class='custom-cell text-center' colspan='2' ></td>
<td class='custom-cell text-center' ></td>`,
    `<td class='custom-cell text-center'  colspan='1'>Umer Farooq</td>
<td class='custom-cell text-center' colspan='2' ></td>
<td class='custom-cell text-center' ></td>`,
    `<td class='custom-cell text-center'  colspan='1'>Amna Khan</td>
<td class='custom-cell text-center' colspan='2'></td>
<td class='custom-cell text-center' ></td>`,
  ],

  permanentLoanFacilityRows: ['', '', ''],

  temporaryLoanFacilityRows: ['', '', ''],
}

function formatCNIC(cnic: string) {
  let cnicArr = cnic
    .split('')
    .map(
      (value: string, index: number) =>
        `<div class=" border border-dark border-1 border-top-0 border-start-0 border-bottom-0 ps-3 pe-3 me-1 ms-1 pt-3 ${
          index === cnic.length - 1 ? 'border-end-0' : ''
        }">${value}</div>`
    )
    .join(' ')
  return `<div class='d-flex flex-direction-row cnic-height '>${cnicArr}</div>`
}

const PFLoanTypeObject: any = {
  fullLoanSettlementFields: {
    name: [{memberName: 'Member Name'}, {employeeId: 'Employee ID'}],
    designation: [{designation: 'Designation'}, {department: 'Department'}],
  },
  temporaryLoanFields: {
    name: [{memberName: 'Member Name'}, {employeeId: 'Employee ID'}],
    designation: [{designation: 'Designation'}, {department: 'Department'}],
  },
  permanentLoanFields: {
    name: [{memberName: 'Member Name'}, {employeeId: 'Employee ID'}],
    designation: [{designation: 'Designation'}, {department: 'Department'}],
  },
  PFOptOutFormFields: {
    memberName: 'Name in Capital Letters (as per CNIC)',
    employeeId: 'Employee ID',
    designation: 'Designation',
    dob: 'Date of Birth',
    CNIC: 'CNIC',
    fatherName: 'Father Name',
    address: 'Address (As per your CNIC)',
    PFActivationMonth: 'PF Activation Month',
    PFDiscontinuedMonth: 'PF Discontinued Month',
    department: 'Department',
  },
}

const dateArr = [
  {appointmentDate: 'Appointment Date (Nextbridge Pvt Ltd)'},
  {dateOfPFJoining: 'Date of Joining (PF)'},
]

const loanPriceObject: any = {
  fullLoanSettlementAmount: [
    {principalAmountOfLoan: 'Principal Amount Of Loan'},
    {totalNumberOfInstallments: 'Total Numbers of Installments'},
    {amountPaid: 'Amount Paid'},
    {installmentsPaid: 'Installments Paid'},
  ],
  temporaryLoanAmount: [
    {currentGrossSalary: 'Current Gross Salary'},
    {
      currentBasicSalary: 'Current Basic Salary',
    },
    {amountApplied: 'Amount Applied'},
    {dateOfApplication: 'Date of Application'},
  ],
  permanentLoanAmount: [
    {currentGrossSalary: 'Current Gross Salary'},
    {
      currentBasicSalary: 'Current Basic Salary',
    },
    {amountApplied: 'Amount Applied'},
    {dateOfApplication: 'Date of Application'},
  ],
}

function getOfficeUseDescription(PFObj: any, loanType: string): string {
  const obj: any = {
    fullLoanSettlementOfficeUse: `

  <div class='mb-2 text-center col-sm-8'>As per Records of Finance it is verified that</div>
  <div class='row mx-auto paymentVia'><div class='square-box float-start col-sm-2'>${
    PFObj?.isCorrect
      ? "<img width='18' height='18' src='/media/icons/tick.png' />"
      : '<span></span>'
  }</div><div class="float-end col-sm-10"> The Information mention on above form is true, if not then mention the details.</div></div>`,

    temporaryLoanOfficeUse: `
  <div class='row mt-2'><div class='col-sm-3 mt-1'>Current Provident Balance</div>
  <div class='col-sm-3'><div class='fw-bolder border border-dark border-1 p-1 w-75' >${getFormattedPrice(
    PFObj?.currentProvidentBalance
  )}
  </div>
  </div><div class="col-sm-3 text-end mt-1">Amount Approved</div> <div class="col-sm-3">
  <div class='fw-bolder border border-dark border-1 p-1 w-75' >${getFormattedPrice(
    PFObj?.approvedAmount
  )}
  </div></div></div>
  `,

    permanentLoanOfficeUse: `
  <div class='row mt-2 '><div class='col-sm-3 mt-1'>Current Provident Balance</div>
  <div class='col-sm-3'>
  <div class='fw-bolder border border-dark border-1 p-1 w-75' >${getFormattedPrice(
    PFObj?.currentProvidentBalance
  )}
  </div></div>
  <div class='col-sm-3 text-end mt-1'>Amount Approved</div>
  <div class='col-sm-3'>
  <div class='fw-bolder border border-dark border-1 p-1 w-75' >${getFormattedPrice(
    PFObj?.approvedAmount
  )}
  </div></div>`,
  }
  return obj?.[`${loanType}OfficeUse`]
}

function getOfficeInstallment(PFObj: any, loanType: string): string {
  const obj: any = {
    fullLoanSettlementOfficeUse: `
  <div class='row mt-2 mx-auto paymentVia'><div class='square-box float-start col-sm-2'>${
    PFObj?.isFeasibleToMonthlySalary
      ? "<img width='18' height='18' src='/media/icons/tick.png' />"
      : '<span></span>'
  }</div><div class="float-end col-sm-10"> The above request is feasible w.r.t member's monthly salary.</div></div>
  <div class='row mt-2 d-none'><div class='col-sm-12 text-end'><span> _____________________</span></div></div>
  <div class='row mt-3'><div class='col-sm-12 text-end'><span >Finance Representative Signature</span></div></div>`,
  }
  return obj?.[`${loanType}OfficeUse`]
}

function getAgreementForm(PFObj: any, loanType: string) {
  const obj: any = {
    temporaryLoanAgreement: `I <span class=' fw-bolder DeclarationBorder '>${PFObj?.memberName}</span> being member of Nextbridge (Pvt.) Ltd -Employees Provident
    Fund agree that I have read the Nextbridge (Pvt.) Ltd -Employees Provident Fund Trust Rules and I hereby agree on
    Temporary Withdrawal from my Provident Fund share.
    
    <div class='row p-1'><div class='col-sm-12 text-end'><span> _________________________________</span></div></div>
    <div class='row p-1 pt-0 mt-0'><div class='col-sm-12 text-end'><span >Member’s Signature (As per CNIC)</span></div></div>`,

    permanentLoanAgreement: `
    <p class='fw-bolder mb-0'>Disclaimer:</p>
    I <span class=' fw-bolder DeclarationBorder'>${PFObj?.memberName}</span> being member of Nextbridge (Pvt.) Ltd -Employees Provident Fund
    have agreed that I have read the Nextbridge (Pvt.) Ltd -Employees Provident Fund Trust Rules and I hereby agreed on
    Permanent Withdrawal from my Provident Fund share.
    
    <div class='row p-1 '><div class='col-sm-12 text-end'><span> _________________________________</span></div></div>
    <div class='row p-1 pt-0 mt-0'><div class='col-sm-12 text-end'><span >Member’s Signature (As per CNIC)</span></div></div>`,

    fullLoanSettlementAgreement: `<p>I 
    <span class='fw-bolder DeclarationBorder'>${
      PFObj?.memberName
    }</span> being member of Nextbridge (Pvt.) Ltd -Employees Provident Fund agreed to pay the remainder
    of Loan Amount that is  <span class=' fw-bolder DeclarationBorder '>${getFormattedPrice(
      PFObj?.amountLeft?.toLocaleString()
    )}</span> in full settlement.</p>
    <p>I will make payment via.</p>
    <div class='row mx-auto mb-2 paymentVia'><div class='square-box float-start col-sm-2'>${
      PFObj?.willMakePaymentVia === 'Salary'
        ? "<img width='18' height='18' src='/media/icons/tick.png' />"
        : '<span></span>'
    }</div><div class="float-end col-sm-10">Monthly Salary.</div></div>
    <div class='row mx-auto mb-2 paymentVia'><div class='square-box float-start col-sm-2'>${
      PFObj?.willMakePaymentVia !== 'Salary'
        ? "<img width='18' height='18' src='/media/icons/tick.png' />"
        : '<span></span>'
    }</div><span class="float-end col-sm-10">${
      PFObj?.willMakePaymentVia !== 'Salary' ? PFObj?.willMakePaymentVia : '___________'
    }</span></div>

    <p>I will settle the loan as per above declaration or will continue to do so on basis of early agreement in case this request is not
    accepted by Trustees of Provident Fund.</p>

    <div class='row p-1 d-none'><div class='col-sm-12 text-end'><span> _____________________</span></div></div>
    <p class="mt-5  DeclarationBorder mb-0"></p>
    <div class='row p-1 mt-1'><div class='col-sm-12 text-end'><span >Member’s Signature (As per CNIC)</span></div></div>`,
    PFOptOutFormAgreement: `<p>I <span class='border-bottom fw-bolder DeclarationBorder'>${
      PFObj?.memberName
    }</span> being member of Nextbridge Ltd. 
    Employees Provident Fund, hereby agreed to Opt Out from the Provident Fund. Please mention the reason.</p>
    <div class='row p-1'><span class='fw-bolder DeclarationBorder mb-6'> ${
      PFObj?.notes || ''
    } </span></div>`,
  }

  return obj?.[`${loanType}Agreement`]
}

function getFacilityFieldData(PFObj: any, loanType: string) {
  const obj: any = {
    permanentLoanFacility: `<div class="row">
  <div class="col-sm-8">
    Have you ever availed the withdrawal facility from Nextbridge Pvt Ltd. Employees Provident
    Fund?
  </div>
  <div class="col-sm-2 float-end">
  ${
    PFObj?.didAvailPFFacility === 'yes'
      ? "<span class='square-box-uncheck'><img width='13' height='13' src='/media/icons/tick.png' /></span>"
      : '<span class="square-box-uncheck"></span>'
  }<span class="ps-2 fw-bolder">Yes</span>
  </div>
  <div class="col-sm-2 float-end">
  ${
    PFObj?.didAvailPFFacility === 'no'
      ? "<span class='square-box-uncheck'><img width='13' height='13' src='/media/icons/tick.png' /></span>"
      : '<span class="square-box-uncheck"></span>'
  }<span class="ps-2 fw-bolder">No</span>
  </div>
</div>`,
    temporaryLoanFacility: `<div class="row">
<div class="col-sm-8">
  Have you ever availed the withdrawal facility from Nextbridge Pvt Ltd. Employees Provident
  Fund?
</div>

<div class="col-sm-2 float-end">
  ${
    PFObj?.didAvailPFFacility === 'yes'
      ? "<span class='square-box-uncheck'><img width='13' height='13' src='/media/icons/tick.png' /></span>"
      : '<span class="square-box-uncheck"></span>'
  }<span class="ps-2 fw-bolder">Yes</span>
</div>
<div class="col-sm-2 float-end">
  ${
    PFObj?.didAvailPFFacility === 'no'
      ? "<span class='square-box-uncheck'><img width='13' height='13' src='/media/icons/tick.png' /></span>"
      : '<span class="square-box-uncheck"></span>'
  }<span class="ps-2 fw-bolder">No</span>
</div>
</div>`,
  }
  return obj?.[`${loanType}Facility`]
}
const getValue = (data: any, loanType: string | number, key: string | number) =>
  data?.[loanType]?.[key]

const renderValue = (value: any, key: string) => {
  console.log(
    '🚀 ~ renderValue ~ key:',
    !isNaN(value) && key !== 'employeeId' && key !== 'department',
    key
  )
  if (!isNaN(value) && key !== 'employeeId' && key !== 'department') {
    return getFormattedPrice(value)
  } else if (isValidDate(value)) {
    return moment(value).format('DD/MM/YYYY')
  }
  return value
}
const PrintTable = forwardRef<HTMLTableElement, PrintTableProps>((props, ref) => {
  const {PFData, treasurers} = props
  return (
    <>
      {PFData && (
        <div
          ref={ref}
          style={{
            padding: '30px',
            marginTop: pfArr.includes(PFData?.loanType) ? '0px' : '30px',
            paddingTop: pfArr.includes(PFData?.loanType) ? '18px' : '30px',
          }}
        >
          <div className='row mb-2'>
            <div className='col-sm-4'>
              <img src='/media/logoNXB.png' width={'150px'} className='mb-2' />
            </div>
            <div className='col-sm-8 text-end pe-5 fw-bold font-size-11'>
              {PFFieldsLabel[`${PFData?.loanType}Heading`] &&
                PFFieldsLabel[`${PFData?.loanType}Heading`]}
            </div>
          </div>
          <table className='table custom-table mb-0'>
            <tbody className='custom-row-declaration'>
              {Object.keys(PFLoanTypeObject?.[`${PFData?.loanType}Fields`])?.map(
                (key: string, index: number) => (
                  <>
                    <tr key={index}>
                      {Array.isArray(PFLoanTypeObject[`${PFData?.loanType}Fields`]?.[key])
                        ? PFLoanTypeObject[`${PFData?.loanType}Fields`]?.[key]?.map(
                            (value: string, index: number) => {
                              return (
                                <>
                                  {PFData[PFData?.loanType][Object.keys(value)?.[0]] &&
                                  key !== 'monthlyInstallment' ? (
                                    <>
                                      <td className='custom-cell-th'>
                                        {Object.values(value)?.[0]}
                                      </td>
                                      <td
                                        className={`custom-cell custom-cell-value-${
                                          index === 0 ? 'first' : 'last'
                                        }`}
                                      >
                                        {!isNaN(
                                          PFData[PFData?.loanType][Object.keys(value)?.[0]]
                                        ) &&
                                        Object.keys(value)?.[0]?.toString() !== 'employeeId' &&
                                        Object.keys(value)?.[0]?.toString() !== 'department'
                                          ? getFormattedPrice(
                                              PFData[PFData?.loanType][Object.keys(value)?.[0]]
                                            )
                                          : isValidDate(
                                              PFData[PFData?.loanType][Object.keys(value)?.[0]]
                                            )
                                          ? moment(
                                              PFData[PFData?.loanType][Object.keys(value)?.[0]]
                                            ).format('DD/MM/YYYY')
                                          : PFData[PFData?.loanType][Object.keys(value)?.[0]]}

                                        {/* {renderValue(
                                          getValue(
                                            PFData,
                                            PFData?.loanType,
                                            Object.keys(value)?.[0]
                                          ),
                                          key
                                        )} */}
                                      </td>
                                    </>
                                  ) : null}
                                </>
                              )
                            }
                          )
                        : PFData[PFData?.loanType]?.[key] && (
                            <>
                              {' '}
                              <td
                                className={`${
                                  PFData?.loanType !== 'PFOptOutForm'
                                    ? 'custom-cell'
                                    : 'custom-cell custom-cell-first'
                                }`}
                                colSpan={1}
                              >
                                {PFLoanTypeObject[`${PFData?.loanType}Fields`]?.[key]}
                              </td>
                              {key === 'CNIC' ? (
                                <td
                                  className={`${
                                    PFData?.loanType !== 'PFOptOutForm'
                                      ? 'custom-cell'
                                      : 'custom-cell custom-cell-last p-0'
                                  }`}
                                  colSpan={3}
                                  dangerouslySetInnerHTML={{
                                    __html: formatCNIC(PFData[PFData?.loanType]?.[key]),
                                  }}
                                ></td>
                              ) : (
                                <td
                                  className={`${
                                    PFData?.loanType !== 'PFOptOutForm'
                                      ? 'custom-cell'
                                      : 'custom-cell custom-cell-last'
                                  }`}
                                  colSpan={3}
                                >
                                  {isValidDate(PFData[PFData?.loanType]?.[key])
                                    ? moment(PFData[PFData?.loanType]?.[key]).format('DD/MM/YYYY')
                                    : PFData[PFData?.loanType]?.[key]}
                                </td>
                              )}
                            </>
                          )}
                    </tr>
                  </>
                )
              )}
            </tbody>
          </table>
          <table className='table custom-table mb-0'>
            <tbody
              className={`${
                [...pfArr, 'fullLoanSettlement'].includes(PFData?.loanType)
                  ? 'custom-row-declaration'
                  : ''
              }`}
            >
              <tr>
                {dateArr.map(
                  (value: any, index: number) =>
                    PFData[PFData?.loanType][Object.keys(value)?.[0]] && (
                      <>
                        <td
                          className={`custom-cell custom-cell-date-field-${
                            index === 0 ? 'first' : 'last'
                          }`}
                        >
                          {Object.values(value)?.[0]}
                        </td>
                        <td
                          className={`custom-cell custom-cell-date-${
                            index === 0 ? 'first' : 'last'
                          }`}
                        >
                          {!isNaN(PFData[PFData?.loanType][Object.keys(value)?.[0]]) &&
                          Object.keys(value)?.[0]?.toString() !== 'employeeId'
                            ? getFormattedPrice(PFData[PFData?.loanType][Object.keys(value)?.[0]])
                            : isValidDate(PFData[PFData?.loanType][Object.keys(value)?.[0]])
                            ? moment(PFData[PFData?.loanType][Object.keys(value)?.[0]]).format(
                                'DD/MM/YYYY'
                              )
                            : PFData[PFData?.loanType][Object.keys(value)?.[0]]}
                        </td>
                      </>
                    )
                )}
              </tr>
            </tbody>
          </table>
          <table className='table custom-table mb-0'>
            <tbody>
              <tr>
                {loanPriceObject[`${PFData?.loanType}Amount`] &&
                  loanPriceObject[`${PFData?.loanType}Amount`].map((value: any, index: number) => (
                    <>
                      <td className={`custom-cell custom-cell-${index}`}>
                        {Object.values(value)?.[0]}
                      </td>
                    </>
                  ))}
              </tr>
              <tr>
                {loanPriceObject[`${PFData?.loanType}Amount`] &&
                  loanPriceObject[`${PFData?.loanType}Amount`].map((value: any) => (
                    <>
                      <td className='custom-cell'>
                        {!isNaN(PFData[PFData?.loanType][Object.keys(value)?.[0]])
                          ? getFormattedPrice(PFData[PFData?.loanType][Object.keys(value)?.[0]])
                          : isValidDate(PFData[PFData?.loanType][Object.keys(value)?.[0]])
                          ? moment(PFData[PFData?.loanType][Object.keys(value)?.[0]]).format(
                              'DD/MM/YYYY'
                            )
                          : PFData[PFData?.loanType][Object.keys(value)?.[0]]}
                      </td>
                    </>
                  ))}
              </tr>
            </tbody>
          </table>
          {pfArr.includes(PFData?.loanType) && (
            <table className='table custom-table mt-2 mb-2'>
              <tbody>
                <tr>
                  <td className='custom-cell'>Reason for withdrawal:</td>
                </tr>
                <tr>
                  <td className='custom-cell'>
                    <span className='pb-0 fw-bolder'>
                      {PFData[PFData?.loanType]['reasonForWithdrawal']}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className='custom-cell'>
                    <p></p>
                  </td>
                </tr>
                <tr>
                  <td className='custom-cell'>Please attach supporting documents (if any)</td>
                </tr>
              </tbody>
            </table>
          )}

          {PFData?.loanType === 'temporaryLoan' && (
            <>
              <h6 className='text-center'>Suggested Repayment Schedule</h6>

              <table className='table custom-table mb-2'>
                <tbody>
                  <>
                    <tr>
                      <td className='custom-cell-th'>Amount Applied</td>
                      <td className='custom-cell-th'>
                        {getFormattedPrice(PFData[PFData?.loanType]['amountApplied'])}
                      </td>
                      <td className='custom-cell-monthly-installment-suggested'>
                        Monthly Installment
                      </td>
                      <td className='custom-cell-th'>
                        {getFormattedPrice(
                          PFData[PFData?.loanType]['monthlyInstallmentSuggestion']
                        )}
                      </td>
                      <td className='custom-cell-th'>No. of Installments</td>
                      <td className='custom-cell-th'>
                        {getFormattedPrice(PFData[PFData?.loanType]['noOfInstallmentsSuggestion'])}
                      </td>
                    </tr>
                  </>
                </tbody>
              </table>
            </>
          )}

          <table className='table custom-table mb-0'>
            <tbody className='custom-row-declaration'>
              {PFFieldsLabel[`${PFData?.loanType}Status`] && (
                <>
                  <tr className='custom-row-status'>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: PFFieldsLabel[`${PFData?.loanType}Status`],
                      }}
                      className='custom-cell border-0'
                      colSpan={6}
                    ></td>
                  </tr>
                  <tr>
                    <td className='custom-cell-th'>Monthly Installment</td>
                    <td className='custom-cell-th'>
                      {getFormattedPrice(PFData[PFData?.loanType]['monthlyInstallment'])}
                    </td>
                    <td className='custom-cell-th'>Monthly Installment Left</td>
                    <td className='custom-cell-th'>
                      {getFormattedPrice(PFData[PFData?.loanType]['remainingInstallments'])}
                    </td>
                    <td className='custom-cell-th'>Amount Left</td>
                    <td className='custom-cell-th'>
                      {getFormattedPrice(PFData[PFData?.loanType]['amountLeft'])}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          <table className='table custom-table mb-0'>
            <tbody className='custom-row-declaration'>
              {getFacilityFieldData(PFData[`${PFData?.loanType}`], PFData?.loanType) && (
                <tr>
                  <td
                    dangerouslySetInnerHTML={{
                      __html: getFacilityFieldData(PFData[`${PFData?.loanType}`], PFData?.loanType),
                    }}
                    className='custom-cell'
                    colSpan={4}
                  ></td>
                </tr>
              )}
            </tbody>
          </table>
          {PFFieldsLabel[`${PFData?.loanType}FacilityRows`] && (
            <table className='table custom-table mb-0'>
              <tbody>
                <tr>
                  <td className='custom-cell custom-td-new'>Type of withdrawal</td>
                  <td className='custom-cell custom-td-new'>Withdrawal / Opt Out Date</td>
                  <td className='custom-cell custom-td-new'>Amount Availed</td>
                  <td className='custom-cell custom-td-new'>No. of Installments</td>
                  <td className='custom-cell custom-td-new'>Repayment Status (If applicable)</td>
                </tr>
                {PFFieldsLabel[`${PFData?.loanType}FacilityRows`]?.map(
                  (row: any, index: number) => (
                    <tr>
                      <td className='custom-cell '></td>
                      <td className='custom-cell '></td>
                      <td className='custom-cell '></td>
                      <td className='custom-cell '></td>
                      <td className='custom-cell '></td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          )}
          <table className='table custom-table mb-0'>
            <tbody className='custom-row-declaration'>
              {getAgreementForm(PFData[PFData?.loanType], PFData?.loanType) && (
                <>
                  {PFData?.loanType === 'fullLoanSettlement' && (
                    <tr>
                      <td className='custom-cell p-1' colSpan={4}>
                        <h6 className='text-center m-0'>Full Settlement Declaration</h6>
                      </td>
                    </tr>
                  )}

                  <tr
                    className={`${PFData?.loanType !== 'PFOptOutForm' ? 'custom-row-status' : ''}`}
                  >
                    <td
                      dangerouslySetInnerHTML={{
                        __html: getAgreementForm(PFData[PFData?.loanType], PFData?.loanType),
                      }}
                      className='custom-cell'
                      colSpan={4}
                    ></td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          <table className='table custom-table mb-0'>
            <tbody className='custom-row-declaration'>
              {getOfficeUseDescription(PFData[PFData?.loanType], PFData?.loanType) && (
                <>
                  <tr className='custom-row-declaration '>
                    <td className='custom-cell p-1 ' colSpan={4}>
                      <h6 className='text-center m-0'>For Office Use Only</h6>
                    </td>
                  </tr>
                  <tr className='custom-row-status'>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: getOfficeUseDescription(PFData[PFData?.loanType], PFData?.loanType),
                      }}
                      className='custom-cell'
                      colSpan={4}
                    ></td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          {PFData?.loanType === 'temporaryLoan' && (
            <>
              <h6 className='text-center'>Repayment Schedule</h6>

              <table className='table custom-table mb-2'>
                <tbody>
                  <>
                    <tr>
                      <td className='custom-cell-monthly-installment'>Monthly Installment</td>
                      <td className='custom-cell-th'>
                        {getFormattedPrice(PFData[PFData?.loanType]['approvedMonthlyInstallment'])}
                      </td>
                      <td className='custom-cell-th'>No. of Installments</td>
                      <td className='custom-cell-th'>
                        {getFormattedPrice(PFData[PFData?.loanType]['approvedNoOfInstallments'])}
                      </td>
                      <td className='custom-cell-th'>Starting Date</td>
                      <td className='custom-cell-th'>
                        {moment(PFData[PFData?.loanType]['startingDate']).format('DD/MM/YYYY')}
                      </td>
                    </tr>
                  </>
                </tbody>
              </table>
            </>
          )}
          <table className='table custom-table mb-0'>
            <tbody>
              {PFFieldsLabel[`${PFData?.loanType}Status`] && (
                <>
                  <tr>
                    <td className='custom-cell-monthly-installment'>Monthly Installment</td>
                    <td className='custom-cell-th'>
                      {getFormattedPrice(PFData[PFData?.loanType]?.['monthlyInstallmentOffice'])}
                    </td>
                    <td className='custom-cell-monthly-installment'>Monthly Installment Left</td>
                    <td className='custom-cell-th'>
                      {getFormattedPrice(PFData[PFData?.loanType]?.['remainingInstallmentsOffice'])}
                    </td>
                    <td className='custom-cell-th'>Amount Left</td>
                    <td className='custom-cell-th'>
                      {getFormattedPrice(PFData[PFData?.loanType]?.['amountLeftOffice'])}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          <table className='table custom-table mb-0'>
            <tbody className='custom-row-declaration'>
              {getOfficeInstallment(PFData[PFData?.loanType], PFData?.loanType) && (
                <>
                  <tr className='custom-row-status'>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: getOfficeInstallment(PFData[PFData?.loanType], PFData?.loanType),
                      }}
                      className='custom-cell'
                      colSpan={4}
                    ></td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          {['permanentLoan'].includes(PFData?.loanType) && (
            <table className='table custom-table mt-2 mb-2'>
              <tbody>
                <tr>
                  <td className='custom-cell fw-bolder'>Notes:</td>
                </tr>
                <tr>
                  <td className='custom-cell'>
                    {' '}
                    <span className='pb-0 fw-bolder'>{PFData[PFData?.loanType]['notes']}</span>
                  </td>
                </tr>
                <tr>
                  <td className='custom-cell'>
                    <p></p>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <table className='table custom-table mb-0'>
            <tbody>
              {PFFieldsLabel[PFData?.loanType] && (
                <tr>
                  <td
                    dangerouslySetInnerHTML={{__html: PFFieldsLabel[PFData?.loanType]}}
                    className='custom-cell'
                    colSpan={4}
                  ></td>
                </tr>
              )}

              {PFFieldsLabel['approvedByText'] && (
                <tr
                  className='custom-row-declaration'
                  dangerouslySetInnerHTML={{__html: PFFieldsLabel['approvedByText']}}
                ></tr>
              )}

              {PFFieldsLabel['approvedByHeading'] && (
                <tr dangerouslySetInnerHTML={{__html: PFFieldsLabel['approvedByHeading']}}></tr>
              )}

              {PFFieldsLabel['approvedBy'] && (
                <tr dangerouslySetInnerHTML={{__html: PFFieldsLabel['approvedBy']}}></tr>
              )}

              {treasurers?.map((treasurer: any) => (
                <tr>
                  <td className='custom-cell text-center' colSpan={1}>
                    {treasurer?.name}
                  </td>
                  <td className='custom-cell text-center' colSpan={2}></td>
                  <td className='custom-cell text-center'></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
})

export default PrintTable
