import React, {lazy, Suspense} from 'react'
// import {TodoTable} from './components/TodoTable'
import {Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../../_metronic/partials'
import {ClientsTable} from './components/CredentialsTable'
import {ClientsAdd} from './components/CredentialsAdd'

const CredentialsPage: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/credentials'>
        <Suspense fallback={<FallbackView />}>
          <ClientsTable />
        </Suspense>
      </Route>
      {/* <Route exact path='/credentials/:id'>
        <TodoDataHeader />
      </Route> */}
    </Switch>
  )
}

export default CredentialsPage
