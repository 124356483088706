import {DateRange, Venue} from '.'

interface DateRangeWithID {
  start: string
  end: string
  _id: string
}

export const convertPascalCaseToSentence = (location: string) => {
  if (location) {
    const result = location.replace(/([A-Z])/g, ' $1')
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    return finalResult.trim()
  } else return '-'
}

export const getWorkingHours = () => {
  const startTime = new Date()
  startTime.setHours(9, 0, 0) // Set start time to 9:00am

  const endTime = new Date()
  endTime.setHours(18, 0, 0) // Set end time to 6:00pm

  return {startTime, endTime}
}

export const getEventAvailabilities = (values: any) => {
  let availability = {}
  Object.keys(values.daysOfWeek).forEach((d) => {
    if (values.daysOfWeek[d]?.isSelected) {
      availability = {
        ...availability,
        [d]: {
          from: values.daysOfWeek[d]?.startTime,
          to: values.daysOfWeek[d]?.endTime,
        },
      }
    }
  })
  return availability
}

function addDaysToCurrentDate(numberOfDays: number) {
  const currentDate = new Date()
  // Increment the date by 1 to get first date
  const tomorrow = new Date(currentDate)
  tomorrow.setDate(currentDate.getDate() + 1)

  const currentDay = currentDate.getDate()
  const newDay = currentDay + numberOfDays
  const updatedDate = new Date(currentDate)
  updatedDate.setDate(newDay)
  return {
    currentDate: tomorrow,
    updatedDate: updatedDate,
  }
}

export const getDateRangeOfEvent = (values: any) => {
  const dateRange: DateRange = {
    start: undefined,
    end: undefined,
  }
  if (values.dateRangeOption === 'option1') {
    const result = addDaysToCurrentDate(values.numOfDay)
    dateRange.start = result.currentDate
    dateRange.end = result.updatedDate
  } else if (values.dateRangeOption === 'option2') {
    dateRange.start = values.selectedDates[0]
    dateRange.end = values.selectedDates[1]
  } else if (values.dateRangeOption === 'option3') {
    dateRange.start = new Date()
    dateRange.end = Infinity
  }
  return dateRange
}

export const getWeekDaysFromRes = (availability: any, values: any) => {
  const weekDays: any = {}
  Object.keys(availability).forEach((d) => {
    weekDays[d] = {
      ...values?.daysOfWeek[d],
      isSelected: true,
      startTime: new Date(availability[d]?.from),
      endTime: new Date(availability[d]?.to),
    }
  })
  return weekDays
}

export const getSelectedDatesRange = (
  dateRangeOption: 'option1' | 'option2' | 'option3',
  dateRange: DateRangeWithID
) => {
  if (dateRangeOption === 'option2') {
    return [new Date(dateRange?.start), new Date(dateRange.end)]
  } else return [null, null]
}

export const getDiffInDays = (
  dateRangeOption: 'option1' | 'option2' | 'option3',
  dateObj: DateRangeWithID
) => {
  if (dateRangeOption === 'option1') {
    const startDate: any = new Date(dateObj.start)
    const endDate: any = new Date(dateObj.end)
    const timeDiff = Math.abs(endDate - startDate)
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
    return diffDays + 1
  } else return 0
}

export const makeKeyOfString = (originalString: string) => {
  return originalString
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (match, character) => character.toUpperCase())
}

export const getAppointmentsLocations = (settings: any) => {
  const appointmentVenues = settings.find((item: any) => item?.name === 'appointmentVenues')
  const appointmentsLocationsArr: Venue[] = []
  appointmentVenues?.value?.split('\n')?.map((venue: string) => {
    appointmentsLocationsArr.push({name: venue, value: makeKeyOfString(venue)})
  })
  return appointmentsLocationsArr
}

function getDayOfWeek(date: Date) {
  const dayOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
  const dayIndex = date.getDay()
  return dayOfWeek[dayIndex]
}

function generateTimeSlots(
  timeObject: any,
  interval: number,
  beforeBreakTime: number,
  afterBreakTime: number,
  date: Date
) {
  const startTime = new Date(timeObject.from)
  const endTime = new Date(timeObject.to)
  const timeSlots = []
  let currentTime = startTime
  const currDate = new Date()

  while (currentTime < endTime) {
    if (
      currDate.getDate() === date.getDate() &&
      currDate.getMonth() === date.getMonth() &&
      currDate.getFullYear() === date.getFullYear()
    ) {
      if (
        currentTime.getMinutes() < currDate.getMinutes() &&
        currentTime.getHours() <= currDate.getHours()
      ) {
        currentTime.setMinutes(currentTime.getMinutes() + interval)
        continue // Skip this slot
      }
    } else if (date < currDate) {
      currentTime.setMinutes(currentTime.getMinutes() + interval)
      continue // Skip this slot
    }

    if (beforeBreakTime) currentTime.setMinutes(currentTime.getMinutes() + beforeBreakTime)

    timeSlots.push(
      currentTime.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})
    )

    currentTime.setMinutes(currentTime.getMinutes() + interval)

    if (afterBreakTime) currentTime.setMinutes(currentTime.getMinutes() + afterBreakTime)
  }

  return timeSlots
}

export function iterateFullMonth(
  currentDate: Date,
  availability: any,
  interval: number,
  beforeBreakTime: number,
  afterBreakTime: number
) {
  const timeSlots = []
  currentDate.setDate(1)
  let lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate()
  for (let day = 1; day <= lastDay; day++) {
    if (availability[getDayOfWeek(currentDate)]) {
      const timeSlot = generateTimeSlots(
        availability[getDayOfWeek(currentDate)],
        interval,
        beforeBreakTime,
        afterBreakTime,
        currentDate
      )
      timeSlots.push(timeSlot)
    } else {
      timeSlots.push([])
    }
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return timeSlots
}

export function areDatesEqual(d1: Date, d2: Date) {
  const hours1 = d1.getUTCHours()
  const minutes1 = d1.getUTCMinutes()
  const hours2 = d2.getUTCHours()
  const minutes2 = d2.getUTCMinutes()

  return hours1 === hours2 && minutes1 === minutes2
}
