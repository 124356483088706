import CryptoJS from 'crypto-js'

const iv: any = process.env.REACT_APP_IV
export function decryptPassword(cipherText: string) {
  const bytes = CryptoJS.AES.decrypt(
    cipherText,
    process.env.REACT_APP_ACCESS_TOKEN_SECRET as string,
    {iv}
  )
  const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8)
  return decryptedPassword
}
