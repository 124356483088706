import React from 'react'
import {ErrorMessage, Field, Form, useFormikContext} from 'formik'
import {NumericFormat, PatternFormat} from 'react-number-format'
import moment from 'moment'

export const PFOptOutForm: React.FC = () => {
  const {values, setFieldValue}: any = useFormikContext()
  return (
    <>
      <div className='col-md-6 fv-row mb-5'>
        <label className='fs-6 fw-bold mb-2 required'>
          <span>Name (as per CNIC)</span>
        </label>

        <Field
          type='text'
          className='form-control form-control-lg  form-control-solid'
          name={`${values.loanType}.memberName`}
          placeholder='Name'
        />
        <div className='text-danger'>
          <ErrorMessage name={`${values.loanType}.memberName`} />
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold required mb-2'>Employee ID</label>
        <NumericFormat
          placeholder='Employee ID'
          className='form-control form-control-lg form-control-solid'
          name={`${values.loanType}.employeeId`}
          value={values?.[values?.loanType]?.['employeeId']}
          onChange={(e: any) => {
            setFieldValue(`${values.loanType}.employeeId`, +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name={`${values.loanType}.employeeId`} />
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='fs-6 fw-bold fw-bold form-label mb-2 required'>Designation</label>
        <div className='row fv-row'>
          <div className='col-12'>
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`${values.loanType}.designation`}
              placeholder='Designation'
            />
            <div className='text-danger'>
              <ErrorMessage name={`${values.loanType}.designation`} />
            </div>
          </div>
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='fs-6 fw-bold mb-2 required'>
          <span>Date of Birth</span>
        </label>

        <Field
          type='date'
          className='form-control form-control-lg  form-control-solid'
          name='PFOptOutForm.dob'
        />
        <div className='text-danger'>
          <ErrorMessage name='PFOptOutForm.dob' />
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold required mb-2'>CNIC</label>

        <PatternFormat
          className='form-control form-control-lg form-control-solid'
          format='#####-#######-#'
          allowEmptyFormatting
          mask='_'
          name='PFOptOutForm.CNIC'
          value={values?.['PFOptOutForm']?.['CNIC']}
          onChange={(e: any) => {
            setFieldValue('PFOptOutForm.CNIC', e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='PFOptOutForm.CNIC' />
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='fs-6 fw-bold fw-bold form-label mb-2 required'>Father Name</label>
        <div className='row fv-row'>
          <div className='col-12'>
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='PFOptOutForm.fatherName'
              placeholder='Father Name'
            />
            <div className='text-danger'>
              <ErrorMessage name='PFOptOutForm.fatherName' />
            </div>
          </div>
        </div>
      </div>

      <div className='col-md-12 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2 required'>
          Address (As per CNIC)
        </label>

        <div className='col-12'>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='PFOptOutForm.address'
            placeholder='Address'
          />
          <div className='text-danger'>
            <ErrorMessage name='PFOptOutForm.address' />
          </div>
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold  mb-2 required'>
          <span className=''>PF Activation Month</span>
        </label>

        <Field
          className='form-control form-control-lg form-control-solid'
          name='PFOptOutForm.PFActivationMonth'
          placeholder='PF Activation Month'
          type='date'
          // type='month'
        />

        <div className='text-danger'>
          <ErrorMessage name='PFOptOutForm.PFActivationMonth' />
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold  mb-2 required'>
          <span className=''>PF Discontinued Month</span>
        </label>

        <Field
          className='form-control form-control-lg form-control-solid'
          name='PFOptOutForm.PFDiscontinuedMonth'
          placeholder='PF Discontinued Month'
          type='date'
        />

        <div className='text-danger'>
          <ErrorMessage name='PFOptOutForm.PFDiscontinuedMonth' />
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2 required'>Department</label>

        <div className='col-12'>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`${values.loanType}.department`}
            placeholder='Department'
          />
          <div className='text-danger'>
            <ErrorMessage name={`${values.loanType}.department`} />
          </div>
        </div>
      </div>
      <div className='col-md-12 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2'>Notes</label>

        <div className='col-12'>
          <Field
            className='form-control form-control-lg form-control-solid'
            name='PFOptOutForm.notes'
            placeholder='Notes'
            as='textarea'
            rows='5'
          />

          <div className='text-danger'>
            <ErrorMessage name='PFOptOutForm.notes' />
          </div>
        </div>
      </div>
    </>
  )
}
