import moment from 'moment'
import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {Can} from '../../../Common/RBAC/Can'
import '../../../../_metronic/assets/sass/components/todo-table-row.scss'
import Ability from '../../../Common/RBAC/Ability'

type Props = {
  index: number
  credential: {
    title: string
    siteURL: string
    userName: string
    password?: any
    createdAt: string
    _id: string
  }
  deleteClient: Function
  setData: Function
  setShow: Function
}

export const ClientsRow: React.FC<Props> = ({
  index,
  credential,
  deleteClient,
  setData,
  setShow,
}) => {
  return (
    <tr key={index} className='border-bottom releaseNote'>
      <td data-label='Name' title={credential?.title ? credential?.title : '-'}>
        {credential?.title ? (
          // <Link
          //   style={{pointerEvents: Ability.can('read', 'credentials') ? undefined : 'none'}}
          //   to={`/credentials/${credential?._id}/view`}
          // >
          <div
            title={credential?.title}
            style={{
              display: 'block',
              width: '120px',
              // minWidth: '280px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {credential?.title}
          </div>
        ) : (
          // </Link>
          <div className='d-flex align-items-center text-center'>
            <div className='d-flex justify-content-start flex-column'>{'-'}</div>
          </div>
        )}
      </td>
      <td data-label='Version'>
        <div className='d-flex align-items-center text-center'>
          <div className='d-flex justify-content-start flex-column'>
            <div title={credential?.siteURL || '-'}>{credential?.siteURL || '-'}</div>
          </div>
        </div>
      </td>
      <td data-label='Release Date' className='text-end' title={credential?.userName ?? '-'}>
        <div className='d-flex flex-column'>
          <div className='d-flex flex-stack'>{credential?.userName ?? '-'}</div>
        </div>
      </td>

      <td data-label='Version'>
        <div className='d-flex align-items-center text-center'>
          <div className='d-flex justify-content-start flex-column'>
            <div title={moment(credential?.createdAt).format('DD/MM/YYYY') ?? '-'}>
              {moment(credential?.createdAt).format('DD/MM/YYYY') ?? '-'}
            </div>
          </div>
        </div>
      </td>
      <td data-label='Actions' className='action_btns'>
        <div className=' flex-shrink-0'>
          {/* <Can I='read' this='credentials'>
            <Link
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
              title='View'
              to={`/credentials/${credential?._id}/view`}
            >
              <KTSVG path='/media/icons/view.svg' className='svg-icon-4' />
            </Link>
          </Can> */}

          <Can I='update' this='credentials'>
            <Link
              to='#'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
              title='Edit'
              onClick={() => {
                setData(credential)
                setShow(true)
              }}
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </Link>
          </Can>
          <Can I='delete' this='credentials'>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm bg-light-blue-actions'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_delete_app'
              id='kt_toolbar_primary_button'
              onClick={deleteClient(credential?._id)}
              title='Delete'
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button>
          </Can>
        </div>
      </td>
    </tr>
  )
}
