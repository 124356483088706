import moment from 'moment'
import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {Can} from '../../../Common/RBAC/Can'
import '../../../../_metronic/assets/sass/components/todo-table-row.scss'
import {mapEventStatus} from '../helpers'
import {convertPascalCaseToSentence} from '../helpers/utils'
type Props = {
  index: number
  appointment: {
    name: string
    duration: string
    venue: string
    status: 'active' | 'inactive'
    createdAt: string
    _id: string
    dateRange: {
      start: string
      end: string
    }
  }
  deleteAppointments: Function
}

export const AppointmentsRow: React.FC<Props> = ({index, appointment, deleteAppointments}) => {
  return (
    <tr key={index} className='border-bottom releaseNote'>
      <td data-label='Name' title={appointment?.name ? appointment?.name : '-'}>
        {appointment?.name ? (
          <Link to={`/appointments/${appointment?._id}`}>
            <div
              title={appointment?.name}
              style={{
                display: 'block',
                width: '280px',
                minWidth: '280px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {appointment?.name}
            </div>
          </Link>
        ) : (
          <div className='d-flex align-items-center text-center'>
            <div className='d-flex justify-content-start flex-column'>{'-'}</div>
          </div>
        )}
      </td>
      <td data-label='Version'>
        <div className='d-flex align-items-center text-center'>
          <div className='d-flex justify-content-start flex-column'>
            <div title={appointment?.duration ?? '-'}>{appointment?.duration ?? '-'}</div>
          </div>
        </div>
      </td>
      <td data-label='Location'>
        <div className='d-flex align-items-center text-center'>
          <div className='d-flex justify-content-start flex-column'>
            <div title={convertPascalCaseToSentence(appointment?.venue)}>
              {convertPascalCaseToSentence(appointment?.venue)}
            </div>
          </div>
        </div>
      </td>
      <td data-label='Location'>
        <div className='d-flex align-items-center text-center'>
          <div className='d-flex justify-content-start flex-column'>
            <div title={appointment?.status ?? '-'}>
              {mapEventStatus[appointment?.status] ?? '-'}
            </div>
          </div>
        </div>
      </td>
      <td
        data-label='Release Date'
        className='text-end'
        title={moment(appointment?.dateRange?.start).format('DD/MM/YYYY')}
      >
        <div className='d-flex flex-column'>
          <div className='d-flex flex-stack'>
            {moment(appointment?.dateRange?.start).format('DD/MM/YYYY')}
          </div>
        </div>
      </td>
      <td data-label='Actions' className='action_btns'>
        <div className=' flex-shrink-0'>
          <Can I='read' this='event-scheduling'>
            <Link
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
              title='View'
              to={`/appointments/${appointment?._id}`}
            >
              <KTSVG path='/media/icons/view.svg' className='svg-icon-4' />
            </Link>
          </Can>

          <Can I='update' this='event-scheduling'>
            <Link
              to={`/appointments/${appointment?._id}/edit`}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
              title='Edit'
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </Link>
          </Can>
          <Can I='delete' this='event-scheduling'>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm bg-light-blue-actions'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_delete_app'
              id='kt_toolbar_primary_button'
              onClick={deleteAppointments(appointment?._id)}
              title='Delete'
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button>
          </Can>
        </div>
      </td>
    </tr>
  )
}
