import {FC, useState} from 'react'

type Props = {
  items: number,
  rowDimension: number
}

const AppLoadingBar: React.FC<Props> = ({rowDimension, items}) => {

  const barItems = [];

  for (let i = 0; i < items; i++) {
    barItems.push(
      <div className='d-flex flex-column margin-b-30px'>
          <div className="placeholder-content_item mb-3" 
            style={{
              zoom: (+rowDimension + 37) + '%',
            }}>
          </div>
          <div className='animated-div-para'></div>
          <div className='animated-div-para mt-2'></div>        
      </div>
    );
  }

  return (
    <>
    <div className="page-content-loading">
      <div className="placeholder-content d-flex dashboard_space flex-wrap cards-wrapper justify-content-center justify-content-sm-start"> 
        {barItems}                                 
      </div>
    </div>
    </>
  )
}

export {AppLoadingBar}
