import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {useDispatch} from 'react-redux'
import * as actions from '../redux/talentActions'
interface Props {
  show: boolean
  handleClose: () => void
  setFetchRecords: any
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Resource name must be at least 2 characters')
    .required('Resource Name is required'),
})

export const CreateTalentModel: FC<Props> = ({show, handleClose, setFetchRecords}) => {
  const dispatch: any = useDispatch()
  const [error, setError] = useState<string>('')

  const onSubmit = (resource: {name: string}) => {
    dispatch(actions.createTalentByName(resource))
      .then((res: any) => {
        setFetchRecords((prev: boolean) => !prev)
        handleClose()
      })
      .catch((error: any) => setError(error.response.data.message))
  }
  return (
    <Modal
      id='kt_modal_delete_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-700px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Create Talent</h2>
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          {error && (
            <div className='mx-lg-15 alert alert-success' role='alert'>
              {error}
            </div>
          )}
          <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
            <div className='flex-row-fluid px-lg-15'>
              <Formik
                initialValues={{name: ''}}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({errors, touched}) => (
                  <Form>
                    <div>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2' htmlFor='name'>
                        <span className='required'>Resource Name</span>
                      </label>
                      <Field
                        className='form-control form-control-lg form-control-solid'
                        name='name'
                        placeholder='Enter resource name'
                      />
                      <div className='text-danger'>
                        {' '}
                        <ErrorMessage className='text-danger' name='name' />
                      </div>
                    </div>

                    <div className='text-end'>
                      <button
                        title='Cancel'
                        type='button'
                        className='btn btn-sm cancel_button me-3 mt-3'
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </button>
                      <button className='btn btn-sm btn-blue fs-7 mt-3' type='submit'>
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
