import {Modal} from 'react-bootstrap-v5'

interface AppointmentModalProps {
  showModal: boolean
  handleCloseModal: () => void
  handleConfirm: () => void
  handleCancel: () => void
  clickedTime?: string
}

const AppointmentModal: React.FC<AppointmentModalProps> = ({
  showModal,
  handleCloseModal,
  handleConfirm,
  handleCancel,
  clickedTime,
}) => {
  return (
    <Modal
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={showModal}
      onHide={handleCloseModal}
      id='kt_modal_book_slot_app'
      tabIndex={-1}
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Book Appointment</h2>
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
            <div className='flex-row-fluid px-lg-15'>
              <div className='current' data-kt-stepper-element='content'>
                <div className='w-100'>
                  <div className='fv-row mb-10'>
                    <label className='align-items-center fs-4'>
                      Are you sure you want to book an appointment at {clickedTime}?
                    </label>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack justify-content-center pt-'>
                <div className='me-2'>
                  <button
                    title='Cancel'
                    type='button'
                    className='btn btn-sm cancel_button me-3'
                    onClick={() => {
                      handleCancel()
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    title='Confirm'
                    type='submit'
                    className='btn btn-sm btn-blue me-3'
                    onClick={() => {
                      handleConfirm()
                    }}
                  >
                    <span className='indicator-label'>Confirm</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AppointmentModal
