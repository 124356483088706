import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PFResponseInterface} from '../Models/ProvidentFundModel'

interface entities {
  _id?: string
  technology: string
  plan: string
  assignees?: []
  teams?: []
  createdBy: String
  activeDate: any
  status: number
}

interface ProvidentFundState {
  listLoading: boolean
  actionsLoading: boolean
  totalCount?: number
  entities: entities[]
  start: number
  end: number
  limitPerPage: number
  error?: any
  errorMessage?: string
  attachments?: any
  pfDetails?: PFResponseInterface | undefined
  loanType?: string
  treasurers?: any[]
}

const initialState: ProvidentFundState = {
  listLoading: true,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  start: 1,
  end: 10,
  limitPerPage: 10,
  attachments: [],
  treasurers: [],
  loanType: '',
  errorMessage: '',
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const providentFundSlice = createSlice({
  name: 'providentFund',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    catchError: (state, action: PayloadAction<ProvidentFundState>) => {
      state.error = `${action.payload}`
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    setErrorMessage: (state, action) => {
      // state.entities.splice(0, 0, action.payload.entities)
      state.errorMessage = action.payload.message
    },

    loadAttachments: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.attachments = action.payload
      } else {
        state.attachments.push(action.payload)
      }
    },
    providentFundFetched: (state, action: PayloadAction<ProvidentFundState>) => {
      state.listLoading = false
      const {totalCount, entities, start, end, treasurers} = action.payload
      state.entities = entities
      state.totalCount = totalCount
      state.start = start
      state.end = end
      state.treasurers = treasurers
    },
    pfDetails: (state, action: PayloadAction<PFResponseInterface | undefined>) => {
      state.listLoading = false
      state.pfDetails = action.payload || undefined
    },
    setLoanType: (state, action) => {
      state.loanType = action.payload
    },
  },
})
