/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import '../../../../../_metronic/assets/sass/components/task.scss'
import { Modal } from 'react-bootstrap-v5'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { RootState } from '../../../../../setup'
import * as boardActions from '../../redux/BoardActions'
import * as taskActions from '../../../Task/redux/TaskAction'
import Select from 'react-select'
import moment from 'moment'
import { Activity } from '../../../generalActivityLogs/components/Activity'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Attachment } from '../../../../Common/attachments/Attachment'
import GCDropZone from '../../../../Common/GCDropzone/GCDropZone'
interface Props {
  show: boolean
  taskId: string
  handleClose: () => void
}

const TaskPopup: FC<Props> = ({ show, handleClose, taskId }) => {
  const { workspaceid, id }: { workspaceid: string; id: string } = useParams()
  const dispatch: any = useDispatch()
  const [dropdownIndex, setDropdownIndex] = useState<string>('')
  const [assignees, setAssignees]: any = useState([])
  const [isHideDescription, setIsHideDescription] = useState<boolean>(false)
  const [showAttachment, setShowAttachment] = useState(false)
  const [title, setTitle] = useState<string>()
  const [isTitleEditable, setIsTitleEditable] = useState<boolean>(false)
  const [taskDescription, setTaskDescription] = useState<string>()

  const {
    columnByProId,
    singleTask,
    loginUser,
    accessToken,
    attachments,
    currentProject,
    currentSpaceUsers,
  }: any = useSelector<RootState>(
    (state) => ({
      columnByProId: state.board.columnByProId,
      singleTask: state.board.singleTask,
      loginUser: state.auth.user,
      accessToken: state.auth.accessToken,
      attachments: state.task.attachments,
      currentProject: state.board.currentProject,
      currentSpaceUsers: state.board.currentSpaceUsers,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (singleTask?._id === taskId && attachments.length > 0) {
      const actualTask = JSON.parse(JSON.stringify(singleTask))
      let filteredAttachments = attachments.map((file: any) => {
        return {
          url: file.url,
          type: file.path.split('.')[1],
          path: file.path.split('.')[0],
          thumbnailUrl:
            file.url.split('attachments/')[0] +
            'attachments/thumbnail/' +
            file.url.split('attachments/')[1],
        }
      })
      let uploadedFiles = filteredAttachments.map((file: any) => {
        return file.path + '.' + file.type
      })

      let filteredFiles = actualTask.attachmentPaths.filter(
        (file: any) => !uploadedFiles.includes(file)
      )
      const finalFiles = filteredFiles.concat(uploadedFiles)

      actualTask.attachmentPaths = finalFiles
      actualTask.attachments = [...actualTask.attachments, ...filteredAttachments]

      let projectReduxUpdate = {
        _id: taskId,
        field: 'attachments',
        value: [...actualTask.attachments, ...filteredAttachments],
      }
      updateCurrentProjectRedux(projectReduxUpdate)

      let updatedTask = actualTask
      dispatch(boardActions.updatedSingleTask(updatedTask))

      let data = { _id: taskId, field: 'attachmentPaths', value: finalFiles }
      dispatch(taskActions.UpdateTaskSpecific(data, workspaceid, id))
      setShowAttachment((showAttachment) => !showAttachment)
      dispatch(taskActions.loadAttachments([]))
    }
  }, [attachments.length])

  useEffect(() => {
    if (taskId) {
      dispatch(boardActions.getColumnByProId(id))
    }
  }, [taskId])

  useEffect(() => {
    if (show && taskId) {
      dispatch(boardActions.getSingleTask(taskId))
    }
  }, [show])

  useEffect(() => {
    setAssignees(singleTask?.assignee?.map((assignee: any) => assignee?._id || assignee))
  }, [singleTask])

  const [taskLabels, setTaskLabels] = useState([])

  const { settings }: any = useSelector<RootState>(
    (state) => ({
      settings: state.settings.entities,
    }),
    shallowEqual
  )

  useEffect(() => {
    const labelsObject = settings.find((data: any) => data.name === 'taskLabels')
    const taskLabelsArray = labelsObject?.value.split('\n')
    const labels: any = []
    taskLabelsArray?.forEach((task: any) => {
      labels.push({
        value: task.toLowerCase(),
        label: task.charAt(0).toUpperCase() + task.slice(1),
        color: '#ABFF8B',
      })
    })
    setTaskLabels(labels)
  }, [settings])

  const priorities = [
    { value: 'low', label: 'Low', color: '#FF8B8B' },
    { value: 'medium', label: 'Medium', color: '#ABFF8B' },
    { value: 'high', label: 'High', color: '#80CEAC' },
  ]

  const options: any = []
  columnByProId?.filter((column: any) => {
    options.push({
      value: column?._id,
      label: column?.name,
      color:
        column?.name === 'To Do'
          ? '#0097A8'
          : column?.name === 'In Progress'
            ? '#A1A5B7'
            : column?.name === 'Done'
              ? '#004B87'
              : '#fff',
    })
  })

  const customStyles = {
    option: (styles: any, { data }: any) => {
      return {
        ...styles,
        backgroundColor: data.color,
      }
    },
  }

  const handleUpdateTask = (data: any) => {
    let updatedTask: any = {}
    if (data.field === 'status') {
      let actualTask = JSON.parse(JSON.stringify(singleTask))
      actualTask.status._id = data.value
      actualTask.status.id = data.value
      actualTask.status.name = data.name
      updatedTask = actualTask
    } else {
      let actualTask = JSON.parse(JSON.stringify(singleTask))
      actualTask[data.field] = data.value
      updatedTask = actualTask
    }

    updateCurrentProjectRedux(data)

    dispatch(boardActions.updatedSingleTask(updatedTask))
    dispatch(taskActions.UpdateTaskSpecific(data, workspaceid, id))
  }

  const updateCurrentProjectRedux = (data: any) => {
    let actualTask = JSON.parse(JSON.stringify(singleTask))
    let currentProjectObject = JSON.parse(JSON.stringify(currentProject))
    const columnList = JSON.parse(JSON.stringify(currentProjectObject?.board?.columns))

    if (data.field === 'status') {
      const dragTaskIndex = columnList.findIndex((col: any) => col?._id === actualTask?.status?._id)
      const dropTaskIndex = columnList.findIndex((col: any) => col?._id === data.value)

      let taskDragIndex = columnList[dragTaskIndex]?.task.findIndex(
        (t: any) => t?._id === actualTask?._id
      )
      let taskExtract = columnList[dragTaskIndex].task.splice(taskDragIndex, 1)[0]
      columnList[dropTaskIndex].task.push(taskExtract)
    } else if (data.field === 'assignee') {
      columnList.map((column: any, index: number) => {
        if (column?._id === actualTask?.status?._id) {
          let taskFound = column?.task.findIndex((t: any) => t?._id === actualTask?._id)
          columnList[index].task[taskFound][data.field] = currentSpaceUsers.filter((user: any) =>
            data?.value?.includes(user._id)
          )
        }
      })
    } else {
      columnList.map((column: any, index: number) => {
        if (column?._id === actualTask?.status?._id) {
          let taskFound = column?.task.findIndex((t: any) => t?._id === actualTask?._id)
          columnList[index].task[taskFound][data.field] = data.value
        }
      })
    }

    currentProjectObject['board']['columns'] = columnList
    dispatch(boardActions.updateCurrentProject(currentProjectObject))
  }

  const boxRef: any = useRef(null)
  function handleClickOutside(event: any) {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setIsHideDescription(false)
      setIsTitleEditable(false)
    }
  }

  useEffect(() => {
    if (taskDescription !== undefined && !isHideDescription) {
      handleUpdateTask({ _id: taskId, field: 'description', value: taskDescription })
    }
  }, [taskId, taskDescription, isHideDescription])

  useEffect(() => {
    if (title !== undefined && !isTitleEditable) {
      handleUpdateTask({ _id: taskId, field: 'title', value: title })
    }
  }, [taskId, title, isTitleEditable])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [boxRef])

  return (
    <Modal
      id='kt_modal_delete_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-900px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div>
        <div className='modal-header border-0 task_header_popup'>
          <div className='d-flex'>
            {!isTitleEditable ? (
              <h2
                className='fs-15w word-alignment'
                onClick={() => {
                  setIsTitleEditable(true)
                  setTitle(singleTask?.title)
                }}
              >
                {singleTask?.title}
              </h2>
            ) : (
              <textarea
                className='form-control form-control-sm form-control-solid mb-8 pt-3'
                ref={boxRef}
                autoFocus={true}
                name='title'
                id='title'
                rows={1}
                value={title}
                onChange={(e: any) => {
                  setTitle(e.target.value)
                }}
                placeholder='Add a title...'
              ></textarea>
            )}

            <div className='task-status mx-8'>
              <div
                className='position-relative text-sm fw-semibold text-end bg-green'
                style={{
                  wordBreak: 'break-all',
                }}
                onClick={() => {
                  dropdownIndex === 'status' ? setDropdownIndex('') : setDropdownIndex('status')
                }}
                data-kt-element='message-text'
              >
                <i className='fa fa-angle-down px-1 padding-top-1px fs-4 cursor-pointer d-block float-end text-white'></i>
                {dropdownIndex === 'status' && (
                  <div
                    className='top-100 end-0 position-absolute show menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px show'
                    data-kt-menu='true'
                  >
                    {options
                      .filter((status: any) => status.value !== singleTask?.status?._id)
                      .map((option: any, i: number) => {
                        return (
                          <div key={i} className='menu-item item-style px-3 my-0'>
                            <div className='menu-link px-3 py-2'>
                              <span
                                className='menu-title'
                                onClick={() => {
                                  const data = {
                                    _id: taskId,
                                    field: 'status',
                                    value: option.value,
                                    name: option.label,
                                  }
                                  handleUpdateTask(data)
                                  setDropdownIndex('')
                                }}
                              >
                                {option.label}
                              </span>
                              <i className='fa fa-angle-down px-1 text-white'></i>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                )}

                {singleTask?.status?.name}
              </div>
            </div>
          </div>

          <div className='d-flex'>
            <div className='eye  mx-1'>
              <a
                onClick={() => {
                  setShowAttachment((showAttachment) => !showAttachment)
                }}
              >
                <i className='bi bi-paperclip fs-3'></i>
              </a>
            </div>
            {(currentProject?.workspace?.maintainers?.includes(loginUser?._id) ||
              currentProject?.workspace?.owner === loginUser?._id ||
              currentProject?.createdBy?._id === loginUser?._id) && (
                <div className='eye  mx-1'>
                  <Link to={`/${workspaceid}/project/${id}/task/${taskId}/edit`} title='Edit'>
                    <i className='bi bi-three-dots fs-2'></i>
                  </Link>
                </div>
              )}
            <div className='eye mx-1' onClick={handleClose}>
              <a>
                {' '}
                <i className='fa fa-times-circle' aria-hidden='true'></i>
              </a>
            </div>
          </div>
        </div>

        <div className='px-7'>
          {!isHideDescription ? (
            <div
              style={{ whiteSpace: 'pre-line' }}
              className='pb-8 pt-3'
              onClick={() => {
                setIsHideDescription(true)
                setTaskDescription(singleTask?.description)
              }}
            >
              {!singleTask?.description && <p>Add a description...</p>}
              {singleTask?.description}
            </div>
          ) : (
            <textarea
              className='form-control form-control-lg form-control-solid mb-8 pt-3'
              ref={boxRef}
              autoFocus={true}
              name='description'
              id='description'
              rows={7}
              value={taskDescription}
              onChange={(e: any) => {
                setTaskDescription(e.target.value)
              }}
              placeholder='Add a description...'
            ></textarea>
          )}
        </div>

        <div className="container">
          <div className='d-flex justify-content-between flex-wrap options-elements pb-5  row'>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='main-style-class main-style-due-date'>
                <h4>
                  <label className='fs-5 fs-15w label-due' htmlFor=''>
                    Priority
                  </label>
                </h4>

                <div
                  className='position-relative text-sm text-dark fw-semibold text-end cursor-pointer'
                  data-kt-element='message-text'
                  onClick={() => {
                    dropdownIndex === 'priority' ? setDropdownIndex('') : setDropdownIndex('priority')
                  }}
                >
                  <i className='fa fa-angle-down px-1 pt-1 fs-4 cursor-pointer d-block float-end'></i>
                  {dropdownIndex === 'priority' && (
                    <div
                      className='top-100 priority-div end-0 position-absolute show menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px show'
                      data-kt-menu='true'
                    >
                      {priorities
                        .filter((priority: any) => priority.value !== singleTask?.priority)
                        .map((option: any, i: number) => {
                          return (
                            <div key={i} className='menu-item px-3 my-0'>
                              <div className='menu-link px-3 py-2'>
                                <span
                                  className='menu-title'
                                  onClick={() => {
                                    const data = {
                                      _id: taskId,
                                      field: 'priority',
                                      value: option.value,
                                      name: option.label,
                                    }
                                    handleUpdateTask(data)
                                    setDropdownIndex('')
                                  }}
                                >
                                  {option.value === 'low' ? (
                                    <div className='pro-main-div'>
                                      <div
                                        className='progress'
                                        style={{ height: '3px', width: '285%' }}
                                      >
                                        <div
                                          className='progress-bar bg-success'
                                          role='progressbar'
                                          style={{ width: '25%' }}
                                        ></div>
                                      </div>
                                      <span className='me-3'>{option.label}</span>{' '}
                                    </div>
                                  ) : option.value === 'medium' ? (
                                    <div className='pro-main-div'>
                                      <div
                                        className='progress'
                                        style={{ height: '3px', width: '285%' }}
                                      >
                                        <div
                                          className='progress-bar bg-warning'
                                          role='progressbar'
                                          style={{ width: '50%' }}
                                        ></div>
                                      </div>
                                      <span className='me-3'>{option.label}</span>{' '}
                                    </div>
                                  ) : option.value === 'high' ? (
                                    <div className='pro-main-div'>
                                      <div
                                        className='progress'
                                        style={{ height: '3px', width: '285%' }}
                                      >
                                        <div
                                          className='progress-bar bg-danger'
                                          role='progressbar'
                                          style={{ width: '100%' }}
                                        ></div>
                                      </div>
                                      <span className='me-3'>{option.label}</span>{' '}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  )}

                  {singleTask?.priority === 'low' ? (
                    <div className='pro-main-div'>
                      <div className='progress' style={{ height: '3px', width: '285%' }}>
                        <div
                          className='progress-bar bg-success'
                          role='progressbar'
                          style={{ width: '25%' }}
                        ></div>
                      </div>
                      <span className='me-3'>Low</span>{' '}
                    </div>
                  ) : singleTask?.priority === 'medium' ? (
                    <div className='pro-main-div'>
                      <div className='progress' style={{ height: '3px', width: '285%' }}>
                        <div
                          className='progress-bar bg-warning'
                          role='progressbar'
                          style={{ width: '50%' }}
                        ></div>
                      </div>
                      <span className='me-3'>Medium</span>{' '}
                    </div>
                  ) : singleTask?.priority === 'high' ? (
                    <div className='pro-main-div'>
                      <div className='progress' style={{ height: '3px', width: '285%' }}>
                        <div
                          className='progress-bar bg-danger'
                          role='progressbar'
                          style={{ width: '100%' }}
                        ></div>
                      </div>
                      <span className='me-3'>High</span>{' '}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='main-style-class main-style-due-date d-flex flex-column'>
                <label className='fs-5 label-due' htmlFor='dueDate' style={{ maxHeight: '20px' }}>
                  Due on <i className='fa fa-angle-down due-on' aria-hidden='true'></i>
                  <DatePicker
                    id='dueDate'
                    name='dueDate'
                    minDate={new Date()}
                    dateFormat='yyyy-MM-dd'
                    selected={(singleTask?.dueDate && new Date(singleTask?.dueDate)) || null}
                    className='hide'
                    onChange={(date: Date) => {
                      let data = {
                        _id: taskId,
                        field: 'dueDate',
                        value: moment(date).format('yyyy-MM-DD'),
                      }
                      handleUpdateTask(data)
                    }}
                    shouldCloseOnSelect={true}
                    closeOnScroll={true}
                  />
                </label>
                <div className='mt-2 color-light-grey'>
                  {singleTask?.dueDate ? moment(singleTask?.dueDate).format('ddd, MMM DD') : ''}
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='task-status label-style main-style-class'>
                <label className='fs-5 label-due' htmlFor=''>
                  Labels
                </label>

                <Select
                  className='multi-select-container'
                  name='label'
                  value={taskLabels.filter(
                    (option: any) => singleTask?.label?.indexOf(option.value) >= 0
                  )}
                  styles={{
                    valueContainer: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '60px',
                      overflow: 'scroll',
                      fontWeight:'100',
                      fontSize:'14px',
                    }),
                  }}
                  onChange={(e: any) => {
                    let data = {
                      _id: taskId,
                      field: 'label',
                      value: e.map((selected: any) => selected.value),
                    }
                    handleUpdateTask(data)
                  }}
                  placeholder='Select labels'
                  options={taskLabels}
                  isMulti={true}
                  isClearable={false}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className=' row'>
            <div className=' col-lg-4 col-md-6 col-sm-12'>
              <h4>
                {' '}
                <label className='fs-5 fs-15w label-due' htmlFor=''>
                  Assigned To
                </label>
              </h4>

              <Select
                className='multi-select-container'
                styles={{
                  valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '60px',
                    overflow: 'scroll',
                    fontWeight:'100',
                    fontSize:'14px',
                  }),
                }}
                name='assignee'
                value={currentSpaceUsers
                  ?.map((user: any) => {
                    return {
                      value: user?._id,
                      label: user?.fullName,
                    }
                  })
                  ?.filter((option: any) => assignees?.includes(option.value))}
                onChange={(e: any) => {
                  let data = {
                    _id: taskId,
                    field: 'assignee',
                    value: e.map((selected: any) => selected.value),
                  }
                  setAssignees(data.value)
                  handleUpdateTask(data)
                }}
                placeholder='Select Assignees'
                options={currentSpaceUsers.map((user: any) => {
                  return {
                    value: user?._id,
                    label: user?.fullName,
                  }
                })}
                isMulti={true}
                isClearable={false}
              />
            </div>

            {singleTask?.attachments?.length > 0 && (
              <div className='d-flex flex-column attachments_popup col-lg-6 col-sm-12 mb-3'>
                <h4>
                  {' '}
                  <label className='fs-5 fs-15w' htmlFor=''>
                    Attachments
                  </label>
                </h4>

                <Attachment
                  attachments={singleTask?.attachments}
                  loadAttachments={taskActions.loadAttachments}
                  attachmentType='listing'
                  id={taskId}
                  itemType='task'
                />
              </div>
            )}
          </div>
        </div>

        <div className='px-7 col-12'>
          {showAttachment && (
            <GCDropZone
              loadAttachments={taskActions.loadAttachments}
              attachments={singleTask?.attachments}
            />
          )}
        </div>
      </div>

      <div className='px-7 pb-10 task_pop_up_activity'>
        <div>
          <h6 className='mb-6 mt-6'>
            {' '}
            <label className='fs-5' htmlFor=''>
              Activity
            </label>
          </h6>
        </div>
        <Activity itemType='Task' activityItemId={taskId} isPopupActivity={true} />
      </div>
    </Modal>
  )
}

export { TaskPopup }
