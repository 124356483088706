import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {useDispatch} from 'react-redux'
import * as actions from '../redux/CredentialsAction'
import {InputField} from '../../../Common/fields/InputField'
import {decryptPassword} from '../../../../_metronic/helpers'
import {Login} from '../../auth/components/Login'
interface Props {
  show: boolean
  handleClose: () => void
  setFetchRecords: any
  credentials: any
}

const validationSchema = Yup.object().shape({
  title: Yup.string().min(5, 'Title must be at least 5 characters').required('Title is required'),
  siteURL: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter correct url!'
  ),
  userName: Yup.string().required('Username/Email is required'),
  password: Yup.string().required('Password is required'),
})
const initialValues = {title: '', siteURL: '', userName: '', password: '', notes: ''}

export const CreateCredentialsModel: FC<Props> = ({
  show,
  handleClose,
  setFetchRecords,
  credentials,
}) => {
  const dispatch: any = useDispatch()
  const [error, setError] = useState<string>('')
  const [showPassword, setShowPassword] = useState<Boolean>(false)
  const [credentialsObj, setCredentialsObj] = useState<any>(initialValues)
  const [isVerified, setIsVerified] = useState<boolean>(false)
  useEffect(() => {
    if (credentials?._id) {
      const credentialsData = JSON.parse(JSON.stringify(credentials))
      credentialsData['password'] = decryptPassword(credentialsData?.password)
      setCredentialsObj(credentialsData)
    } else {
      setIsVerified(true)
    }
    return () => {
      setCredentialsObj(initialValues)
      setIsVerified(false)
    }
  }, [credentials])

  const onSubmit = (credentials: {
    _id?: string
    title: string
    siteURL: string
    userName: string
    password: string
    notes: string
  }) => {
    if (!credentials._id) {
      dispatch(actions.createClients(credentials))
        .then((res: any) => {
          setFetchRecords((prev: boolean) => !prev)
          handleClose()
        })
        .catch((error: any) => setError(error.response.data.message))
    } else {
      const {_id, title, siteURL, userName, password, notes} = credentials
      dispatch(actions.updateClients({_id, title, siteURL, userName, password, notes}))
        .then((res: any) => {
          setFetchRecords((prev: boolean) => !prev)
          handleClose()
        })
        .catch((error: any) => setError(error.response.data.message))
    }
  }
  return (
    <Modal
      id='kt_modal_delete_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-700px h-auto'
      show={show}
      onHide={handleClose}
    >
      {!isVerified && (
        <div style={{padding: '30px'}}>
          <Login credentialModule={true} setIsVerified={setIsVerified} />
        </div>
      )}
      {isVerified && (
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>{!credentials?._id ? 'Create' : 'Edit'} Credentials</h2>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            {error && (
              <div className='mx-lg-15 alert alert-success' role='alert'>
                {error}
              </div>
            )}
            <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
              <div className='flex-row-fluid px-lg-15'>
                <Formik
                  initialValues={credentialsObj}
                  validationSchema={validationSchema}
                  enableReinitialize
                  onSubmit={onSubmit}
                >
                  {({errors, touched, values, setFieldValue}) => (
                    <Form>
                      <div className='pt-2'>
                        <label
                          className='d-flex align-items-center fs-5 fw-bold mb-2'
                          htmlFor='title'
                        >
                          <span className='required'>Title</span>
                        </label>
                        <Field
                          className='form-control form-control-lg form-control-solid'
                          name='title'
                          placeholder='Enter site name'
                        />
                        <div className='text-danger'>
                          {' '}
                          <ErrorMessage className='text-danger' name='title' />
                        </div>
                      </div>
                      <div className='pt-2'>
                        <label
                          className='d-flex align-items-center fs-5 fw-bold mb-2'
                          htmlFor='siteURL'
                        >
                          <span>URL</span>
                        </label>
                        <Field
                          className='form-control form-control-lg form-control-solid'
                          name='siteURL'
                          placeholder='Enter url'
                        />
                        <div className='text-danger'>
                          {' '}
                          <ErrorMessage className='text-danger' name='siteURL' />
                        </div>
                      </div>
                      <div className='pt-2'>
                        <label
                          className='d-flex align-items-center fs-5 fw-bold mb-2'
                          htmlFor='userName'
                        >
                          <span className='required'>Username / Email</span>
                        </label>
                        <Field
                          className='form-control form-control-lg form-control-solid'
                          name='userName'
                          placeholder='Enter Username / Email'
                        />
                        <div className='text-danger'>
                          {' '}
                          <ErrorMessage className='text-danger' name='userName' />
                        </div>
                      </div>

                      <div className='pt-2'>
                        <label
                          className='d-flex align-items-center fs-5 fw-bold mb-2'
                          htmlFor='password'
                        >
                          <span className='required'>Password</span>
                        </label>

                        <div className='col-lg-12 fv-row position-relative'>
                          <i
                            title={showPassword ? 'Hide Password' : 'Show Password'}
                            onClick={() => setShowPassword(!showPassword)}
                            className={`bi position-absolute cursor-pointer ${
                              showPassword ? 'bi-eye' : 'bi-eye-slash'
                            }`}
                            style={{right: '20px', top: '16px'}}
                          ></i>

                          <Field
                            className='form-control form-control-lg form-control-solid bg-light-grey'
                            // className='form-control form-control-lg form-control-solid'
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Enter password'
                          />
                          <div className='text-danger'>
                            {' '}
                            <ErrorMessage className='text-danger' name='password' />
                          </div>
                        </div>
                      </div>
                      <div className='pt-2'>
                        <InputField
                          label='Add notes'
                          fieldTye='textarea'
                          fieldName='notes'
                          fieldPlaceholder='Notes'
                        />
                      </div>

                      <div className='text-end'>
                        <button
                          title='Cancel'
                          type='button'
                          className='btn btn-sm cancel_button me-3 mt-3'
                          onClick={() => handleClose()}
                        >
                          Cancel
                        </button>
                        <button className='btn btn-sm btn-blue fs-7 mt-3' type='submit'>
                          Submit
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}
