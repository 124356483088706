import React from 'react'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import {NumericFormat} from 'react-number-format'

export const FullLoanSettlementForm: React.FC = () => {
  const {values, setFieldValue}: any = useFormikContext()
  return (
    <>
      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2 required'>
          <span className=''>Appointment Date (Nextbridge Pvt Ltd) </span>
        </label>

        <Field
          className='form-control form-control-lg form-control-solid'
          name='fullLoanSettlement.appointmentDate'
          placeholder='Appointment Date'
          type='date'
        />

        <div className='text-danger'>
          <ErrorMessage name='fullLoanSettlement.appointmentDate' />
        </div>
      </div>
      <div className='col-md-3 fv-row mb-5'>
        <label className='fs-6 fw-bold mb-2 required'>
          <span>Principal Amount Of Loan</span>
        </label>

        <NumericFormat
          placeholder='Principal Amount Of Loan'
          className='form-control form-control-lg form-control-solid'
          name='fullLoanSettlement.principalAmountOfLoan'
          value={values?.[values?.loanType]?.['principalAmountOfLoan']}
          onChange={(e: any) => {
            setFieldValue('fullLoanSettlement.principalAmountOfLoan', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='fullLoanSettlement.principalAmountOfLoan' />
        </div>
      </div>

      <div className='col-md-3 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold required mb-2'>
          Total No. of Installments
        </label>

        <NumericFormat
          placeholder='Total No. of Installments'
          className='form-control form-control-lg form-control-solid'
          name='fullLoanSettlement.totalNumberOfInstallments'
          value={values?.[values?.loanType]?.['totalNumberOfInstallments']}
          onChange={(e: any) => {
            setFieldValue('fullLoanSettlement.totalNumberOfInstallments', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='fullLoanSettlement.totalNumberOfInstallments' />
        </div>
      </div>

      <div className='col-md-3 fv-row mb-5'>
        <label className='fs-6 fw-bold fw-bold form-label mb-2 required'>Amount Paid</label>
        <div className='row fv-row'>
          <div className='col-12'>
            <NumericFormat
              placeholder='Amount Paid'
              className='form-control form-control-lg form-control-solid'
              name='fullLoanSettlement.amountPaid'
              value={values?.[values?.loanType]?.['amountPaid']}
              onChange={(e: any) => {
                setFieldValue('fullLoanSettlement.amountPaid', +e.target.value)
              }}
            />

            <div className='text-danger'>
              <ErrorMessage name='fullLoanSettlement.amountPaid' />
            </div>
          </div>
        </div>
      </div>

      <div className='col-md-3 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold  mb-2 required'>
          <span className=''>Installments Paid</span>
        </label>

        <NumericFormat
          placeholder='Installments Paid'
          className='form-control form-control-lg form-control-solid'
          name='fullLoanSettlement.installmentsPaid'
          value={values?.[values?.loanType]?.['installmentsPaid']}
          onChange={(e: any) => {
            setFieldValue('fullLoanSettlement.installmentsPaid', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='fullLoanSettlement.installmentsPaid' />
        </div>
      </div>
      <h4 className='mb-6 mt-6'>Current Payment/Loan Settlement Status</h4>

      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2 required'>
          Monthly Installment
        </label>

        <div className='col-12'>
          <NumericFormat
            placeholder='Monthly Installment'
            className='form-control form-control-lg form-control-solid'
            name='fullLoanSettlement.monthlyInstallment'
            value={values?.[values?.loanType]?.['monthlyInstallment']}
            onChange={(e: any) => {
              setFieldValue('fullLoanSettlement.monthlyInstallment', +e.target.value)
            }}
          />

          <div className='text-danger'>
            <ErrorMessage name='fullLoanSettlement.monthlyInstallment' />
          </div>
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2 required'>
          <span className=''>Monthly Installment Left</span>
        </label>

        <NumericFormat
          placeholder='Monthly Installment Left'
          className='form-control form-control-lg form-control-solid'
          name='fullLoanSettlement.remainingInstallments'
          value={values?.[values?.loanType]?.['remainingInstallments']}
          onChange={(e: any) => {
            setFieldValue('fullLoanSettlement.remainingInstallments', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='fullLoanSettlement.remainingInstallments' />
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2 required'>Amount Left</label>

        <div className='col-12'>
          <NumericFormat
            placeholder='Amount Left'
            className='form-control form-control-lg form-control-solid'
            name='fullLoanSettlement.amountLeft'
            value={values?.[values?.loanType]?.['amountLeft']}
            onChange={(e: any) => {
              setFieldValue('fullLoanSettlement.amountLeft', +e.target.value)
            }}
          />

          <div className='text-danger'>
            <ErrorMessage name='fullLoanSettlement.amountLeft' />
          </div>
        </div>
      </div>

      <div className='col-md-6 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2 required'>
          <span className=''>Will Make Payment Via</span>
        </label>

        <Field
          className='form-control form-control-lg  form-control-solid'
          as='select'
          name='fullLoanSettlement.willMakePaymentVia'
        >
          <option value=''>Select Payment Method</option>
          <option value='Salary'>Salary</option>
          <option value='IBFT'>IBFT</option>
          <option value='Check'>Check</option>
          <option value='Pay Order'>Pay Order</option>
        </Field>

        <div className='text-danger'>
          <ErrorMessage name='fullLoanSettlement.willMakePaymentVia' />
        </div>
      </div>
      <h5>Finance Verification</h5>
      <div className='col-sm-12 form-check ms-3 mb-5'>
        <input
          className='form-check-input smaller-form-input'
          type='checkbox'
          id='is-correct'
          checked={!!values?.fullLoanSettlement?.isCorrect}
          onChange={(e) => {
            setFieldValue('fullLoanSettlement.isCorrect', e.target.checked)
            if (e.target.checked) {
              setFieldValue(
                'fullLoanSettlement.monthlyInstallmentOffice',
                values?.fullLoanSettlement?.monthlyInstallment
              )
              setFieldValue(
                'fullLoanSettlement.remainingInstallmentsOffice',
                values?.fullLoanSettlement?.remainingInstallments
              )
              setFieldValue(
                'fullLoanSettlement.amountLeftOffice',
                values?.fullLoanSettlement?.amountLeft
              )
            } else {
              setFieldValue('fullLoanSettlement.monthlyInstallmentOffice', '')
              setFieldValue('fullLoanSettlement.remainingInstallmentsOffice', '')
              setFieldValue('fullLoanSettlement.amountLeftOffice', '')
            }
          }}
        />
        <label className='form-check-label' htmlFor='is-correct'>
          The Information mention on above form is true, if not then mention the details.
        </label>
      </div>
      <div className='col-md-4 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2'>Monthly Installment</label>

        <div className='col-12'>
          <NumericFormat
            placeholder='Monthly Installment'
            className='form-control form-control-lg form-control-solid'
            name='fullLoanSettlement.monthlyInstallmentOffice'
            readOnly={!!values?.fullLoanSettlement?.isCorrect}
            value={values?.[values?.loanType]?.['monthlyInstallmentOffice']}
            onChange={(e: any) => {
              setFieldValue('fullLoanSettlement.monthlyInstallmentOffice', +e.target.value)
            }}
          />

          <div className='text-danger'>
            <ErrorMessage name='fullLoanSettlement.monthlyInstallmentOffice' />
          </div>
        </div>
      </div>

      <div className='col-md-4 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
          <span className=''>Monthly Installment Left</span>
        </label>

        <NumericFormat
          placeholder='Monthly Installment Left'
          className='form-control form-control-lg form-control-solid'
          name='fullLoanSettlement.remainingInstallmentsOffice'
          readOnly={!!values?.fullLoanSettlement?.isCorrect}
          value={values?.[values?.loanType]?.['remainingInstallmentsOffice']}
          onChange={(e: any) => {
            setFieldValue('fullLoanSettlement.remainingInstallmentsOffice', +e.target.value)
          }}
        />

        <div className='text-danger'>
          <ErrorMessage name='fullLoanSettlement.remainingInstallments' />
        </div>
      </div>

      <div className='col-md-4 fv-row mb-5'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-2'>Amount Left</label>

        <div className='col-12'>
          <NumericFormat
            placeholder='Amount Left'
            className='form-control form-control-lg form-control-solid'
            name='fullLoanSettlement.amountLeftOffice'
            readOnly={!!values?.fullLoanSettlement?.isCorrect}
            value={values?.[values?.loanType]?.['amountLeftOffice']}
            onChange={(e: any) => {
              setFieldValue('fullLoanSettlement.amountLeftOffice', +e.target.value)
            }}
          />

          <div className='text-danger'>
            <ErrorMessage name='fullLoanSettlement.amountLeftOffice' />
          </div>
        </div>
      </div>

      <div className='col-sm-12 form-check ms-3 mb-5'>
        <input
          className='form-check-input smaller-form-input'
          type='checkbox'
          id='is-correct-2'
          checked={!!values?.fullLoanSettlement?.isFeasibleToMonthlySalary}
          onChange={(e) => {
            setFieldValue('fullLoanSettlement.isFeasibleToMonthlySalary', e.target.checked)
          }}
        />
        <label className='form-check-label' htmlFor='is-correct-2'>
          The above request is feasible w.r.t member's monthly salary.
        </label>
      </div>
    </>
  )
}
